import { useEffect, useState } from "react";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchByStDt, fetchFilterByGod, fetchGodNames, fetchTemplesList, retrieveGodNames, retrieveTemples } from "../../../../redux/actions/acions";
import { getGodNames, getGodNamesLoading, getGodNamesError, getTemplesList, getTemplesListLoading, getTemplesListError, getFavouritesList, getFavouritesLoading, getFavouritesError, getFilterByGodList, getFilterByGodLoading, getFilterByGodError, getFilterByStDtLoading, getFilterByStDtList, getFilterByStDtError, getByStDtList, getByStDtLoading, getByStDtError } from "../../../../redux/selectors/selectors";
import { Card, message, Select } from 'antd';
import Error from '../error';
import "../../search.css"
import statesData from '../../states.json';
import { useTranslation } from "react-i18next";
import TemplesPage from "../../../common/TemplesPage";
import { useLocation } from "react-router-dom";

function TemplesList() {
    const dispatch = useDispatch();
    const [filterId, setFilterId] = useState(localStorage.getItem('filterId'));
    const localEnv = false;
    const records_per_page = 12;
    const [did, setDid] = useState(0);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [state, setState] = useState(null);
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');

    // Replace last_rec and god_last_rec with state variables
    const [lastRec, setLastRec] = useState(0);
    const [godLastRec, setGodLastRec] = useState(0);
    const location = useLocation();
    const selectedAvatar = location.state && location.state.selectedAvatar ? location.state.selectedAvatar : null;
    const id = Number(localStorage.getItem('filterId'));
    useEffect(() => {
        if (selectedAvatar) {
            updateFilterId(selectedAvatar);
        }
    }, [selectedAvatar]);

    function updateFilterId(id) {
        localStorage.setItem('filterId', id);
        setFilterId(id);
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };

    const { fList, fLoading, templesList, templesListLoading, godCtgryList, godCtgryListLoading, sList, sLoading, fstList } = useSelector(state => ({
        templesList: getTemplesList(state),
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        sList: getFilterByGodList(state),
        sLoading: getFilterByGodLoading(state),
        sError: getFilterByGodError(state),
        stList: getFilterByStDtList(state),
        stLoading: getFilterByStDtLoading(state),
        stError: getFilterByStDtError(state),
        fstList: getByStDtList(state),
        fstLoading: getByStDtLoading(state),
        fstError: getByStDtError(state),
    }));

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage));
    }, [storedLanguage]);



    function filterTemplesList(gDid) {
        setDid(gDid);
        if (gDid === filterId) {
            setFilterId(null);
            dispatch(retrieveTemples(storedLanguage, lastRec));
        } else {
            setFilterId(gDid);
            updateFilterId(gDid);
            dispatch(fetchFilterByGod(storedLanguage, gDid, lastRec));
        }
    }


    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageTemples, setCurrentPageTemples] = useState([]);

    useEffect(() => {
        if (filterId === null) {
            dispatch(retrieveTemples(storedLanguage, lastRec));
        } else {
            dispatch(fetchFilterByGod(storedLanguage, filterId, godLastRec));
        }
    }, [storedLanguage, filterId]);

    useEffect(() => {
        if (id !== 0) {
            dispatch(fetchFilterByGod(storedLanguage, id, 0)); // Fetch on page load
        } else {
            dispatch(retrieveTemples(storedLanguage, 0)); // Default fetch
        }
    }, [id, dispatch]);


    useEffect(() => {
        if (!templesListLoading && templesList) {
            setCurrentPageTemples([...currentPageTemples, ...templesList]);
        }
    }, [templesListLoading]);

    const nextTemplesList = async () => {
        setIsLoading(true);
    
        if (!id) {  // id is either 0 or null
            setLastRec(prevLastRec => {
                const newLastRec = prevLastRec + records_per_page;
                dispatch(retrieveTemples(storedLanguage, newLastRec));
                return newLastRec;
            });
        } else {
            setGodLastRec(prevGodLastRec => {
                const newGodLastRec = prevGodLastRec + records_per_page;
                dispatch(fetchFilterByGod(storedLanguage, filterId, newGodLastRec));
                return newGodLastRec;
            });
        }
    
        setIsLoading(false);
        window.scrollTo(0, 0);
    };
    
    const prevTemplesList = () => {
        if (!id) { // When id is 0 or null, use normal temple fetch
            setLastRec(prevLastRec => {
                const newLastRec = Math.max(0, prevLastRec - records_per_page);
                dispatch(retrieveTemples(storedLanguage, newLastRec));
                return newLastRec;
            });
        } else {
            setGodLastRec(prevGodLastRec => {
                const newGodLastRec = Math.max(0, prevGodLastRec - records_per_page);
                dispatch(fetchFilterByGod(storedLanguage, filterId, newGodLastRec));
                return newGodLastRec;
            });
        }
    };
    

    useEffect(() => {
        dispatch(localEnv ? fetchTemplesList(failure) : retrieveTemples(storedLanguage, lastRec));
    }, [storedLanguage]);

    const [filled, setFilled] = useState({});

    const godList = selectedState ? fstList : sList;

    return (
        <>
            {contextHolder}
            {filterId === null ? (templesListLoading || templesList === null) ? (<Error />) :
                (
                    <TemplesPage
                        title={t("label_fam_temples")}
                        description={t("label_these_famous")}
                        tList={templesList}
                        tLoading={templesListLoading}
                        setFilterId={setFilterId}
                        godCtgryList={godCtgryList}
                        filterTemplesList={filterTemplesList}
                        godCtgryListLoading={godCtgryListLoading}
                        pageTitle={t("label_fam_temples")}
                        filterId={filterId}
                        linkPage={'/templeCtgry'}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={lastRec}
                        latit={templesList.latit}
                        longi={templesList.longi}
                        templesList={templesList}
                        godLastRec={godLastRec}
                    />
                ) : sLoading ? (<Error />) : (
                    <TemplesPage
                        title={t("label_fam_temples")}
                        description={t("label_these_famous")}
                        tList={sList}
                        tLoading={sLoading}
                        setFilterId={setFilterId}
                        godCtgryList={godCtgryList}
                        filterTemplesList={filterTemplesList}
                        godCtgryListLoading={godCtgryListLoading}
                        pageTitle={'label_fam_temples'}
                        filterId={filterId}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={lastRec}
                        templesList={godList}
                        godLastRec={godLastRec}
                    />
                )}
        </>
    );
}

export default TemplesList;
