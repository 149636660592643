import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Result, Row, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchRegionalNotifi } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';

const Events = () => {
    const { t } = useTranslation();
const storedLanguage = localStorage.getItem('lng');

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('fail', t('label_horodetails_failure'))
        // messageApi.error(msg); // You can use 'error' directly
    };

    
    const shareResLink = (title, txt, rtype, rid, lang) => {
        const web_url = 'https://www.templeswiki.com/share?id=' + rid + '&type=' + rtype + '&lang=' + lang;
		if (!window.flutter_inappwebview) {
            navigator.share({
                title: title,
                text: txt,
                url: web_url
            })
            .then(() => console.warn("Share successful."))
            .catch((error) => console.error("Error sharing:", error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error("Web Share API not supported.");
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, web_url);
        }
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getRegionalNotifiList(state),
        rLoading: getRegionalNotifiLoading(state),
        rError: getRegionalNotifiError(state),
    }));


    useEffect(() => {
        dispatch(fetchRegionalNotifi(storedLanguage, 0));
    }, [storedLanguage, dispatch]);

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = ''
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                if (allLines[i].includes('<B>')) {
                    finalTxt = finalTxt + (allLines[i] + '</B>') + '\n';
                } else {
                    finalTxt = finalTxt + allLines[i] + '\n';
                }
            }
            return finalTxt;
        } else {
            return ''
        }
    }

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_events')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                    <span className="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to="/">
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                        <span className="bc-active-link" onClick={handleGoBack}>{t("label_events_near_you")}</span>
                    </span>
                </div>
            <div class="bg-details-highlight" >
            <div class=" details-headertxt-singleLine container">
                    <div class="header_txt"> {t("label_events_near_you")}</div>                  
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className="container mt-3">
                    {rLoading ? (
                        <Error />
                    ) : Array.isArray(rList) && rList.length > 0 ? (
                        rList.map((notificationList) => (
                            <div key={notificationList.nid} className="tiles-designs-events">
                                <div className="d-flex flex-space-between">
                                    <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                                        <div className="f1 events-block-link-title">
                                            <i className="fa-solid fa-calendar-week mr-3"></i>
                                            <span className="f1">{notificationList.subject}</span>
                                        </div>
                                    </Link>
                                    <div className="d-flex ml-3">
                                        <a
                                           className="detail-but"
                                            onClick={() => shareResLink("Know " + notificationList.subject,
                                                            "Know " + notificationList.subject,
                                                            'notification', notificationList.nid, storedLanguage
                                            )}
                                        >
                                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                                        </a>
                                    </div>
                                </div>
                                <span className="f1 events-block-link-value">{formatPlainText(notificationList.txt || t('label_nodata_found'))}</span>
                            </div>
                        ))
                    ) : (
                        <Result
                            status="404"
                            title={t("label_nodata_found")}
                            subTitle={t("label_no_events_msg")}
                        />
                    )}
                </div>

                <Footer />
            </div>
        </div >
    );
};

export default Events;
