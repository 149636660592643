import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Spin, Typography, message, } from 'antd';
import { EditOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { editLang, fetchNotifications, fetchOAuthValidUserLogin, fetchRefreshToken, fetchState, fetchTempleFavourites, fetchValidUserLogin, postPasswordRequest, postRegisterUserRequest, postUserActivity, removeNotification } from '../../../../redux/actions/acions';
import { useDispatch, useSelector } from 'react-redux';
import { getStateError, getStateList, getStateLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import { ToastContainer } from 'react-toastify';
import Error from '../error';
import AP_LANGUAGES from "../../../common/appLangs"
import { getServerImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg'
import FooterMobile from '../footer/footerMobile';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import useScreenWidth from './useScreenWidth';

function Login1({ showLoginForm1, setShowLoginForm1 }) {
    const [token, setToken] = useState('');
    const [name, setName] = useState('')
    const { Option } = Select;
    const showDrawer = () => {
        setOpen(true);
    };

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const storedLanguage = localStorage.getItem('lng');
    const [refresh, setRefresh] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const googleLoginRef = useRef(null);
    //const firstLetter = getName.charAt(0);

    const phoneRegex = /^[0-9]{1,10}$/;

    const validatePhoneNumber = (_, value) => {
        if (value && !phoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
        }
        return Promise.resolve();
        if (value && !phoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
        }
        return Promise.resolve();
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validateEmail = (_, value) => {
        if (value && !emailRegex.test(value)) {
            return Promise.reject(new Error(t("label_invalid_email")));
        }
        return Promise.resolve();
    };
    const screenWidth = useScreenWidth();
    const isMobile = screenWidth <= 768;

    const oAuthLoginOnFinish = async (oauthResp) => {
        let payload = {
            "email": oauthResp.email,
            "phone": oauthResp.email,
            "fullname": oauthResp.name,
            "scode": oauthResp.jti
        }
        dispatch(fetchOAuthValidUserLogin(payload, success, failure), () => success());
    }

    const [selectedRole, setSelectedRole] = useState(null);
    const success = async () => {
        showToast('success', t('label_login_sucess'));
        setIsLoginModalOpen(false);
        setIsLoading(true);

        await new Promise(resolve => setTimeout(resolve, 2000));

        navigate(selectedRole === 'AD' ? '/quicklinks' : selectedRole === 'TA' ? '/templeDashboard' : '/');
        setIsLoading(false);
        setShowLoginForm1(false);
        window.location.reload()
        setTimeout(() => {
            setShowWelcome(true);
        }, 30);
        localStorage.setItem('showWelcome', true);
    };

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handleFormCancel(); // Close popup when clicking outside
            }
        };

        if (showLoginForm1) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showLoginForm1]);

    // On component mount, check if showWelcome is stored in localStorage
    useEffect(() => {
        const storedShowWelcome = localStorage.getItem('showWelcome') === 'true';

        if (storedShowWelcome) {
            setShowWelcome(true);  // Show the welcome message
            localStorage.removeItem('showWelcome'); // Clean up after showing the welcome message

            // Automatically hide the welcome message after 3 seconds
            setTimeout(() => {
                setShowWelcome(false);
            }, 3000);
        }
    }, []);


    const failure = (msg) => {
        setLoginError(msg); // Set the error message using the `setLoginError` state function
        showToast('error', msg);
    };


    const key = 'updatable';
    const openMessage = () => {

        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });
        setTimeout(() => {
            showToast('success', t('label_register_sucess'));
        }, 1000);
    };

    const { sDetails, sLoading, sError } = useSelector(state => ({
        sDetails: getStateList(state),
        sLoading: getStateLoading(state),
        sError: getStateError(state)
    }));
    useEffect(() => {
        if (getToken) {
            dispatch(fetchState());
        }
    }, [])


    const forgetSuccess = () => {
        showToast('success', 'Check your mail to change password!');
        // messageApi.open({
        //   type: 'success',
        //   content: 'Check your mail to change password',
        // });
    };
    const forgetFailure = (msg) => {
        showToast('failure', msg);
        // messageApi.open({
        //   type: 'failure',
        //   content: msg,
        // });
    };

    const onRegister = (values) => {
        const { confirmPassword, ...formData } = values;
        dispatch(postRegisterUserRequest(formData, openMessage, failure));
    };


    const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);

    const forgetOnFinish = (values) => {
        const data = {
            "email": values.email
        }
        dispatch(postPasswordRequest(data, forgetSuccess, forgetFailure));

    };

    const handlePredefinedLogin = (email, password) => {
        // Set predefined values
        form.setFieldsValue({
            username: email,
            password: password
        });
        // Automatically submit the form
        form.submit();
    };

    const [form] = Form.useForm();

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const dispatch = useDispatch();


    const loginOnFinish = async (values) => {
        dispatch(fetchValidUserLogin(values.username, values.password, success, failure), () => success());
    }

    const handleRoleClick = (roleEmail, rolePassword, role) => {
        setSelectedRole(role);
        form.setFieldsValue({
            username: roleEmail,
            password: rolePassword,
        });
        form.submit();
    };

    const loginUsingOAuth = (roleEmail) => {
        console.log('OAuth Login Clicked = ', roleEmail);
        window.flutter_inappwebview.callHandler('loginUsingOAuth', roleEmail);
    };

    const getToken = Boolean(localStorage.getItem('token'));

    // const [visible, setVisible] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        // Close the welcome message after 30 seconds
        const closeWelcomeTimeout = setTimeout(() => {
            setShowWelcome(false);
        }, 3000);

        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(closeWelcomeTimeout);
    }, [showWelcome]);

    const [visible, setVisible] = useState(true); // For popover visibility

    const [activeForm, setActiveForm] = useState('login'); // To switch between login, register, and forget

    if (activeForm === 'register') {
        const location = useLocation();
        const pageName = location.pathname;
        const data = {
            page: pageName,
            action: 'Click',
            message: `Visited ${pageName}`,
        };

        dispatch(postUserActivity(data));
    }

    const handleVisibleChange = (visible) => {
        setVisible(visible);
    };

    const handleFormSwitch = (formType) => {
        setActiveForm(formType);
    };

    const handleFormCancel = () => {
        setShowLoginForm1(false);
        setActiveForm('login');
    };

    const [isLoginDisabled, setIsLoginDisabled] = useState(true);

    const handleValuesChange = () => {
        const hasErrors = form
            .getFieldsError(["username", "password"])
            .some(({ errors }) => errors.length > 0);
        setIsLoginDisabled(hasErrors);
    };

    const handleGoogleLogin = (response) => {
        console.log('Login Successful..');
        let oauthResp = jwtDecode(response.credential);
        oAuthLoginOnFinish(oauthResp);
    };

    const handleGoogleFailure = () => {
        console.log("Login Failed");
    };

    const [phone, setPhone] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let interval;
        if (otpSent && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [otpSent, timer]);

    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
        setPhone(value);

        // Validate phone number (should be exactly 10 digits)
        if (/^[0-9]{10}$/.test(value)) {
            setIsPhoneValid(true);
        } else {
            setIsPhoneValid(false);
        }
    };

    const handleSendOtp = () => {
        if (isPhoneValid) {
            setOtpSent(true);
            showToast('success', t('label_otp_sucess'));
            setTimer(60);
        }
    };

    const handleEditPhone = () => {
        setOtpSent(false);
        setPhone("");
        setIsPhoneValid(false);
        setTimer(0);
    };

    return (
        <>
            {contextHolder}
            <ToastContainer />

            {showLoginForm1 && (

                <div className={`signin ${showLoginForm1 ? 'show' : 'hide'}`} >
                    {isLoading && (<Error />)}
                    <Row>
                        <Col>
                            <div className="popup-bg" style={{ display: 'flex' }}>
                                <div className="popup-main-login animate__animated animate__fadeInUp">
                                    <div className="popup-main-container-login">
                                        <div className="popup-close" onClick={handleFormCancel}>
                                            <i className="fa-solid fa-xmark"></i>
                                        </div>
                                        <div className="popup-back" onClick={handleFormCancel}>
                                            <i className="fa-solid fa-chevron-left" title={t('label_previous')}></i>
                                        </div>
                                        {activeForm === 'login' && (
                                            <div className="d-block popup-login-main">

                                                <Form
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={loginOnFinish}
                                                    autoComplete="off"
                                                    className="form-box"
                                                    form={form}
                                                    onValuesChange={handleValuesChange}
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t('Login')}</h1>
                                                    <Form.Item
                                                        name="username"
                                                        rules={[
                                                            { required: true, message: t('label_enter_email') },
                                                            { max: 70, message: t("label_email_max_70") }
                                                            // {required: true, message: t('label_invalid_email'), validator: validateEmail },
                                                            // {
                                                            //     pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|in)$/,
                                                            //     message: t('label_invalid_email')
                                                            // }
                                                        ]}
                                                    >
                                                        <Input
                                                            prefix={<MailOutlined />}
                                                            placeholder={t('label_email')}
                                                            allowClear
                                                            minLength={5}
                                                            maxLength={70}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                const errorMessage = !emailRegex.test(value) ? t("label_invalid_email") : "";

                                                                form.setFields([
                                                                    {
                                                                        name: "username",
                                                                        errors: errorMessage ? [errorMessage] : [],
                                                                    }
                                                                ]);
                                                            }}
                                                            onInput={(e) => {
                                                                let value = e.target.value.replace(/[^a-zA-Z0-9@._%+-]/g, '');

                                                                if (value.match(/(\.com|\.org|\.in)/)) {
                                                                    value = value.replace(/(\.com|\.org|\.in).*/, '$1');
                                                                }

                                                                e.target.value = value;
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    {/* Password Input */}
                                                    <Form.Item
                                                        name="password"
                                                        rules={[
                                                            { required: true, message: t('label_enter_pwd') },
                                                            // {
                                                            //     pattern: /^[a-zA-Z][a-zA-Z0-9@#$%^&*()-+=!]{7,29}$/,
                                                            //     message: t('label_invalid_pwd')
                                                            // }
                                                        ]}
                                                    >
                                                        <Input.Password
                                                            prefix={<LockOutlined />}
                                                            placeholder={t('label_pwd')}
                                                            allowClear
                                                            minLength={8}
                                                            maxLength={30}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                if (value.length < 8 || value.length > 30) {
                                                                    form.setFields([
                                                                        {
                                                                            name: "password",
                                                                            errors: [t("label_pwd_length_error")], // Display error under input
                                                                        }
                                                                    ]);
                                                                } else {
                                                                    form.setFields([
                                                                        {
                                                                            name: "password",
                                                                            errors: [], // Clear error if valid
                                                                        }
                                                                    ]);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <a className="login-form-forgot" onClick={() => handleFormSwitch('forget')}>
                                                        {t("label_forgot_pwd")}
                                                    </a>
                                                    {loginError && <p class="login-form-error"> {t("error_msg_27")}</p>}
                                                    <div>
                                                        <div class="custom-input clear-both">
                                                            <Form.Item name="remember" valuePropName="checked">
                                                                <Checkbox class="font_main login-form-checkbox"><span class="login-form-checkbox-text">{t("label_remember")}</span></Checkbox>
                                                            </Form.Item>

                                                        </div>
                                                        <Button type="primary" disabled={isLoginDisabled} htmlType="submit" style={{ marginBottom: "10px" }} className="login-form-button hoverbg">
                                                            {t('Login')}
                                                        </Button>
                                                    </div>

                                                    <Form.Item>

                                                        <Row>

                                                            <p className="reg-btn-login">
                                                                {t("error_msg_73")} <a onClick={() => handleFormSwitch('register')}>{t("error_msg_71")}</a>
                                                            </p>
                                                        </Row>
                                                        <div class="login-or"><span>or Login With</span></div>

                                                        <div class="source-login">
                                                            <aside class="phone-pic source-login-link" onClick={() => handleFormSwitch('phonelogin')}></aside>
                                                            <aside
                                                                className="ml-4 gmail-pic source-login-link"
                                                                onClick={() => {
                                                                    if (googleLoginRef.current) {
                                                                        googleLoginRef.current.click();
                                                                    }
                                                                }}
                                                            ></aside>
                                                            {/* Hidden Google Login Button */}
                                                            <div style={{ display: "none" }}>
                                                                <GoogleLogin
                                                                    onSuccess={handleGoogleLogin}
                                                                    onFailure={handleGoogleFailure}
                                                                    cookiePolicy="single_host_origin"
                                                                    ref={googleLoginRef}
                                                                />
                                                            </div>

                                                        </div>


                                                        {/* <div class="ft-lt w-100">

                                                            <Form.Item name="phone" rules={[{ message: t('label_enter_phone') }]}>
                                                                <Input
                                                                    prefix={<PhoneOutlined />}
                                                                    placeholder={t('label_enter_ph_no')}
                                                                    onInput={(e) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                    }}
                                                                />
                                                                <Button type="primary" htmlType="submit" style={{ marginBottom: "10px" }} className="login-form-button hoverbg mt-2" onClick={() => handleFormSwitch('phonelogin')}>
                                                                    {t('label_send')}
                                                                </Button>
                                                            </Form.Item>
                                                        </div> */}
                                                        {/* <div class="login-or"><span>or</span></div>
                                                        <div class="ft-lt w-100">
                                                            <GoogleLogin width="100%"
                                                                onSuccess={(resp) => {
                                                                    console.log('Login Successfull..');
                                                                    let oauthResp = jwtDecode(resp.credential)
                                                                    oAuthLoginOnFinish(oauthResp);
                                                                }
                                                                }
                                                                onFailure={() => {
                                                                    loginError = "Loging Failed";
                                                                    console.log(loginError);
                                                                }}
                                                                buttonText={t('label_google_login')} cookiePolicy={'single_host_origin'} />
                                                        </div> */}

                                                    </Form.Item>


                                                    <div className="role-buttons" style={{ marginTop: "5px" }}>
                                                        <a className='button_move' style={{ marginRight: "5px" }} onClick={() => handleRoleClick('anitha@vavili.com', 'test@123', 'AD')}>AD</a>
                                                        <a className='button_move' style={{ marginRight: "5px" }} onClick={() => handleRoleClick('sairam@vavili.com', 'test@123', 'TA')}>TA</a>
                                                        <a className='button_move' style={{ marginRight: "5px" }} onClick={() => handleRoleClick('shannu@vavili.com', 'test@123', 'PL')}>PL</a>
                                                        <a className='button_move' onClick={() => handleRoleClick('vanarasi18@gmail.com', 'test@123')}>SU</a>
                                                        <a className='button_move ml-2' onClick={() => loginUsingOAuth('vanarasi18@gmail.com')}>GL</a>
                                                    </div>
                                                </Form>
                                            </div>
                                        )}

                                        {activeForm === 'register' && (
                                            <div className="d-block popup-login-main">
                                                <Form
                                                    form={form}
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={onRegister}
                                                    autoComplete="off"
                                                    className="form-box"
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t("error_msg_71")}</h1>
                                                    <Form.Item name="fullname"
                                                        rules={[{ required: true, message: t("label_enter_name") },
                                                        { max: 60, message: t("label_name_max_60") }]}>
                                                        <Input allowClear prefix={<UserOutlined />} placeholder={t('label_fullname')} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            { required: true, message: t('label_enter_email') },
                                                            { max: 70, message: t("label_email_max_70") }

                                                        ]}
                                                    >
                                                        <Input
                                                            prefix={<MailOutlined />}
                                                            placeholder={t('label_email')}
                                                            allowClear
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                const errorMessage = !emailRegex.test(value) ? t("label_invalid_email") : "";

                                                                form.setFields([
                                                                    {
                                                                        name: "email",
                                                                        errors: errorMessage ? [errorMessage] : [],
                                                                    }
                                                                ]);
                                                            }}
                                                            onInput={(e) => {
                                                                let value = e.target.value.replace(/[^a-zA-Z0-9@._%+-]/g, '');

                                                                if (value.match(/(\.com|\.org|\.in)/)) {
                                                                    value = value.replace(/(\.com|\.org|\.in).*/, '$1');
                                                                }

                                                                e.target.value = value;
                                                            }} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="phone"
                                                        placeholder="phone"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('label_enter_phone'),
                                                            },
                                                            // {
                                                            //     validator: validatePhoneNumber,
                                                            // },
                                                        ]}
                                                    >
                                                        <Input
                                                            prefix={<PhoneOutlined />}
                                                            placeholder={t('label_enter_ph_no')}
                                                            allowClear
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                const phoneRegex = /^[0-9]{10}$/; // Ensure exactly 10 digits

                                                                if (!phoneRegex.test(value)) {
                                                                    form.setFields([
                                                                        {
                                                                            name: "phone",
                                                                            errors: [t("label_phone_length_error")], // Error message if invalid
                                                                        }
                                                                    ]);
                                                                } else {
                                                                    form.setFields([
                                                                        {
                                                                            name: "phone",
                                                                            errors: [], // Clear error if valid
                                                                        }
                                                                    ]);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="scode" rules={[{ required: true, message: t('label_enter_pwd') },

                                                    ]}>
                                                        <Input.Password maxLength={30} minLength={8} prefix={<LockOutlined />} placeholder={t('label_pwd')}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                if (value.length < 8 || value.length > 30) {
                                                                    form.setFields([
                                                                        {
                                                                            name: "password",
                                                                            errors: [t("label_pwd_length_error")], // Display error under input
                                                                        }
                                                                    ]);
                                                                } else {
                                                                    form.setFields([
                                                                        {
                                                                            name: "password",
                                                                            errors: [], // Clear error if valid
                                                                        }
                                                                    ]);
                                                                }
                                                            }}

                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="confirmPassword"
                                                        dependencies={['scode']}
                                                        hasFeedback
                                                        rules={[{ required: true, message: t('label_confirm_pwd') }]}
                                                    >
                                                        <Input.Password
                                                            allowClear
                                                            maxLength={30}
                                                            minLength={8}
                                                            prefix={<LockOutlined />}
                                                            placeholder={t('label_confirm_pwd')}
                                                            onBlur={(e) => {
                                                                const confirmPassword = e.target.value;
                                                                const password = form.getFieldValue('scode');
                                                                if (confirmPassword && password !== confirmPassword) {
                                                                    form.setFields([
                                                                        {
                                                                            name: 'confirmPassword',
                                                                            errors: ['The passwords you entered do not match!'],
                                                                        },
                                                                    ]);
                                                                } else {
                                                                    form.setFields([
                                                                        {
                                                                            name: 'confirmPassword',
                                                                            errors: [],
                                                                        },
                                                                    ]);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className="login-form-button hoverbg" style={{ marginTop: "5px" }}>
                                                            {t('label_sign_up')}
                                                        </Button>
                                                        <p className="reg-btn-login">
                                                            {t("error_msg_a")} <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('login')}>{t('label_login_options')}</a>
                                                        </p>
                                                        <div class="login-or"><span>or</span></div>
                                                        <div class="ft-lt w-100">
                                                            <GoogleLogin width="100%"
                                                                onSuccess={(resp) => {
                                                                    console.log('Login Successfull..');
                                                                    let oauthResp = jwtDecode(resp.credential)
                                                                    oAuthLoginOnFinish(oauthResp);
                                                                }
                                                                }
                                                                onFailure={() => {
                                                                    loginError = "Loging Failed";
                                                                    console.log(loginError);
                                                                }}
                                                                buttonText={t('label_google_login')} cookiePolicy={'single_host_origin'} />
                                                        </div>
                                                        {isLoading && <Spin />}
                                                        {loginError && <p style={{ color: 'red' }}> {t("error_msg_72")}</p>}

                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        )}

                                        {activeForm === 'forget' && (
                                            <div className="d-block popup-login-main">
                                                <Form
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={forgetOnFinish}
                                                    autoComplete="off"
                                                    className="form-box"
                                                    form={form}
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t('label_forget_pwd')}</h1>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[{ required: true, message: t('label_enter_email') },
                                                        { max: 70, message: t("label_email_max_70") }

                                                        ]}
                                                    >
                                                        <Input
                                                            prefix={<MailOutlined />}
                                                            placeholder={t('label_email')}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                const errorMessage = !emailRegex.test(value) ? t("label_invalid_email") : "";

                                                                form.setFields([
                                                                    {
                                                                        name: "email",
                                                                        errors: errorMessage ? [errorMessage] : [],
                                                                    }
                                                                ]);
                                                            }}
                                                            onInput={(e) => {
                                                                let value = e.target.value.replace(/[^a-zA-Z0-9@._%+-]/g, '');

                                                                if (value.match(/(\.com|\.org|\.in)/)) {
                                                                    value = value.replace(/(\.com|\.org|\.in).*/, '$1');
                                                                }

                                                                e.target.value = value;
                                                            }} />
                                                    </Form.Item>
                                                    <Form.Item name="phone" rules={[{ message: t('label_enter_phone') }]}>
                                                        <Input
                                                            prefix={<PhoneOutlined />}
                                                            placeholder={t('label_enter_ph_no')}
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                const phoneRegex = /^[0-9]{10}$/; // Ensure exactly 10 digits

                                                                if (!phoneRegex.test(value)) {
                                                                    form.setFields([
                                                                        {
                                                                            name: "phone",
                                                                            errors: [t("label_phone_length_error")], // Error message if invalid
                                                                        }
                                                                    ]);
                                                                } else {
                                                                    form.setFields([
                                                                        {
                                                                            name: "phone",
                                                                            errors: [], // Clear error if valid
                                                                        }
                                                                    ]);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className="login-form-button hoverbg" style={{ marginTop: "5px" }}>
                                                            {t("label_reset_pwd")}
                                                        </Button>
                                                        <p className="reg-btn-login">
                                                            {t("label_remem_pwd")} Goto  <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('login')}>{t('label_login_options')}</a>
                                                        </p>
                                                        {isLoading && <Spin />}
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        )}
                                        {activeForm === 'phonelogin' && (
                                            <div className="d-block popup-login-main">
                                                <Form
                                                    name="basic"
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValues={{ remember: true }}
                                                    onFinish={forgetOnFinish}
                                                    autoComplete="off"
                                                    className="form-box"
                                                >
                                                    <div className="logo_login"></div>
                                                    <h1 className="login-txt">{t("label_phone_login")}</h1>

                                                    {!otpSent ? (
                                                        // Show phone input initially
                                                        <Form.Item name="phone" rules={[{ message: t("label_enter_phone") }]}>
                                                            <Input
                                                                prefix={<PhoneOutlined />}
                                                                placeholder={t("label_enter_ph_no")}
                                                                value={phone}
                                                                onChange={handlePhoneChange}
                                                            />
                                                        </Form.Item>
                                                    ) : (
                                                        // Show entered phone number with edit icon
                                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                            <PhoneOutlined style={{ marginRight: "8px" }} />
                                                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>{phone}</span>
                                                            <EditOutlined
                                                                onClick={handleEditPhone}
                                                                style={{ marginLeft: "10px", cursor: "pointer", color: "#fb9c09" }}
                                                            />
                                                        </div>
                                                    )}

                                                    {!otpSent && (
                                                        <Button
                                                            type="primary"
                                                            disabled={!isPhoneValid}
                                                            style={{ marginBottom: "10px" }}
                                                            className="login-form-button hoverbg mt-2"
                                                            onClick={handleSendOtp}
                                                        >
                                                            {t("label_send")}
                                                        </Button>
                                                    )}

                                                    {otpSent && (
                                                        <>
                                                            <Form.Item name="otp" rules={[{ message: t("label_enter_otp") }]}>
                                                                <Input
                                                                    prefix={<LockOutlined />}
                                                                    placeholder={t("label_enter_otp1")}
                                                                    onInput={(e) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                                    }}
                                                                />
                                                            </Form.Item>

                                                            <Button type="primary" htmlType="submit" style={{ marginBottom: "10px" }} className="login-form-button hoverbg">
                                                                {t("Login")}
                                                            </Button>

                                                            <Row style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                <Col>
                                                                    {timer === 0 ? (
                                                                        <span className="login-form-forgot" onClick={handleSendOtp} >
                                                                            {t("label_resend_otp")}
                                                                        </span>
                                                                    ) : (
                                                                        null
                                                                    )}
                                                                </Col>

                                                                <Col style={{ textAlign: "right", flexGrow: 1 }}>
                                                                    {timer > 0 && (
                                                                        <span className="login-form-forgot">{t("label_resend_otp_in")} {timer}s</span>
                                                                    )}
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    )}

                                                    <Row>
                                                        <a className="reg-btn-login" onClick={() => handleFormSwitch("login")}>
                                                            {t("label_login_options")}
                                                        </a>
                                                    </Row>
                                                </Form>
                                            </div>
                                        )}
                                    </div>

                                    {/* <div className="popup-main-footer-login">
                                        <a className='button_move' style={
                                            isMobile
                                                ? {
                                                    position: 'fixed',
                                                    bottom: '10%', // Place near the bottom
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                }
                                                : {}
                                        } type="default" onClick={handleFormCancel}>{t('label_cancel')}</a>
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div >
            )
            }
            {
                showWelcome && (
                    <div class="welcomebg">
                        <div class="welcome-image"></div>
                    </div>
                )
            }

        </>
    )
}

export default Login1;