import React, { useEffect, useState } from 'react'

import { Breadcrumb, Modal, Result, Select, Skeleton, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchTempleGroups, fetchTempleSubGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import famousTemples from '../../../../assets/images/famous-temple.jpg'
import { getTempleSubGroupError, getTempleSubGroupList, getTempleSubGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import Images from '../../../common/images';
import showToast from '../../../../utils/showToast';
import defImage from '../../../../assets/images/recentlyviewed.jpeg';
import favourite from '../../../../assets/images/favouritetemples.jpeg';
import map from '../../../../assets/images/maprelated.jpeg';
import AP_LANGUAGES from "../../../common/appLangs"

var last_rec = 0;
const SubCagegeries = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng');
    const { gid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };

    useEffect(() => {
        if (gid < 100) {
            dispatch(fetchTempleSubGroups(storedLanguage, gid, last_rec));
        }
    }, [storedLanguage, gid, dispatch]);

    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };



    const { nList, nLoading } = useSelector((state) => ({
        nList: getTempleSubGroupList(state),
        nLoading: getTempleSubGroupLoading(state),
        nError: getTempleSubGroupError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups('groups', storedLanguage));
    }, [storedLanguage]);

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);

    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', t('label_loginerror'));
    };

    const handleRecentlyViewed = () => {
        navigate('/recentlyViewed')
    };

    const recentlyViewed = [
        { id: 1, title: t("label_fam_temples"), image: famousTemples, link: "/templesList" },
        { id: 2, title: t("lable_recent_view"), image: defImage, link: "/recentlyViewed" },
        { id: 3, title: t("label_fav_temples"), image: favourite, link: "/searchFavourite" },
        { id: 4, title: t("label_temples_by_map"), image: map, link: "/nearby" }
    ];

    return (
        <div class="mainsec temple2-bg   ">
            <div className='breadcrum '>
                <div className='breadcrum-block'>
                    <div class="d-flex">
                        <Link to='/' className='search-back'><i class="fa-solid fa-angle-left"></i></Link>
                        <Breadcrumb
                            items={[
                                {

                                    title: <Link to='/'><span style={{ color: 'white' }}>Home</span></Link>
                                },
                                {
                                    title: (
                                        <>

                                            <span style={{ color: 'white' }}>{("label_temples_list")}</span>
                                        </>
                                    ),
                                },

                            ]}
                        />
                    </div>
                    <div className='Ind'>
                        <Typography.Text style={{ color: 'white' }}>Ind -  {selectedLanguage}</Typography.Text>
                        <span className="color-yellow" onClick={() => setDialogVisible(true)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                        </span>
                    </div>
                </div>
                <Modal
                    title={t("label_select_language")}
                    visible={dialogVisible}
                    onCancel={() => setDialogVisible(false)}
                    onOk={() => setDialogVisible(false)}>
                    <Select
                        value={selectedLanguage}
                        onChange={handleChange}
                        options={AP_LANGUAGES}
                    />
                </Modal>
            </div>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link to='/templeCtgry' ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t("label_temples_list")}</span>
                </div>
                <div></div>
            </div>
            <div className=' mb-3 mt-3  breadcrumb-link container  justify-content-between align-items-center'>
                <div class=" white-space-pre  d-flex">
                    <span class="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span></span>
                    <Link to='/templeCtgry'><span class="bc-active-link">{t("label_temples_list")}</span></Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span><span class="bc-link">{t("label_sub_categeries")}</span>
                </div>
                <div class="f2 font-weight-bold  container title-head-txt pageheader-buttons">
                </div>
                <div>
                </div>
            </div>
            <div class="mainsec-block">
                <div className="container">
                    <div className="container">
                        <h5 class="card-text-title">{t("label_sub_categeries")}
                        </h5>
                    </div>
                    <div className="tiles-main">
                        {nLoading ? (
                            <Skeleton active />
                        ) : Array.isArray(nList) && nList.length > 0 ? (
                            nList.map((temple, index) => (
                                <div
                                    className="tiles-design-temple-cat animate__animated animate__flipInX"
                                    key={index}
                                    onClick={() => { fetchGroupTemples(temple.gid); }}
                                >
                                    <div className="title-img-bg ">
                                        <img className="card-img-temple-cat " alt="temples" src={Images[temple.imgpath] || defImage} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.group_name}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Result
                                status="404"
                                title={t("label_nodata_found")}
                                subTitle={t("label_no_resources_msg")}
                            />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )

}

export default SubCagegeries;