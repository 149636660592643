import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Input, message } from 'antd';
import { getTempleGroupDetailsError, getTempleGroupDetailsList, getTempleGroupDetailsLoading } from '../../../../redux/selectors/selectors';
import { addTempleToGroup, editGroupDescr, editGroupName, editGroupRank, fetchTempleGroupDetails, removeTempleFromGroup } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import { getImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg'
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../../../utils/showToast';
import ShowMoreText from '../../../common/showMoreText';

const GroupDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');
    const { gid } = useParams()
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [templeid, setTempleid] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [removeDialogVisible, setRemoveDialogVisible] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const phoneRegex = /^[0-9]+$/;
    const [rank, setRank] = useState(null);
    const [description, setDescription] = useState(null);

    const success = () => {
        showToast('success', t('label_tempgrp_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        //form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_tempgrp_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleGroupDetailsList(state),
        rLoading: getTempleGroupDetailsLoading(state),
        rError: getTempleGroupDetailsError(state),
    }));


    useEffect(() => {
        dispatch(fetchTempleGroupDetails(storedLanguage, gid));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));


    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: rList && rList.deityname });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const updateName = () => {
        const updatedName = name || rList.group_name;
        dispatch(editGroupName(gid, storedLanguage, { group_name: updatedName }, () => showToast('success', 'Updated Temple Group Details Successfully', 'failure', 'Error occurred')));
    };

    const handleRank = (e) => {
        setRank(e.target.value);
    };

    const updateRank = () => {
        const updatedRank = rank || rList.grank;
        dispatch(editGroupRank(gid, { grank: parseInt(updatedRank) }, () => showToast('success', 'Updated Temple Group Details Successfully', 'failure', 'Error occurred')));
    };

    const handleDescr = (e) => {
        setDescription(e.target.value);
    };

    const updateDescr = () => {
        const updatedDescr = description || rList.descr;
        dispatch(editGroupDescr(gid, storedLanguage, { descr: updatedDescr }, () => showToast('success', 'Updated Temple Group Details Successfully', 'failure', 'Error occurred')));
    };


    const sanitizeText = (text) => {
        if (!text) return '';
        return text
            .replace(/<BR\s*\/?>/gi, '\n')
            .replace(/<B>(.*?)<\/B>/gi, '**$1**')
            .replace(/<[^>]*>?/gm, '');
    };


    const openDialog = (group) => {
        setSelectedGroup(group);
        setDialogVisible(true);
    };

    const removeOpenDialog = (group) => {
        setSelectedGroup(group);
        setRemoveDialogVisible(true);
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (dialogVisible) {
                handleDialogueCancel();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (dialogVisible && event.key === 'Escape') {
                handleDialogueCancel();
                event.preventDefault();
            }
        };
        if (dialogVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [dialogVisible, handleDialogueCancel]);

    useEffect(() => {
        const handlePopState = (event) => {
            if (removeDialogVisible) {
                removeOpenDialog();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (removeDialogVisible && event.key === 'Escape') {
                removeOpenDialog();
                event.preventDefault();
            }
        };
        if (removeDialogVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [removeDialogVisible, removeOpenDialog]);

    const handleDialogSubmit = () => {
        dispatch(addTempleToGroup(gid, templeid, success, failure));
        setDialogVisible(false);
    };

    const handleRemoveTemple = () => {
        dispatch(removeTempleFromGroup(gid, templeid, success, failure));
        setRemoveDialogVisible(false);
    };
    const handleDialogueCancel = () => {
        setDialogVisible(false)
    }

    const removeDialogueCancel = () => {
        setRemoveDialogVisible(false)
    }

    const [currentLang, setCurrentLang] = useState('');

    const languageMap = {
        '5': 'English',
        '4': 'हिंदी',
        '1': 'తెలుగు',
        '2': 'ಕನ್ನಡ',
        '3': 'தமிழ்',
        '6': 'বাংলা',
        '7': 'Española'
    };

    useEffect(() => {
        // Get the stored language from localStorage or default to '5' (English)
        const storedLanguage = localStorage.getItem('lng');
        setCurrentLang(languageMap[storedLanguage] || languageMap['5']);

        // Listen for localStorage changes across tabs
        const handleStorageChange = (event) => {
            if (event.key === 'lng') {
                window.location.reload();  // Reload the page when language changes
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [languageMap]);

    // Function to handle language change from dropdown
    const handleLanguageChange = (newLang) => {
        localStorage.setItem('lng', newLang);  // Update language in localStorage
        window.location.reload();  // Reload the page after the change
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_tmpl_group_details')}</span>
                </div>
                <div></div>
            </div>

            {contextHolder}
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'black' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                    <span className="bc-active-link" onClick={handleGoBack}>{t("label_tmpl_groups_list")}</span>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                </span>
                <span className="bc- active-link" onClick={handleGoBack}>{t("label_tmpl_group_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">
                        {rList && rList.group_name}
                    </div>
                    <div class="details-headertxt-singleLine-btns">
                        {editable ? (
                            <>
                                <a class="button_move" onClick={() => {
                                    updateDescr();
                                    updateName();
                                    updateRank();
                                }} style={{ marginRight: '5px' }}>
                                    {t("label_save")}
                                </a>
                                <a class="button_move" onClick={handleCancelClick} style={{ marginRight: '10px' }}>
                                    {t("label_cancel")}
                                </a>
                            </>
                        ) : (
                            <a class="button_move" onClick={handleEditClick} style={{ marginRight: '10px' }} >
                                {t("edit")}
                            </a>
                        )}
                        <a class="button_move" onClick={() => openDialog()} style={{ marginRight: '10px' }}>
                            {t("add_temple")}
                        </a>
                        <div class="popup-bg" style={{ display: dialogVisible ? 'flex' : 'none' }}>
                            <div class="popup-main animate__animated animate__fadeInUp ">
                                <div class="popup-main-header">
                                    <span class="popup-main-header-text">{t('label_enter_temple_id')}</span>
                                    <div class="popup-close" onClick={handleDialogueCancel}><i class="fa-solid fa-xmark"></i></div>
                                    <div class="popup-back" onClick={handleDialogueCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div class="popup-main-container">
                                    <div class="d-block htinner-popup-main-container">
                                        {/* <Form

                                            name="nest-messages"
                                            labelCol={{
                                                span: 24,
                                            }}
                                            wrapperCol={{
                                                span: 24,
                                            }}
                                        > */}
                                        <Form.Item

                                            label="Temple ID"
                                            name="templeid"
                                            rules={[
                                                { required: true, message: 'Please enter TempleID' },
                                                {
                                                    pattern: phoneRegex,
                                                    message: 'Please enter a valid Phone Number! Only numbers are allowed.',
                                                },
                                            ]}
                                        > <Input
                                                value={templeid}
                                                onChange={(e) => setTempleid(e.target.value)}
                                            />

                                        </Form.Item>
                                        {/* </Form> */}
                                    </div>
                                </div>
                                <div class="popup-main-footer">
                                    <a className="button_move" onClick={handleDialogueCancel} style={{ float: 'left', marginRight: "5px" }}>
                                        {t("label_cancel")}
                                    </a>
                                    <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleDialogSubmit() }}>
                                        {t("label_save")}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a class="button_move" onClick={() => removeOpenDialog()} style={{ marginRight: '10px' }}>
                            {t("remove_temple")}
                        </a>
                        <div class="popup-bg" style={{ display: removeDialogVisible ? 'flex' : 'none' }}>
                            <div class="popup-main animate__animated animate__fadeInUp ">
                                <div class="popup-main-header">
                                    <span class="popup-main-header-text">{t('label_enter_temple_id')}</span>
                                    <div class="popup-close" onClick={removeDialogueCancel}><i class="fa-solid fa-xmark"></i></div>
                                    <div class="popup-back" onClick={removeDialogueCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div class="popup-main-container">
                                    <div class="d-block htinner-popup-main-container">
                                        <Form.Item

                                            label="Temple ID"
                                            name="templeid"
                                            rules={[
                                                { required: true, message: 'Please enter TempleID' },
                                                {
                                                    pattern: phoneRegex,
                                                    message: 'Please enter a valid Phone Number! Only numbers are allowed.',
                                                },
                                            ]}
                                        > <Input
                                                value={templeid}
                                                onChange={(e) => setTempleid(e.target.value)}
                                            />

                                        </Form.Item>
                                    </div>
                                </div>
                                <div class="popup-main-footer">
                                    <a className="button_move" onClick={removeDialogueCancel} style={{ float: 'left', marginRight: "5px" }}>
                                        {t("label_cancel")}
                                    </a>
                                    <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleRemoveTemple() }}>
                                        {t("label_save")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                    {rLoading ? (
                        <p>Loading...</p>
                    ) : (
                        rList && (
                            <div className='horoscope-item' >
                                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_tmpl_group_details')} <span>| {t('label_group_id_name_desc')}</span></h5></div>
                                {/* <h2>{rList.author}</h2> */}
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_group_id")}</Typography.Title>}
                                            name="st"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.gid}</Typography.Text>
                                        </Form.Item>

                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_group_name")}</Typography.Title>}
                                            name="group_name"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.group_name}
                                                    onChange={handleName}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.group_name}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_rank")}</Typography.Title>}
                                            name="grank"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.grank}
                                                    onChange={handleRank}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.grank}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="lang"
                                            label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a language.',
                                                },
                                            ]}
                                        >
                                            <Typography.Text level={5} className="typ">
                                                {currentLang}
                                            </Typography.Text>
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("Description")}</Typography.Title>}
                                            name="descr"
                                        >
                                            {editable ? (
                                                <TextArea
                                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                                    style={{ width: '50', minWidth: '500px', maxWidth: '100%' }}
                                                    defaultValue={sanitizeText(rList && rList.descr)}
                                                    onChange={handleDescr}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ' style={{ whiteSpace: 'pre-wrap' }}>
                                                    {sanitizeText(rList && rList.descr)}
                                                    <ShowMoreText
                                                        charLimit={10}
                                                        text={rList.descr}
                                                    />
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_galary')} <span>| {t('label_galary_info')}</span></h5>
                                    <div class="active-details">
                                        <div class="tiles-designs" >
                                            <div className='detail-images ' style={{ marginTop: '2px' }}>
                                                {rList.images != null && rList.images.length > 0 ? (
                                                    <div className="tiles-grid">
                                                        <Image.PreviewGroup allowClose={true} maskClosable={true}
                                                            mask={false}>
                                                            {rList.images.map((image, index) => (
                                                                <div key={index}>
                                                                    <Image
                                                                        className="card-img"
                                                                        src={getImageAPI() + image.imgpath}
                                                                        alt="images"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '200px',
                                                                            marginBottom: '20px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Image.PreviewGroup>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img
                                                            className="card-img"
                                                            alt="temples"
                                                            src={deftemples}
                                                            style={{
                                                                width: '20%',
                                                                height: '20%',
                                                                marginBottom: '2px',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default GroupDetails;
