import React, { useEffect, useState } from 'react'
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deftemples from '../../../../assets/images/templeicon.jpg'
import horoscope_icon from "../../../../assets/images/horoscope-img4.png";
import panchang_icon from "../../../../assets/images/horoscope-img3.png";
import AdBlock from '../Advertisements/adBlock';

const HoroCategeries = () => {
    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    return (
        <>

            <div class="mainsec horo-bg">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_daily_panchamgam')}</span>
                    </div>
                    <div></div>
                </div>
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                        <span className="d-flex align-items-center">
                            <Link style={{ color: 'black' }} to="/">
                                <span className="bc-active-link">{t('Home')}</span>
                            </Link>
                            <span className="ml-3 mr-3">
                                <i className="fa-solid fa-angle-right f-9"></i>
                            </span>
                        </span>
                        <span className="bc-link">{t("label_daily_panchamgam")}</span>
                    </div>
                <div class="mainsec-block">
                    <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
                        {(userRole === 'AD' || userRole === 'AA') && (
                            <>
                               
                                <Link to='/editWeeklyHoroscope/0'>
                                    <button type="button" class="declineButton">{t("label_create_horo")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                                <Link to='/adddailypanchangam'>
                                    <button type="button" class="declineButton">{t("label_create_daily_panchangam")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                            </>
                        )}
                       
                    </div>
                    <div className="container">
                        <div class="container">
                            <h5 class="card-text-title">{t("Categories")}
                                {/* <span>| {t("label_library_divided_groups")}</span> */}
                            </h5>
                        </div>
                        <div className="tiles-main">
                            <Link to='/viewPanchangam'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={panchang_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_daily_panchamgam")}</span> <span>{("label_daily_panchamgam")}</span>
                                    </div>
                                </div>
                            </Link>                          
                            <Link to='/viewHoroscope'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={horoscope_icon} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_daily_horoscope")}</span> <span>{("label_daily_horoscope")}</span>
                                    </div>
                                </div>
                            </Link>                                                                           
                        </div>
                         {/* <AdBlock /> */}
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )

}

export default HoroCategeries;