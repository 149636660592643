import React, { useEffect, useState } from 'react'
import india from '../../../../assets/images/india.png';
import uk from '../../../../assets/images/uk.jpg';
import us from '../../../../assets/images/126.jpg';
import nepal from '../../../../assets/images/nepal.jpg';
import singapore from '../../../../assets/images/singapore.jpg';
import thailand from '../../../../assets/images/thailand.jpg';
import srilanka from '../../../../assets/images/srilanka.jpg';
import australia from '../../../../assets/images/australia.jpg';
import canada from '../../../../assets/images/canada.jpg';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';

const TempleOutsideIndia = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng');

    const dispatch = useDispatch();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };

    const templeData = [
        {
            imageSrc: nepal,
            labelFamousTemples: t("label_nepal"),
            labelTemplesList: t("label_temples_list"),
            gid: "172"
        },
        {
            imageSrc: singapore,
            labelFamousTemples: t("label_singapore"),
            labelTemplesList: t("label_temples_list"),
            gid: "125"
        },
        {
            imageSrc: thailand,
            labelFamousTemples: t("label_thailand"),
            labelTemplesList: t("label_temples_list"),
            gid: "105"
        },
        {
            imageSrc: srilanka,
            labelFamousTemples: t("label_srilanka"),
            labelTemplesList: t("label_temples_list"),
            gid: "145"
        },
        {
            imageSrc: australia,
            labelFamousTemples: t("label_australia"),
            labelTemplesList: t("label_temples_list"),
            gid: "62"
        },
        {
            imageSrc: canada,
            labelFamousTemples: t("label_canada"),
            labelTemplesList: t("label_temples_list"),
            gid: "102"
        },
        {
            imageSrc: us,
            labelFamousTemples: t("label_usa"),
            labelTemplesList: t("label_temples_list"),
            gid: "265"
        },
        {
            imageSrc: uk,
            labelFamousTemples: t("label_uk"),
            labelTemplesList: t("label_temples_list"),
            gid: "287"
        },
    ];


    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };

    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups('groups', storedLanguage));
    }, [storedLanguage]);


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_temples_outside_india')}</span>
                </div>
                <div></div>
            </div>
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'black' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                </span>
                <Link style={{ color: 'black' }} to="/">
                    <span className="bc-active-link" onClick={handleGoBack}>{t("label_temples_list")}</span>
                </Link>
                <span className="ml-3 mr-3">
                    <i className="fa-solid fa-angle-right f-9"></i>
                </span>
                <span className="bc-link">{t('label_temples_outside_india')}</span>
            </div>
            <div class="mainsec-block">
                {/* <div class="container d-flex mt-2 mb-2" >
                    {token ? (
                        <Link to='/addTemple'><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                    <Link to='/recentlyViewed'><button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    <Link to='/nearby'> <button type="button" class="declineButton ">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                </div> */}


                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
                
                Temple Categories</div> */}
                <div className="container">
                    <div class="container">
                        <h5 class="card-text-title">{t("label_temples_outside_india")}
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </h5>
                    </div>
                    <div className="tiles-main">
                        {templeData.map((temple, index) => (
                            <Link to={`/OutsideIndia/${temple.labelFamousTemples}/${temple.gid}`} key={index}>
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt="temples" src={temple.imageSrc} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.labelFamousTemples}</span> <span>{temple.labelTemplesList}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {/* {nList && nList
                            .filter(temple => temple.group_name.startsWith("Temples in"))
                            .map((temple, index) => (
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX" key={index} onClick={() => {
                                    if (temple.group_name.toLowerCase() === 'outside of india') {
                                        // Navigate to /outsideIndia page
                                        navigate('/outsideIndia');
                                    } else {
                                        // Navigate to the specific group temples page
                                        fetchGroupTemples(temple.gid);
                                    }
                                }}>
                                    <div className="title-img-bg ">
                                        <img className="card-img-temple-cat " alt="temples" src={Images[temple.imgpath] || ''} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.group_name}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            ))} */}

                    </div>
                </div>

                <Footer />
            </div>
        </div>



    )

}

export default TempleOutsideIndia;