import React, { useEffect, useState } from 'react'
import { Breadcrumb, Modal, Select, Skeleton, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import famousTemples from '../../../../assets/images/famous-temple2.jpg'
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import Images from '../../../common/images';
import showToast from '../../../../utils/showToast';
import defImage from '../../../../assets/images/recentlyviewed.jpeg';
import templeSearch from '../../../../assets/images/temple-search.png';
import AP_LANGUAGES from "../../../common/appLangs"
import oc from '../../../../assets/images/temp_cat_world.png';
import india2 from '../../../../assets/images/temp_cat_india.png';
import Search from 'antd/es/transfer/search';
import Login1 from '../Header/login';

const TempleCtgry = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [showLoginForm1, setShowLoginForm1] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng');
    const [searchTerm, setSearchTerm] = useState('');
    const [states, setStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };

    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };



    const { nList, nLoading } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
        dispatch(fetchTempleGroups('groups', storedLanguage));
    }, [storedLanguage, dispatch]);


    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);

    const handleLinkClick = () => {
        showToast('error',
            <>
                {t('label_loginerror')}
                <br /><br />
                <button
                    onClick={() => {
                        setShowLoginForm1(true);
                    }}
                    className='button_move'
                >
                    Please login
                </button>
            </>
        );
    };


    useEffect(() => {
        setFilteredStates(nList);
    }, [nList]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (value.trim() === '') {
            setFilteredStates(nList); // Show all results if search is empty
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = nList.filter((temple) =>
                temple.group_name.toLowerCase().includes(lowercasedValue)
            );
            setFilteredStates(filtered);
        }
    };

    const [searchQuery, setSearchQuery] = useState("");


    const handleReset = () => {
        setSearchTerm('');
        setFilteredStates(states);
    };

    const recentlyViewed = [
        { id: 1, title: t("label_fam_temples"), image: famousTemples, link: "/templesList" },
        { id: 5, title: t("label_temples_by_state"), image: india2, link: "/inIndia/507" },
        { id: 6, title: t("label_temples_outside_india"), image: oc, link: "/outsideIndia" }
    ];

    const filteredTemples = (nList || []).filter(temple =>
        temple.group_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredMainList = filteredTemples.filter(
        temple => !temple.group_name.startsWith("Temples in") &&
            temple.gid !== 123 &&
            temple.gid !== 124 &&
            temple.gid !== 507 &&
            temple.gid !== 125
    );

    const filteredRecentlyViewed = recentlyViewed.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div class="mainsec temple2-bg   ">
            <div class=" mob-nav fluid-container page-title justify-content-between position-relative">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t("label_temples_list")}</span>
                </div>
                <div></div>
                <div class="bg-btns-icon">
                    {token ? (
                        <Link to='/addTemple'><i class="fa-solid fa-gopuram main-gopuram"></i><i class="fa-solid fa-plus main-icon"></i></Link>
                    ) : (
                        <Link onClick={handleLinkClick}><i class="fa-solid fa-gopuram main-gopuram"></i><i class="fa-solid fa-plus main-icon"></i></Link>
                    )}
                    <Link to='/recentlyViewed'><i class="fa-solid fa-gopuram main-gopuram"></i><i class="fa-solid fa-eye main-icon"></i></Link>
                    <Link to='/nearby'><i class="fa-solid fa-gopuram main-gopuram"></i><i class="fa-solid fa-location-dot main-icon"></i></Link>
                    {token ? (
                        <Link to='/searchfavourite'><i class="fa-solid fa-gopuram main-gopuram"></i><i class="fa-solid fa-heart-circle-check main-icon"></i></Link>
                    ) : (
                        <Link onClick={handleLinkClick}><i class="fa-solid fa-gopuram main-gopuram"></i><i class="fa-solid fa-heart-circle-check main-icon"></i></Link>
                    )}
                </div>
            </div>
            <div className=' mb-3 mt-3  breadcrumb-link container  justify-content-between align-items-center position-relative'>
                <div class="white-space-pre  d-flex">
                    <span class="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link>
                        <span class="ml-3 mr-3"> <i class="fa-solid fa-angle-right f-9"></i> </span></span>
                    <span class="bc-link">{t("label_temples_list")}</span>
                </div>
                <div class="f2 font-weight-bold  container title-head-txt pageheader-buttons">
                    {token ? (
                        <Link to='/addTemple'>
                            <button type="button" class="declineButton" title={t("add_temple")}><i class="fa-solid fa-plus txt-orange1"></i> {t("add_temple")}</button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton" title={t("add_temple")}><i class="fa-solid fa-plus txt-orange1"></i> {t("add_temple")}</button></Link>
                    )}
                    <Link to='/recentlyViewed'>
                        <button type="button" class="declineButton" title={t("lable_recent_view")}>

                            <i class="fa-solid fa-eye txt-orange1"></i> {t("lable_recent_view")}
                        </button>
                    </Link>
                    <Link to='/nearby'>
                        <button type="button" class="declineButton" title={t("lable_near_by_temples")}>

                            <i class="fa-solid fa-location-dot txt-orange1"></i> {t("lable_near_by_temples")}
                        </button>
                    </Link>
                    {token ? (
                        <Link to='/searchFavourite'>
                            <button type="button" class="declineButton" title={t("label_fav_temples")}><i class="fa-solid fa-heart txt-orange1"></i> {t("label_fav_temples")}</button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton" title={t("label_fav_temples")}><i class="fa-solid fa-heart txt-orange1"></i> {t("label_fav_temples")}</button></Link>
                    )}
                </div>
                <div>

                </div>

            </div>
            {showLoginForm1 && <Login1 showLoginForm1={showLoginForm1} setShowLoginForm1={setShowLoginForm1} />}
            <div class="mainsec-block">

                <div className="container">
                    <div className="card-text-title d-flex justify-content-between mob-search-row">
                        <div class="d-flex align-items-center">{t("Categories")}
                        </div>
                        <div class="sec-cat">
                            <Search
                                placeholder={t("label_search_categories")}
                                value={searchTerm}
                                onSearch={handleSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>


                    <div class="temples-search-icon"><img src={templeSearch} />
                    </div>

                    <div className="tiles-main ">
                        {recentlyViewed.map((item) => (
                            <Link key={item.id} to={item.link}>
                                <div className="tiles-design-temple-cat animate_animated animate_flipInX">
                                    <div className="title-img-bg templescatbg">
                                        <img className="card-img-temple-cat" title={item.title} alt="temple-category" src={item.image} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{item.title}</span> <span>{t("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {nLoading ? (
                            <Skeleton active />
                        ) : (
                            filteredMainList.map((temple, index) => (
                                <div
                                    className="tiles-design-temple-cat animate__animated animate__flipInX"
                                    key={index}
                                    onClick={() => {
                                        if (temple.group_name.toLowerCase() === "remedy temples") {
                                            navigate("/remedytemples");
                                        } else if (temple.gid < 100) {
                                            navigate(`/subCategery/${temple.gid}`);
                                        } else {
                                            fetchGroupTemples(temple.gid);
                                        }
                                    }}
                                >
                                    <div className="title-img-bg templescatbg">
                                        <img className="card-img-temple-cat" alt="temples" src={Images[temple.imgpath] || defImage} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.group_name}</span> <span>{t("label_temples_list")}</span>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );

}

export default TempleCtgry;