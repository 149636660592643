import {
    Form, Input, Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGodNames, getGodNamesError, getGodNamesLoading, } from '../../../../redux/selectors/selectors';
import { fetchGodNames, postNotifiByTxt, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';

const AddNotifiTxt = () => {

    const { t } = useTranslation();
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const [selectedImage, setSelectedImage] = useState(null);
    const localEnv = false;
    const dispatch = useDispatch()
    const userole = localStorage.getItem('urole')
    const tid = localStorage.getItem('orgid')
    const [form] = Form.useForm();
    const storedLanguage = localStorage.getItem('lng');
    const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    const rid = localStorage.getItem('resid')
    const success = () => {
        showToast('success', t('label_add_notifi_sucess'))
        form.resetFields()
        Navigate(`/editResource/${rid}`)
    };

    const failure = (msg) => {
        showToast('error', msg)
    };

    const [showAllImages, setShowAllImages] = useState(false);


    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (deityNumber) => {
        setSelectedDeity(deityNumber);
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const userid = localStorage.getItem('uid')
    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const rawText = values.raw_text || "";
                let templeId = 0;
                if (userole !== "TA" && userole !== "TU") {
                    const templeMatch = rawText.match(/(?:tid|Temple|temple):(\d+)/);
                    templeId = templeMatch ? Number(templeMatch[1]) : 0;
                }
                const data = {
                    tid: templeId,
                    raw_text: rawText,
                };

                dispatch(postNotifiByTxt(data, success, failure));
            })
            .catch((errorInfo) => {
                console.error("Validation failed:", errorInfo);
            });
    };


    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        form.resetFields();
        navigate(-1);
    };

    return (
        <>

            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_res_details')}</span>
                    </div>
                    <div></div>
                </div>
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                    <span className="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to="/">
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                        <span className="bc-active-link" onClick={handleGoBack}>{t("label_add_edit_notif")}</span>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                    </span>
                    <span className="bc- active-link" onClick={handleGoBack}>{t("label_notifi_by_text")}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t('label_notifi_by_text')}</div>
                        <div class="">
                            <a class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t('label_cancel')}
                            </a>
                            <a class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                                {t("label_save")}
                            </a>
                        </div>
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages" onFinish={onFinish}
                        >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_notifi_by_text")} <span></span></h5></div>
                            <div class="active-details">
                                {(userole === "TA" || userole === "TU") && (
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="tid"
                                            label={<Typography.Title level={4}>{t("label_temple_id")}</Typography.Title>}
                                        >
                                            {userole === "AD" ? (
                                                <Input style={{ height: "30px" }} placeholder={t("label_enter_tmpl_id")} />
                                            ) : (
                                                <Typography.Text>{tid ? tid : 0}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                )}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='raw_text'
                                        label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={13} placeholder={t('label_enter_details')} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    );
};
export default AddNotifiTxt;