import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getTempleBooksError, getTempleBooksList, getTempleBooksLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchTempleBooks, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Form, Image, Row, Skeleton, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TempleCard from '../../../common/card';
import ResourceCard from '../../../common/resourceCard';
import TempleDetailsLibraryCard from '../../../common/templeDetailsLibrary';


let last_rec = 0;
let god_last_rec = 0;
function TempleBooks() {
  const { t } = useTranslation();
  const records_per_page = 12;
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const userid = localStorage.getItem('urole')
  const localEnv = false;
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  // const failure = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   });
  // };
  const { uid } = useParams()
  const { tid } = useParams()

  const { bDetails, bLoading, bError, godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({
    bDetails: getTempleBooksList(state),
    bLoading: getTempleBooksLoading(state),
    bError: getTempleBooksError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))




  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  const nextSetRef = useRef(null);


  useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;

    // Detect if the user has scrolled to the bottom of the page
    if (windowBottom >= docHeight && !isLoading) {
      // Load more data when scrolling to the end
      nextTemplesList();
    }
  };

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }

  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      };
    } else {
      return {
        borderColor: '#d9d9d9',
        color: '#000',
      };
    }
  }

  function getGodStyle(did) {
    if (did === filterId) {
      return {

        border: '4px solid #1250c1',
      };
    } else {
      return {
        border: '4px solid #6da1e1',

      };
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);

  useEffect(() => {
    dispatch(fetchTempleBooks(storedLanguage, tid, did))
  }, [storedLanguage, did, currentPage]);

  useEffect(() => {
    if (!bLoading && bDetails) {
      setCurrentPageTemples([...currentPageTemples, ...bDetails]);
    }
  }, [bLoading]);

  const nextTemplesList = async () => {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
    dispatch(fetchTempleBooks(storedLanguage, tid, did))
    // setIsLoading(false);
  }

  function prevTemplesList() {
    last_rec = last_rec - records_per_page;
    last_rec = (last_rec < 0) ? 0 : last_rec;
    // god_last_rec = god_last_rec - records_per_page;
    // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
    dispatch(fetchTempleBooks(storedLanguage, tid, did))
  };

  const [refresh, setRefresh] = useState(false);


  const success = (title) => {
    messageApi.open({
      type: 'success',
      content: `Added ${title} to favorites`,
    });
  };
  const warning = (title) => {
    messageApi.open({
      type: 'warning',
      content: `Removed ${title} from favourites`,
    });
  };

  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: t('label_loginerror'),
    });
  };

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    dispatch(fetchBooksFavourites());
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }



  return (
    <div className="h-100">
      {contextHolder}

      <div className="search">
        <div className="tiles-main" style={{ justifyContent: 'left' }}>
          {bDetails && Object.entries(
            bDetails.reduce((acc, temple) => {
              (acc[temple.res_type] = acc[temple.res_type] || []).push(temple);
              return acc;
            }, {})
          ).map(([resType, temples]) => (
            <div key={resType} className="resource-section ">
              <div className="tiles-designs">
              <Form.Item
                label={<Typography.Title level={4} className='ml-4'>{t('label_'+ resType)}</Typography.Title>}
                name="history"></Form.Item>
                
              {/* <h3 className="resource-heading ml-4">{resType.toUpperCase()}</h3> */}
              <div className="resource-row tileswidth">
                {temples.map((temple) => (

                  <TempleDetailsLibraryCard
                    key={temple.rid}
                    id={temple.rid}
                    imgpath={temple.imgpath}
                    title={temple.title}
                    city={temple.res_type}
                    state={temple.author}
                    temple={temple}
                    favList={favList}
                    toggleFavorite={toggleFavorite}
                    toggleFilled={toggleFilled}
                    link={(temple.res_type === "articles" && temple.src_url === "www.templeswiki.com")
                      ? `/editResource/${temple.rid}`
                      : (temple.res_type === "mantras" ? `/editResource/${temple.rid}` : temple.src_url)}
                    target={temple.res_type === "articles" ? "_self" : "_blank"}
                    hideButton={false}
                    resType={temple.res_type}
                    
                  />
                ))}
              </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>


  )
}


export default TempleBooks;