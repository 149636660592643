import React, { Component } from "react";
import "./chatbot.css"; // Import your CSS file

class Chatbot extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      chatMessages: [],
      userInput: "",
      showHiText: true, // New state to control the "hi" text visibility
    };
  }

  componentDidMount() {
    // Show the "hi" text for a brief moment on component mount
    setTimeout(() => {
      this.setState({ showHiText: false });
    }, 2000); // Adjust the duration as needed
  }

  togglePopup = () => {
    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
      showHiText: false, // Hide the "hi" text when the chatbot is opened
    }));
  };

  handleUserInput = (e) => {
    this.setState({ userInput: e.target.value });
  };

  sendMessage = () => {
    const { userInput } = this.state;
    if (userInput.trim() === "") return;

    // Add the user's message to the chat with "You: " prefix
    this.addMessage(`You: "${userInput}"`, "user");

    // Handle specific user responses
    const aiResponse = this.handleUserResponse(userInput);

    // Add the AI's response to the chat with "AI: " prefix
    this.addMessage(`AI: "${aiResponse}"`, "bot");

    // Clear user input
    this.setState({ userInput: "" });
  };

  handleUserResponse = (userInput) => {
    // Handle specific user responses
    if (userInput.toLowerCase() === "hi") {
      return "Hello, welcome to TemplesWiki!";
    } else if (userInput.toLowerCase().includes("templeswiki")) {
      return "Sure, I can help you showcase temples from different places.";
    } else {
      // Replace this with your actual AI logic for other responses
      return "I'm sorry, I don't understand. Please ask another question.";
    }
  };

  addMessage = (message, sender) => {
    const { chatMessages } = this.state;
    const newMessage = { text: message, sender };
    this.setState({
      chatMessages: [...chatMessages, newMessage],
    });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { showPopup, chatMessages, userInput, showHiText } = this.state;

    return (
      <div className="chatbot-container">
        <div
          className={`chatbot-icon ${showPopup ? "open" : ""}`}
          onClick={this.togglePopup}
        >
          {showPopup ? (
            <div className="popup" onClick={this.stopPropagation}>
              <div className="popup-content">
                <div className="chat-messages">
                  {chatMessages.map((message, index) => (
                    <div
                      key={index}
                      className={`message ${message.sender}`}
                    >
                      {message.text}
                    </div>
                  ))}
                </div>
                <input
                  type="text"
                  className="user-input"
                  placeholder="Type your message..."
                  value={userInput}
                  onChange={this.handleUserInput}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.sendMessage();
                  }}
                />
                <div className="send" onClick={this.sendMessage}>
                  Send
                </div>
                <div className="cancel" onClick={this.togglePopup}>
                  Cancel
                </div>
              </div>
            </div>
          ) : (
           ""
          )}
        </div>
      </div>
    );
  }
}

export default Chatbot;
