import React, { useState } from 'react'
import { Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
const { Title, Paragraph } = Typography;

const AboutUs = () => {

    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class='mainsec'>
            <div class="mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack}><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('About')}</span>
                </div>
                <div></div>
            </div>
          
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                    <span className="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to="/">
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                    </span>
                    <span className="bc-link">{t("About")}</span>
                </div>
                <div class="bg-details-highlight">
                <div class="details-headertxt-singleLine container">
                    <div class="header_txt">{t('About')}</div>
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
            <div className="container">
                <div className="privacy-policy-container" style={{ marginTop: '24px' }}>
                    <div className="privacy-policy-content">
                        <Typography>
                            <Title level={2}>About Us</Title>
                            <Paragraph>
                                Welcome to www.templeswiki.com. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                            </Paragraph>
                        </Typography>
                    </div>
                </div>

             
            </div>
            <Footer />
            </div>
        </div>
    );

}

export default AboutUs;