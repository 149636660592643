import React from 'react';
import nodata from "../../assets/images/nodata.png";
const DataNotFound = (title, subtitle) => {

    return (
            <div >
                <img src={nodata} alt='Data Not Found'/>
            </div>
    )
}

export default DataNotFound;
