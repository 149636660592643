const godNames = {
    0: null,
    1: 'label_ganesha',
    2: 'label_vishnu',
    3: 'label_lakshmi',
    4: 'label_durga',
    5: 'label_rama',
    6: 'label_shiva',
    7: 'label_krishna',
    8: 'label_kartikeya',
    9: 'label_hanuman',
    10: 'label_brahma',
    11: 'label_saraswati',
    12: 'label_ayyappa',
    13: 'label_saibaba',
    14: 'label_narasimha',
    15: 'label_surya',
    16: 'label_buddha',
    17: 'label_naga',
    18: 'label_gurudev',
    19: 'label_balaji'
};

export default godNames;
