import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import videos from '../../../../assets/images/video.jpg'
import livetv from '../../../../assets/images/livetv.jpg'
import books_library from '../../../../assets/images/books_library.jpg'
import audios from '../../../../assets/images/audio.jpg'
import mantras from '../../../../assets/images/mantras.jpg'
import articles from '../../../../assets/images/article.jpg'
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Login1 from '../Header/login';

const Library = () => {
    const { t } = useTranslation();
    const [showLoginForm1, setShowLoginForm1] = useState(false);
    const userRole = localStorage.getItem('urole');
    const handleLinkClick = () => {
        showToast('error',
            <>
                {t('label_loginerror')}
                <br /><br />
                <button
                    onClick={() => {
                        setShowLoginForm1(true);  // ✅ Ensure correct prop usage
                    }}
                    className='button_move'
                >
                    Please login
                </button>
            </>
        );
    };

    const token = localStorage.getItem('token');

    return (
        <>
            <div class="mainsec">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_library')}</span>
                    </div>
                    <div></div>
                    <div class="bg-btns-icon">
                        {userRole === 'AD' || userRole === 'AS' ? (
                            <Link to='/addResource'>
                                <button type="button" className="declineButton">
                                    <i class="fa-solid fa-plus txt-orange1"></i> {t("label_create_resource")}

                                </button>
                            </Link>
                        ) : null}
                        {token ? (
                            <Link to='/resourceFavorite'>
                                <button
                                    type="button"
                                    className="declineButton"
                                >
                                    <i class="fa-solid fa-heart txt-orange1"></i> {t('label_library_fav')}


                                </button>
                            </Link>
                        ) : (
                            <button
                                type="button"
                                className="declineButton"
                                onClick={handleLinkClick}
                            >
                                <i class="fa-solid fa-heart txt-orange1"></i> {t('label_library_fav')}
                            </button>
                        )}
                    </div>
                </div>
                <div className=' mb-3 mt-3  breadcrumb-link container  justify-content-between align-items-center'>
                    <div class=" white-space-pre  d-flex">
                        <span class="d-flex align-items-center">
                            <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link><span class="ml-3 mr-3"> <i class="fa-solid fa-angle-right f-9"></i> </span></span>
                        <span class="bc-link">{t("label_library")}</span>
                    </div>
                    <div class="f2 font-weight-bold  container title-head-txt pageheader-buttons">
                        {userRole === 'AD' || userRole === 'AS' ? (
                            <Link to='/addResource'>
                                <button type="button" className="declineButton">
                                    <i class="fa-solid fa-plus txt-orange1"></i> {t("label_create_resource")}

                                </button>
                            </Link>
                        ) : null}
                        {token ? (
                            <Link to='/resourceFavorite'>
                                <button
                                    type="button"
                                    className="declineButton"
                                >
                                    <i class="fa-solid fa-heart txt-orange1"></i> {t('label_library_fav')}


                                </button>
                            </Link>
                        ) : (
                            <button
                                type="button"
                                className="declineButton"
                                onClick={handleLinkClick}
                            >
                                <i class="fa-solid fa-heart txt-orange1"></i> {t('label_library_fav')}
                            </button>
                        )}
                    </div>
                </div>
                {showLoginForm1 && <Login1 showLoginForm1={showLoginForm1} setShowLoginForm1={setShowLoginForm1} />}
                <div class="mainsec-block">

                    <div className="container">
                        <div class="container">
                            <h5 class="card-text-title">{t("Categories")}
                            </h5>
                        </div>
                        <div className="tiles-main">
                            <Link to='/books'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="hindu temples gods books" src={books_library} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_books")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/videos'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="hindu temples gods videos" src={videos} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_videos")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/articles'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="hindu temples gods articles" src={articles} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_articles")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/audios'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="mantras audios" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_audios")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/livetv'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples livetv" src={livetv} />
                                        <span className="blinking-dot"></span>
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_livetv")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/mantras'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="hindu temples gods mantras" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_mantras")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )

}

export default Library;