import React, { useState, useEffect } from "react";
import { Button } from "antd";
import HeaderSearch from "../Temples/headerSearch";
import { useTranslation } from "react-i18next";


const SearchPopup = ({ visible, onClose, handleSubmit, searchText, setSearchText, searchLibraryText, setSearchLibraryText }) => {
  
  const { t } = useTranslation();
  useEffect(() => {
    const handlePopState = (event) => {
      if (visible) {
        onClose();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (visible && event.key === "Escape") {
        onClose();
        event.preventDefault();
      }
    };
    if (visible) {
      window.history.pushState({ popupOpen: true }, "");
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [visible, onClose]);

  return (
    <div className="popup-bg" style={{ display: visible ? "flex" : "none" }}>
      <div className="popup-main-search">
        <div className="popup-main-header-search">
          <div className="popup-back-search" onClick={onClose}>
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <span className="popup-main-header-text">{t("value_search")}</span>
          <div className="popup-close-search" onClick={onClose}><i className="fa-solid fa-xmark"></i></div>
          
        </div>
        <div className="popup-main-container-serch">
          <HeaderSearch
            closeModal={onClose}
            handleSubmit={handleSubmit}
            searchText={searchText}
            setSearchText={setSearchText}
            searchLibraryText={searchLibraryText}
            setSearchLibraryText={setSearchLibraryText}
            modalVisible={visible}
          />
        </div>
        <div className="popup-main-footer-search">
          <Button className="button_move" type="primary" onClick={onClose}>{t("label_cancel")}</Button>
        </div>
      </div>
    </div>
  );
};

export default SearchPopup;
