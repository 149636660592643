import {
    Form, Input, Typography, message,
    Upload,
    Col,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading} from '../../../../redux/selectors/selectors';
import { fetchTempleName, postNotification } from '../../../../redux/actions/acions';
import dayjs from 'dayjs';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import statesData from '../../states.json';
import TextArea from 'antd/es/input/TextArea';
import i18next from 'i18next';
import Footer from '../footer/footer';
import { Option } from 'antd/es/mentions';
import showToast from '../../../../utils/showToast';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const AddNotification = () => {
    const [showMap, setShowMap] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [lat, setLat] = useState(null); // Latitude state variable
    const [lon, setLon] = useState(null); // Longitude state variable
    const [locationSelected, setLocationSelected] = useState(false);
    const [mapKey, setMapKey] = useState(1);
    const [currentLocation, setCurrentLocation] = useState(null);
    const states = statesData.states.map((state) => state.name);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    });

    const onImageLoaded = (image) => {
        console.warn(image);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = (crop, pixelCrop) => {
        console.warn(crop, pixelCrop);
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            // File has been uploaded successfully
            setSelectedImage(info.file.response.path); // Update state with the uploaded image path
        }
    };

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_add_notifi_sucess'))
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('failure', msg)
    };

    const onChange = (event) => {
        setSelectedDate(event.target.value);
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    // const {
    //     tDetails,
    //     tLoading, templesList
    // } = useSelector(state => ({
    //     tDetails: templeName(state),
    //     tLoading: templeNameLoading(state),
    //     tError: templeNameError(state),
    // }));

    // useEffect(() => {
    //     if (tid) {
    //         dispatch(fetchTempleName(storedLanguage, tid));
    //     }
    // }, [storedLanguage, tid, dispatch]);

    const storedLanguage = localStorage.getItem('lng');
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const userid = localStorage.getItem('uid')
    const userole = localStorage.getItem('urole')
    const { tid } = useParams();
    const { tname } = useParams();
    const orgid = localStorage.getItem('orgid')
    const orgname = localStorage.getItem('orgname')
    const onFinish = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    tid: tid ? Number(tid) : 0,
                    subject: values.subject,
                    txt: values.txt,
                    src: values.src || null,
                    dvdt: dayjs(values.dvdt).format('YYYY-MM-DD'),
                    lnk: values.lnk || null,
                    ctgid: 1,
                    lang: selectedLanguage,
                    ctgry: Number(values.ctgry),
                    img: selectedImage || null,
                };

                dispatch(postNotification(data, success, failure));
                navigate('/managenotification');
            })
            .catch((errorInfo) => {
                if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
                    const missingFields = errorInfo.errorFields.map(field => field.name.join('.')).join(', ');
                    message.error(`Please fill the required fields: ${missingFields}`);
                }
            });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default form submission behavior
            onFinish(); // Trigger form submission
        }
    };


    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
        // setButtonText('Edit');
        // setEditable(false);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getNotificationDetailsList(state),
        rLoading: getNotificationDetailsLoading(state),
        rError: getNotificationDetailsError(state),
    }));


    return (
        <>

            {contextHolder}


            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_add_edit_notif')}</span>
                    </div>
                    <div></div>
                </div>
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                    <span className="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to="/">
                            <span className="bc-active-link" onClick={handleGoBack}>{t('Home')}</span>
                        </Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                    </span>
                    <span className="bc-link">{t("label_add_edit_notif")}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt-singleLine container">
                        <div class="header_txt"> {t('label_add_edit_notif')}</div>
                        <div class="details-headertxt-singleLine-btns">
                            <a class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t("label_cancel")}
                            </a>
                            <a class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                                {t("label_save")}
                            </a>
                            <Link className="button_move" to='/addnotifiTxt' style={{ marginLeft: "5px" }}>{t("label_notifi_by_text")}</Link>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        {/* <div className='details-page'> */}
                        {/* <Link to={'/'}><Typography.Text>Back</Typography.Text></Link> */}
                        <Form form={form} name="nest-messages" onFinish={onFinish} onKeyDown={handleKeyDown}
                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }}
                        >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_noti_details")} <span>| {t('label_name_details_source')}</span></h5></div>
                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
                            <div class="active-details">
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="tid"
                                        label={<Typography.Title level={4}>{t("label_temple_id")}</Typography.Title>}
                                       
                                    >
                                        {userole === "AD" || userole === "AS" ? (
                                            <Input
                                                style={{ height: "30px" }}
                                                placeholder={t("label_enter_tmpl_id")}
                                                onChange={() => dispatch(fetchTempleName(storedLanguage, form.getFieldValue("tid")))}
                                            />
                                        ) : (
                                            <Typography.Text>{tid ? tid : orgid}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="tname"
                                        label={<Typography.Title level={4}>{t("label_temple_name")}</Typography.Title>}
                                    >
                                        <Typography.Text>{ tname ? tname: orgname}</Typography.Text>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='subject'
                                        label={<Typography.Title level={4}>{t('Subject')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,

                                                validator: (_, value) => {
                                                    if (value && value.length > 100) {
                                                        return Promise.reject(new Error("Subject cannot exceed 100 characters."));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder={t('label_enter_sub')} maxLength={100} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='src'
                                        label={<Typography.Title level={4}>{t('label_source')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder={t('label_enter_source_link')} />
                                    </Form.Item>
                                </div>
                                {/* </Col> */}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='txt'
                                        label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} placeholder={t('label_enter_details')} />
                                    </Form.Item>
                                </div>
                                {/* <div class="tiles-designs" >
                    <Form.Item
                      name='history'
                      label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('Enter History')} />
                    </Form.Item>
                  </div> */}
                            </div>
                            {/* <Form.Item
                  name='otherdeities'
                  label={t('Other Diety in same temple')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
  
                <Form.Item name='descr' label="Temple History">
  
                  <Input.TextArea />
  
                </Form.Item> */}
                            <div class="float-left w-100" ><h5 class="card-text-title">{t('label_additi_det')} <span>| {t('label_ctgry_lang')}</span></h5></div>
                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('Address/Location Details')}</Typography.Title> */}
                            <div class="active-details">
                                <Col span={24}>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="ctgry"
                                            label={<Typography.Title level={4}>{t('label_categ')}</Typography.Title>}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select placeholder={t('label_pls_sel_categ')}>
                                                <Option value="Special Puja">{t("label_spcl_puja")}</Option>
                                                <Option value="Brahmotsav">{t("lable_brahmotsav")}</Option>
                                                <Option value="Festivals">{t("Festivals")}</Option>
                                                <Option value="Annadanam">{t("label_Annadanam")}</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                {/* <Form.Item
                  name='pincode'
                  label={t('Pincode')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}
                                {/* <div class="tiles-designs" >
                                    <Form.Item
                                        name='addr2'
                                        label={<Typography.Title level={4}>{t('village/locality')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder={t('Enter Village/Locality')} />
                                    </Form.Item>
                                </div> */}
                                {/* <Row gutter={12}>
                  <Col span={12}> */}
                                {/* <div class="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_state")}</Typography.Title>}
                                        name="st"
                                        rules={[
                                            { required: true, message: 'Please select your state' },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue={t('Select State')}
                                            onChange={handleStateChange}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {states.map((state) => (
                                                <Select.Option key={state} value={state}>
                                                    {state}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t("label_city")}</Typography.Title>}
                                        name="city"
                                        rules={[
                                            { required: true, message: 'Please enter your city' },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue={t('Select City')}
                                            value={selectedState ? undefined : ''}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {cities.map((city) => (
                                                <Select.Option key={city} value={city}>
                                                    {city}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>*/}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='lnk'
                                        label={<Typography.Title level={4}>{t('label_link')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder={t('label_enter_link')} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs">
                                    <Form.Item
                                        name="dvdt"
                                        label={<Typography.Title level={4}>{t('label_date')}</Typography.Title>}
                                        initialValue={dayjs().add(1, 'day')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('Please select a date'),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ height: '30px' }}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                </div>
                                {/* <div class="tiles-designs" >
                                    <Form.Item
                                        name="ctgid"
                                        label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                        initialValue={selectedLanguage}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Category ID.',
                                            },
                                        ]}
                                    >
                                    </Form.Item>
                                </div> */}
                                {/* </Col>
                </Row> */}

                                {/* <Form.Item
                  name='country'
                  label={t('Country')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}


                            </div>


                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)", marginTop: '20px' }}>{t('Add/Remove Photos')}</Typography.Title> */}
                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name="img"
                                        label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        extra="use png/jpg/jpeg format only"
                                    >
                                        <ImgCrop rotationSlider>
                                            <Upload
                                                name="logo"
                                                action="/upload.do"
                                                listType="picture"
                                                onChange={onFileChange}
                                            >
                                                <button icon={<UploadOutlined style={{ marginBottom: '5px' }} />} class="btn btn-outline-dark"><i class="fa-solid fa-upload"></i>&nbsp;{t('Click to upload')}</button>
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                    <ReactCrop
                                        src="https://via.placeholder.com/150"
                                        crop={crop}
                                        onImageLoaded={onImageLoaded}
                                        onChange={onCropChange}
                                        onComplete={onCropComplete}
                                    />

                                </div>

                                {/* <Button type="primary" htmlType="submit" class="button_move" >{t("label_save")}</Button> */}
                            </div>
                            {/* <Button className='profile-form-button' style={{ width: '25%' }} type="primary" htmlType="submit" >
              {t("label_save")}
            </Button> */}

                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    );
};
export default AddNotification;