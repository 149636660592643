import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form,  message } from 'antd';
import { getPriestDetailsError, getPriestDetailsList, getPriestDetailsLoading } from '../../redux/selectors/selectors';
import { fetchPriestDetails, postProfileImageRequest } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import chaganti from '../../assets/images/chaganti.jpg'
import { useTranslation } from 'react-i18next';
import showToast from '../../utils/showToast';
import Footer from '../pages/Homepage/footer/footer';
import Cropper from 'cropperjs';
import { getServerImageAPI } from '../../http-common';

const PriestDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');
    const { id } = useParams()
    const { name } = useParams()
    const { city } = useParams()
    const { st } = useParams()
    const dispatch = useDispatch();
    const urole = localStorage.getItem('urole')
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);

    const success = () => {
        showToast('success', 'label_add_temple_sucess')
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', msg); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getPriestDetailsList(state),
        rLoading: getPriestDetailsLoading(state),
        rError: getPriestDetailsError(state),
    }));

    useEffect(() => {
        dispatch(fetchPriestDetails(id));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const [previewImage, setPreviewImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result);
                    setIsModalVisible(true);
                };
                reader.readAsDataURL(selectedFile);
            }
        });

        fileInput.click();
    };

    const imageRef = useRef();
    const [cropper, setCropper] = useState(null);
    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy();
        }
    };

    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [newImagePath, setNewImagePath] = useState('');
    const getToken = Boolean(localStorage.getItem('token'));

    const handleCropAndUpload = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData();
                formData.append('file', blob);
                setLoading(true);
                setHasError(false);
                dispatch(
                    postProfileImageRequest(
                        formData,
                        () => {
                            const uploadedImagePath = URL.createObjectURL(blob);
                            setNewImagePath(uploadedImagePath);
                            handleImageCloseModal();
                            setLoading(false);
                            if (getToken) {
                                dispatch(fetchPriestDetails(id));
                            }
                        },
                        (errorMsg) => {
                            console.error('Image upload failed:', errorMsg);
                            setLoading(false);
                            setHasError(true);
                        }
                    )
                );
            });
        }
    };

    useEffect(() => {
        if (isModalVisible && imageRef.current) {
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 16 / 9,
                viewMode: 1,
                autoCropArea: 1,
                responsive: true,
                scalable: false,
                zoomable: true,
                cropBoxResizable: true,
                minCropBoxHeight: 200,
                maxCropBoxHeight: 200,
                minCropBoxWidth: imageRef.current.clientWidth,
                maxCropBoxWidth: imageRef.current.clientWidth,
            });
            setCropper(cropperInstance);
        }

        return () => {
            if (cropper) {
                cropper.destroy();
            }
        };
    }, [isModalVisible]);


    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_priest_by_spclty')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'black' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                    <span className="bc-active-link" onClick={handleGoBack}>{t("label_role_pr")}</span>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                    <span className="bc-link" onClick={handleGoBack}>{t("label_priest_details")}</span>
                </span>
            </div>
            <div class="bg-details-highlight ">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">
                        {name}
                    </div>
                    <div>
                        {(urole === 'PL' || urole === 'PR') && (
                            <a
                                href={`/editpriestDetails/${id}/${name}`}
                                className="button_move"
                                style={{ marginRight: '5px' }}
                            >
                                {t('edit')}
                            </a>
                        )}

                        <a href={`/addPriestReq/${id}/${name}`} className="button_move">
                            {t('label_priest_by_spclty')}
                        </a>
                    </div>

                </div>
            </div>















            <div class="main-container new-prest orangeflavour container mt-3">






                <header class="block d-none">
                    {/*    
<div class="profile-menu">
<p>Me <a href="#26"><span class="entypo-down-open scnd-font-color"></span></a></p>
<div class="profile-picture small-profile-picture">
<img width="40px" alt="Anne Hathaway picture" src="http://upload.wikimedia.org/wikipedia/commons/e/e1/Anne_Hathaway_Face.jpg"/>
</div>
</div> */}
                    <ul class="header-menu horizontal-list">
                        <li>
                            <a class="header-menu-tab" href="#1"><span class="icon entypo-cog scnd-font-color"></span>Settings</a>
                        </li>
                        <li>
                            <a class="header-menu-tab" href="#2"><span class="icon fontawesome-user scnd-font-color"></span>Account</a>
                        </li>
                        <li>
                            <a class="header-menu-tab" href="#3"><span class="icon fontawesome-envelope scnd-font-color"></span>Messages</a>
                            <a class="header-menu-number" href="#4">5</a>
                        </li>
                        <li>
                            <a class="header-menu-tab" href="#5"><span class="icon fontawesome-star-empty scnd-font-color"></span>Favorites</a>
                        </li>
                    </ul>
                </header>
                <div class="">
                    <div class="row">
                        <div class="left-container col-12 col-lg-3 d-none">
                            <div class="menu-box block">
                                <h2 class="titular">MENU BOX</h2>
                                <ul class="menu-box-menu">
                                    <li>
                                        <a class="menu-box-tab" href="#6"><span class="icon fontawesome-envelope scnd-font-color"></span>Messages<div class="menu-box-number">24</div></a>
                                    </li>
                                    <li>
                                        <a class="menu-box-tab" href="#8"><span class="icon entypo-paper-plane scnd-font-color"></span>Invites<div class="menu-box-number">3</div></a>
                                    </li>
                                    <li>
                                        <a class="menu-box-tab" href="#10"><span class="icon entypo-calendar scnd-font-color"></span>Events<div class="menu-box-number">5</div></a>
                                    </li>
                                    <li>
                                        <a class="menu-box-tab" href="#12"><span class="icon entypo-cog scnd-font-color"></span>Account Settings</a>
                                    </li>
                                    <li>
                                        <a class="menu-box-tab" href="#13"><sapn class="icon entypo-chart-line scnd-font-color"></sapn>Statistics</a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="left-container col-12 col-lg-3">
                            <div class="profile block">

                                {/* <div class="profile-picture big-profile-picture clear">
                    <img width="150px" alt="{name} picture" src="http://upload.wikimedia.org/wikipedia/commons/e/e1/Anne_Hathaway_Face.jpg" />
                </div> */}


                                <div class="margin-auto user-pic mt-4 mb-4">

                                    {rList && rList.profimg ? (
                                        <img alt={rList.fullname || 'Default Name'} src={rList.profimg ? getServerImageAPI() + rList.profimg : deftemples} />
                                        //   ) : previewImage ? (
                                        //     <img src={previewImage} alt="Profile" />
                                    ) : (
                                        <img src={chaganti} alt="Default Profile" />
                                    )}
                                </div>
                                <h1 class="user-name">{name} <br />
                                    <span>Memeber Since: {rList && rList.apprdt}</span>
                                </h1>
                                <div class="profile-description">
                                    <p class="scnd-font-color">{rList && rList.addr2},{rList && rList.addr1}</p>
                                    <p class="scnd-font-color">{`${st}, ${city}`}, {rList && rList.pin}</p>
                                    <p class="scnd-font-color">{rList && rList.phone}</p>
                                </div>
                                <ul class="pt-activities-list">
                                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 class="mb-0 d-flex align-items-center"><i class="fa-regular fa-thumbs-up"></i>Likes</h6>
                                        <span class="text-secondary">3</span>
                                    </li>
                                    <Link to='/priestReq' style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 class="mb-0 d-flex align-items-center"><i class="fa-regular fa-message"></i> Messages</h6>
                                        <span class="text-secondary">3</span>
                                    </li>
                                    </Link>
                                </ul>
                            </div>

                            <div class="menu-box block">
                                <h2 class="titular mb-4 pl-4"><span class="icon zocial-twitter"></span>{t('label_email')}</h2>
                                <div class="ml-4 mb-2">TemplesWiki user Email</div>
                                <div class="input-container">
                                    <input type="text" placeholder="email@gmail.com" class="email text-input" />
                                    <div class="input-icon envelope-icon-newsletter"><span class="fontawesome-envelope scnd-font-color"></span></div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center w-100 pb-4"><a class="subscribe button_move" href="#21">Search</a></div>
                            </div>


                            <div class="donut-chart-block block d-none">
                                <h2 class="titular">OS AUDIENCE STATS</h2>
                                <div class="donut-chart">

                                    <div id="porcion1" class="recorte"><div class="quesito ios" data-rel="21"></div></div>
                                    <div id="porcion2" class="recorte"><div class="quesito mac" data-rel="39"></div></div>
                                    <div id="porcion3" class="recorte"><div class="quesito win" data-rel="31"></div></div>
                                    <div id="porcionFin" class="recorte"><div class="quesito linux" data-rel="9"></div></div>

                                    <p class="center-date">JUNE<span class="scnd-font-color">2013</span></p>
                                </div>
                                <ul class="os-percentages horizontal-list">
                                    <li>
                                        <p class="ios os scnd-font-color">iOS</p>
                                        <p class="os-percentage">21<sup>%</sup></p>
                                    </li>
                                    <li>
                                        <p class="mac os scnd-font-color">Mac</p>
                                        <p class="os-percentage">48<sup>%</sup></p>
                                    </li>
                                    <li>
                                        <p class="linux os scnd-font-color">Linux</p>
                                        <p class="os-percentage">9<sup>%</sup></p>
                                    </li>
                                    <li>
                                        <p class="win os scnd-font-color">Win</p>
                                        <p class="os-percentage">32<sup>%</sup></p>
                                    </li>
                                </ul>
                            </div>
                            <div class="line-chart-block block clear d-none">
                                <div class="line-chart">

                                    <div class='grafico'>
                                        <ul class='eje-y'>
                                            <li data-ejeY='30'></li>
                                            <li data-ejeY='20'></li>
                                            <li data-ejeY='10'></li>
                                            <li data-ejeY='0'></li>
                                        </ul>
                                        <ul class='eje-x'>
                                            <li>Apr</li>
                                            <li>May</li>
                                            <li>Jun</li>
                                        </ul>
                                        <span data-valor='25'>
                                            <span data-valor='8'>
                                                <span data-valor='13'>
                                                    <span data-valor='5'>
                                                        <span data-valor='23'>
                                                            <span data-valor='12'>
                                                                <span data-valor='15'>
                                                                </span></span></span></span></span></span></span>
                                    </div>

                                </div>
                                <ul class="time-lenght horizontal-list">
                                    <li><a class="time-lenght-btn" href="#14">Week</a></li>
                                    <li><a class="time-lenght-btn" href="#15">Month</a></li>
                                    <li><a class="time-lenght-btn" href="#16">Year</a></li>
                                </ul>
                                <ul class="month-data clear">
                                    <li>
                                        <p>APR<span class="scnd-font-color"> 2013</span></p>
                                        <p><span class="entypo-plus increment"> </span>21<sup>%</sup></p>
                                    </li>
                                    <li>
                                        <p>MAY<span class="scnd-font-color"> 2013</span></p>
                                        <p><span class="entypo-plus increment"> </span>48<sup>%</sup></p>
                                    </li>
                                    <li>
                                        <p>JUN<span class="scnd-font-color"> 2013</span></p>
                                        <p><span class="entypo-plus increment"> </span>35<sup>%</sup></p>
                                    </li>
                                </ul>
                            </div>
                            <div class="media block d-none">
                                <div id="media-display">
                                    <a class="media-btn play" href="#23"><span class="fontawesome-play"></span></a>
                                </div>
                                <div class="media-control-bar">
                                    <a class="media-btn play" href="#23"><span class="fontawesome-play scnd-font-color"></span></a>
                                    <p class="time-passed">4:15 <span class="time-duration scnd-font-color">/ 9:23</span></p>
                                    <a class="media-btn volume" href="#24"><span class="fontawesome-volume-up scnd-font-color"></span></a>
                                    <a class="media-btn resize" href="#25"><span class="fontawesome-resize-full scnd-font-color"></span></a>
                                </div>
                            </div>
                            <ul class="social horizontal-list block d-none ">
                                <li class="facebook"><p class="icon"><span class="zocial-facebook"></span></p><p class="number">248k</p></li>
                                <li class="twitter"><p class="icon"><span class="zocial-twitter"></span></p><p class="number">30k</p></li>
                                <li class="googleplus"><p class="icon"><span class="zocial-googleplus"></span></p><p class="number">124k</p></li>
                                <li class="mailbox"><p class="icon"><span class="fontawesome-envelope"></span></p><p class="number">89k</p></li>
                            </ul>

                        </div>

                        <div class="middle-container col-12 col-lg-9">
                        {urole !== 'PL' && urole !== 'PR' && (
                            <div class="menu-box block pt-4">

                                <div class="ml-4 mb-2">Ask {name}</div>
                                <div class="input-container d-flex prist-msg">
                                    <input type="text" placeholder="Need Puja for.." class="email text-input" /><button class="ml-2 button_move mr-2">Send</button>
                                </div>
                            </div>
                        )}
                            <div class="weather block clear d-none">
                                <h2 class="titular"><span class="icon entypo-location"></span><strong>CLUJ-NAPOCA</strong>/RO</h2>
                                <div class="current-day">
                                    <p class="current-day-date">FRI 29/06</p>
                                    <p class="current-day-temperature">24º<span class="icon-cloudy"></span></p>
                                </div>
                                <ul class="next-days">
                                    <li>
                                        <a href="#43">
                                            <p class="next-days-date"><span class="day">SAT</span> <span class="scnd-font-color">29/06</span></p>
                                            <p class="next-days-temperature">25º<span class="icon icon-cloudy scnd-font-color"></span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#44">
                                            <p class="next-days-date"><span class="day">SUN</span> <span class="scnd-font-color">30/06</span></p>
                                            <p class="next-days-temperature">22º<span class="icon icon-cloudy2 scnd-font-color"></span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#45">
                                            <p class="next-days-date"><span class="day">MON</span> <span class="scnd-font-color">01/07</span></p>
                                            <p class="next-days-temperature">24º<span class="icon icon-cloudy2 scnd-font-color"></span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#46">
                                            <p class="next-days-date"><span class="day">TUE</span> <span class="scnd-font-color">02/07</span></p>
                                            <p class="next-days-temperature">26º<span class="icon icon-cloudy scnd-font-color"></span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <p class="next-days-date"><span class="day">WED</span> <span class="scnd-font-color">03/07</span></p>
                                            <p class="next-days-temperature">27º<span class="icon icon-sun scnd-font-color"></span></p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <p class="next-days-date"><span class="day">THU</span> <span class="scnd-font-color">04/07</span></p>
                                            <p class="next-days-temperature">29º<span class="icon icon-sun scnd-font-color"></span></p>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="tweets block">
                                <h2 class="titular"><span class="icon zocial-twitter"></span>Educational Info</h2>
                                <div class="tweet first">
                                    <span><a class="time-ago scnd-font-color" href="#18">{rList && rList.degree1}</a></span>
                                    <p>   </p>
                                    <span><a class="time-ago scnd-font-color" href="#18">{rList && rList.degree2}</a></span>
                                </div>

                            </div>
                            <div class="tweets block">
                                <h2 class="titular"><span class="icon zocial-twitter"></span>Pujas Offered</h2>
                                <div class="tweet first">
                                    <p>
                                        {rList && rList.pujas}
                                    </p>
                                </div>
                            </div>
                            <div class="tweets block">
                                <h2 class="titular"><span class="icon zocial-twitter"></span>Specialities</h2>
                                <div class="tweet first">
                                    <p>{rList && rList.splty}</p>
                                </div>
                            </div>
                            <div class="tweets block">
                                <h2 class="titular"><span class="icon zocial-twitter"></span>About {name}</h2>
                                <div class="tweet first">
                                    <p>{rList && rList.summary}</p>
                                </div>
                            </div>
                            <div class="tweets block">
                                <h2 class="titular"><span class="icon zocial-twitter"></span>Awards Info</h2>
                                <div class="tweet first">
                                    <span><a class="time-ago scnd-font-color" href="#18">Great Pujari</a></span>
                                    <p>
                                        Award  in 1995

                                    </p>

                                    <span><a class="time-ago scnd-font-color" href="#18">Sanskrit Bandhu</a></span>
                                    <p>
                                        Award was given for the state teacher group  in 2020 for the most
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="right-container col-12 col-md-3 d-none">
                            <div class="join-newsletter block">
                                <h2 class="titular">{t('label_priest_by_spclty')}</h2>
                                <div class="input-container">
                                    <input type="text" placeholder="email@gmail.com" class="email text-input" />
                                    <div class="input-icon envelope-icon-newsletter"><span class="fontawesome-envelope scnd-font-color"></span></div>
                                </div>
                                <a class="subscribe button" href="#21">Search</a>
                            </div>
                            <div class="account block">
                                <h2 class="titular">SIGN IN TO YOUR ACCOUNT</h2>
                                <div class="input-container">
                                    <input type="text" placeholder="yourname@gmail.com" class="email text-input" />
                                    <div class="input-icon envelope-icon-acount"><span class="fontawesome-envelope scnd-font-color"></span></div>
                                </div>
                                <div class="input-container">
                                    <input type="text" placeholder="Password" class="password text-input" />
                                    <div class="input-icon password-icon"><span class="fontawesome-lock scnd-font-color"></span></div>
                                </div>
                                <a class="sign-in button" href="#22">SIGN IN</a>
                                <p class="scnd-font-color">Forgot Password?</p>
                                <a class="fb-sign-in" href="58">
                                    <p><span class="fb-border"><span class="icon zocial-facebook"></span></span>Sign in with Facebook</p>
                                </a>
                            </div>
                            <div class="loading block d-none">
                                <div class="progress-bar downloading"></div>
                                <p><span class="icon fontawesome-cloud-download scnd-font-color"></span>Downloading...</p>
                                <p class="percentage">81<sup>%</sup></p>
                                <div class="progress-bar uploading"></div>
                                <p><span class="icon fontawesome-cloud-upload scnd-font-color"></span>Uploading...</p>
                                <p class="percentage">43<sup>%</sup></p>
                            </div>
                            <div class="calendar-day block d-none">
                                <div class="arrow-btn-container">
                                    <a class="arrow-btn left" href="#200"><span class="icon fontawesome-angle-left"></span></a>
                                    <h2 class="titular">WEDNESDAY</h2>
                                    <a class="arrow-btn right" href="#201"><span class="icon fontawesome-angle-right"></span></a>
                                </div>
                                <p class="the-day">26</p>
                                <a class="add-event button" href="#27">ADD EVENT</a>
                            </div>
                            <div class="calendar-month block d-none">
                                <div class="arrow-btn-container">
                                    <a class="arrow-btn left" href="#202"><span class="icon fontawesome-angle-left"></span></a>
                                    <h2 class="titular">APRIL 2013</h2>
                                    <a class="arrow-btn right" href="#203"><span class="icon fontawesome-angle-right"></span></a>
                                </div>
                                <table class="calendar">
                                    <thead class="days-week">
                                        <tr>
                                            <th>S</th>
                                            <th>M</th>
                                            <th>T</th>
                                            <th>W</th>
                                            <th>R</th>
                                            <th>F</th>
                                            <th>S</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><a class="scnd-font-color" href="#100">1</a></td>
                                        </tr>
                                        <tr>
                                            <td><a class="scnd-font-color" href="#101">2</a></td>
                                            <td><a class="scnd-font-color" href="#102">3</a></td>
                                            <td><a class="scnd-font-color" href="#103">4</a></td>
                                            <td><a class="scnd-font-color" href="#104">5</a></td>
                                            <td><a class="scnd-font-color" href="#105">6</a></td>
                                            <td><a class="scnd-font-color" href="#106">7</a></td>
                                            <td><a class="scnd-font-color" href="#107">8</a></td>
                                        </tr>
                                        <tr>
                                            <td><a class="scnd-font-color" href="#108">9</a></td>
                                            <td><a class="scnd-font-color" href="#109">10</a></td>
                                            <td><a class="scnd-font-color" href="#110">11</a></td>
                                            <td><a class="scnd-font-color" href="#111">12</a></td>
                                            <td><a class="scnd-font-color" href="#112">13</a></td>
                                            <td><a class="scnd-font-color" href="#113">14</a></td>
                                            <td><a class="scnd-font-color" href="#114">15</a></td>
                                        </tr>
                                        <tr>
                                            <td><a class="scnd-font-color" href="#115">16</a></td>
                                            <td><a class="scnd-font-color" href="#116">17</a></td>
                                            <td><a class="scnd-font-color" href="#117">18</a></td>
                                            <td><a class="scnd-font-color" href="#118">19</a></td>
                                            <td><a class="scnd-font-color" href="#119">20</a></td>
                                            <td><a class="scnd-font-color" href="#120">21</a></td>
                                            <td><a class="scnd-font-color" href="#121">22</a></td>
                                        </tr>
                                        <tr>
                                            <td><a class="scnd-font-color" href="#122">23</a></td>
                                            <td><a class="scnd-font-color" href="#123">24</a></td>
                                            <td><a class="scnd-font-color" href="#124">25</a></td>
                                            <td><a class="today" href="#125">26</a></td>
                                            <td><a href="#126">27</a></td>
                                            <td><a href="#127">28</a></td>
                                            <td><a href="#128">29</a></td>
                                        </tr>
                                        <tr>
                                            <td><a href="#129">30</a></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className="container">
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default PriestDetails;
