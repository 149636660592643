import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchNotifications, fetchTempleNotifi, postClearALLNotifi } from '../../../../redux/actions/acions';
import { getTempleNotifiError, getTempleNotifiList, getTempleNotifiLoading } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import Error from '../error';
import showToast from '../../../../utils/showToast';

let last_rec = 0;
const TempleNotifications = () => {
  const { t } = useTranslation();
  const { tid } = useParams()
  const records_per_page = 12;
  const [clearAll, setClearAll] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
const storedLanguage = localStorage.getItem('lng') || 5;
  const dispatch = useDispatch();
  const { nList, nLoading, nError } = useSelector(state => ({
    nList: getTempleNotifiList(state),
    nLoading: getTempleNotifiLoading(state),
    nError: getTempleNotifiError(state),
  }));

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Cleared all notifications',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Notification deleted',
    });
  };
  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    dispatch(fetchTempleNotifi(storedLanguage, tid, 0))
  }, [tid, storedLanguage]);


  function isNotificationDeleted(id) {
    return deletedIds.includes(id);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);

  // useEffect(() => {
  //     dispatch(fetchNotifications(storedLanguage, last_rec));
  // }, [storedLanguage, currentPage]);


  function nextTemplesList() {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
    dispatch(fetchNotifications(last_rec));
    // setIsLoading(false);
  }

    const navigate = useNavigate();
    const handleGoBack = () => {
      navigate(-1);
    };

    const clearAllsuccess = () => {
      showToast('success', t('label_clearnotifi_success'));
      window.location.reload();
    };
  
    const clearAllfailure = (msg) => {
      messageApi.open({
        type: 'error',
        content: msg,
      });
    };

    function ClearALLNotifi() {
      if (nList && nList.length === 0) {
        showToast('info', t('error_msg_33'));
        return;
      }
      dispatch(postClearALLNotifi(clearAllsuccess,clearAllfailure));
      setClearAll(true);
    }


  return (
    <div class="mainsec">
              <div className="mob-nav fluid-container page-title justify-content-between">
                <div className="d-flex align-items-center left-sec-mob-nav">
                  <Link onClick={handleGoBack}><i className="fa-solid fa-angle-left"></i></Link>
                  <span className="bc-link">{t('label_temple_notifi')}</span>
                </div>
                <div></div>
              </div>
                      <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link">
                        <Link style={{ color: 'black' }} to="/">
                          <span className="bc-active-link">{t('Home')}</span>
                        </Link> - <span className="bc-link"> {t("label_temple_notifi")}</span>
                      </div>
                      <div className="bg-details-highlight">
          <div className="details-headertxt-singleLine container">
            <div className="header_txt"> {t("label_temple_notifi")}</div>
            <div className="details-headertxt-singleLine-btns">
              <a type="button" className="button_move" onClick={ClearALLNotifi} style={{ fontSize: "bold" }}>{t("label_clear_all")}</a>
            </div>
          </div>
        </div>
        <div className="mainsec-block-addtemple-detailspage">
        <div className="container">
        {nLoading ? (
          <Error />
        ) : Array.isArray(nList) ? (
          nList.map((notificationList) => {
            return (
              
                <div key={notificationList.nid} className="tiles-designs-events temp-detils-notifications" >
                   <div className="d-flex flex-space-between">
                  <Link style={{ color: 'black' }} to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                  <span class="events-block-link-title" >{notificationList.subject}</span>
                  </Link>
                  <div className="d-flex" >
                    <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                      <div className="detail-but ml-3">
                        {t('label_show_details')}
                      </div>
                    </Link>
                  </div>
                  </div>
                  <span class="f1 events-block-link">{notificationList.txt}</span>
                </div>
              
            );
          })
        ) : (
          <h4>{t('label_no_notifi')}</h4>
        )}
        </div>

      </div>


    </div>

  )
}

export default TempleNotifications;
