import HTTPClient from "../http-common";
import API_KEYS from "./apiKeys";

class UsersDataService {

  getValidStandardUserLogin(uid, pwd) {
    return HTTPClient.getUserAuthHttp(uid, pwd).get(`/user/authtoken`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getValidOAuthUserLogin(data) {
    return HTTPClient.getHttp().post(`/user/oauthtoken`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }

  getRefreshToken(uid, pwd) {
    return HTTPClient.getUserAuthHttp(uid, pwd).get(`/user/refreshtoken`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getRecentlyViewed(lang, lr) {
    return HTTPClient.getAuthHttp().get(`/temple/${lang}/recent/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateRecentlyViewed(data) {
    return HTTPClient.getAuthHttp().put(`/user/recent`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteImage(tid, imgid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${tid}/image/${imgid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateImage(tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/imgpath/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateInactiveImage(tid, imgid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${tid}/image/${imgid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getGodCategories() {
    return HTTPClient.getHttp().get(`/godcategories`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getFilterByGod(lang, did, lr) {
    return HTTPClient.getHttp().get(`/temple/${lang}/avatar/${did}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getUserNotifications(lang, lr) {
    return HTTPClient.getAuthHttp().get(`/notifi/lang/${lang}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleADNotifications(role, lang, lr) {
    return HTTPClient.getAuthHttp().get(`/notifi/${role}/lang/${lang}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getAppAdmnNotifications(lang, lr) {
    return HTTPClient.getAuthHttp().get(`/notifi/appadmin/lang/${lang}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }


  getNotificationDetails(nid, lang) {
    return HTTPClient.getAuthHttp().get(`/notifi/${nid}/lang/${lang}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getFavourites(lang, avatar, lr) {
    return HTTPClient.getAuthHttp().get(`temple/${lang}/favs/avatar/${avatar}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getResourceFavourites(res, lang, lr) {
    return HTTPClient.getAuthHttp().get(`resource/${res}/${lang}/favs/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleFavourites() {
    return HTTPClient.getAuthHttp().get(`favs/temples`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getBooksFavourites(res) {
    return HTTPClient.getAuthHttp().get(`favs/${res}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getFavouriteAuthors() {
    return HTTPClient.getAuthHttp().get(`favs/writer`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getUsername(id) {
    return HTTPClient.getAuthHttp().get(`/user/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postFavourites(id) {
    return HTTPClient.getAuthHttp().put(`favs/temples/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postClearALLNotifi() {
    return HTTPClient.getAuthHttp().post(`/notifi/clearall`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postBooksFavourites(text, rid) {
    return HTTPClient.getAuthHttp().put(`favs/${text}/${rid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  subscribeToAuthor(id) {
    return HTTPClient.getAuthHttp().put(`favs/writer/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  unsubscribeToAuthor(id) {
    return HTTPClient.getAuthHttp().delete(`favs/writer/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteFavourites(id) {
    return HTTPClient.getAuthHttp().delete(`favs/temples/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteBooksFavourites(text, rid) {
    return HTTPClient.getAuthHttp().delete(`favs/${text}/${rid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }


  postRegisterNewUser(data) {
    return HTTPClient.getHttp().post(`/user`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }

  postPasswordRequest(data) {
    return HTTPClient.getHttp().post(`/user/forgotpwd`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }

  anonomousUser() {
    return HTTPClient.getHttp().get(`/user/pubtoken`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }

  postPasswordReset(mail, data) {
    return HTTPClient.getHttp().put(`/user/resetpwd/${mail}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }

  postSetNewPassword(data) {
    return HTTPClient.getAuthHttp().put(`/user/pcode`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postUploadImage(tid, formData) {
    return HTTPClient.getAuthHttp().post(`/temple/${tid}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  postProfileImage(formData) {
    return HTTPClient.getAuthHttp().post(`/user/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getProfileImage(path) {
    return HTTPClient.getAuthHttp().get(`/user/img/${path}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        // 'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }

  postLibraryUploadImage(res, rid, formData) {
    return HTTPClient.getAuthHttp().post(`/resource/${res}/${rid}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }


  getVideoLinks(res, lang, did, lr) {
    return HTTPClient.getHttp().get(`resource/${res}/${lang}/avatar/${did}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getBooks(res, lang, did, lr) {
    return HTTPClient.getHttp().get(`resource/${res}/${lang}/avatar/${did}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }


  getBooksByTag(res, lang, did, tag, lr) {
    return HTTPClient.getHttp().get(`resource/${res}/${lang}/avatar/${did}/tag/${tag}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleBooks(lang, tid, did) {
    return HTTPClient.getHttp().get(`resource/${lang}/tid/${tid}/avatar/${did}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleNotifi(lang, tid, lr) {
    return HTTPClient.getHttp().get(`notifi/lang/${lang}/tid/${tid}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getRegionalNotifi(lang, lr) {
    return HTTPClient.getHttp().get(`notifi/lang/${lang}/state/Andhra Pradesh/city/Kakinada/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleAudio(path, filename) {
    return HTTPClient.getHttp().get(`temple/audio/${path}/${filename}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  getTempleVideo(lang, did, tid) {
    return HTTPClient.getHttp().get(`resource/${tid}/video/${lang}/avatar/${did}/lr/0`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  get(id) {
    return HTTPClient.getHttp().get(`/temple/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  create(data) {
    return HTTPClient.getHttp().post("/temple", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postNotification(data) {
    return HTTPClient.getAuthHttp().post("/notifi", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postNotifiTxt(data) {
    return HTTPClient.getAuthHttp().post("/notifi/dataload", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  update(id, data) {
    return HTTPClient.getHttp().put(`/temple/${id}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateLang(data) {
    return HTTPClient.getAuthHttp().put(`/user/lang`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateGender(data) {
    return HTTPClient.getAuthHttp().put(`/user/gender`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateCity(data) {
    return HTTPClient.getAuthHttp().put(`/user/loc`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateName(data) {
    return HTTPClient.getAuthHttp().put(`/user/name`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateNakshtra(data) {
    return HTTPClient.getAuthHttp().put(`/user/nakshtra`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteMyProfile() {
    return HTTPClient.getAuthHttp().delete(`/user`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateAvatar(tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/avatar/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleDetails(tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateNotificationDetails(lang, nid, data) {
    return HTTPClient.getAuthHttp().put(`/notifi/${nid}/fields/${lang}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteAdminNotification(nid) {
    return HTTPClient.getAuthHttp().delete(`/notifi/${nid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteNotification(data) {
    return HTTPClient.getAuthHttp().put(`/notifi`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateNotificationDate(nid, data) {
    return HTTPClient.getAuthHttp().put(`/notifi/${nid}/date`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleLocation(tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/location/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleFields(lang, tid, ts, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/fields/${tid}/ts/${ts}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleDescr(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/descr/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleHist(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/history/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleAddress(tid, ts, data) {
    return HTTPClient.getAuthHttp().put(`/temple/address/${tid}/ts/${ts}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  // updateTempleGodAvatar(tid, data) {
  //   return HTTPClient.getAuthHttp().put(`/temple/avatar/${tid}`, data);
  // }

  updateTempleImagesStatus(tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${tid}/images`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTempleStatus(tid, status, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${tid}/status/${status}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getState() {
    return HTTPClient.getAuthHttp().get(`/user`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getMutliRoles(role) {
    return HTTPClient.getAuthHttp().get(`/user/switchrole/${role}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getShare(type, id, lang, scode) {
    return HTTPClient.getHttp().get(`/${type}/share/${id}/lang/${lang}/scode/${scode}`);
  }

  getResource(lang, lr) {
    return HTTPClient.getAuthHttp().get(`/resource/${lang}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getResourceByTxt(res, lang, txt, lr) {
    return HTTPClient.getHttp().get(`/resource/${res}/${lang}/txt/${txt}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  postResource(data) {
    return HTTPClient.getAuthHttp().post("/resource", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postResourceTxt(data) {
    return HTTPClient.getAuthHttp().post("/resource/dataload", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postHoroTxt(data) {
    return HTTPClient.getAuthHttp().post("/horoscope/dataload", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getByStDt(lang, st, dt, lr) {
    return HTTPClient.getAuthHttp().get(`temple/${lang}/state/${st}/city/${dt}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  getByStDtText(lang, st, dt, txt, lr) {
    return HTTPClient.getAuthHttp().get(`temple/${lang}/state/${st}/city/${dt}/txt/${txt}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  getCityTemplesList(lang, st, lr) {
    return HTTPClient.getAuthHttp().get(`temple/${lang}/state/${st}/city/ALL/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getFilterByStDt(lang, st, dt, did, lr) {
    return HTTPClient.getHttp().get(`temple/${lang}/state/${st}/city/${dt}/avatar/${did}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  getTemplesByGroup(lang, gid, lr) {
    return HTTPClient.getHttp().get(`/temple/${lang}/groups/${gid}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getDailyHoroscope(lang, dt) {
    return HTTPClient.getHttp().get(`/calendar/1/lang/${lang}/dt/${dt}/info`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getHoroscopeDetailsByDate(id, lang, date) {
    return HTTPClient.getHttp().get(`/calendar/${id}/lang/${lang}/dt/${date}/details`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }


  getWeeklyHoroscope(type, lang, dt) {
    return HTTPClient.getHttp().get(`/horoscope/${type}/1/lang/${lang}/dt/${dt}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getDailyHoroscopeDetails(id, lang) {
    return HTTPClient.getHttp().get(`/calendar/${id}/lang/${lang}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getWeeklyHoroscopeDetails(id, lang) {
    return HTTPClient.getHttp().get(`/horoscope/${id}/lang/${lang}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

 

  getDailyHoroscopeList(lang, lr) {
    return HTTPClient.getHttp().get(`/calendar/lang/${lang}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getCityStDescr(lang, st, city) {
    return HTTPClient.getHttp().get(`/content/lang/${lang}/state/${st}/city/${city}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getWeeklyHoroscopeList(lang, type, lr) {
    return HTTPClient.getHttp().get(`/horoscope/lang/${lang}/${type}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleGroups(name, lang) {
    return HTTPClient.getHttp().get(`/${name}/${lang}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getGroupDescription(lang, gid) {
    return HTTPClient.getAuthHttp().get(`/groups/${lang}/gid/${gid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  postTempleGroup(data) {
    return HTTPClient.getAuthHttp().post("/groups", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  addTempleToGroup(gid, tid) {
    return HTTPClient.getAuthHttp().post(`/groups/${gid}/temple/${tid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  removeTempleFromGroup(gid, tid) {
    return HTTPClient.getAuthHttp().delete(`/groups/${gid}/temple/${tid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTemplesByTxt(lang, txt, lr) {
    return HTTPClient.getHttp().get(`/temple/${lang}/txt/${txt}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getNearest(lang, lat, lon, lr) {
    return HTTPClient.getHttp().get(`temple/${lang}/latit/${lat}/longi/${lon}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getNearByMe(lang, lat, lon, txt, lr) {
    return HTTPClient.getHttp().get(`temple/${lang}/latit/${lat}/longi/${lon}/txt/${txt}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getFilterNearest(lang, lat, lon, did, lr) {
    return HTTPClient.getHttp().get(`temple/${lang}/latit/${lat}/longi/${lon}/avatar/${did}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }
  //   delete(id) {
  //     return HTTPClient.getHttp().delete(`/temple/${id}`);
  //   }

  findByNameOrCity(name_city) {
    return HTTPClient.getHttp().get(`/temple?title=${name_city}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }
}

export default new UsersDataService();
