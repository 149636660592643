import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading,  getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, removeBooksFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import {Input, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CommonResource from './commonResource';
import showToast from '../../../../utils/showToast';

function Books() {
  const { t } = useTranslation();
  const records_per_page = 12;
  const [isLoading, setIsLoading] = useState(false);
const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const localEnv = false;
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();;
  const { uid } = useParams()


  const [books] = useState('books');
  const { bDetails } = useSelector(state => ({
    bDetails: getBooksList(state),
    bLoading: getBooksLoading(state),
    bError: getBooksError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    if (selectedItem === null) {
      dispatch(fetchBooks(books, storedLanguage, did, 0))
    }
    else if (selectedItem != null) {
      dispatch(fetchBooksByTag(books, storedLanguage, filterId, selectedItem, 0));
    }
  }, [storedLanguage, did])
  

  const { tDetails } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
  }))

  
  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

 

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }


  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

 
  const [currentPage, setCurrentPage] = useState(0);
  const mainSecBlockRef = useRef(null);
  const nextTemplesList = async () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;

      if (filterId !== null && selectedItem === null) {
        const newLastRec = newPage * records_per_page;
        dispatch(fetchBooks(books, storedLanguage, did, newLastRec));
      }
      else if (selectedItem !== null) {
        const newSelectedLastRec = newPage * records_per_page;
        dispatch(fetchBooksByTag(books, storedLanguage, filterId, selectedItem, newSelectedLastRec));
      }
      else if (searchText !== null) {
        const newSearchLastRec = newPage * records_per_page;
        dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
      }
      if (mainSecBlockRef.current) {
        mainSecBlockRef.current.scrollTop = 0; // Scroll to the top of the container
      }
      return newPage;
    });
    setIsLoading(false);
  };

  // Previous button handler
  const prevTemplesList = () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      if (prevPage > 0) { // Ensure we don't go below the first page
        const newPage = prevPage - 1; // Decrement page

        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
          dispatch(fetchBooks(books, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * records_per_page; // Previous offset for selected items
          dispatch(fetchBooksByTag(books, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * records_per_page; // Previous offset for search books
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
          mainSecBlockRef.current.scrollTop = 0; // Scroll to the top of the container
        }
        return newPage; // Return updated page number
      }

      return prevPage; // If we're on the first page, don't decrement
    });
    setIsLoading(false);
  };


  const [refresh, setRefresh] = useState(false);

  const success = (tname) => {
    showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
};

const warning = (tname) => {
    showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
};

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };

  const [getFavouriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    if(getToken){
      dispatch(fetchBooksFavourites());
    }
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }


  const getToken = Boolean(localStorage.getItem('token'));


  const { sList } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtList(state),
    sError: getResourceByTxtError(state),
  }));

  useEffect(() => {
    if(uid != '0'){
      dispatch(fetchResourceByTxt(books, storedLanguage, searchText, 0));
    }
    else{
      dispatch(fetchResourceByTxt(books, storedLanguage, uid, 0));
    }
    
  }, [storedLanguage, searchText]);

  const [searchText, setSearchText] = useState(uid);

  const navigate = useNavigate();

  return (
    <>
      {contextHolder}
      
      <CommonResource 
      title={t('label_books')}
      description={t('label_about_books')}
      bDetails={bDetails}
      sList={sList}
      tDetails={tDetails}
      nextTemplesList={nextTemplesList}
      prevTemplesList={prevTemplesList}
      fetchResource={fetchBooks}
      text={books}
      filterTemplesList={filterTemplesList}
      currentPage={currentPage}
      mainSecBlockRef={mainSecBlockRef}
      />
      


    </>
  )
}


export default Books;