import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Carousel, Input, Select, Typography, message } from 'antd';
import {
    ShareAltOutlined,
    SearchOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
} from '@ant-design/icons';
import './details.css';
import logo from '../../../../assets/images/img.png';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading, getTempleDetails, getTempleDetailsError, getTempleDetailsLoading } from '../../../../redux/selectors/selectors';
import { fetchNotifications, fetchTempleDetails } from '../../../../redux/actions/acions';
import { Footer } from 'antd/es/layout/layout';
import Error from '../error';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { getImageAPI } from '../../../../http-common';
import { useTranslation } from 'react-i18next';
import video from '../../../../assets/video/temple.mp4'
import useScreenWidth from '../Header/useScreenWidth';
import { Option } from 'antd/es/mentions';
import i18next from 'i18next';
import AP_LANGUAGES from "../../../common/appLangs"


function Details() {
    const [deletedIds, setDeletedIds] = useState([]);
    const [clearAll, setClearAll] = useState(false);
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false);
    const storedLanguage = localStorage.getItem('lng');
    const toggleReadMore = () => {
        setExpanded(!expanded);
    };
    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector(state => ({
        nList: getNotificationsList(state),
        nLoading: getNotificationstionsLoading(state),
        nError: getNotificationsError(state),
    }));

    useEffect(() => {
        dispatch(fetchNotifications(storedLanguage, 0));
    }, []);

    function isNotificationDeleted(id) {
        return deletedIds.includes(id);
    }

    function deleteNotification(id) {
        setDeletedIds([...deletedIds, id]);
    }

    const shareResLink = (title, txt, rtype, rid, lang) => {
        const web_url = 'https://www.templeswiki.com/share?id=' + rid + '&type=' + rtype + '&lang=' + lang;
		if (!window.flutter_inappwebview) {
            navigator.share({
                title: title,
                text: txt,
                url: web_url
            })
            .then(() => console.warn("Share successful."))
            .catch((error) => console.error("Error sharing:", error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error("Web Share API not supported.");
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, web_url);
        }
    };
    
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Cleared all notifications',
        });
    };
    const warning = () => {
        messageApi.open({
            type: 'warning',
            content: 'Notification deleted',
        });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
        });
    };

    const { tid } = useParams();

    const { tDetails = [], tLoading, tError } = useSelector(state => ({
        tDetails: getTempleDetails(state),
        tLoading: getTempleDetailsLoading(state),
        tError: getTempleDetailsError(state)
    }));

    useEffect(() => {
        dispatch(fetchTempleDetails(storedLanguage, tid, failure));
    }, [tid, dispatch]);

    const scrollToNotifications = () => {
        const notificationsSection = document.getElementById('notifications-section');
        if (notificationsSection) {
            notificationsSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const [expandedCircleId, setExpandedCircleId] = useState(null);

    const handleCircleHover = (id) => {
        setExpandedCircleId(id);
    };

    const handleCircleHoverOut = () => {
        setExpandedCircleId(null);
    };

    const handleLogoClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleChange = (value) => {
        i18next.changeLanguage(value)
        localStorage.setItem('lng', value)
      };

    const templeDetails = (tLoading || tDetails == null) ? {} : tDetails
    const screenWidth = useScreenWidth();
    const isMobile = screenWidth <= 768;

    return (
        <>
            {contextHolder}
            <div className="header ">
                {/* <BackTop /> */}
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="TemplesWIKI Logo" />
                </div>
                <div className="nav-links">
                    <Link to="/about">ABOUT</Link>
                    <Link to="/services">SERVICES</Link>
                    <Link to="/calendar">CALENDER</Link>
                    <Link to="/donate">DONATE</Link>
                    <Link to="/contact">CONTACT</Link>
                    <Link to="/d2d">D2D</Link>
                    <div className='search1'>
                        <Input className='in custom-input'
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            style={{ width: 200, height: '30px', top: '-2px' }}
                        />
                    </div>
                    <div className='language'>
                      <Select
                        className="select"
                        defaultValue={storedLanguage}
                        onChange={handleChange}
                        style={{ width: '100%'}}
                      >
                        {AP_LANGUAGES.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {isMobile ? item.label2 : item.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                </div>
            </div>
            <div className="video-container ">
                <div className="welcome-text">
                    <Typography.Title>{t("label_welcome")} {templeDetails.tname}!</Typography.Title>
                    <div className="down-arrow" onClick={scrollToNotifications}>
                        <div className="circle">
                            <ArrowDownOutlined />
                        </div>
                    </div>
                </div>
                <div className="autoplay-video">
                    <video autoPlay loop muted playsInline>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </div>
            <section>
                <div id="notifications-section" className="not container">
                    <Typography.Title style={{ color: '#140430', fontFamily: "LeagueSpartan" }} level={1}>{t('label_notif')}</Typography.Title>
                    {clearAll ? (
                        <></>
                    ) : (
                        <div className="carousel">
                            <Carousel
                                slidesToShow={1}
                                swipeable={true}
                                showDots={false}
                                infiniteLoop={true}
                                autoPlay={false}
                            >
                                {nLoading ? (
                                    <p>Loading</p>
                                ) : (
                                    (Array.isArray(nList) ? nList : [])
                                        .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                                        .map((notificationList) => {
                                            const isRead = notificationList.read;
                                            const notificationClass = isRead ? 'notification-read' : 'notification-unread';
                                            return (
                                                <div key={notificationList.nid} className={`notification-list ${notificationClass}`}>
                                                    <Card hoverable>
                                                        <Typography.Title level={3}>{notificationList.ctgry}</Typography.Title>
                                                        <Typography.Title level={4}>{notificationList.txt}</Typography.Title>
                                                        <DeleteOutlined style={{ marginTop: '-17px' }} onClick={() => { deleteNotification(notificationList.nid); warning(); }} />
                                                    </Card>
                                                </div>
                                            );
                                        })
                                )}
                            </Carousel>
                        </div>
                    )}
                </div>
            </section>

            <section>
                <div className="image">
                    <img src={logo} alt="TemplesWIKI Logo" />
                </div>
                <div className="container1 center-text">
                    <Typography.Title style={{ color: '#140430' }} level={1}>{t("label_descr_history")}</Typography.Title>
                    <div className="centered-paragraphs">
                        {tLoading ? <p>Loading...</p> : null}
                        {tError ? <p>{<Error />}</p> : null}
                        {templeDetails && (
                            <div>

                                <Typography.Title level={4}> {templeDetails.tname}</Typography.Title>
                                <h3>{templeDetails.descr ? templeDetails.descr.split("<BR>").join("\n") : 'No Data Found'}</h3>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <section>   
                <div className=" center-text1">
                    <Typography.Title style={{ color: '#140430' }} level={1}>{t("label_tpl_images")}</Typography.Title>
                    <div className="carousel-container1">
                        <Carousel autoplay>
                            {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                templeDetails.images.map(image => {
                                    return (
                                        <div>
                                            <img
                                                style={{ width: '40vw', height: '70vh' }}
                                                src={getImageAPI() + image.imgpath}
                                                alt="images"
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <div>
                                    <img
                                        style={{ width: '30vw', height: '40vh' }}
                                        alt="temples"
                                        src={deftemples}
                                    />
                                </div>
                            )}
                        </Carousel>
                    </div>
                </div>
            </section>
            <section>
                <div className=" center-text">
                    <Typography.Title style={{ color: '#140430' }} level={1}>{t("label_get_inv")}</Typography.Title>
                </div>
                <div className="image-container container-fluid">
                    <div className="circle-info-container">
                        <div
                            className={`circle-image ${expandedCircleId === 'circle1' ? 'expanded' : ''}`}
                            onMouseEnter={() => handleCircleHover('circle1')}
                            onMouseLeave={handleCircleHoverOut}
                        >
                            <img src={deftemples} alt="Image 1" />
                        </div>

                        <div className='daily'>
                        <Typography.Title level={4}>Daily Pujas</Typography.Title>
                            <h1 style={{ whiteSpace: "pre-wrap" }}>{templeDetails.dailypujas ? templeDetails.dailypujas.split("<BR>").join("\n") : ''}</h1>
                        </div>

                    </div>
                    <div className="circle-info-container">
                        <div
                            className={`circle-image ${expandedCircleId === 'circle2' ? 'expanded' : ''}`}
                            onMouseEnter={() => handleCircleHover('circle2')}
                            onMouseLeave={handleCircleHoverOut}
                        >
                            <img src={deftemples} alt="Image 2" />
                        </div>
                        <div className='daily' >
                        <Typography.Title level={4}>festivals</Typography.Title>
                            <h1 style={{ whiteSpace: "pre-wrap" }}>{templeDetails.festivals ? templeDetails.festivals.split("<BR>").join("\n") : ''}</h1>
                        </div>
                    </div>
                    <div className="circle-info-container">
                        <div
                            className={`circle-image ${expandedCircleId === 'circle3' ? 'expanded' : ''}`}
                            onMouseEnter={() => handleCircleHover('circle3')}
                            onMouseLeave={handleCircleHoverOut}
                        >
                            <img src={deftemples} alt="Image 3" />
                        </div>
                        <div className='daily' >
                        <Typography.Title level={4}>Special Pujas</Typography.Title>
                            <h1 style={{ whiteSpace: "pre-wrap" }}>{templeDetails.splpujas ? templeDetails.splpujas.split("<BR>").join("\n") : 'No Data Found'}</h1>
                        </div>
                    </div>
                    <div className="circle-info-container">
                        <div
                            className={`circle-image ${expandedCircleId === 'circle4' ? 'expanded' : ''}`}
                            onMouseEnter={() => handleCircleHover('circle4')}
                            onMouseLeave={handleCircleHoverOut}
                        >
                            <img src={deftemples} alt="Image 4" />
                        </div>
                        <div className='daily' >
                        <Typography.Title level={4}>Accomodation</Typography.Title>
                            <h1 style={{ whiteSpace: "pre-wrap" }}>{templeDetails.accomodation ? templeDetails.accomodation.split("<BR>").join("\n") : 'No Data Found'}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <Footer>
                <div className='copyright'>
                    <Typography.Title level={2}>{t("label_time")}</Typography.Title>
                    <Typography.Title level={4} style={{ whiteSpace: "pre-wrap" }}>{templeDetails.timings ? templeDetails.timings.split("<BR>").join("\n") : 'No Data Found'}</Typography.Title><br />
                    <Button
                        className="share-button"
                        type="link"
                        onClick={() => shareResLink(templeDetails.tname,
                                        "Check out this temple!",
                                        'temple', templeDetails.tid, storedLanguage// Share the current page URL
                        )}
                    >
                        <ShareAltOutlined style={{ fontSize: '24px', marginRight: '55px' }} />
                    </Button>
                    <Typography.Text>© 2023 by TemplesWIKI designed by VAVILI TECHNOLOGIES</Typography.Text>
                </div>
            </Footer>


        </>
    )
}

export default Details;
