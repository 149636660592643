import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGodNames,
  fetchGroupDescription,
  fetchTempleGroups,
  fetchTemplesByGroup,
  retrieveGodNames,
  retrieveTemples,
} from "../../../../redux/actions/acions";
import {
  getTempleGroupsList,
  getTempleGroupsLoading,
  getTempleGroupsError,
  getTempleGroupList,
  getTempleGroupLoading,
  getTempleGroupError,
  getGroupDescriptionList,
  getGroupDescriptionLoading,
  getGroupDescriptionError,
} from "../../../../redux/selectors/selectors";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../../search.css";
import { useTranslation } from "react-i18next";
import TemplesPage from "../../../common/TemplesPage";
import deftemples from "../../../../assets/images/templeicon.png";
import Images from "../../../common/images";

const TempleGroup = () => {
  const { t } = useTranslation();
  const [filterId, setFilterId] = useState(null);
  const records_per_page = 12;
  const { gid } = useParams();
  const localEnv = false;
  const storedLanguage = localStorage.getItem("lng") || 5;
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [lastRec, setLastRec] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    tList,
    tLoading,
    godCtgryList,
    godCtgryListLoading,
    gList,
  } = useSelector((state) => ({
    tList: getTempleGroupsList(state),
    tLoading: getTempleGroupsLoading(state),
    tError: getTempleGroupsError(state),
    godCtgryList: getTempleGroupList(state),
    godCtgryListLoading: getTempleGroupLoading(state),
    godCtgryListError: getTempleGroupError(state),
    gList: getGroupDescriptionList(state),
    gLoading: getGroupDescriptionLoading(state),
    gError: getGroupDescriptionError(state),
  }));

  // Fetch necessary data on component mount
  useEffect(() => {
    dispatch(localEnv ? fetchGodNames() : retrieveGodNames(storedLanguage));
    dispatch(fetchTempleGroups('groups', storedLanguage));
    dispatch(fetchGroupDescription(storedLanguage, gid));
  }, [storedLanguage, gid, dispatch, localEnv]);

  useEffect(() => {
    if (gid !== 507) {
      dispatch(fetchTemplesByGroup(storedLanguage, gid, lastRec));
    }
  }, [storedLanguage, gid, dispatch, lastRec]);

  const filterTemplesList = (gDid) => {
    setFilterId(gDid);
    if (gDid === filterId) {
      setFilterId(null);
      dispatch(retrieveTemples(storedLanguage, lastRec));
    }
  };

  // Handle pagination: Next
  const nextTemplesList = async () => {
    setIsLoading(true);
    const updatedLastRec = lastRec + records_per_page;
    await dispatch(fetchTemplesByGroup(storedLanguage, gid, updatedLastRec));
    setLastRec(updatedLastRec);
    setIsLoading(false);
  };

  // Handle pagination: Previous
  const prevTemplesList = () => {
    const updatedLastRec = Math.max(lastRec - records_per_page, 0);
    dispatch(fetchTemplesByGroup(storedLanguage, gid, updatedLastRec));
    setLastRec(updatedLastRec);
  };

  // Save the previous path to the session
  useEffect(() => {
    const currentPath = location.pathname;
    const previousPath = sessionStorage.getItem('previousPath');
    if (currentPath !== previousPath) {
      sessionStorage.setItem('previousPath', currentPath);
    }
  }, [location]);

  const handleGoBack = () => {
    const previousPath = sessionStorage.getItem('previousPath') || '/';
    sessionStorage.removeItem('previousPath'); // Clear the stored path
    navigate(previousPath, { replace: true });
  };

  return (
    <>
      <TemplesPage
        title={gList&&gList.group_name}
        description={gList&&gList.descr}
        tLoading={tLoading}
        tList={tList}
        pageTitle={gid}
        filterId={filterId}
        setFilterId={setFilterId}
        filterTemplesList={filterTemplesList}
        gList={godCtgryList}
        godCtgryListLoading={godCtgryListLoading}
        temple={gList&&gList.descr}
        nextTemplesList={nextTemplesList}
        prevTemplesList={prevTemplesList}
        last_rec={lastRec}
        image={Images[gList&&gList.imgpath] || deftemples}
        onBack={handleGoBack}
        isLoading={isLoading}
      />
    </>
  );
};

export default TempleGroup;
