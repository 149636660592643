import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Checkbox, Col, Drawer, Dropdown, Form, Input, Menu, Modal, Popover, Result, Row, Select, Spin, Typography, Upload, message, notification } from 'antd';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { editLang, fetchNotifications, fetchRefreshToken, fetchState, fetchTempleFavourites, fetchValidUserLogin, fetchOAuthValidUserLogin, postClearALLNotifi, postPasswordRequest, postRegisterUserRequest, postUserActivity, removeNotification } from '../../../../redux/actions/acions';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading, getStateError, getStateList, getStateLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from '../../../../redux/selectors/selectors';
import SubMenu from 'antd/es/menu/SubMenu';
import useScreenWidth from './useScreenWidth';
import showToast from '../../../../utils/showToast';
import { ToastContainer } from 'react-toastify';
import Error from '../error';
import AP_LANGUAGES from "../../../common/appLangs"
import { getServerImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import SearchPopup from './searchPopup';
import Login1 from './login';

function PageHeader() {
  const [token, setToken] = useState('');
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [selectedNotification, setSelectedNotification] = useState(null); // Store selected notification ID
  const [loading, setLoading] = useState(false); // Control loading state
  const { Option } = Select;
  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const getName = localStorage.getItem('name');
    const getUrole = localStorage.getItem('urole');
    if (getToken && getName) {
      setToken(getToken);
      setName(getName)
      setRole(getUrole)
    }
  }, [showDrawer])

  const [clearAll, setClearAll] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const { t } = useTranslation();
  const version = process.env.REACT_APP_VERSION;

  const { tid } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [placement, setPlacement] = useState('left');

  function fetchUseractivity() {
    const pageName = location.pathname;
    const data = {
      page: pageName,
      action: 'Click',
      message: `Visited ${pageName}`,
    };

    dispatch(postUserActivity(data));
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Set i18n language based on localStorage or default
    i18next.changeLanguage(selectedLanguage);
    localStorage.setItem('lng', selectedLanguage); // Ensure consistency on initial load
  }, [selectedLanguage]);

  const handleChange = (value) => {
    setSelectedLanguage(value); // Update state
    i18next.changeLanguage(value); // Change i18n language
    localStorage.setItem('lng', value); // Save in localStorage
  };
  const storedEmail = localStorage.getItem('userEmail');
  const defaultLanguage = sDetails && sDetails.lang ? sDetails.lang : '1';

  const defaultLanguageObj = AP_LANGUAGES.find(language => language.value === defaultLanguage);

  const handleChange1 = (value) => {
    i18next.changeLanguage(value)
    localStorage.setItem('lng', value)
    dispatch(
      editLang({ lang: `${value}` }, () => {
        showToast('success', t('label_update_lang_sucess'));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      )
    );
  };

  const phoneRegex = /^[0-9]{1,10}$/;

  const validatePhoneNumber = (_, value) => {
    if (value && !phoneRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
    }
    return Promise.resolve();
  };

  const handleLoginCount = () => {
    const token = localStorage.getItem('token'); // Replace with your actual token key

    if (token) {
      let loginCount = parseInt(localStorage.getItem('userLogin'), 10) || 0;

      // Get the last login timestamp
      const lastLogin = localStorage.getItem('lastLoginTime');
      const currentTime = Date.now();

      // Increment only if it's a new login session (avoid page refresh increments)
      if (!lastLogin || currentTime - parseInt(lastLogin, 10) > 5000) {
        loginCount += 1;
        localStorage.setItem('userLogin', loginCount);
        localStorage.setItem('lastLoginTime', currentTime);
      }

      // Set userLoginCount as well
      localStorage.setItem('userLoginCount', loginCount);
    }
  };

  // Call this function **only after a successful login or token refresh**
  handleLoginCount();

  // localStorage.setItem('userEmail', storedEmail);

  const { uid } = useParams()
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('lng') || '1'
  );
  const [refresh, setRefresh] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  //const firstLetter = getName.charAt(0);
  const isHomepage = window.location.pathname === '/';
  const [image, setImage] = useState(null); // State variable to store the uploaded image
  const [previewVisible, setPreviewVisible] = useState(false); // State variable to control the visibility of the image preview modal
  const [previewImage, setPreviewImage] = useState(''); // State variable to store the URL of the image to be previewed

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmail = (_, value) => {
    if (value && !emailRegex.test(value)) {
      return Promise.reject(new Error("Please enter a valid email address."));
    }
    return Promise.resolve();
  };

  //   const success = async () => {
  //     showToast('success', t('label_login_sucess'));
  //     setIsLoginModalOpen(false);
  //     setIsLoading(true);
  //     // Simulate a 2-second delay for the example
  //     await new Promise(resolve => setTimeout(resolve, 2000));
  //     window.location.reload();
  //     setTimeout(() => {
  //         setShowWelcome(true);
  //     }, 30); // 30-second delay for welcome message

  // };


  const [selectedRole, setSelectedRole] = useState(null);
  const success = async () => {
    showToast('success', t('label_login_sucess'));
    setIsLoginModalOpen(false);
    setIsLoading(true);


    await new Promise(resolve => setTimeout(resolve, 2000));

    navigate(selectedRole === 'AD' ? '/quicklinks' : selectedRole === 'TA' ? '/templeDashboard' : '/');
    setIsLoading(false);
    setShowLoginForm(false);
    window.location.reload()
    setTimeout(() => {
      setShowWelcome(true);
    }, 30);
    localStorage.setItem('showWelcome', true);
    const token = Boolean(localStorage.getItem('token'));
    localStorage.setItem('logintime', token ? true : false);
  };

  // On component mount, check if showWelcome is stored in localStorage
  useEffect(() => {
    const storedShowWelcome = localStorage.getItem('showWelcome') === 'true';

    if (storedShowWelcome) {
      setShowWelcome(true);  // Show the welcome message
      localStorage.removeItem('showWelcome'); // Clean up after showing the welcome message

      // Automatically hide the welcome message after 3 seconds
      setTimeout(() => {
        setShowWelcome(false);
      }, 3000);
    }
  }, []);


  const failure = (msg) => {
    setLoginError(msg); // Set the error message using the `setLoginError` state function
    showToast('error', msg);
  };


  const key = 'updatable';
  const openMessage = () => {

    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    setTimeout(() => {
      showToast('success', t('label_register_sucess'));
    }, 1000);
  };

  const { sDetails, sLoading, sError } = useSelector(state => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state)
  }));
  useEffect(() => {
    if (getToken) {
      dispatch(fetchState());
    }
  }, [])


  // useEffect(() => {
  //   i18next.changeLanguage('1')
  //   localStorage.setItem('lng', 1)
  // }, [])


  const forgetSuccess = () => {
    showToast('success', 'Check your mail to change password!');
    // messageApi.open({
    //   type: 'success',
    //   content: 'Check your mail to change password',
    // });
  };
  const forgetFailure = (msg) => {
    showToast('error', msg);
  };
  const { pid } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onRegister = (values) => {


    dispatch(postRegisterUserRequest(values, openMessage, failure))
  };


  const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);

  const forgetShowModal = () => {
    setIsForgetModalOpen(true);
  };

  const forgethandleOk = () => {
    setIsForgetModalOpen(false);
  };

  const forgethandleCancel = () => {
    setIsForgetModalOpen(false);
  };

  const handleForgetCancel = () => {
    // Add your logic for handling cancel here
    setIsForgetModalOpen(false); // Assuming you want to close the Forget Password modal
  };

  const forgetOnFinish = (values) => {
    const data = {
      "email": values.email
    }
    dispatch(postPasswordRequest(data, forgetSuccess, forgetFailure));

  };

  const handlePredefinedLogin = (email, password) => {
    // Set predefined values
    form.setFieldsValue({
      username: email,
      password: password
    });
    // Automatically submit the form
    form.submit();
  };

  const [form] = Form.useForm();

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const dispatch = useDispatch();

  const loginShowModal = () => {
    setIsLoginModalOpen(true);
  };

  const loginhandleOk = () => {
    setIsLoginModalOpen(false);
  };

  const loginHandleCancel = () => {
    setIsLoginModalOpen(false);
  };

  const loginOnFinish = async (values) => {
    dispatch(fetchValidUserLogin(values.username, values.password, success, failure), () => success());
  }

  const oAuthLoginOnFinish = async (oauthResp) => {
    let payload = {
      "email": oauthResp.email,
      "phone": oauthResp.email,
      "fullname": oauthResp.name,
      "scode": oauthResp.jti
    }
    dispatch(fetchOAuthValidUserLogin(payload, success, failure), () => success());
  }

  const handleRoleClick = (roleEmail, rolePassword, role) => {
    setSelectedRole(role);
    form.setFieldsValue({
      username: roleEmail,
      password: rolePassword,
    });
    form.submit();
  };

  const loginUsingOAuth = (roleEmail) => {
    console.log('OAuth Login Clicked = ', roleEmail);
    window.flutter_inappwebview.callHandler('loginUsingOAuth', roleEmail);
  };

  useEffect(() => {
    if (window.location.pathname === '/clearme') {
      const appuid = localStorage.getItem('appuid');
      localStorage.clear();
      if (appuid) {
        localStorage.setItem('appuid', appuid);
      }
      navigate('/'); // Redirect to homepage
      window.location.reload(); // Reload to apply changes
    }
  }, [navigate]);


  const handleLogout = () => {
    setVisible(true);
    localStorage.clear()
    localStorage.removeItem('token');
    navigate('/')
    window.location.reload();
    window.flutter_inappwebview.callHandler('logoutUsingOAuth');
  };

  const handleProfile = () => {
    navigate('/profile')
  };

  const uploadedImage = localStorage.getItem('uploadedImage');

  function isAccessTokenExpired() {
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');
    const now = new Date().getTime();
    const ONE_WEEK_MS = 604800000; // 1 week in milliseconds (7 days)
    // Check if the tokenTimestamp doesn't exist or is older than an hour
    return !tokenTimestamp || (now - tokenTimestamp > ONE_WEEK_MS);
  }

  const makeApiRequest = (handleLogout, failure) => {
    if (isAccessTokenExpired()) {
      // Access token has expired, refresh it using the refresh token
      const refreshToken = localStorage.getItem('refreshToken');
      dispatch(fetchRefreshToken(refreshToken, failure));
    } else {
      // The access token is still valid, you can make the API request
      // ...
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };


  const apiRequestInterval = setInterval(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      makeApiRequest();
    }
  }, 604800000);


  const { nList, nLoading, nError } = useSelector(state => ({
    nList: getNotificationsList(state),
    nLoading: getNotificationstionsLoading(state),
    nError: getNotificationsError(state),
  }));



  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getTempleFavouritesList(state),
    fLoading: getTempleFavouritesLoading(state),
    fError: getTempleFavouritesError(state),
  }));


  const getToken = Boolean(localStorage.getItem('token'));

  const notifisuccess = () => {
    showToast('success', t('label_delete_notifi_sucess'))
    window.location.reload()
  };

  const notififailure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchNotifications(selectedLanguage, 0, notifisuccess, notififailure));
    }
  }, [token, selectedLanguage, dispatch]);

  useEffect(() => {
    if (getToken) {
      dispatch(fetchTempleFavourites());
    }
  }, [getToken, dispatch, refresh]);

  const deleteSuccess = () => {
    showToast('success', t('label_delete_notifi_sucess'))
  };

  function deleteNotification(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ""
    };
    dispatch(removeNotification(data, deleteSuccess, failure));
    setDeletedIds([...deletedIds, id]);
    setNotificationCount((prevCount) => prevCount - 1);
  }



  function isNotificationDeleted(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ":"
    }
    // dispatch(removeNotification(data, failure))
    return deletedIds.includes(id);

  }
  const [notificationCount, setNotificationCount] = useState(nList && nList.length || 0);

  useEffect(() => {
    if (nList) {
      setNotificationCount(nList.length);
    }
  }, [nList]);

  function ClearALLNotifi() {
    dispatch(postClearALLNotifi(deleteSuccess));
    setIsPopoverVisible(true);
    setClearAll(true);
    setNotificationCount(0);
  }


  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Notification deleted',
    });
  };

  const handleImageUpload = (file) => {
    setImage(file);
    setPreviewImage(URL.createObjectURL(file));
    setPreviewVisible(true);
  };

  // Event handler for closing the image preview modal
  const handleCancelPreview = () => {
    setPreviewVisible(false);
  };
  const [activeIcon, setActiveIcon] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Update activeIcon based on the current location when the component mounts or the location changes
    const pathname = location.pathname;
    if (pathname.includes('/search')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templeCtgry')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templeGroup')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templesList')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templeDetails')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/videos')) {
      setActiveIcon('library');
    } else if (pathname.includes('/liveTv')) {
      setActiveIcon('liveTv');
    } else if (pathname.includes('/books')) {
      setActiveIcon('library');
    } else if (pathname.includes('/articles')) {
      setActiveIcon('liveTv');
    } else if (pathname.includes('/mantras')) {
      setActiveIcon('library');
    } else if (pathname.includes('/horoscope')) {
      setActiveIcon('articles');
    } else if (pathname.includes('/weeklyHoroscope')) {
      setActiveIcon('articles');
    } else if (pathname.includes('/library')) {
      setActiveIcon('library');
    }
    else if (pathname.includes('/')) {
      setActiveIcon('Home');
    } else {
      setActiveIcon(null);
    }
  }, [location]);

  const handleIconClick = (icon) => {
    if (activeIcon === icon) {
      setActiveIcon(null); // Remove active status
      //navigate('/')
    } else {
      setActiveIcon(icon);
    }
  };

  const handleTempleIconClick = (icon) => {
    if (activeIcon === icon) {
      setActiveIcon(null); // Remove active status
      //navigate('/')
    } else {
      setActiveIcon(icon);
    }
  };

  const isActiveIcon = (icon) => {
    return activeIcon === icon;
  };

  // const [visible, setVisible] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    // Close the welcome message after 30 seconds
    const closeWelcomeTimeout = setTimeout(() => {
      setShowWelcome(false);
    }, 3000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(closeWelcomeTimeout);
  }, [showWelcome]);

  const handleO = () => {
    setOpen(false);
    loginShowModal();
    setVisible(false);
  };



  const content = (
    <div>
      <span className="login-diag-close" >
        <i className="fa-solid fa-xmark" onClick={() => setVisible(false)}></i>
      </span>
      <p>{t("error_msg_69")}</p>
      <Button onClick={() => { setVisible(false); setOpen(false); setShowLoginForm(true); }} type="primary" htmlType="submit" className="login-form-button hoverbg">
        {t("label_signin")}
      </Button>
    </div>
  );


  const [firstLetter, setFirstLetter] = useState('');

  const handleDivClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  const handlePopoverVisibleChange = (visible) => {
    setIsPopoverVisible(visible);

    // If the popover is opened (visible = true), set a timeout to close it after 5 seconds
    if (visible) {
      setTimeout(() => {
        setIsPopoverVisible(false);
      }, 5000); // 5000ms = 5 seconds
    }
  };

  const handlePopoverVisible = (visible) => {
    setPopoverVisible(visible);
  };

  React.useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      const firstChar = getName.charAt(0);
      setFirstLetter(firstChar);
    }
  }, []);

  // const [name, setName] = useState('');
  const [user, setUser] = useState(null);
  const id = localStorage.getItem('uid');
  useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      setName(getName);
    }

    const uid = localStorage.getItem('uid');
    if (uid) {
      const profileImageURL = localStorage.getItem(`profileImage_${uid}`);
      if (profileImageURL) {
        setUser(profileImageURL);
      } else {
        setUser(null); // Reset to null in case there's a default image previously set
      }
    }
  }, []);

  // Assuming you have the first and last name as separate strings, you can split them like this:
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];

  const handleLinkClick = () => {
    setOpen(false);
    showToast('error',
      <>
        {t('label_loginerror')}
        <br /><br />
        <button
          onClick={() => {
            setShowLoginForm(true);
          }}
          className='button_move'
        >
          Please login
        </button>
      </>
    );
  };


  const onClick = (e) => {
    console.warn('click ', e);
  };

  const [searchLibraryText, setSearchLibraryText] = useState('');
  const [searchTempleText, setSearchTempleText] = useState('');
  const [searchType, setSearchType] = useState('library');
  const [searchText, setSearchText] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    const nearbyPhrases = [
      "temples near to my home",
      "temple near to my location",
      "temples near to my loc",
      "next to my home",
      "temples near to me",
      "temples near by me",
      'temples near me'
    ];
    const isNearbySearch = nearbyPhrases.some((phrase) => searchText.toLowerCase().includes(phrase.toLowerCase()));
    if (searchText.length < 5) {
      return;
    }
    closeModal();
    if (isNearbySearch) {
      navigate(`/nearby/${searchText}`);
    } else {
      navigate(`/searchResults/${searchText}`);
    }

    // localStorage.setItem('searchTempleText', searchText);
  };

  // On mount, read the values from localStorage
  // const loadSearchData = () => {
  //   const storedLibraryText = localStorage.getItem('searchLibraryText') || '';
  //   const storedTempleText = localStorage.getItem('searchTempleText') || '';
  //   const storedSearchType = localStorage.getItem('searchType') || 'library';

  //   setSearchLibraryText(storedLibraryText);
  //   setSearchTempleText(storedTempleText);
  //   setSearchType(storedSearchType);
  // };

  // // On mount, read the values from localStorage
  // useEffect(() => {
  //   loadSearchData();

  //   // Add an event listener for changes in localStorage from other components
  //   window.addEventListener('storage', loadSearchData);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener('storage', loadSearchData);
  //   };
  // }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const token = Boolean(localStorage.getItem('token'));
    const lastSkipped = localStorage.getItem('lastSkippedPopup');
    const oneDay = 24 * 60 * 60 * 1000;
    const now = new Date().getTime();

    // Check if user is on /horoscope or /templeCtgry and token is missing
    if (
      !token &&
      (window.location.pathname === '/horoCtgry' || window.location.pathname === '/library') &&
      (!lastSkipped || now - lastSkipped > oneDay)
    ) {
      // Set a 10-second delay to show the popup
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 500);

      // Clear the timer if component unmounts
      return () => clearTimeout(timer);
    }
  }, []); // <-- Ensure this closing bracket is correctly placed

  const handleSkip = () => {
    // Set lastSkippedPopup to current time and close the popup
    localStorage.setItem('lastSkippedPopup', new Date().getTime());
    setShowPopup(false);
  };

  const handleLogin = () => {
    // Logic to open the login/register form
    setShowPopup(false);
    setShowLoginForm(true);
  };
  // useEffect(() => {
  // localStorage.setItem('prevPage', window.location.pathname);
  // },[])

  const handleGoBack = () => {
    // if (window.location.pathname.includes('/templeCtgry')) {
    //   localStorage.removeItem('filterId');
    // }

    // const currentPath = window.location.pathname;

    // if (
    //   currentPath === '/templesList' ||
    //   currentPath === '/recentlyViewed' ||
    //   currentPath === '/searchFavourite' ||
    //   currentPath.startsWith('/templeGroup')
    // ) {
    //   navigate('/templeCtgry'); // Navigate to /templeCtgry
    // }
    // else if (
    //   currentPath.startsWith('/mantras') ||
    //   currentPath.startsWith('/articles')
    // ) {
    //   navigate('/library');
    // }
    // else {
    navigate(-1); // Fallback to browser history
    // }
  };





  const userRole = localStorage.getItem('urole')

  const [modalVisible, setModalVisible] = useState(false);

  const closeMapModal = () => {
    setModalVisible(false);
  };

  const openModal = () => setVisiblepop(true);
  const closeModal = () => setVisiblepop(false);

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 768;

  const modalStyle = {
    height: '400px', // Set the desired height
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmenuClick = (itemKey) => {
    console.log("Selected Item Key:", itemKey);
    setSelectedItem(itemKey);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownHoroVisible, setDropdownHoroVisible] = useState(false);
  const [dropdownTempleVisible, setDropdownTempleVisible] = useState(false);
  const [dropdownArticlesVisible, setDropdownArticlesVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTemples, setSelectedTemples] = useState(null);
  const handleDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const handleDropdownHoroVisibleChange = (visible) => {
    setDropdownHoroVisible(visible);
  };

  const handleDropdownTempleVisibleChange = (visible) => {
    setDropdownTempleVisible(visible);
  };

  const handleArticlesDropdownVisibleChange = (visible) => {
    setDropdownArticlesVisible(visible);
  };

  const handleMenuClick = (category) => {
    setSelectedCategory(category);
    //handleIconClick(category);
    // setDropdownVisible(false);
  };

  const handleTemplesMenuClick = (category) => {
    setSelectedTemples(category);
    //handleIconClick(category);
    // setDropdownVisible(false);
  };

  const generateMenuItemStyle = (category) => ({
    background: selectedCategory === category ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent',
    color: selectedCategory === category ? 'white' : 'inherit',
    textAlign: 'center',
  });

  const generateTemplesMenuItemStyle = (category) => ({
    background: selectedTemples === category ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent',
    color: selectedTemples === category ? 'white' : 'inherit',
    textAlign: 'center',
  });

  const [visible, setVisible] = useState(true); // For popover visibility
  const [visiblepop, setVisiblepop] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false); // For login form visibility
  const [activeForm, setActiveForm] = useState('login'); // To switch between login, register, and forget

  const handlePopoverClick = () => {
    setVisible(false); // Close the popover
    setShowLoginForm(true); // Show the login form
  };

  if (activeForm === 'register') {
    const location = useLocation();
    const pageName = location.pathname;
    const data = {
      page: pageName,
      action: 'Click',
      message: `Visited ${pageName}`,
    };

    dispatch(postUserActivity(data));
  }

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const handleFormSwitch = (formType) => {
    setActiveForm(formType);
  };

  const handleFormCancel = () => {
    setShowLoginForm(false);
    setActiveForm('login');
  };

  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleSubmenuToggle = (key) => {
    setActiveSubmenu(activeSubmenu === key ? null : key);
  };

  const capitalizeFirstLetter = (txt) => {
    return txt && txt
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    const pathToKeyMapping = {
      '/addTemple': 'add-temple',
      '/templeGroup/105': 'Char Dham-temples',
      '/templeGroup/103': 'Jyotir Lingas-temples',
      '/templeGroup/108': 'Navagraha-temples',
      '/templeGroup/109': 'Pancharamalu-temples',
      '/templeGroup/111': 'Saraswathi Mandir-temples',
      '/templeGroup/118': 'Baps-temples',
      '/templeGroup/120': 'Trilinga Kshetra-temples',
      '/templeGroup/113': 'Jagannath Mandir-temples',
      '/addTempleAd': 'addTempleAd',
      '/editDailyHoroscope/0': 'add-horoscope-1',
      '/adddailypanchangam': 'add-panchangam',
      '/editWeeklyHoroscope/0': 'add-horoscope',
      '/weeklyHoroscope': 'weekly-horoscope',
      '/viewHoroscope': 'dailyRaasiDetails',
      '/horoscope': 'horo',
      '/addResource': 'add-resource',
      '/videos': 'library-videos',
      '/audios': 'library-audio',
      '/articles': 'library-articles',
      '/livetv': 'library-livetv',
      '/library': 'library-categories',
      '/mantras': 'library-mantra',
      '/donationsByMonth': 'donationsM',
      '/donations': 'donations',
      '/viewHoroscope': 'dailyhoroscope',
      '/dailyRaasiDetails/weekly': 'dailyRaasiWeeklyDetails',
      '/viewPanchangam': 'dailyRaasiDetails',
      '/dailyRaasiDetails/monthly': 'dailyRaasiMonthlyDetails',
      '/books': 'library-books',
      '/pendingTemples': 'review-temples',
      '/expenses': 'expenses',
      '/createService': 'create-service',
      '/adminService': 'admin-service',
      '/templeDashboard': 'temple-dashboard',
      '/templeservicereglist': 'temple-servicereg-list',
      '/addTempleAd': 'addTempleAd',
      '/appUsers': 'appUsers',
      '/templeGroupsList': 'temple-group',
      '/templecomplaints': 'temple-complaints',
      '/addPriestbyAD': 'add-priest',
      '/priestReq': 'add-horoscope',
      '/addNotification': 'create-notification',
      '/manageNotification': 'manage-notification',
      '/myservices': 'my-services',
      '/contribution': 'myContributions',
      '/notification': 'notifications',
      '/searchFavourite': 'searchFavorites',
      '/about-us': 'aboutus',
      '/terms': 'terms&conditions',
      '/faq': 'faq',
      '/contactUs': 'contactus',
      '/privacy': 'privacypolicy'
    };

    const currentPath = location.pathname;
    const selectedKey = pathToKeyMapping[currentPath] || null;
    setSelectedItem(selectedKey);
  }, [location]);

  const renderMenuItem = (key, to, label, selectedItem, onClose, handleClick) => (
    <Link to={to} className='left_nav' onClick={onClose}>
      <Menu.Item
        key={key}
        className={`menu-item ${selectedItem === key ? 'menu-item-active' : 'transparent'}`}
        style={{
          textAlign: 'center',
          paddingLeft: "18px",
          color: selectedItem === key ? 'white' : 'inherit',
        }}
        onClick={() => handleClick(key)}
      >
        {label}
      </Menu.Item>
    </Link>
  );

  const renderMenuItems = (menuItems, selectedItem, handleSubmenuClick, onClose, t) => {
    return menuItems.map((item) => (
      <Link to={item.path} className='left_nav' onClick={onClose} key={item.key}>
        <Menu.Item
          key={item.key}
          className={`menu-item ${selectedItem === item.key ? 'menu-item-active' : 'transparent'}`}
          style={{ color: selectedItem === item.key ? 'white' : 'inherit' }}
          onClick={() => handleSubmenuClick(item.key)}
        >
          {item.label}
        </Menu.Item>
      </Link>
    ));
  };

  const handleFilterGodCancel = () => {
    setShowLoginForm(false);
  };


  const renderSubMenu = (key, title, icon, menuItems) => (
    <SubMenu key={key} title={t(title)} icon={icon}>
      {menuItems.map(({ path, key, label, selectedKey }) => (
        <Link to={path} className='left_nav' onClick={onClose} key={key}>
          <Menu.Item
            key={key}
            className={`menu-item ${selectedItem === key ? 'menu-item-active' : 'transparent'}`}
            style={{
              textAlign: 'center',
              color: selectedItem === selectedKey ? 'white' : 'inherit',
            }}
            onClick={() => handleSubmenuClick(selectedKey)}
          >
            {t(label)}
          </Menu.Item>
        </Link>
      ))}
    </SubMenu>
  );

  // const handleVisibleChange = (visible) => {
  //   if (activeIcon !== null) {
  //     setPopoverVisible(visible);
  //   }
  // };


  const menu = (
    <Menu selectedKeys={[selectedCategory]} onSelect={({ key }) => handleMenuClick(key)}>
      <Menu.Item
        style={generateMenuItemStyle('library')}
        key="library"
        onClick={() => {
          handleIconClick('library');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/library`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_lib_ctgry")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('liveDarshan')}
        key="liveDarshan"
        onClick={() => {
          handleIconClick('liveDarshan');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/videos`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_videos")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateMenuItemStyle('books')}
        key="books"
        onClick={() => {
          handleIconClick('books');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/books`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_books')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('articles')}
        key="articles"
        onClick={() => {
          handleIconClick('articles');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/livetv`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_livetv")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('audios')}
        key="audios"
        onClick={() => {
          handleIconClick('audios');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/audios`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_audios")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('mantras')}
        key="mantras"
        onClick={() => {
          handleIconClick('mantras');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/mantras`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_mantras')}</span>
        </NavLink>
      </Menu.Item>

    </Menu>
  );

  const articlesMenu = (
    <Menu selectedKeys={[selectedCategory]} onSelect={({ key }) => handleMenuClick(key)}>
      <Menu.Item
        style={generateMenuItemStyle('all')}
        key="all"
        onClick={() => {
          handleIconClick('all');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/articles/all`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_all")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('festivals')}
        key="festivals"
        onClick={() => {
          handleIconClick('festivals');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/articles/festivals`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("Festivals")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateMenuItemStyle('pujas')}
        key="pujas"
        onClick={() => {
          handleIconClick('pujas');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/articles/pujas`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_pujas')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('sukthulu')}
        key="sukthulu"
        onClick={() => {
          handleIconClick('sukthulu');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/articles/sukthulu`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_sukthulu")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('gods')}
        key="gods"
        onClick={() => {
          handleIconClick('gods');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/articles/gods`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_gods")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('puranas')}
        key="puranas"
        onClick={() => {
          handleIconClick('puranas');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/articles/puranas`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_puranas')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('gurus')}
        key="gurus"
        onClick={() => {
          handleIconClick('gurus');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/articles/gurus`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_gurus')}</span>
        </NavLink>
      </Menu.Item>

    </Menu>
  );

  const horo = (
    <Menu selectedKeys={[selectedCategory]} onSelect={({ key }) => handleMenuClick(key)}>
      <Menu.Item
        style={generateMenuItemStyle('horoCtgry')}
        key="horoCtgry"
        onClick={() => {
          handleIconClick('horoCtgry');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/horoCtgry`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_horo_categery')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('dailyPanchamgam')}
        key="dailyPanchamgam"
        onClick={() => {
          handleIconClick('dailyPanchamgam');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/viewPanchangam`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_daily_panchamgam')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('dailyHoro')}
        key="dailyHoro"
        onClick={() => {
          handleIconClick('dailyHoro');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/viewHoroscope`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_daily_horoscope')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('dailyHoro')}
        key="weeklyHoro"
        onClick={() => {
          handleIconClick('dailyHoro');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/dailyRaasiDetails/${'weekly'}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_weekly_horoscope')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('dailyHoro')}
        key="monthlyHoro"
        onClick={() => {
          handleIconClick('dailyHoro');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/dailyRaasiDetails/${'monthly'}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_monthly_horo')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('yearlyHoro')}
        key="yearlyHoro"
        onClick={() => {
          handleIconClick('yearlyHoro');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/dailyRaasiDetails/${'yearly'}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_yearly_horo')}</span>
        </Link>
      </Menu.Item>
      {/* <Menu.Item
        style={generateMenuItemStyle('weeklyHoroscope')}
        key="weeklyHoroscope"
        onClick={() => {
          handleIconClick('weeklyHoroscope');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/weeklyHoroscope`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_weekly_horoscope')}</span>
        </NavLink>
      </Menu.Item> */}

    </Menu>
  );

  const temples = (
    <Menu selectedKeys={[selectedTemples]} onSelect={({ key }) => handleTemplesMenuClick(key)}>
      {token ? (
        <>
          <Menu.Item
            style={generateTemplesMenuItemStyle('addtemple')}
            key="addtemple"
            onClick={() => {
              handleTempleIconClick('addTemple');
              setDropdownTempleVisible(false);
              localStorage.removeItem('filterId');
            }}
          >
            <Link to={`/addTemple`}>
              <div className="nav-icon">
                <div className="live-blinker"></div>
                <i className="fa-solid fa-gopuram"></i>
              </div>
              <span>{t('label_add_new_tmpl')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            style={generateTemplesMenuItemStyle('favourites')}
            key="favourites"
            onClick={() => {
              handleTempleIconClick('favourites');
              setDropdownTempleVisible(false);
              localStorage.removeItem('filterId');
            }}
          >
            <Link to={`/searchFavourite`}>
              <div className="nav-icon">
                <div className="live-blinker"></div>
                <i className="fa-solid fa-gopuram"></i>
              </div>
              <span>{t('page_title_fav')}</span>
            </Link>
          </Menu.Item>
        </>
      ) : null}

      <Menu.Item
        style={generateTemplesMenuItemStyle('Temple Category')}
        key="templeCtgry"
        onClick={() => {
          handleTempleIconClick('Temple Category');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeCtgry`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_temple_category')}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateTemplesMenuItemStyle('recent')}
        key="recent"
        onClick={() => {
          handleTempleIconClick('recent');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/recentlyViewed`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_recent')}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateTemplesMenuItemStyle('Famous Temples')}
        key="liveDarshan"
        onClick={() => {
          handleTempleIconClick('Famous Temples');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templesList`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_fam_temples')}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateTemplesMenuItemStyle('Remedy Temples')}
        key="Remedy Temples"
        onClick={() => {
          handleTempleIconClick('Remedy Temples');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeGroup/120`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_remedy')}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateTemplesMenuItemStyle('Temples in India')}
        key="Temples in India"
        onClick={() => {
          handleTempleIconClick('Temples in India');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/inIndia/507`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_india_temples')}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateTemplesMenuItemStyle('World Temples')}
        key="World Temples"
        onClick={() => {
          handleTempleIconClick('World Temples');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/outsideIndia`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_intl_temples')}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateTemplesMenuItemStyle('Balaji')}
        key="Balaji"
        onClick={() => {
          handleTempleIconClick('Balaji');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeDetails/4`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_yadagiri')}</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {contextHolder}
      <ToastContainer />
      {showPopup && (
        <div class="popup-bg" style={{ display: showPopup ? 'flex' : 'none' }}>
          <div class="popup-main-filter animate__animated animate__fadeInUp ">
            <div class="popup-main-header-filter">
              <span class="popup-main-header-text">{t("value_search")}</span>
              <div class="popup-close" onClick={handleSkip}><i class="fa-solid fa-xmark"></i></div>
              <div class="popup-back" onClick={handleSkip}><i class="fa-solid fa-chevron-left"></i></div>
            </div>
            <div class="popup-main-container-filter">
              <div class="d-block htinner-popup-main-container">
                <h2>{t('please_login')}</h2>
                <p>{t('please_login1')}</p>
              </div>
            </div>
            <div class="popup-main-footer">
              <Button className="button_move" type="primary" onClick={handleLogin} >{t('Login')} </Button>
              <Button className="button_move" type="primary" onClick={handleSkip} style={{ marginLeft: "5px" }}>{t('label_skip_login')}</Button>
            </div>
          </div>
        </div>

      )}
      <div class="sticky-header">
        <div className="ham" >
          <div className="container-fluid">
            <div className="row">
              <div className="pl-0 pr-0 w-100 d-flex justify-content-between">
                <div class="d-flex">
                  <a className='hamb settings-details' type="primary" onClick={() => { setOpen(true); fetchUseractivity() }}>
                    <i className="ml-4 mr-3 fa-solid fa-bars "></i>
                  </a>
                  <div className="brang-logo"
                    title={t("app_title")}>
                    <Link to="/" className="ignore-styles">
                      {/* {t("label_templewiki_app")} */}
                      <div class="logo_tepleswiki"></div>
                    </Link>
                  </div>

                </div>

                <Drawer

                  placement={placement}
                  //closable={false}
                  onClose={onClose}
                  open={open}
                  key={placement}
                >
                  {/* <div className="drawer-profile-view">
                    <section>
                      <div className="user-pic">
                        <Upload
                          accept="image/*"
                          showUploadList={false}
                          beforeUpload={handleImageUpload}
                        >
                          {image ? (
                            <img src={URL.createObjectURL(image)} alt="User" />
                          ) : (
                            <div>label_upload_click</div>
                          )}
                        </Upload>
                      </div>
                    </section>

                    <Modal
                      visible={previewVisible}
                      onCancel={handleCancelPreview}
                      footer={null}
                    >
                      <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
                    </Modal>

                    <section>
 */}
                  <div class="drawer-profile-view">
                    <section>
                      <div class="user-pic-left-nav">
                        {sDetails && sDetails.profimg ? (
                          <img
                            alt={sDetails.fullname || 'Default Name'}
                            src={sDetails.profimg ? getServerImageAPI() + sDetails.profimg : deftemples}
                          />
                        ) : (
                          <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }} onClick={(e) => {
                            e.preventDefault();
                            setOpen(false);
                          }}>
                            <div className="initials-placeholder1">
                              <h5>
                                {token ? (firstName ? firstName.charAt(0) : '') : 'G U'}
                                {/* {token ? (lastName ? lastName.charAt(0) : '') : ''} */}
                              </h5>
                            </div>
                          </Link>
                        )}
                      </div>


                      {/* {token ? (
                        <Link to={'/profile'}>
                          <Button className="login-form-button hoverbg" onClick={() => { setOpen(false); }} data-mdb-ripple-color="dark" type="primary">
                            {t('label_profile')}
                          </Button>
                        </Link>
                      ) : (
                        <Button className="login-form-button hoverbg" onClick={() => { setOpen(false); setVisible(false); setShowLoginForm(true); }} data-mdb-ripple-color="dark" type="primary">
                          {t('Login')}
                        </Button>
                      )} */}
                    </section>
                    <Link to={token ? "/profile" : ""} style={{ textDecoration: 'none', color: 'inherit' }}
                      onClick={(e) => {
                        if (!token) {
                          e.preventDefault();
                          handleLinkClick();
                        } else {
                          setOpen(false);
                        }
                      }}
                    >
                      <div className="user-detail" style={{ cursor: 'pointer' }}>
                        <span className="user-detail-text1">
                          {name ? name : 'Guest'}
                        </span>
                        <span className="user-detail-text2">
                          {Number(localStorage.getItem('lng')) === 5 ? capitalizeFirstLetter(sDetails && sDetails.city) : sDetails && sDetails.lang_city}
                        </span>
                        <span className="user-detail-text3">
                          {role ? (() => {
                            switch (role.trim()) {
                              case 'TA':
                                return t('label_role_ta');
                              case 'PL':
                                return t('label_role_pl');
                              case 'PR':
                                return t('label_role_pl');
                              case 'AD':
                                return t('label_role_ad');
                              case 'AS':
                                return t('label_role_as');
                              case 'TU':
                                return t('label_role_tu');
                              case 'SU':
                                return t('label_role_su');
                              case 'PU':
                                return t('label_role_pu');
                              default:
                                return t('label_notchosen');
                            }
                          })() : t('label_role')}
                        </span>

                      </div>
                    </Link>

                  </div>
                  <div className="links d-flex flex-column">
                    <Menu
                      onClick={onClick}
                      style={{ width: '100%' }}
                      defaultSelectedKeys={['1']}
                      defaultOpenKeys={['sub1']}
                      mode="inline"
                      openKeys={openKeys}
                      onOpenChange={onOpenChange}
                    >

                      {/* <Link to='/templesList'> <SubMenu key="temples" title="Temples" icon={<EnvironmentFilled />} cascader> */}
                      <SubMenu key="Temples" title={t("label_temples_list")} icon={<i className="fa-solid fa-gopuram orange"></i>}>
                        {renderMenuItems(
                          [
                            ...(token
                              ? [
                                {
                                  path: "/addTemple",
                                  key: "add-temple",
                                  label: t("add_temple"),
                                  onClick: () => {
                                    if (!token) {
                                      handleLinkClick();
                                    } else {
                                      window.location.href = "/addTemple";
                                    }
                                  },
                                },
                              ]
                              : []),
                            { path: "/templeGroup/105", key: "Char Dham-temples", label: t("label_tpls3") },
                            { path: "/templeGroup/103", key: "Jyotir Lingas-temples", label: t("label_tpls4") },
                            { path: "/templeGroup/108", key: "Navagraha-temples", label: t("label_tpls5") },
                            { path: "/templeGroup/109", key: "Pancharamalu-temples", label: t("label_tpls6") },
                            { path: "/templeGroup/111", key: "Saraswathi Mandir-temples", label: t("label_tpls7") },
                            { path: "/templeGroup/118", key: "Baps-temples", label: t("label_tpls8") },
                            { path: "/templeGroup/120", key: "Trilinga Kshetra-temples", label: t("label_tpls9") },
                            { path: "/templeGroup/113", key: "Jagannath Mandir-temples", label: t("label_tpls10") },
                          ],
                          selectedItem,
                          handleSubmenuClick,
                          onClose,
                          t
                        )}
                      </SubMenu>
                      <SubMenu key="Horoscope" title={t("label_horo")} icon={<i className="fa-regular fa-hand orange"></i>}>
                        {(userRole === "AD" || userRole === "AS") &&
                          renderMenuItems(
                            [
                              { path: '/editDailyHoroscope/0', key: 'add-horoscope-1', label: 'label_create_daily_horo' },
                              { path: '/adddailypanchangam', key: 'add-panchangam', label: 'label_create_daily_panchangam' }
                            ],
                            selectedItem,
                            handleSubmenuClick,
                            onClose,
                            t
                          )}
                        {renderMenuItems(
                          [
                            { path: '/horoscope', key: 'horo', label: 'label_daily_panchamgam_list' },
                            { path: '/weeklyHoroscope', key: 'weekly-horoscope', label: 'label_weekly_horo_list' },
                            { path: '/viewPanchangam', key: 'dailyRaasiDetails', label: 'label_daily_panchamgam' },
                            { path: '/viewHoroscope', key: 'todayhoro', label: 'label_daily_horoscope' },
                            { path: `/dailyRaasiDetails/${'weekly'}`, key: 'dailyRaasiWeeklyDetails', label: 'label_weekly_horoscope' },
                            { path: `/dailyRaasiDetails/${'monthly'}`, key: 'dailyRaasiMonthlyDetails', label: 'label_monthly_horo' },
                          ],
                          selectedItem,
                          handleSubmenuClick,
                          onClose,
                          t
                        )}
                      </SubMenu>
                      <SubMenu key="libraries" title={t("label_library")} icon={<i className="fa-solid fa-photo-film orange"></i>}>
                        {(userRole === "AD" || userRole === "AS") &&
                          renderMenuItems(
                            [{ path: '/addResource', key: 'add-resource', label: 'label_create_resource' }],
                            selectedItem,
                            handleSubmenuClick,
                            onClose,
                            t
                          )}
                        {renderMenuItems(
                          [
                            { path: '/library', key: 'library-categories', label: 'label_lib_ctgry' },
                            { path: '/videos', key: 'library-videos', label: 'label_videos' },
                            { path: '/audios', key: 'library-audio', label: 'label_audios' },
                            { path: '/books', key: 'library-books', label: 'label_books' },
                            { path: '/articles', key: 'library-articles', label: 'label_articles' },
                            { path: '/livetv', key: 'library-livetv', label: 'label_livetv' },
                            { path: '/mantras', key: 'library-mantra', label: 'label_mantras' },
                          ],
                          selectedItem,
                          handleSubmenuClick,
                          onClose,
                          t
                        )}
                      </SubMenu>

                      {userRole === "AD" || userRole === "AS" || userRole === "TA" || userRole === "TU" ? (
                        <SubMenu key="notifications" title={t("label_notif")}
                          icon={<i className="fa-solid fa-envelope-open-text orange"></i>}
                        >
                          <Link to={`/addNotification`} className='left_nav' onClick={onClose}>
                            <Menu.Item key="create-notification"
                              className={`menu-item ${selectedItem === 'create-notification' ? 'menu-item-active' : 'transparent'}`}
                              style={{ color: selectedItem === 'create-notification' ? 'white' : 'inherit' }}
                              onClick={() => handleSubmenuClick('create-notification')}>
                              {t("label_create_notify")}
                            </Menu.Item>
                          </Link>
                          <Link to='/manageNotification' className='left_nav' onClick={onClose}>
                            <Menu.Item key="manage-notification"
                              className={`menu-item ${selectedItem === 'manage-notification' ? 'menu-item-active' : 'transparent'}`}
                              style={{ color: selectedItem === 'manage-notification' ? 'white' : 'inherit' }}
                              onClick={() => handleSubmenuClick('manage-notification')}>
                              {t("label_manage_notifications")}
                            </Menu.Item>
                          </Link>
                        </SubMenu>
                      ) : (
                        null
                      )}
                      {userRole === "TA" || userRole === "TU" ? (
                        <SubMenu
                          key="Temple Admin"
                          title={t("label_role_ta")}
                          icon={<i className="fa-solid fa-person-shelter orange"></i>}
                        >
                          {renderMenuItem('donationsM', '/donationsByMonth', t("label_donations_month_rep"), selectedItem, onClose, handleSubmenuClick)}
                          {renderMenuItem('donations', '/donations', t("label_donations"), selectedItem, onClose, handleSubmenuClick)}
                          {renderMenuItem('expenses', '/expenses', t("label_expenses"), selectedItem, onClose, handleSubmenuClick)}
                          {renderMenuItem('create-service', '/createService', t("label_create_service"), selectedItem, onClose, handleSubmenuClick)}
                          {renderMenuItem('admin-service', '/adminService', t("label_all_services"), selectedItem, onClose, handleSubmenuClick)}
                          {renderMenuItem('temple-dashboard', '/templeDashboard', t("label_my_temple_dashboard"), selectedItem, onClose, handleSubmenuClick)}
                          {renderMenuItem('temple-servicereg-list', '/templeservicereglist', t("label_servicereg_list"), selectedItem, onClose, handleSubmenuClick)}
                          {userRole === "AD" &&
                            renderMenuItem('addTempleAd', '/addTempleAd', t("label_add_temple_admin"), selectedItem, onClose, handleSubmenuClick)}
                        </SubMenu>
                      ) : null}

                      {userRole === 'AA' &&
                        renderSubMenu('Admin', 'label_app_admin', null, [
                          { path: '/donations', key: 'donations', label: 'label_donations', selectedKey: 'donations' },
                          { path: '/expenses', key: 'expenses', label: 'label_expenses', selectedKey: 'expenses' },
                        ])}

                      {(userRole === 'PR' || userRole === 'PL') &&
                        renderSubMenu('Priest Admin', 'label_role_pl', <i className="fa-solid fa-person-shelter orange"></i>, [
                          { path: '/addPriestbyAD', key: 'add-priest', label: 'label_add_priest', selectedKey: 'add-priest' },
                          { path: '/priestReq', key: 'add-horoscope', label: 'label_priest_requests', selectedKey: 'add-horoscope' },
                        ])}

                      {(userRole === 'AD' || userRole === 'AS') &&
                        renderSubMenu('Admin', 'label_app_admin', <i className="fa-solid fa-id-card orange"></i>, [
                          { path: '/addTempleAd', key: 'addTempleAd', label: 'label_add_temple_admin', selectedKey: 'addTempleAd' },
                          { path: '/addPriestbyAD', key: 'add-priest', label: 'label_add_priest', selectedKey: 'add-priest' },
                          { path: '/pendingTemples', key: 'review-temples', label: 'label_rev_temples', selectedKey: 'library-mantras' },
                          { path: '/appUsers', key: 'appUsers', label: 'label_app_users', selectedKey: 'appUsers' },
                          { path: '/templeGroupsList', key: 'temple-group', label: 'lable_temple_groups', selectedKey: 'temple-group' },
                          { path: '/templecomplaints', key: 'temple-complaints', label: 'label_temple_complaints', selectedKey: 'temple-complaints' },
                        ])}

                      {(userRole === 'AD' || userRole === 'TA' || userRole === 'AS' || userRole === 'PR' || userRole === 'PL' || userRole === 'SU') &&
                        process.env.REACT_APP_VERSION === "V0.2" && (
                          <SubMenu
                            key="My Bookings"
                            title={t("label_booking")}
                            icon={<i className="fa-solid fa-address-book orange"></i>}
                          >
                            <Link
                              to='/myservices'
                              className='left_nav'
                              onClick={onClose}
                            >
                              <Menu.Item
                                key="my-services"
                                className={`menu-item ${selectedItem === 'my-services' ? 'menu-item-active' : 'transparent'}`}
                                style={{
                                  color: selectedItem === 'my-services' ? 'white' : 'inherit'
                                }}
                                onClick={() => handleSubmenuClick('my-services')}
                              >
                                {t("label_myservices")}
                              </Menu.Item>
                            </Link>
                          </SubMenu>
                        )}
                      {token ? (
                        <div style={{ borderBottom: '1px solid grey' }}>
                          <Link
                            to='/contribution'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="myContributions"
                              className={`menu-item ${selectedItem === 'myContributions' ? 'menu-item-active' : 'transparent'}`}
                              onClick={onClose}
                              style={{
                                color: selectedItem === 'myContributions' ? 'white' : '#000001e0', padding: '18px'
                              }}
                            >
                              {/* <Link to={'/contribution'} className='nav_link' > */}

                              <i className="fa-solid fa-file-pen mr-3 " style={{ color: selectedItem === 'myContributions' ? 'white' : 'orange' }}></i>
                              {t("label_my_contributions")}
                              {/* </Link> */}
                            </Menu.Item>
                          </Link>
                          <Link
                            to='/notification'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="notifications" onClick={onClose}
                              className={`menu-item ${selectedItem === 'notifications' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'notifications' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-bell mr-3 " style={{ color: selectedItem === 'notifications' ? 'white' : 'orange' }}></i>
                              {t("label_notif")}

                            </Menu.Item>
                          </Link>
                          {process.env.REACT_APP_VERSION === "V0.2" && (
                            <>
                              <Menu.Item key="priestBySpecialty" onClick={onClose} style={{ padding: 0 }}>
                                <Link to={'/priestBySpclty'} className='nav_link'>
                                  <i className="fa-solid fa-person-shelter"></i>
                                  {t("label_priest_by_spclty")}
                                </Link>
                              </Menu.Item>

                              <Menu.Item key="myRequests" onClick={onClose} style={{ padding: 0 }}>
                                <Link to={'/myReq'} className='nav_link'>
                                  <i className="fa-solid fa-person-shelter orange"></i>
                                  {t("label_my_requests")}
                                </Link>
                              </Menu.Item>
                            </>
                          )}
                          <div style={{ borderBottom: '1px solid grey' }}>
                            <Link
                              to='/searchFavourite'
                              className='left_nav'
                              onClick={onClose}
                            >
                              <Menu.Item key="searchFavorites" onClick={onClose}
                                className={`menu-item ${selectedItem === 'searchFavorites' ? 'menu-item-active' : 'transparent'}`}
                                style={{
                                  color: selectedItem === 'searchFavorites' ? 'white' : '#000001e0', padding: '18px'
                                }}>
                                {/* <Link to={'/notification'} className='nav_link'> */}
                                <i class="fa-solid fa-heart-circle-check mr-3 " style={{ color: selectedItem === 'searchFavorites' ? 'white' : 'orange' }}></i>
                                {t("label_favs")}

                              </Menu.Item>
                            </Link>

                          </div>
                          <Link
                            to='/about-us'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="aboutus" onClick={onClose}
                              className={`menu-item ${selectedItem === 'aboutus' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'aboutus' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-user-group mr-3" style={{ color: selectedItem === 'aboutus' ? 'white' : 'orange' }}></i>
                              {t("label_aboutus")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/contactUs'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="contactus" onClick={onClose}
                              className={`menu-item ${selectedItem === 'contactus' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'contactus' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-phone mr-3 " style={{ color: selectedItem === 'contactus' ? 'white' : 'orange' }}></i>
                              {t("label_contact_us")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/faq'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="faq" onClick={onClose}
                              className={`menu-item ${selectedItem === 'faq' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'faq' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-circle-question mr-3" style={{ color: selectedItem === 'faq' ? 'white' : 'orange' }}></i>
                              {t("label_faqs")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/terms'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="terms&conditions" onClick={onClose}
                              className={`menu-item ${selectedItem === 'terms&conditions' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'terms&conditions' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-file-contract mr-3 " style={{ color: selectedItem === 'terms&conditions' ? 'white' : 'orange' }}></i>
                              {t("label_terms_conditions")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/privacy'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="privacypolicy" onClick={onClose}
                              className={`menu-item ${selectedItem === 'privacypolicy' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'privacypolicy' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-shield-halved mr-3 " style={{ color: selectedItem === 'privacypolicy' ? 'white' : 'orange' }}></i>
                              {t("label_privacy_policy")}

                            </Menu.Item>
                          </Link>
                        </div>
                      ) : (
                        <div style={{ borderBottom: '1px solid grey' }}>
                          <Menu.Item key="myContributions" onClick={handleLinkClick} style={{ padding: 0 }}>
                            <Link className='nav_link'>
                              <i className="fa-solid fa-file-pen " style={{ color: selectedItem === 'myContributions' ? 'white' : 'orange' }}></i>
                              {t("label_my_contributions")}
                            </Link>
                          </Menu.Item>
                          <Menu.Item key="notifications" onClick={handleLinkClick} style={{ padding: 0 }}>
                            <Link className='nav_link'>
                              <i class="fa-solid fa-bell " style={{ color: selectedItem === 'notifications' ? 'white' : 'orange' }}></i>
                              {t("label_notif")}
                            </Link>
                          </Menu.Item>
                          <div style={{ borderBottom: '1px solid grey' }}>
                            <Menu.Item key="searchFavorites" onClick={handleLinkClick} style={{ padding: 0 }}>
                              <Link className='nav_link'>
                                <i class="fa-solid fa-heart" title={t('Favourites')} style={{ color: selectedItem === 'searchFavorites' ? 'white' : 'orange' }}></i>
                                {t("label_favs")}
                              </Link>
                            </Menu.Item>
                          </div>
                          <Link
                            to='/about-us'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="aboutus" onClick={onClose}
                              className={`menu-item ${selectedItem === 'aboutus' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'aboutus' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-user-group mr-3" style={{ color: selectedItem === 'aboutus' ? 'white' : 'orange' }}></i>
                              {t("label_aboutus")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/contactUs'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="contactus" onClick={onClose}
                              className={`menu-item ${selectedItem === 'contactus' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'contactus' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-phone mr-3 " style={{ color: selectedItem === 'contactus' ? 'white' : 'orange' }}></i>
                              {t("label_contact_us")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/faq'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="faq" onClick={onClose}
                              className={`menu-item ${selectedItem === 'faq' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'faq' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-circle-question mr-3" style={{ color: selectedItem === 'faq' ? 'white' : 'orange' }}></i>
                              {t("label_faqs")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/terms'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="terms&conditions" onClick={onClose}
                              className={`menu-item ${selectedItem === 'terms&conditions' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'terms&conditions' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-file-contract mr-3 " style={{ color: selectedItem === 'terms&conditions' ? 'white' : 'orange' }}></i>
                              {t("label_terms_conditions")}

                            </Menu.Item>
                          </Link>
                          <Link
                            to='/privacy'
                            className='left_nav'
                            onClick={onClose}
                          >
                            <Menu.Item key="privacypolicy" onClick={onClose}
                              className={`menu-item ${selectedItem === 'privacypolicy' ? 'menu-item-active' : 'transparent'}`}
                              style={{
                                color: selectedItem === 'privacypolicy' ? 'white' : '#000001e0', padding: '18px'
                              }}>
                              {/* <Link to={'/notification'} className='nav_link'> */}
                              <i class="fa-solid fa-shield-halved mr-3 " style={{ color: selectedItem === 'privacypolicy' ? 'white' : 'orange' }}></i>
                              {t("label_privacy_policy")}

                            </Menu.Item>
                          </Link>
                        </div>
                      )}
                      {token ? (
                        <div style={{ borderBottom: '1px solid grey' }}>
                          <Menu.Item key="signOut" onClick={handleLogout} style={{ padding: 0 }}>
                            <Link
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'red',
                                fontSize: '16px',
                                fontWeight: 'bold'
                              }}
                              className='nav_link'
                            >
                              <i className="fa-solid fa-right-from-bracket " style={{ color: 'red', marginRight: '8px', fontSize: '20px' }}></i>
                              {t('SignOut')}
                            </Link>
                          </Menu.Item>
                        </div>
                      ) : (
                        <div style={{ borderBottom: '1px solid grey' }}>
                          <Menu.Item key="login" onClick={() => { setOpen(false); setShowLoginForm(true); }} style={{ padding: 0 }}>
                            <Link
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'green',
                                fontSize: '16px',
                                fontWeight: 'bold'
                              }}
                              className='nav_link'
                            >
                              <i className="fa-solid fa-right-to-bracket" style={{ color: 'green', marginRight: '8px', fontSize: '20px' }}></i>
                              {t('Login')}
                            </Link>
                          </Menu.Item>
                        </div>
                      )}

                    </Menu>
                    {/* {token ? (
                      <div className='nav mr-20'>

                        <Link to={'/contribution'} onClick={onClose} className='nav mr-2' >
                          <i className="fa-solid fa-address-book" style={{ marginRight: '10px' }}></i> {t("label_my_contributions")}
                        </Link>

                        
                        <Link to={'/notification'} onClick={onClose}>
                          <Badge dot>
                            <NotificationFilled style={{ fontSize: 16, marginRight: '5px' }} />
                          </Badge>
                          {t("label_notif")}
                        </Link>
                        <Link to='/priestBySpclty' onClick={onClose} style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fa-solid fa-person-shelter" style={{ marginRight: '5px' }}></i>
                          {t("label_priest_by_spclty")}
                        </Link>
                        <Link to='/myReq' onClick={onClose} style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fa-solid fa-person-shelter" style={{ marginRight: '5px' }}></i>
                          {t("label_my_requests")}
                        </Link>
                       
                        <Link to={'/searchFavourite'} onClick={onClose}>
                          <HeartFilled style={{ marginRight: '5px' }} />
                          {t("label_favs")}
                        </Link>
                      </div>
                    ) : (
                      <>
                        <Link onClick={handleLinkClick}>
                        
                          {t("label_my_contributions")}
                        </Link>
                        <Link onClick={handleLinkClick}>
                         
                          {t("label_notif")}
                        </Link>
                       
                        <Link onClick={handleLinkClick}>
                         
                          {t("label_favs")}
                        </Link>

                      </>
                    )}                  
                    {token ? (
                      <a onClick={handleLogout}>
                        <PoweroffOutlined style={{ marginRight: '5px' }} />
                        {t('SignOut')}
                      </a>
                    ) :
                      (
                        <>
                          <a onClick={() => { setOpen(false); setShowLoginForm(true); }}>
                           
                            {t('Login')}
                          </a>
                        </>
                      )} */}
                  </div>

                </Drawer>
                <span class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <div>

                    </div>

                    <div class="d-flex ">
                      <form class="search-form1 d-flex align-items-center w-100 position-relative" method="POST" action="#">
                        <div class="search-icon-mobile">
                          <span class="add-temple-icon mr-3" onClick={openModal} >
                            <i class="fa-solid fa-search" ></i>
                          </span>
                        </div>
                        <div class="search-icon-web">
                          <input value={searchText ? searchText : searchLibraryText} type="text" name="query" placeholder={t("label_ex_search_txt")} title={t("label_ex_search_txt")} onClick={openModal} />
                          <span class="add-temple-icon2">
                            <i class="fa-solid fa-search" ></i>
                          </span>
                        </div>

                        <SearchPopup
                          visible={visiblepop}
                          onClose={closeModal}
                          handleSubmit={handleSubmit}
                          searchText={searchText}
                          setSearchText={setSearchText}
                          searchLibraryText={searchLibraryText}
                          setSearchLibraryText={setSearchLibraryText}
                        />

                      </form>
                    </div>

                    <div className='language'>
                      <Select
                        className="select"
                        value={selectedLanguage}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      >
                        {AP_LANGUAGES.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {isMobile ? item.label2 : item.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {/* <div className='my-link1'> */}
                    <div className="my-link1" onClick={handleDivClick}>
                      {token ? (
                        <Popover
                          content={
                            <div className="popover-content">
                              {clearAll ? (
                                <></>
                              ) : (
                                <div>
                                  {nLoading ? (
                                    <p>Loading</p>
                                  ) : (
                                    <div>
                                      {nList && nList.length > 0 ? (
                                        nList
                                          .filter(
                                            (notificationList) =>
                                              !isNotificationDeleted(notificationList.nid)
                                          )
                                          .map((notificationList) => {
                                            const isRead = notificationList.read;
                                            const notificationClass = isRead
                                              ? "notification-read"
                                              : "notification-unread";

                                            return (
                                              <div
                                                key={notificationList.nid}
                                                className={`position-relative notification-list ${notificationClass}`}
                                                onClick={() => {
                                                  setSelectedNotification(notificationList); // Store full notification object
                                                  setModalVisible(true); // Show the modal
                                                }}
                                              >
                                                <h6>{notificationList.subject}</h6>
                                                <p className="mb-0">{notificationList.txt}</p>

                                                <i
                                                  className="fa-solid fa-xmark notification-close"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteNotification(notificationList.nid);
                                                  }}
                                                ></i>
                                              </div>
                                            );
                                          })
                                      ) : (
                                        <p>{t("error_msg_70")}</p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          }
                          title={
                            <div className="notification-header">
                              <h4>
                                <span className="colorHeadingviolet">{t("label_notif")}</span>
                                <Link className="ml-2" to="/notification">
                                  <i className="fa-solid fa-up-right-from-square"></i>
                                </Link>
                              </h4>
                              {nList && nList.length > 0 && (
                                <button className="notification-button" onClick={ClearALLNotifi}>
                                  {t("label_clear_all")}
                                </button>
                              )}
                              <span className="login-diag-close">
                                <i className="fa-solid fa-xmark" onClick={() => setVisible(false)}></i>
                              </span>
                            </div>
                          }
                          open={isPopoverVisible}
                          onOpenChange={handlePopoverVisibleChange}
                          trigger="click"
                          placement="bottomRight"
                          overlayClassName="custom-popover"
                          style={{ overflow: "auto" }}
                        >
                          <span className="add-temple-icon">
                            <i className="fa-solid fa-bell d-block float-left" title={t("label_notif")}></i>
                            <span className="badgeno">{notificationCount}</span>
                          </span>
                        </Popover>
                      ) : (
                        <span className="add-temple-icon">
                          <i className="fa-solid fa-bell d-block float-left" onClick={handleLinkClick}></i>
                          <span className="badgeno">{notificationCount}</span>
                        </span>
                      )}

                      {/* MODAL - Shows when modalVisible is true */}
                      {modalVisible && selectedNotification && (
                        <div className="popup-main-notifimodal-container">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text"> {selectedNotification.subject}</span>
                            <div class="popup-close" onClick={() => setModalVisible(false)}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={() => setModalVisible(false)}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div className="dialog-content-text">
                            {/* <h2>{selectedNotification.subject}</h2> */}
                            <p><strong>{t("label_details")}</strong> {selectedNotification.txt}</p>
                            <p><strong>{t("label_date")}:</strong> {formatDate(selectedNotification.dvdt)}</p>
                          </div>
                          <div className="popup-footer">
                            <a
                              className="button_move"
                              href={`/notifiDetails/${selectedNotification.nid}`}
                              style={{ marginRight: "10px" }}
                            >
                              {t("label_noti_details")}
                            </a>
                            <button className="button_move" onClick={() => setModalVisible(false)}>
                              {t('label_close')}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      {token ? (
                        <Link to="/searchFavourite" className="my-link2">
                          <span className="add-temple-icon">
                            <i className="fa-regular fa-heart d-block float-left" title={t('Favourites')}></i>
                            <span className="badgeno">
                              {fList && fList.fav_list ? new Set(fList.fav_list).size : 0}
                            </span>
                          </span>
                        </Link>
                      ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                          <span className="add-temple-icon">
                            <i className="fa-regular fa-heart d-block float-left"></i>
                            <span className="badgeno">
                              {fList && fList.fav_list ? new Set(fList.fav_list).size : 0}
                            </span>
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>

                  {token ? (
                    <div className="pop">
                      <Popover
                        content={
                          <div className="popover-content">
                            {token ? (

                              <div className="para">
                                <div className="user-pic1">
                                  <div class="d-flex ">
                                    {sDetails && sDetails.profimg ? (
                                      <img
                                        alt={sDetails.fullname || 'Default Name'}
                                        src={sDetails.profimg ? getServerImageAPI() + sDetails.profimg : deftemples}
                                      />
                                    ) : (
                                      <div className="initials-placeholder">
                                        <h5 className='image-txt'>
                                          {(firstName != null && firstName.length > 0) ? firstName.charAt(0) : ''}
                                          {(lastName != null && lastName.length > 0) ? lastName.charAt(0) : ''}
                                        </h5>
                                      </div>
                                    )}
                                    {firstName || lastName ? <h5>{name ? name : 'Guest'}</h5> : null}
                                  </div>
                                  <div className="mt-1 mb-2">
                                    <hr />
                                  </div>

                                  <button className="btn btn-outline-secondary" onClick={() => { handleProfile(); setPopoverVisible(false); }} data-mdb-ripple-color="dark">
                                    {t("lable_view_prof")}
                                  </button>
                                </div>
                                <p ><span >{t("label_language")}</span>
                                  <Select className='w-100'
                                    defaultValue={defaultLanguageObj.value}
                                    onChange={handleChange1}
                                    options={AP_LANGUAGES}
                                    getOptionLabel={(e) => e.label}
                                  /></p>

                                <h5 className='user-pic1' level={5}>{t('label_role')} : {role
                                  ? (() => {
                                    switch (role.trim()) {
                                      case 'TA':
                                        return t('label_role_ta');
                                      case 'PL':
                                        return t('label_role_pl');
                                      case 'PR':
                                        return t('label_role_pl');
                                      case 'PU':
                                        return t('label_role_pu');
                                      case 'AD':
                                        return t('label_role_ad');
                                      case 'AS':
                                        return t('label_role_as');
                                      case 'TU':
                                        return t('label_role_tu');
                                      case 'SU':
                                        return t('label_role_su');
                                      default:
                                        return t('label_notchosen');
                                    }
                                  })()
                                  : t('Role')}</h5>

                                <div class="mt-2 mb-2">
                                  <hr />
                                </div>

                                <button onClick={handleLogout} type="button" class="btn btn-outline-dark"><i class="fa-solid fa-arrow-right-from-bracket"></i>&nbsp; {t("SignOut")}</button>

                                <br />

                              </div>
                            ) : (
                              <p>Loading user information...</p>
                            )}
                          </div>
                        }
                        title={<div className="profile-header">{t("label_profile")}<span class="login-diag-close"><i class="fa-solid fa-xmark" onClick={() => { setPopoverVisible(!popoverVisible) }}></i></span></div>}
                        open={popoverVisible}
                        onOpenChange={handlePopoverVisible}
                        trigger="click"
                        placement="bottomRight"
                        overlayClassName="custom-popover1"
                      >
                        <div
                          className="user-txt add-temple-icon"
                          title={t('label_profile')}
                          onClick={() => setPopoverVisible(!popoverVisible)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          {sDetails && sDetails.profimg ? (
                            <img
                              alt={sDetails.fullname || 'Default Name'}
                              src={getServerImageAPI() + sDetails.profimg}
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                objectFit: 'cover'
                              }}
                            />
                          ) : (
                            <span className="image-txt">
                              {(firstName && firstName.length > 0 ? firstName.charAt(0) : '')}
                              {/* {(lastName && lastName.length > 0 ? lastName.charAt(0) : '')} */}
                            </span>
                          )}
                        </div>
                      </Popover>
                    </div>
                  ) : (
                    <Popover
                      content={content}
                      trigger="click"
                      open={visible}
                      onOpenChange={handleVisibleChange}
                      placement="bottomRight"
                    >
                      <a className='user_icon' onClick={() => { setVisible(false); setOpen(false); setShowLoginForm(true); }}>

                        <i class="ml-4 mr-3 fa-solid fa-user "></i>
                      </a>
                    </Popover>
                  )}

                  {showLoginForm && <Login1 showLoginForm1={showLoginForm} setShowLoginForm1={setShowLoginForm} />}
                </span>
              </div>
            </div>
          </div>
        </div >
        {window.location.pathname !== '/' && (
          <div className=" search-mobile">
            <div class="search">
              <input type="text" class="searchTerm" placeholder={t("label_what_looking")} onClick={openModal}
                value={searchText}
                readOnly
              />
              <button type="submit" class="searchButton">
                <i class="fa fa-search"></i>
              </button>
            </div>
            {/* <input type="text" style={{    height: '30px',
    margin: '5px',
    borderRadius: '4px',    width: '100%'}} /> */}

          </div>
        )}
        <div className="mainmenu justify-content-between" style={{ overflowX: 'auto' }}>
          {!isHomepage && (
            <div className="d-flex align-items-center back-arrow">
              <i onClick={handleGoBack} className="fa-solid fa-chevron-left"></i>
            </div>
          )}
          {isHomepage && (
            <div className="d-flex align-items-center back-arrow">
              <i onClick={handleGoBack} class="fa-solid fa-house" title={t('Home')}></i>
            </div>
          )}
          {/* {isHomepage ? ( */}
          <div className="container nav-topbanner d-flex" >
            <section className="d-flex justify-content-around align-items-center h-100 w-100">

              <Dropdown
                open={dropdownTempleVisible}
                onOpenChange={handleDropdownTempleVisibleChange}
                overlay={temples}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('horoscope') ? 'active' : ''}`}
                  onClick={() => handleIconClick('horoscope')}
                >
                  <NavLink className='ignore-style' >
                    <div className="nav-icon">
                      <i className="fa-solid fa-gopuram"></i>
                    </div>
                    <span>{t("label_temples_list")} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>
              {/* <div
                  className={`nav-icon-main ${isActiveIcon('liveDarshan') ? 'active' : ''}`}
                  onClick={() => handleIconClick('liveDarshan')}
                >
                  <NavLink className='ignore-style' to={`/videos/${uid}`}>
                    <div className="nav-icon">
                      <div className="live-blinker"></div>
                      <i className="fa-solid fa-gopuram"></i>
                    </div>
                    <span>{t("label_library")}</span>
                  </NavLink>
                </div> */}
              <Dropdown
                open={dropdownVisible}
                onOpenChange={handleDropdownVisibleChange}
                overlay={menu}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('library') ? 'active' : ''}`}
                  onClick={() => handleIconClick('library')}
                >
                  <NavLink className='ignore-style' >
                    <div className="nav-icon">
                      <div className="live-blinker"></div>
                      <i className="fa-solid fa-gopuram"></i>
                    </div>
                    <span>{t("label_library")} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>
              <Dropdown
                open={dropdownArticlesVisible}
                onOpenChange={handleArticlesDropdownVisibleChange}
                overlay={articlesMenu}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('liveTv') ? 'active' : ''}`}
                  onClick={() => handleIconClick('liveTv')}
                >
                  <NavLink className='ignore-style' to={`/articles`}>
                    <div className="nav-icon">
                      <div className="live-blinker"></div>
                      <i className="fa-solid fa-tv"></i>
                    </div>
                    <span>{t("label_articles")} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>
              <Dropdown
                open={dropdownHoroVisible}
                onOpenChange={handleDropdownHoroVisibleChange}
                overlay={horo}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('articles') ? 'active' : ''}`}
                  onClick={() => handleIconClick('articles')}
                >
                  <NavLink className='ignore-style' >
                    <div className="nav-icon">
                      <div className="live-blinker"></div>
                      <i className="fa-regular fa-newspaper"></i>
                    </div>
                    <span>{t('label_horo')} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>

            </section>
          </div>

          <div></div>
        </div>
      </div >
      {showWelcome && (
        <div class="welcomebg">
          <div class="welcome-image"></div>
        </div>
      )}
    </>
  )
}

export default PageHeader;