import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Table, Popconfirm } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationsList, getDonationsListError, getDonationsListLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsList, fetchRegionalNotifi } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const Donations = () => {
    const { t } = useTranslation();
  const storedLanguage = localStorage.getItem('lng');
    const userid = localStorage.getItem('urole')
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('failure', t('label_donation_emptylist_failure'))
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationsList(state),
        rLoading: getDonationsListLoading(state),
        rError: getDonationsListError(state),
    }));

    useEffect(() => {
        dispatch(fetchDonationsList(0));
    }, [dispatch]);

    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts));
    }

    const modifiedData = rList && rList.map((item, index) => ({ ...item, "S.No": index + 1 }));
    const downloadAsPDF = () => {
        const doc = new jsPDF();

        // Define column headers
        const headers = columns.map(column => column.title.props.children);

        // Extract data from modifiedData
        const data = modifiedData.map(item => columns.map(column => item[column.dataIndex]));

        // Add headers and data to the PDF
        doc.autoTable({
            head: [headers],
            body: data,
        });

        // Save the PDF
        doc.save('donations.pdf');
    };

    const getDonationTypeLabel = (dontype) => {
        switch (dontype) {
            case 'M':
                return t('label_Maintainance');
            case 'H ':
                return t('label_Hundi');
            case 'A':
                return t('label_Annadanam');
            case 'D ':
                return t('label_daily_archana');
            case 'R':
                return t('label_repaire');
            case 'N':
                return t('label_new_constr');
            default:
                return t('label_notchosen');
        }
    };


    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_id')}
                </span>
            ),
            dataIndex: "donid",
            key: "projname",
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_donor')}
                </span>
            ),
            dataIndex: "donor",
            key: "projname",

        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_date')}
                </span>
            ),
            dataIndex: "dondt",
            key: "sponsor",
            render: (text) => {
                const formattedDate = new Date(text).toISOString().split('T')[0]; // Format as YYYY-MM-DD
                return <span>{formattedDate}</span>;
            },
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_amount')}
                </span>
            ),
            dataIndex: "amount",
            key: "startdate",
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_download')}
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i class="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'Delete',
        //     key: 'delete',
        //     render: (text, record) => (
        //       <Popconfirm
        //         title="Are you sure you want to delete?"
        //         onConfirm={() => deleteDon(record.donid, record.dondt)}
        //         okText="Yes"
        //         cancelText="No"
        //       >
        //         <Link>Delete</Link>
        //       </Popconfirm>
        //     ),
        //   },
    ];

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_donations')}</span>
                    </div>
                    <div></div>
                </div>
                {contextHolder}
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                        <span className="d-flex align-items-center">
                            <Link style={{ color: 'black' }} to="/">
                                <span className="bc-active-link" onClick={handleGoBack}>{t('Home')}</span>
                            </Link>
                            <span className="ml-3 mr-3">
                                <i className="fa-solid fa-angle-right f-9"></i>
                            </span>
                        </span>
                        <span className="bc-link">{t("label_donations")}</span>
                    </div>
                <div class="bg-details-highlight" >
                    <div class=" details-headertxt-singleLine container">
                        <div class="header_txt"> {t("label_donations")}</div>
                        <div class="details-headertxt-singleLine-btns">
                            <Link to='/expenses' ><button type="button" class="button_move" style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('label_expenses')}</button></Link>
                            <Link to='/addDonations' ><button type="button" class="button_move" style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('label_add_donations')}</button></Link>
                            {userid === 'TA' || 'TU' ? (
                                <>
                                    <Link to='/templeDashboard' style={{ marginLeft: '10px' }} class="button_move" >{t('label_my_temple_dashboard')}</Link>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                     
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div class="container">
                        <h5 class="card-text-title">{t("label_donations")} <span>| {t("label_donations_contents")}</span></h5>
                    </div>


                    <div className='container' style={{ overflowX: 'auto' }}>
                        <Table dataSource={modifiedData} columns={columns} bordered
                            className="grid-style"
                        />

                    </div>
                    <div className='profile-butt' style={{ marginTop: '5px' }}>


                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default Donations;
