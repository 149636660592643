import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, message } from 'antd';
import { fetchFavAuthorError, fetchFavAuthorList, fetchFavAuthorList1, fetchFavAuthorLoading, getAuthorArtcilesError, getAuthorArtcilesList, getAuthorArtcilesLoading, getPriestDetailsError, getPriestDetailsList, getPriestDetailsLoading } from '../../redux/selectors/selectors';
import { fetchAuthorArticles, fetchFavouriteAuthors, fetchPriestDetails, subscribeAuthor, unsubscribeAuthor } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';
import AP_LANGUAGES from '../common/appLangs';
import { getServerImageAPI } from '../../http-common';
import { Option } from 'antd/es/mentions';

const PublicProfile = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');
    const { id } = useParams()
    const { author } = useParams()
    const { city } = useParams()
    const { st } = useParams()
    const dispatch = useDispatch();
    const urole = localStorage.getItem('urole')
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const success = () => {
        showToast('success', 'label_add_temple_sucess')
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', msg);
    };

    const { favAuthorList, favAuthorLoading, favAuthorError } = useSelector(state => ({
        favAuthorList: fetchFavAuthorList1(state),
        favAuthorLoading: fetchFavAuthorLoading(state),
        favAuthorError: fetchFavAuthorError(state),
    }));

    const { authorArticleList, authorArticleLoading, authorArticleError } = useSelector(state => ({
        authorArticleList: getAuthorArtcilesList(state),
        authorArticleLoading: getAuthorArtcilesLoading(state),
        authorArticleError: getAuthorArtcilesError(state),
    }));

    useEffect(() => {
        dispatch(fetchAuthorArticles(id, storedLanguage));
    }, [dispatch]);

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getPriestDetailsList(state),
        rLoading: getPriestDetailsLoading(state),
        rError: getPriestDetailsError(state),
    }));

    useEffect(() => {
        dispatch(fetchPriestDetails(id));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: rList.fullname || '',
        // gender: rList.gender || '',
        // lang: rList.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const editsucess = () => {
        showToast('success', t('label_subscribe_success'))
        form.resetFields()
    };

    const editfailure = (msg) => {
        showToast('error', msg)
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    let last_rec = 0;

    useEffect(() => {
        dispatch(fetchFavouriteAuthors(storedLanguage, last_rec));
    }, [dispatch, storedLanguage, last_rec]);

    useEffect(() => {
        if (favAuthorList && favAuthorList.fav_list) {
            setIsSubscribed(favAuthorList && favAuthorList.fav_list.includes(Number(id)));
        }
    }, [favAuthorList, id]);

    const handleSaveClick = useCallback(() => {
        if (isSubscribed) {
            dispatch(unsubscribeAuthor(id,
                () => {
                    setIsSubscribed(false);
                    showToast('success', t('label_unsubscribe_success'));
                },
                (msg) => {
                    showToast('error', msg);
                }
            ));
        } else {
            dispatch(subscribeAuthor(id,
                () => {
                    setIsSubscribed(true);
                    showToast('success', t('label_subscribe_success'));

                },
                (msg) => {
                    showToast('error', msg);
                }
            ));
        }
    }, [dispatch, id, isSubscribed, subscribeAuthor, unsubscribeAuthor, showToast, t]);




    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_author_speaker')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'black' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                </span>
                <Link style={{ color: 'black' }} to="/">
                    <span className="bc-active-link" onClick={handleGoBack}>{t("label_author")}</span>
                </Link>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">
                        {author}
                    </div>
                    <div className="details-headertxt-singleLine-btns">
                        <button
                            className="button_move"
                            onClick={handleSaveClick}
                            style={{ marginRight: '10px' }}
                            disabled={isSubscribed === null}
                        >
                            {isSubscribed ? t('label_unsubscribe') : t('label_subscribe')}
                        </button>
                    </div>

                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className="container">

                    <div class="container">
                        <div class="main-body">

                            <div class="row gutters-sm">
                                <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex flex-column align-items-center text-center">
                                                <section>
                                                    <div className="user-pic">
                                                        {rList && rList.profimg ? (
                                                            <img alt={rList.fullname || 'Default Name'} src={rList.profimg ? getServerImageAPI() + rList.profimg : deftemples} />
                                                        ) : (
                                                            <img src={deftemples} alt="Default Profile" />
                                                        )}
                                                    </div>
                                                </section>
                                                <div class="mt-3">
                                                    <h4>{author}</h4>
                                                    <p class="text-secondary mb-1">{rList && rList.addr1}</p>
                                                    <p class="text-muted font-size-sm">{rList && rList.addr2}</p>
                                                    <button class="btn btn-primary">Follow</button>
                                                    <button class="btn btn-outline-primary">Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mt-3">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Temples</h6>
                                                <span class="text-secondary">3</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>Images</h6>
                                                <span class="text-secondary">3</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Articles</h6>
                                                <span class="text-secondary">10</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <Form form={form} name="nest-messages" labelCol={{ span: 6 }} wrapperCol={{ span: 8 }} >
                                                <div class="float-left w-100" >
                                                    <h5 class="card-text-title2">{t('label_author_details')}</h5>
                                                </div>
                                                <div class="active-details" >
                                                    <div className="tiles-designs">
                                                        <Form.Item
                                                            label={<Typography.Title level={4}>{t("label_village_area")}</Typography.Title>}
                                                            name="addr1"
                                                        >
                                                            {editable ? (
                                                                <Input style={{ height: '35px' }} defaultValue={rList && rList.addr1} />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.addr1}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item
                                                            label={<Typography.Title level={4}>{t("label_addr")}</Typography.Title>}
                                                            name="addr2"
                                                        >
                                                            {editable ? (
                                                                <Input style={{ height: '30px' }} defaultValue={rList && rList.addr2} />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.addr2}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item
                                                            label={<Typography.Title level={4}>{t("label_phone")}</Typography.Title>}
                                                            name="phone"
                                                        >
                                                            {editable ? (
                                                                <Input style={{ height: '30px' }} defaultValue={rList && rList.phone} />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.phone}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item
                                                            label={<Typography.Title level={4}>{t("label_pin")}</Typography.Title>}
                                                            name="pin"
                                                        >
                                                            {editable ? (
                                                                <Input style={{ height: '30px' }} defaultValue={rList && rList.pin} />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.pin}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div class="tiles-designs" >
                                                        <Form.Item
                                                            label={<Typography.Title level={4} >{t("label_Degree1")}</Typography.Title>}
                                                            name="degree1"
                                                        >
                                                            {editable ? (
                                                                <Input style={{ height: '30px' }}
                                                                    defaultValue={rList && rList.degree1}
                                                                />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.degree1}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div class="tiles-designs" >
                                                        <Form.Item
                                                            label={<Typography.Title level={4} >{t("label_Degree2")}</Typography.Title>}
                                                            name="degree2"
                                                        >
                                                            {editable ? (
                                                                <Input style={{ height: '30px' }}
                                                                    defaultValue={rList && rList.degree2}
                                                                />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.degree2}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div class="tiles-designs" >
                                                        <Form.Item
                                                            label={<Typography.Title level={4} >{t("label_summary")}</Typography.Title>}
                                                            name="summary"
                                                        >
                                                            {editable ? (
                                                                <Input.TextArea placeholder="Enter History " rows={3}
                                                                    defaultValue={rList && rList.summary}
                                                                />
                                                            ) : (
                                                                <Typography.Text level={5} className='typ'>{rList && rList.summary}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default PublicProfile;