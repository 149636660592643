import React, { useState } from "react";
import templeSearch from "../../../assets/images/temple-search.png";

const HomeIcon = ({ iconSrc }) => {
  const [showIcons, setShowIcons] = useState(false);

  return (
    <div className="home-icon-container">
      {/* Main Icon (Clickable) */}
      <div className="temple-search-icon1" onClick={() => setShowIcons(!showIcons)}>
        <img src={templeSearch} alt="Famous Hindu Temple Search" />
      </div>

      {/* Icons Appearing Above the Main Icon */}
      <div className={`icons-container ${showIcons ? "show" : ""}`}>
        <img src={templeSearch} className="icon-animation first" alt="Hindu Temples Search by Location" />
        <img src={templeSearch} className="icon-animation second" alt="Hindu Temples Search by Name" />
      </div>
    </div>
  );
};

export default HomeIcon;
