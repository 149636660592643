import { Form, Input, Typography, message, Image, Col, Row, Select, Modal, Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGodNames, postTemples, postUploadImageRequest, retrieveGodNames } from '../../../../redux/actions/acions';
import Cropper from 'cropperjs';
import { useTranslation } from 'react-i18next';
import aum from '../../../../assets/icons/om.jpg'
import statesData from '../../states.json';
import { GoogleMap, Marker } from '@react-google-maps/api';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import { getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import deityImages from '../../../common/godImages';
import AP_LANGUAGES from "../../../common/appLangs"

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
};

const AddTemple = () => {
  const [showMap, setShowMap] = useState(false);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);
  const [mapKey, setMapKey] = useState(1);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [error, setError] = useState(false);
  const [currentLocationClicked, setCurrentLocationClicked] = useState(false);
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [selectedDeity, setSelectedDeity] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const localEnv = false;
  const [markerVisible, setMarkerVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [newImagePath, setNewImagePath] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const userole = localStorage.getItem('urole')

  const { godCtgryList } = useSelector(state => ({
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const tid = localStorage.getItem('templeid')

  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    showToast('success', t('label_add_temple_sucess'));
  };

  const handleModalOk = () => {
    setIsVisible(false);
  };

  const handleModalSkip = () => {
    setIsVisible(false);
  };

  const failure = (msg) => {
    showToast('error', msg)
  };

  const handleCameraClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        dispatch(postUploadImageRequest(tid, formData, () => {
          const uploadedImagePath = URL.createObjectURL(selectedFile);
          setNewImagePath(uploadedImagePath);
          console.warn('Image uploaded successfully');
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
        }));
      } else {
        console.warn('No file selected');
      }

      document.body.removeChild(fileInput); // Clean up the file input
    });

    document.body.appendChild(fileInput);
    fileInput.click();
  };

  const [cropper, setCropper] = useState(null);
  const imageRef = useRef();

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const handleGalleryClick = (event) => {
    event.preventDefault();
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result); // Show image in modal
          setIsModalVisible(true); // Open modal
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    fileInput.click();
  };

  const handleImageCloseModal = () => {
    setIsModalVisible(false);
    if (cropper) {
      cropper.destroy(); // Destroy cropper instance when modal is closed
    }
  };

  const websiteRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.com$/;

  const validateWebsite = (_, value) => {
    if (value && !websiteRegex.test(value)) {
      return Promise.reject(new Error("Please enter a valid website URL ending in .com."));
    }
    return Promise.resolve();
  };

  const onImgDelete = () => {
    setNewImagePath(null); // Remove the image path
    // Add any other functionality you need here
  };

  const handleCropAndUpload = (event) => {
    event.preventDefault();
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob);

        // Replace the following line with your image upload logic
        dispatch(postUploadImageRequest(0, formData, () => {
          const uploadedImagePath = URL.createObjectURL(blob);
          setNewImagePath(uploadedImagePath);
          handleImageCloseModal(); // Close modal after uploading
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
        }));
      });
    }
  };

  useEffect(() => {
    if (isModalVisible && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
        viewMode: 1,
        autoCropArea: 1, // Ensure the crop area covers the whole image
        responsive: true, // Ensure responsiveness
        scalable: false,
        zoomable: true,
        cropBoxResizable: true,
        minCropBoxHeight: 200, // Min height as per your style
        maxCropBoxHeight: 200, // Max height as per your style
        minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
        maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
      });
      setCropper(cropperInstance);
    }

    return () => {
      if (cropper) {
        cropper.destroy(); // Cleanup when the modal is closed or component unmounts
      }
    };
  }, [isModalVisible]);

  const storedLanguage = localStorage.getItem('lng') || 5;

  const values = form.getFieldsValue();

  const onFinish = () => {
    form.validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        const data = {
          tname: values.tname,
          deityname: values.deityname,
          avatar: selectedDeity !== null ? selectedDeity : values.maindeity,
          imgpath: localStorage.getItem('img'),
          addr1: values.addr1,
          addr2: values.addr2,
          timings: values.timings || '',
          descr: values.descr || '',
          city: values.city,
          st: values.st,
          pincode: values.pincode,
          country: "india",
          website: values.website,
          latit: parseFloat(values.lat),
          longi: parseFloat(values.lon),
          lang: values.lang,
          otherdeities: values.otherdeities,
        };

        if (imageFile) {
          // Upload image first if imageFile is present
          uploadImageWithTid(0, imageFile, (response) => {
            console.log('Upload response received:', response); // Log the entire response

            // Dispatch the postTemples action with the updated data
            dispatch(postTemples(data, () => {
              success(); // Show success toast
              setIsVisible(true); // Show modal on success
            }, failure()))
              .then((tid) => {
                console.debug('Form submission successful, tid:', tid);
              })
              .catch((error) => {
                console.error('Form submission failed:', error);
                // Do not reset the form on failure
              });
          });
        } else {
          // If no image file is selected, directly submit the form
          dispatch(postTemples(data, () => {
            success(); // Show success toast
            setIsVisible(true); // Show modal on success
          }, failure()))
            .then((tid) => {
              console.debug('Form submission successful, tid:', tid);
            })
            .catch((error) => {
              console.error('Form submission failed:', error);
              // Do not reset the form on failure
            });
        }
      })
      .catch((errorInfo) => {
        if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
          const missingFields = errorInfo.errorFields.map(field => field.name.join('.')).join(', ');

          // Use Ant Design's message.error to display missing fields
          message.error(`Please fill the required fields: ${missingFields}`);
        }
        console.error('Validation failed:', errorInfo);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission behavior
      onFinish(); // Trigger form submission
    }
  };



  const uploadImageWithTid = (tid = 0, file, onSuccess) => {
    const formData = new FormData();
    formData.append('file', file);

    dispatch(
      postUploadImageRequest(
        tid,
        formData,
        (response) => {
          console.log('Raw response from upload API:', response); // Log the raw response
          if (onSuccess) onSuccess(response); // Pass the raw response to onSuccess callback
        },
        (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
          if (onSuccess) onSuccess(null); // Pass null on error
        }
      )
    );
  };

  useEffect(() => {
    if (imageFile) {
      uploadImageWithTid(0, imageFile); // Use 0 as the temporary tid for the upload
    }
  }, [imageFile]);

  const pincodeRegex = /^[0-9]{6}$/;

  const validatePincode = (_, value) => {
    if (value && !pincodeRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid pincode! It must be exactly 6 digits.'));
    }
    return Promise.resolve();
  };

  const [showAllImages, setShowAllImages] = useState(false);

  const handleImageClick = () => {
    setShowAllImages(true);
  };

  const handleDeityImageClick = (deityNumber) => {
    setSelectedDeity(deityNumber);
    form.setFieldsValue({ avatar: deityNumber }); // Update the form field with the selected deity
    handleCloseModal(); // Close the modal after selecting the deity
  };

  const handleCloseModal = () => {
    setShowAllImages(false);
  };


  const handleStateChange = (value) => {
    // Update the selected state and list of cities
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities : []);
  };

  useEffect(() => {
    if (selectedState) {
      form.setFieldsValue({ city: selectedState.cities[0] });
    }
  }, [selectedState]);

  const handleLocationButtonClick = () => {
    setShowMap(!showMap);
    setMarkerVisible(true);
    setLocationSelected(false);
  };

  const handleMarkerDragEnd = async (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();

    setCurrentLocation({
      lat: newLat,
      lng: newLng,
    });

    // Update latitude and longitude state variables
    setLat(newLat.toFixed(6));
    setLon(newLng.toFixed(6));

    // Perform reverse geocoding
    await reverseGeocode(newLat, newLng);
  };

  const handleGetCurrentLocation = async (event) => {
    event.preventDefault();
    if (navigator.geolocation) {
      try {
        // Wrap getCurrentPosition in a Promise to use await
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        setCurrentLocation({ lat: latitude, lng: longitude });
        setCurrentLocationClicked(true);
        setError(false);
        setLat(latitude.toFixed(6));
        setLon(longitude.toFixed(6));
        setShowMap(true);
        setMarkerVisible(true);
        setMapKey(prevKey => prevKey + 1); // Force map re-render

        // Now we can await since we're in an async function
        await reverseGeocode(latitude, longitude);
      } catch (error) {
        console.error('Error getting current location:', error);
      }
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBLWiZul9MyYq8DwaGhosrNWP7QJJT9krU`
      );

      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        // Find the most detailed address result
        const addressResult = data.results.find(r =>
          r.types.includes('street_address') ||
          r.types.includes('route') ||
          r.types.includes('sublocality')
        ) || data.results[0];

        const addressComponents = addressResult.address_components.filter(
          component => !component.types.includes('plus_code')
        );

        // Clean formatted address by removing plus codes
        let formattedAddress = addressResult.formatted_address;
        formattedAddress = formattedAddress.replace(/\b[A-Z0-9]+\+[A-Z0-9]+\b/g, '').trim();
        formattedAddress = formattedAddress.replace(/^,\s*|\s*,/g, '').trim();

        // Initialize address parts
        const address = {
          state: '',
          city: '',
          pincode: '',
          street: '',
          village: '',
          templename: '',
          fullAddress: formattedAddress
        };

        // Extract components (excluding plus codes)
        addressComponents.forEach(component => {
          if (component.types.includes('administrative_area_level_1')) {
            address.state = component.long_name;
          }
          if (component.types.includes('locality') || component.types.includes('postal_town')) {
            address.city = component.long_name;
          }
          if (component.types.includes('postal_code')) {
            address.pincode = component.long_name;
          }
          if (component.types.includes('route')) {
            address.street = component.long_name;
          }
          if (component.types.includes('sublocality') ||
            component.types.includes('neighborhood') ||
            component.types.includes('sublocality_level_1')) {
            // Filter out plus codes from village name
            address.village = component.long_name.replace(/\b[A-Z0-9]+\+[A-Z0-9]+\b/g, '').trim();
          }
        });

        // Check for temple name
        if (addressResult.types.includes('place_of_worship') ||
          formattedAddress.toLowerCase().includes('temple')) {
          const placeResult = await getPlaceDetails(latitude, longitude);
          address.templename = placeResult.name || '';
        }

        // Set form fields with proper mapping
        form.setFieldsValue({
          st: address.state,
          city: address.city,
          pincode: address.pincode,
          addr1: address.village || address.street || formattedAddress.split(',')[0],
          addr2: address.street || formattedAddress,
          tname: address.templename
        });

        if (address.state) {
          handleStateChange(address.state);
        }
      }
    } catch (error) {
      // Error handling
    }
  };


  const getPlaceDetails = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${latitude},${longitude}&radius=50&key=AIzaSyBLWiZul9MyYq8DwaGhosrNWP7QJJT9krU`
      );

      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        // Return the first result (closest place)
        return data.results[0];
      }
      return null;
    } catch (error) {
      console.error('Error getting place details:', error);
      return null;
    }
  };

  const handleGetLatLonFromMap = () => {
    if (currentLocation) {
      setLat(currentLocation.lat.toFixed(6));
      setLon(currentLocation.lng.toFixed(6));
      form.setFieldsValue({
        lat: currentLocation.lat.toFixed(6),
        lon: currentLocation.lng.toFixed(6),
      });

      // Close map after selection
      setShowMap(false);
    }
    if (!currentLocationClicked) {
      setError(true); // Show the error if 'Use Current Location' wasn't clicked first
    } else {
      setError(false); // No error if 'Use Current Location' was already clicked
      // Logic for using the selected location from the map
    }
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const indiaCenter = { lat: 20.5937, lng: 78.9629 };

  const customButtonStyle = {
    marginBottom: '10px',
    flex: '1',
  };

  const handleCancelClick = () => {
    // Reset the form to its initial values
    form.resetFields();
    navigate(-1);
    // setEditable(false);
  };

  return (
    <>
      {contextHolder}

      <div class='mainsec'>
        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span class="bc-link">{t('add_temple')}</span>
          </div>
          <div></div>
        </div>
        <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
          <span className="d-flex align-items-center">
            <Link style={{ color: 'black' }} to="/">
              <span className="bc-active-link" onClick={handleGoBack}>{t('Home')}</span>
            </Link>
            <span className="ml-3 mr-3">
              <i className="fa-solid fa-angle-right f-9"></i>
            </span>
          </span>
          <span className="bc-link">{t("label_temples_list")}</span>
        </div>
        {/* <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
          <Link style={{ color: 'black' }} to='/'>
            <span className="bc-active-link">{t('Home')}</span>
          </Link> -&nbsp;<span className="bc-active-link" onClick={handleGoBack}>{t('label_temples_list')}</span> -&nbsp;<span className="bc-link">{t("add_temple")}</span>
        </div> */}
        <div class="bg-details-highlight">
          <div class=" details-headertxt-singleLine container">
            <div class="header_txt"> {t('label_add_new_tmpl')}</div>
            <div class="details-headertxt-singleLine-btns">
              <a class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                {t('label_cancel')}
              </a>
              <a class="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                {t('label_save')}
              </a>
            </div>
            <Modal
              title="Temple Activation"
              visible={isVisible} // Use isVisible state here
              onOk={handleModalOk}
              onCancel={handleModalSkip}
              footer={[
                <Button key="contributions" onClick={() => navigate("/contribution")}>
                  My Contributions
                </Button>,
                <Button key="details" type="primary" onClick={() => navigate(`/templeDetails/${tid}`)}>
                  Show Details
                </Button>

              ]}
            >
              <p>
                <strong>
                  After carefully reviewing your temple request, the admin will activate your temple and showcase it in the listing screens. Meanwhile, you can track the status of your contributions using the options below.
                </strong>
              </p>
              <p>
                <strong>Temple Name:</strong> {values.tname}
              </p>
              <p>
                <strong>State:</strong> {values.st}
              </p>
              <p>
                <strong>City:</strong> {values.city}
              </p>
              <p>
                <strong>Contributed on:</strong> {new Date().toLocaleDateString()}
              </p>
            </Modal>
          </div>
        </div>
        {/* temple_detailsbg */}
        <div class="mainsec-block-addtemple-detailspage ">
          <div className='container temple-form'>
            <Form form={form} name="nest-messages" onFinish={onFinish} onKeyDown={handleKeyDown}
              initialValues={{
                lat: lat || undefined,
                lon: lon || undefined,
              }}
            >
              <div class="float-left w-100" ><h5 class="card-text-title">Add Temple - General Details</h5> </div>


              <div class=" addTemp aos-init aos-animate " data-aos="fade-up" data-aos-delay="100">

                <div class="row gy-4">
                  <div class="col-lg-6 mt-4">

                    <div class="row gy-4">
                      <div class="col-md-12">
                        <div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="200">
                          <div class="at_title mb-3"><i class="fa-solid fa-gopuram"></i>
                            <h3>Address</h3>
                          </div>

                          <div class="active-details">
                            <div class="tiles-designs m-m-at" >
                              <Form.Item
                                name="tname"
                                label={<Typography.Title level={4}>{t('label_temple_name')}</Typography.Title>}
                                rules={[
                                  {
                                    required: true,
                                    message: t('error_required_field'),
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (value && value.length > 100) {
                                        return Promise.reject(new Error("Temple name cannot exceed 100 characters."));
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  style={{ height: '30px' }}
                                  placeholder={t('label_enter_temple_name')}
                                  maxLength={100}
                                />
                              </Form.Item>
                            </div>


                            <div class="tiles-designs" >
                              <Form.Item
                                name="deityname"
                                label={<Typography.Title level={4}>{t('label_main_deity')}</Typography.Title>}
                                rules={[
                                  {
                                    required: true,
                                    message: t('error_required_diety_field'),
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (value && value.length > 50) {
                                        return Promise.reject(new Error("Diety name cannot exceed 50 characters."));
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  style={{ height: '30px' }}
                                  placeholder={t('label_enter_main_deity')}
                                  maxLength={50}
                                />
                              </Form.Item>
                            </div>
                            <div className="tiles-designs">
                              <Form.Item
                                name="otherdeities"
                                label={<Typography.Title level={4}>{t('label_other_deities')}</Typography.Title>}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (value && value.length > 60) {
                                        return Promise.reject(new Error("Deity name cannot exceed 60 characters."));
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  style={{ height: '30px' }}
                                  placeholder={t('label_enter_other_deities')}
                                  maxLength={60}
                                />
                              </Form.Item>
                            </div>

                            <div class="tiles-designs" >
                              <Form.Item
                                name="descr"
                                label={<Typography.Title level={4}>{t('label_descr')}</Typography.Title>}
                              >
                                <TextArea rows={4} placeholder={t('label_enter_details')} />
                              </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                              <Form.Item
                                name="timings"
                                label={<Typography.Title level={4}>{t("label_timings")}</Typography.Title>}
                              >
                                <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('label_enter_timings')} />
                              </Form.Item>
                            </div>
                          </div>



                        </div>
                      </div>




                    </div>

                  </div>
                  <div class="col-lg-6">

                    <div class="row gy-4">


                      <div class="col-md-6 mt-4">




                        <div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="500">



                          <div class="at_title"><i class="fa-solid fa-om"></i>
                            <button type="button" class="declineButton selected ml-4 " onClick={handleImageClick}>{t('label_sel_diety_img')}
                            </button>
                          </div>




                          <div className="tiles-designs float-left">



                            <img
                              src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                              alt="Selected Deity Image"
                              onClick={handleImageClick}
                              class="add-temple-img"
                            />
                            <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                              <div class="popup-main-filter animate__animated animate__fadeInUp ">
                                <div class="popup-main-header-filter">
                                  <span class="popup-main-header-text-filter">{t('label_god_categ')}</span>
                                  <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                  <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div className="popup-main-container-filter">
                                  <div class="d-block float-left content-filter">
                                    <div className=" god-category-list">
                                      <div className="all-gods-sec">{t('label_all')}</div>
                                      <div className="god_select">
                                        <div className="god_pic">
                                          <img
                                            className="dietyImg"
                                            src={aum}
                                            alt="All"
                                            onClick={() => handleDeityImageClick(null)}
                                          />
                                        </div>
                                      </div>
                                      <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                      <Row gutter={[16, 16]}>
                                        {godCtgryList && godCtgryList.map((godCtgry) => (
                                          <Col key={godCtgry.avatar}>
                                            <div className="god_select">
                                              <div className="god_pic">
                                                <img
                                                  src={deityImages[godCtgry.avatar]}
                                                  alt="Deity Image"
                                                  onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                />
                                              </div>
                                              <p>{godCtgry.deity}</p>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                                <div class="popup-main-footer">
                                  <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 mt-4">
                        <div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="400">
                          <div class="at_title"><i class="fa-solid fa-map-pin"></i>
                            <button type="button" class="declineButton selected ml-4 " onClick={handleLocationButtonClick}>{t('label_show_map')}
                            </button>
                          </div>


                          {/* <h3>{t('label_map')}</h3> */}
                          <div className="tiles-designs float-left m-m-at" >

                            {showMap && (
                              <>
                                <div class="popup-bg" style={{ display: showMap ? 'flex' : 'none' }}>
                                  <div class="popup-main animate__animated animate__fadeInUp ">
                                    <div class="popup-main-header">
                                      <span class="popup-main-header-text">{t('label_sel_loc_on_map')}</span>
                                      <div class="popup-close" onClick={() => setShowMap(false)}><i class="fa-solid fa-xmark"></i></div>
                                      <div class="popup-back" onClick={() => setShowMap(false)}><i class="fa-solid fa-chevron-left"></i></div>
                                    </div>
                                    <div class="popup-main-container">
                                      <div class="d-block htinner-popup-main-container">
                                        <GoogleMap
                                          center={currentLocation || indiaCenter}
                                          zoom={currentLocation ? 14 : 5}
                                          mapContainerStyle={{ width: '100%', height: '64vh' }}
                                        >
                                          {currentLocation && markerVisible && (
                                            <Marker
                                              position={currentLocation}
                                              draggable={true}
                                              onDragEnd={handleMarkerDragEnd}
                                            />
                                          )}
                                        </GoogleMap>
                                      </div>
                                    </div>
                                    <div class="position-relative">
                                      <div class="position-absolute map-btns ml-4">
                                        <button type="button" class="btnmap" onClick={handleGetCurrentLocation}><i class="fa-solid fa-location-crosshairs"></i> <span>{t('label_show_curr_loc')}</span></button><br />
                                        <button type="button" class="btnmap" onClick={handleGetLatLonFromMap}><i class="fa-solid fa-magnifying-glass-location"></i> <span>{t("label_use_this_loc")}</span></button>
                                      </div>
                                    </div>
                                    <div className='map-txt1'>
                                      {t("label_drag_pointer_to_change_location")}
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                      <a className="button_move" onClick={() => setShowMap(false)}>
                                        {t("back")}
                                      </a>

                                    </div>
                                    {/* <div className="popup-container">
            <div className="popup-main-footer1">
              <div className="location-button">
                <button
                  key="button"
                  className="button_move1"
                  type="primary"
                  onClick={handleGetCurrentLocation}
                  style={customButtonStyle}
                >
                  {t("label_use_curr_loc")}
                </button>
                <button
                  key="submit"
                  className="button_move1"
                  type="primary"
                  onClick={handleGetLatLonFromMap}
                  style={customButtonStyle}
                >
                  {t("label_use_this_loc")}
                </button>
              </div>
              <div className="location-button1">
                <button
                  key="back"
                  className="button_move1"
                  type="primary"
                  onClick={() => setShowMap(false)}
                  style={customButtonStyle}
                >
                  {t("label_cancel")}
                </button>
              </div>
            </div>
          </div> */}
                                    {error && (
                                      <div className="popup-error" style={{ color: "red", marginTop: "10px" }}>
                                        {t("label_location_error")}
                                      </div>
                                    )}

                                  </div>
                                </div>
                              </>
                            )}

                            {/* <Form.Item name="lat" label={<Typography.Title level={4}>{t('label_latit')}</Typography.Title>}
              rules={[
                {
                  required: true,
                  message: t('error_msg_map'),
                },
              ]}
            > */}
                            {userole === 'AD' || userole === 'AS' ? (
                              <Typography.Text className='mb-4'>{lat ? lat : '0.000000'} - Latitude    </Typography.Text>
                             
                            ) : (
                              <div class="tiles-designs"><Input style={{ height: '30px' }} placeholder={t('label_select_lat')} value={lat} onChange={(e) => setLat(e.target.value)} /></div>
                            )}

                            {/* </Form.Item> */}

                            {/* <Form.Item name="lon" label={<Typography.Title level={4}>{t('label_longi')}</Typography.Title>}
              rules={[
                {
                  required: true,
                  message: t('error_msg_map'),
                },
              ]}
            > */}
                            <br />
                            {userole === 'AD' || userole === 'AS' ? (
                              <Typography.Text>{lon ? lon : '0.000000'} - Longitude</Typography.Text>
                            ) : (
                              <div class="tiles-designs"><Input style={{ height: '30px' }} placeholder={t('label_select_lon')} value={lon} onChange={(e) => setLon(e.target.value)}/></div>
                            )}

                            {/* </Form.Item> */}

                          </div>
                        </div>
                      </div>
                      {/* <div class="col-md-12">
<div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="300">
<i class="fa-solid fa-mobile-retro"></i>
<h3>Call Us</h3>
<p>+1 5589 55488 55</p>
<p>+1 6678 254445 41</p>
</div>
</div> */}
                      <div class="col-md-12 mt-4">

                        <div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="200">
                          <div class="at_title mb-3">
                            <i class="fa-solid fa-address-card"></i>
                            <h3>{t('label_addr_loc_dtl')}</h3>
                          </div>
                          {/*  <p>A108 Adam Street</p>
<p>New York, NY 535022</p> */}
                          <div class="active-details m-m-at">
                            <div class="tiles-designs" >
                              <Form.Item
                                name="addr1"
                                label={<Typography.Title level={4}>{t('label_village_area')}</Typography.Title>}
                                rules={[
                                  {
                                    required: true,
                                    message: t('error_msg_addrs')
                                  },
                                ]}
                              >
                                <Input style={{ height: '30px' }} placeholder={t('label_enter_vill_loc')} />
                              </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                              <Form.Item
                                name="addr2"
                                label={<Typography.Title level={4}>{t('label_addr')}</Typography.Title>}
                              >
                                <Input style={{ height: '30px' }} placeholder={t('label_enter_address')} />
                              </Form.Item>
                            </div>
                            <div class="tiles-designs">
                              <Form.Item
                                label={<Typography.Title level={4}>{t('label_state')}</Typography.Title>}
                                name="st"
                                rules={[
                                  { required: true, message: t('error_msg_st') },
                                ]}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  placeholder={t('label_select_state')}
                                  onChange={handleStateChange}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {states.map((state) => (
                                    <Select.Option key={state} value={state}>
                                      {state}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                              <Form.Item
                                label={<Typography.Title level={4}>{t("label_city")}</Typography.Title>}
                                name="city"
                                rules={[
                                  { required: true, message: t('error_msg_city') },
                                ]}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  placeholder={t('label_select_city')}
                                  value={selectedState ? undefined : ''}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {cities.map((city) => (
                                    <Select.Option key={city} value={city}>
                                      {city}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="tiles-designs">
                              <Form.Item
                                name="pincode"
                                label={<Typography.Title level={4}>{t('label_pin')}</Typography.Title>}
                                rules={[
                                  {
                                    validator: validatePincode,
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  maxLength={6}
                                  style={{ height: '30px' }}
                                  placeholder={t('label_enter_pincode')}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                              <Form.Item
                                name="website"
                                label={<Typography.Title level={4}>{t('label_website')}</Typography.Title>}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      const websiteRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com)$/;
                                      if (value && !websiteRegex.test(value)) {
                                        return Promise.reject(new Error("Please enter a valid website ending in .com"));
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  style={{ height: '30px' }}
                                  placeholder={t('label_enter_website')}
                                  onInput={(e) => {
                                    let value = e.target.value.replace(/[^a-zA-Z0-9:/._-]/g, '');

                                    if (value.includes('.com')) {
                                      value = value.substring(0, value.indexOf('.com') + 4);
                                    }

                                    e.target.value = value;
                                  }}
                                />

                              </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                              <Form.Item
                                name="lang"
                                label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                rules={[
                                  {
                                    required: true,
                                    message: t('error_msg_lng'),
                                  },
                                ]}
                              >
                                <Select options={[{ value: '', label: t('label_select_language') }, ...AP_LANGUAGES]}
                                  placeholder={t('label_select_language')}
                                  style={{ width: "30%" }}
                                />
                              </Form.Item>
                            </div>
                            {/* <div class="center-align"> */}

                          </div>
                        </div>
                      </div>


                      {/* <div class="col-md-6">
<div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="400">
<i class="fa-solid fa-envelope"></i>
<h3>Email Us</h3>
<p>info@example.com</p>
<p>contact@example.com</p>
</div>
</div>

<div class="col-md-6">
<div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="500">
<i class="fa-solid fa-map-pin"></i>
<h3>Open Hours</h3>
<p>Monday - Friday</p>
<p>9:00AM - 05:00PM</p>
</div>
</div> */}

                    </div>

                  </div>



                </div>
                <div class="row gy-4">
                  <div class="col-lg-12">
                    <div class="row gy-4">
                      <div class="col-md-12 mt-4">

                        <div class="info-item aos-init aos-animate" data-aos="fade" data-aos-delay="500">

                          <div class="at_title"><i class="fa-solid fa-om"></i>
                            <h3>{t('label_add_photos')}</h3>
                            <button type="button" class="declineButton selected ml-4 mt-2" onClick={handleImageClick}>{t('label_sel_diety_img')}
                            </button>
                          </div>

                          <div className="tiles-designs float-left">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="active-details d-none">
                <div class="tiles-designs" >
                  <Form.Item
                    name="tname"
                    label={<Typography.Title level={4}>{t('label_temple_name')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_required_field'),
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.length > 100) {
                            return Promise.reject(new Error("Temple name cannot exceed 100 characters."));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{ height: '30px' }}
                      placeholder={t('label_enter_temple_name')}
                      maxLength={100}
                    />
                  </Form.Item>
                </div>

                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                    rules={[{ required: true, message: t('error_msg_avatar'), }]}
                    name='avatar'
                  >
                    <>
                      <img
                        src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                        alt="Selected Deity Image"
                        onClick={handleImageClick}
                        class="add-temple-img"
                      />
                      <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                        <div class="popup-main-filter animate__animated animate__fadeInUp ">
                          <div class="popup-main-header-filter">
                            <span class="popup-main-header-text-filter">{t('label_god_categ')}</span>
                            <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div className="popup-main-container-filter">
                            <div class="d-block float-left content-filter">
                              <div className=" god-category-list">
                                <div className="all-gods-sec">{t('label_all')}</div>
                                <div className="god_select">
                                  <div className="god_pic">
                                    <img
                                      className="dietyImg"
                                      src={aum}
                                      alt="All"
                                      onClick={() => handleDeityImageClick(null)}
                                    />
                                  </div>
                                </div>
                                <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                <Row gutter={[16, 16]}>
                                  {godCtgryList && godCtgryList.map((godCtgry) => (
                                    <Col key={godCtgry.avatar}>
                                      <div className="god_select">
                                        <div className="god_pic">
                                          <img
                                            src={deityImages[godCtgry.avatar]}
                                            alt="Deity Image"
                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                          />
                                        </div>
                                        <p>{godCtgry.deity}</p>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>
                          </div>
                        </div>
                      </div>
                    </>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="deityname"
                    label={<Typography.Title level={4}>{t('label_main_deity')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_required_diety_field'),
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.length > 50) {
                            return Promise.reject(new Error("Diety name cannot exceed 50 characters."));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{ height: '30px' }}
                      placeholder={t('label_enter_main_deity')}
                      maxLength={50}
                    />
                  </Form.Item>
                </div>
                <div className="tiles-designs">
                  <Form.Item
                    name="otherdeities"
                    label={<Typography.Title level={4}>{t('label_other_deities')}</Typography.Title>}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.length > 60) {
                            return Promise.reject(new Error("Deity name cannot exceed 60 characters."));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{ height: '30px' }}
                      placeholder={t('label_enter_other_deities')}
                      maxLength={60}
                    />
                  </Form.Item>
                </div>

                <div class="tiles-designs" >
                  <Form.Item
                    name="descr"
                    label={<Typography.Title level={4}>{t('label_descr')}</Typography.Title>}
                  >
                    <TextArea rows={4} placeholder={t('label_enter_details')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="timings"
                    label={<Typography.Title level={4}>{t("label_timings")}</Typography.Title>}
                  >
                    <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('label_enter_timings')} />
                  </Form.Item>
                </div>
              </div>

              <div class="d-none">
                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_addr_loc_dtl')}</h5></div>
                <div class="active-details">
                  <div class="tiles-designs" >
                    <Form.Item
                      name="addr1"
                      label={<Typography.Title level={4}>{t('label_village_area')}</Typography.Title>}
                      rules={[
                        {
                          required: true,
                          message: t('error_msg_addrs')
                        },
                      ]}
                    >
                      <Input style={{ height: '30px' }} placeholder={t('label_enter_vill_loc')} />
                    </Form.Item>
                  </div>
                  <div class="tiles-designs" >
                    <Form.Item
                      name="addr2"
                      label={<Typography.Title level={4}>{t('label_addr')}</Typography.Title>}
                    >
                      <Input style={{ height: '30px' }} placeholder={t('label_enter_address')} />
                    </Form.Item>
                  </div>
                  <div class="tiles-designs">
                    <Form.Item
                      label={<Typography.Title level={4}>{t('label_state')}</Typography.Title>}
                      name="st"
                      rules={[
                        { required: true, message: t('error_msg_st') },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t('label_select_state')}
                        onChange={handleStateChange}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {states.map((state) => (
                          <Select.Option key={state} value={state}>
                            {state}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div class="tiles-designs" >
                    <Form.Item
                      label={<Typography.Title level={4}>{t("label_city")}</Typography.Title>}
                      name="city"
                      rules={[
                        { required: true, message: t('error_msg_city') },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder={t('label_select_city')}
                        value={selectedState ? undefined : ''}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map((city) => (
                          <Select.Option key={city} value={city}>
                            {city}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="tiles-designs">
                    <Form.Item
                      name="pincode"
                      label={<Typography.Title level={4}>{t('label_pin')}</Typography.Title>}
                      rules={[
                        {
                          validator: validatePincode,
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        maxLength={6}
                        style={{ height: '30px' }}
                        placeholder={t('label_enter_pincode')}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div class="tiles-designs" >
                    <Form.Item
                      name="website"
                      label={<Typography.Title level={4}>{t('label_website')}</Typography.Title>}
                      rules={[
                        {
                          validator: (_, value) => {
                            const websiteRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com)$/;
                            if (value && !websiteRegex.test(value)) {
                              return Promise.reject(new Error("Please enter a valid website ending in .com"));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        style={{ height: '30px' }}
                        placeholder={t('label_enter_website')}
                        onInput={(e) => {
                          let value = e.target.value.replace(/[^a-zA-Z0-9:/._-]/g, '');

                          if (value.includes('.com')) {
                            value = value.substring(0, value.indexOf('.com') + 4);
                          }

                          e.target.value = value;
                        }}
                      />

                    </Form.Item>
                  </div>
                  <div class="tiles-designs" >
                    <Form.Item
                      name="lang"
                      label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                      rules={[
                        {
                          required: true,
                          message: t('error_msg_lng'),
                        },
                      ]}
                    >
                      <Select options={[{ value: '', label: t('label_select_language') }, ...AP_LANGUAGES]}
                        placeholder={t('label_select_language')}
                        style={{ width: "30%" }}
                      />
                    </Form.Item>
                  </div><br />
                  <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t('label_map')}
                  </h5></div>
                  {/* <div class="center-align"> */}
                  <div className="tiles-designs">
                    <button type="button" class="button_move mb-2" style={{ marginLeft: '5px' }} onClick={handleLocationButtonClick}>{t('label_show_map')}
                    </button>
                    {showMap && (
                      <>
                        <div class="popup-bg" style={{ display: showMap ? 'flex' : 'none' }}>
                          <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                              <span class="popup-main-header-text">{t('label_sel_loc_on_map')}</span>
                              <div class="popup-close" onClick={() => setShowMap(false)}><i class="fa-solid fa-xmark"></i></div>
                              <div class="popup-back" onClick={() => setShowMap(false)}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                              <div class="d-block htinner-popup-main-container">
                                <GoogleMap
                                  center={currentLocation || indiaCenter}
                                  zoom={currentLocation ? 14 : 5}
                                  mapContainerStyle={{ width: '100%', height: '64vh' }}
                                >
                                  {currentLocation && markerVisible && (
                                    <Marker
                                      position={currentLocation}
                                      draggable={true}
                                      onDragEnd={handleMarkerDragEnd}
                                    />
                                  )}
                                </GoogleMap>
                              </div>
                            </div>
                            <div class="position-relative">
                              <div class="position-absolute map-btns ml-4">
                                <button type="button" class="btnmap" onClick={handleGetCurrentLocation}><i class="fa-solid fa-location-crosshairs"></i> <span>{t('label_show_curr_loc')}</span></button><br />
                                <button type="button" class="btnmap" onClick={handleGetLatLonFromMap}><i class="fa-solid fa-magnifying-glass-location"></i> <span>{t("label_use_this_loc")}</span></button>
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                              <a className="button_move" onClick={() => setShowMap(false)}>
                                {t("back")}
                              </a>
                            </div>
                            {error && (
                              <div className="popup-error" style={{ color: "red", marginTop: "10px" }}>
                                {t("label_location_error")}
                              </div>
                            )}


                          </div>
                        </div>
                      </>
                    )}
                    <div class="tiles-designs" >
                      <Form.Item name="lat" label={<Typography.Title level={4}>{t('label_latit')}</Typography.Title>}
                        rules={[
                          {
                            required: true,
                            message: t('error_msg_map'),
                          },
                        ]}
                      >
                        {userole === 'AD' || userole === 'AS' ? (
                          <Input style={{ height: '30px' }} placeholder={t('label_select_lat')} />
                        ) : (
                          <Typography.Text className='mb-4'>{lat ? lat : '0.000000'}</Typography.Text>
                        )}

                      </Form.Item>
                    </div>
                    <div class="tiles-designs" >
                      <Form.Item name="lon" label={<Typography.Title level={4}>{t('label_longi')}</Typography.Title>}
                        rules={[
                          {
                            required: true,
                            message: t('error_msg_map'),
                          },
                        ]}
                      >
                        {userole === 'AD' || userole === 'AS' ? (
                          <Input style={{ height: '30px' }} placeholder={t('label_select_lon')} />
                        ) : (
                          <Typography.Text>{lon ? lon : '0.000000'}</Typography.Text>
                        )}

                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left w-100" ><h5 class="card-text-title">{t('label_add_photos')}</h5></div>
              <div className="active-details">
                <div className="tiles-designs">
                  <div text="Add Image" >
                    {previewImage ? (
                      <img src={previewImage} alt="Hindu Temple Image" />
                    ) : (
                      <>
                        {isMobile && (
                          <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                            {t('label_camera')}
                          </button>
                        )}
                      </>
                    )}
                    <button className="button_move" onClick={handleGalleryClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>
                      {t('label_upload')}
                    </button>
                  </div>
                  <div className="detail-container detail-container-image">
                    {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                    {newImagePath && (
                      <CloseCircleOutlined
                        className="close-icon"
                        onClick={onImgDelete}
                      />
                    )}
                  </div>
                  <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                    <div class="popup-main animate__animated animate__fadeInUp ">
                      <div class="popup-main-header">
                        <span class="popup-main-header-text">{t('label_temple_image')}</span>
                        <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                      </div>
                      <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                          {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                        </div>
                      </div>
                      <div class="popup-main-footer">
                        <button class="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default AddTemple;