import React, { useEffect, useState } from 'react';
import deftemples from '../../assets/images/templeicon.jpg'
import { Link } from 'react-router-dom';
import { getImageAPI } from '../../http-common';
import { message } from 'antd';
import spin from '../../assets/images/Rolling.gif'
import TempleMap from '../pages/Homepage/Temples/templeMap';
import { Navigate, useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { deleteTempleImage, fetchTempleDetails, postUploadImageRequest } from '../../redux/actions/acions';
import temple1 from '../../assets/images/templeimg.png'
import temple2 from '../../assets/images/templeimg2.png'
import { useTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';
import article1 from "../../assets/images/altcles1.jpg";
import article2 from "../../assets/images/altcles-copy.jpg";
import article3 from "../../assets/images/altcles-3y.jpg";
import CustomToastContainer from './CustomToastContainer';
import AdBlock from '../pages/Homepage/Advertisements/adBlock';
import TempleBooks from '../pages/Homepage/Temples/templeResource';
import { Helmet } from "react-helmet-async";
import Error from '../pages/Homepage/error';
import audioBg from '../../assets/images/audio_detail.png';
import bookBg from '../../assets/images/book_detail.png';
import liveBg from '../../assets/images/live_detail.png';
import videoBg from '../../assets/images/video_detail.png';

const TempleDetailsLibraryCard = ({ id, resType, newImage, index, title, city, state, description, addr1, addr2, audio, text, tList, src, temple, favList, toggleFavorite, handleClick, toggleFilled, storeTempleDetails, latit, longi, link, handleDelete, target }) => {
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();


    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };

    const success = (tname) => {
        messageApi.open({
            type: 'success',
            content: `Added ${tname} to favorites`,
        });
    };
    const warning = (tname) => {
        messageApi.open({
            type: 'warning',
            content: `Removed ${tname} from favourites`,
        });
    };
    

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [modalVisible, setModalVisible] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };


    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const backgroundImages = {
        audios: audioBg,
        books: bookBg,
        livetv: liveBg,
        videos: videoBg
    };

    const shareResLink = (title, txt, rtype, rid, lang) => {
        const web_url = 'https://www.templeswiki.com/share?id=' + rid + '&type=' + rtype + '&lang=' + lang;
		if (!window.flutter_inappwebview) {
            navigator
                .share({
                    title: title,
                    text: txt,
                    url: web_url,
                })
                .then(() => console.warn('Share successful.'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error('Web Share API not supported.');
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, web_url);
        }
    };

    // const isLocationButtonVisible = () => {
    //     const isLiveTVPage = window.location.pathname.includes(<TempleBooks/>);
    //     return !( isLiveTVPage );
    // };

    const isPreviewButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isPriestBySpclty = window.location.pathname.includes('/priestBySpclty');
        const isAudiosPage = window.location.pathname.includes('/audios');
        const isDetailsPage = window.location.pathname.includes('/templeDetails');
        const isTempleResPage = window.location.pathname.includes('/templeResource');
        const isResFavPage = window.location.pathname.includes('/resourceFavorite');
        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage || isPriestBySpclty || isAudiosPage || isDetailsPage || isTempleResPage || isResFavPage);
    };
    const userid = localStorage.getItem('urole')

    const isEditButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/articles');
        const isLiveDarshanPage = window.location.pathname.includes('/templeDetails');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isAudiosPage = window.location.pathname.includes('/audios');
        // Return true if the edit button should be visible, false otherwise
        return userid === 'AS' || userid === 'AD' ? (isBookPage || isLiveTVPage || isLiveDarshanPage || isMantrasPage || isAudiosPage) : false;
    };

    // useEffect(() => {
    //     const handlePopState = () => {
    //         if (modalPreviewVisible) {
    //             closePreviewModal();
    //         }
    //     };

    //     const handleKeyDown = (event) => {
    //         if (modalPreviewVisible && event.key === 'Escape') {
    //             closePreviewModal();
    //         }
    //     };

    //     if (modalPreviewVisible) {
    //         // Push new state when popup opens (so back button closes it first)
    //         window.history.pushState({ popupOpen: true }, '');
    //         window.addEventListener('popstate', handlePopState);
    //         window.addEventListener('keydown', handleKeyDown);
    //     } else {
    //         // When popup closes, remove history entry without navigating back
    //         if (window.history.state && window.history.state.popupOpen) {
    //             window.history.back();
    //         }
    //     }

    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //         window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [modalPreviewVisible]);



    const isAudiosPage = () => {
        const isAudioPage = window.location.pathname.includes('/audios');
        return isAudioPage ? isAudioPage : false;
    };

    const isTemple = () => {
        const isTemplesPage = window.location.pathname.includes('/templesList')
            || window.location.pathname.includes('/searchResults')
            || window.location.pathname.includes('/templeGroup')
            || window.location.pathname.includes('/templeDetails')
            || window.location.pathname.includes('/recentlyViewed')
            || window.location.pathname.includes('/contribution')
            || window.location.pathname.includes('/nearby')
            || window.location.pathname.includes('/searchFavourite')
            || window.location.pathname.includes('/pendingTemples')
            || window.location.pathname.includes('/OutsideIndia')
            || window.location.pathname.includes('/state');
        return isTemplesPage ? isTemplesPage : false;
    };

    const isDeleteButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isContributionPage = window.location.pathname.includes('/contribution');


        // Return true if the edit button should be visible, false otherwise
        return userid === 'AD' ? (isContributionPage) : false;
    };

    const articleImages = [
        { src: article1, alt: 'article1' },
        { src: article2, alt: 'article2' },
        { src: article3, alt: 'article3' },
    ];



    const isPriestPage = window.location.pathname.includes('/books') ||
        window.location.pathname.includes('/articles') ||
        window.location.pathname.includes('/mantras') ||
        window.location.pathname.includes('/audios') ||
        window.location.pathname.includes('/priestBySpclty')
    // window.location.pathname.includes('/templeDetails');


    const dispatch = useDispatch();
    const [newImagePath, setNewImagePath] = useState('');
    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                dispatch(postUploadImageRequest(id, formData, success, failure));
                // Simulating an asynchronous upload process
                setTimeout(() => {
                    // Get the URL or path of the uploaded image and update the state
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                }, 1000); // Simulating a delay for upload

                event.target.value = null; // Clear the input to allow selecting the same file again
            }
        });

        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const onFinish = (imgid) => {
        const data = {
            'active': false
        };
        dispatch(deleteTempleImage(temple.tid, imgid, data));
    };

    // const handleClick = () => {
    //     // Check if the link corresponds to the books or articles page
    //     if (target === '_blank') {
    //         // If it does, do nothing else
    //         return;
    //     }
    //     // For other pages, execute storeTempleDetails
    //     storeTempleDetails(temple);
    // };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleId, setCurrentTempleId] = useState(null);

    const handleFilterGodCancel = () => {
        setModalPreviewVisible(false);
        setModalVisible(false);
    };

    const openPreviewModal = (id) => {
        setCurrentTempleId(id);
        setModalPreviewVisible(true);
        window.history.pushState({ popupOpen: true }, '');
    };

    const closePreviewModal = () => {
        setModalPreviewVisible(false);
        window.history.back();
    };

    useEffect(() => {
        const handlePopState = () => {
            console.log("Back Pressed. Closing Popup.");
            if (modalPreviewVisible) {
                setModalPreviewVisible(false);
                window.history.pushState(null, null, window.location.pathname); // Prevents extra back navigation
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [modalPreviewVisible]);


    useEffect(() => {
        // Check if the current temple is in the favorite list
        if (favList && currentTemple) {
            setIsFavourite(favList.includes(currentTemple.tid));
        }
    }, [favList, currentTemple]);

    const cachedFavourites = JSON.parse(localStorage.getItem('favourites')) || [];
    const cachedLibraryFavourites = JSON.parse(localStorage.getItem('articles')) || [];


    const handleNext = () => {
        const currentIndex = tList.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex < tList.length - 1) {
            const nextTempleId = tList[currentIndex + 1].tid;
            setCurrentTempleId(nextTempleId);
            // Update favorite status dynamically
            const isFavourite = cachedFavourites.includes(nextTempleId);
            console.log(`Next Temple ID: ${nextTempleId}, Is Favourite: ${isFavourite}`);
        }
    };

    const handlePrevious = () => {
        const currentIndex = tList.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex > 0) {
            const previousTempleId = tList[currentIndex - 1].tid;
            setCurrentTempleId(previousTempleId);
            // Update favorite status dynamically
            const isFavourite = cachedFavourites.includes(previousTempleId);
            console.log(`Previous Temple ID: ${previousTempleId}, Is Favourite: ${isFavourite}`);
        }
    };

    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        if (isTemple) {
            const cachedFavourites = JSON.parse(localStorage.getItem('favourites')) || [];
            setIsFavourite(cachedFavourites.includes(currentTempleId));
        }
        else {
            const cachedFavourites = JSON.parse(localStorage.getItem('articles')) || [];
            setIsFavourite(cachedFavourites);
        }

    }, [currentTempleId]);

    const currentTemple = tList && tList.find(temple => temple.tid === currentTempleId);
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const { tid } = useParams();
    const [currentTempleIndex, setCurrentTempleIndex] = useState(0);
    const storedTemplesList = JSON.parse(localStorage.getItem('templesList')) || [];
    const storedTid = localStorage.getItem('currentTempleTid') || tid;
    const storedLanguage = localStorage.getItem('lng');
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleShowDetails = () => {
        const currentTid = localStorage.getItem('currentTempleTid') || storedTid;
        const currentTemple = storedTemplesList[currentTempleIndex];

        if (currentTemple) {
            window.history.replaceState(null, '', `/templeDetails/${currentTid}`);
            dispatch(fetchTempleDetails(storedLanguage, currentTid));
            setModalPreviewVisible(false);
            window.location.reload();
        }
    };

    const openVideoModal = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setIsModalOpen(true);
    };

    // Function to close the modal and clear the selected video URL
    const closeVideoModal = () => {
        setSelectedVideoUrl(null);
        setIsModalOpen(false);
    };


    // useEffect(() => {
    //     const handlePopState = (event) => {
    //         if (modalPreviewVisible) {
    //             closePreviewModal();
    //             event.preventDefault();
    //         }
    //     };
    //     const handleKeyDown = (event) => {
    //         if (modalPreviewVisible && event.key === 'Escape') {
    //             closePreviewModal();
    //             event.preventDefault();
    //         }
    //     };
    //     if (modalPreviewVisible) {
    //         // Add history entry and listeners
    //         window.history.pushState({ popupOpen: true }, '');
    //         window.addEventListener('popstate', handlePopState);
    //         window.addEventListener('keydown', handleKeyDown);
    //     }
    //     return () => {
    //         // Cleanup listeners when popup closes or component unmounts
    //         window.removeEventListener('popstate', handlePopState);
    //         window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [modalPreviewVisible, closePreviewModal]);

    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate()
    function getYouTubeVideoId(url) {
        // This function extracts the YouTube video ID from a YouTube URL
        const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    return (
        <>
            {isModalOpen && selectedVideoUrl && (
                <>
                    <div class="popup-bg" style={{ display: isModalOpen ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                                <span class="popup-main-header-text">{temple.title}</span>
                                <div class="popup-close" onClick={closeVideoModal}><i class="fa-solid fa-xmark"></i></div>
                                <div class="popup-back" onClick={closeVideoModal}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                                <div class="d-block htinner-popup-main-container">
                                    {isLoading ? (
                                        <Error />
                                    ) : (
                                        <iframe
                                            title="YouTube Video"
                                            width="100%"
                                            height="400"
                                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(selectedVideoUrl)}`}
                                            frameBorder="0"
                                            allowFullScreen
                                            onLoad={() => setIsLoading(false)}
                                        ></iframe>
                                    )}
                                </div>
                                 {/* <AdBlock /> */}
                            </div>
                            <div class="popup-main-footer">
                                <a className="button_move" onClick={closeVideoModal}>{t('label_cancel')}</a>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div
                className="tiles-design bg-library animate__animated animate__flipInX"
                key={id}
                style={{
                    backgroundImage: `url(${backgroundImages[resType] || ''})`,
                }}
            >
                <div >
                    {/* <Link to={link} target={target} onClick={handleClick}> */}
                    <Link
                        to={link}
                        target={target}
                        onClick={(e) => {
                            if (text === 'articles' && temple.src_url === 'www.templeswiki.com') {
                                e.preventDefault();
                                handleClick();
                            }
                            else if (text === 'mantras' && temple.src_url === 'www.templeswiki.com') {
                                e.preventDefault();
                                handleClick();
                            }
                            else if (resType === 'livetv') {
                                e.preventDefault();
                                openVideoModal(temple.src_url)
                            }
                            else if (resType === 'videos') {
                                e.preventDefault();
                                openVideoModal(temple.src_url)
                            }
                            else if (resType === 'audios') {
                                e.preventDefault();
                                navigate(`/editResource/${temple.rid}`)
                            }
                        }}
                    >

                        <div className="tile-text">
                            <div>

                                <h6 className="home-temple-text" title={title}>{title}</h6>
                                {/* {isTemple() ? (
                                    <> */}
                                {/* <p className="mb-1 f-grey tile-add">{addr1}, {addr2}</p> */}
                                <p className="mb-1 f-grey tile-add">
                                    {t(capitalizeFirstLetter(city))}
                                </p>



                            </div>

                        </div>
                    </Link>

                    <div className="tile-quicklink">

                        <a
                            className="share-button"
                            onClick={() => {
                                const titleText = storedLanguage === 5
                                    ? `${t("label_know_about")} ${title}`
                                    : `${title} ${t("label_know_about")}`;

                                shareResLink(
                                    titleText,
                                    `${t("label_share1")} ${title} ${t("label_share2")}`,
                                    'temple', id, storedLanguage
                                );
                            }}
                        >
                            <i title={t('label_share')} className="fa-solid fa-share-nodes"></i>
                        </a>


                        {favList && (
                            <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === id) ? (
                                    <i className="fa-solid fa-heart" title={t('Favourites')}></i>
                                ) : (
                                    <i className="fa-regular fa-heart"></i>
                                )}
                            </a>
                        )}
                    </div>

                </div>
            </div >

        </>


    )
}

export default TempleDetailsLibraryCard;