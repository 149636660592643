import { Button, Col, Form, Input, Row, Typography, message } from 'antd';
import React from 'react';
import "../../style.css"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postPasswordRequest } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';



const ForgetPassword = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Check your mail to change password',
    });
  };

  const failure = (msg) => {
    messageApi.open({
      type: 'failure',
      content: msg,
    });
  };

  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(postPasswordRequest(values , success , failure));
  };

  return (
    <div className='temples'>
      {contextHolder}
      
      <div className='login-form'>
        <div className='form-card'>
          <Form
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className='form-box'
          >
            <Typography.Title style={{ color: "rgb(255, 136, 0)" }} level={5}>{t('label_forget_pwd')}</Typography.Title>

            <Form.Item
              label={t('Email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Email !',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Row>
              <Col>
                <Link to={"/forget"}><Typography.Text >{t('label_remember_password')} </Typography.Text></Link>
              </Col>
              <Col>
                <Link to={"/"}><Typography.Text style={{ color: "#f78b75" }} > Login</Typography.Text></Link>
              </Col>
            </Row>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Reset Password
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
