import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import logo from '../../../../assets/images/img.png';
import showToast from '../../../../utils/showToast';

const Footer = () => {
    const { t } = useTranslation();
    const [token, setToken] = useState('');
    const [name, setName] = useState('');

    const logintoken = localStorage.getItem('token');

    useEffect(() => {
        const getToken = localStorage.getItem('token');
        const getName = localStorage.getItem('name');
        if (getToken && getName) {
            setToken(getToken);
            setName(getName);
        }
    }, []);

    const handleLinkClick = () => {
        showToast('error', 'Please Login to use additional features')
    };


    return (
        <div className='site-footer' style={{ marginTop: '7px' }}>
            <div className="footer-container">
                <div className="footer-section text-center">
                    <img src={logo} className="footer-logo img" alt="logo" style={{ width: "130px", height: "auto" }} />
                    <div className="contactdiv mt-2">
                        <h4 className="foothd" style={{ fontSize: "16px", color: "#fab223" }}>
                            {t('label_contact')} : <br />
                            <a href="mailto:contact@templeswiki.com" style={{ color: "white", textDecoration: "none", marginLeft: "5px" }}>
                                contact@templeswiki.com
                            </a>
                        </h4>
                        <b style={{ color: '#fab223', fontWeight: 'bold', fontSize: "14px" }}>{t("label_follow_us")}</b><br />
                        <ul className="social-icons">
                            <li><a className="facebook"><i className="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                            <li><a className="twitter" href="https://x.com/TemplesWIKI"><i className="fa fa-twitter"></i></a></li>
                            <li><a className="instagram" href="https://www.instagram.com/templeswiki"><i className="fa fa-instagram"></i></a></li>
                            <li><a className="youtube" href="https://www.youtube.com/@TemplesWIKI"><i className="fa fa-youtube"></i></a></li>
                        </ul>
                        <div className="fbtn">
                            <Link className="footbtn" to="/addDonations">{t("Donate")}</Link>
                        </div>
                    </div>
                </div>

                <div className="footer-section">
                    <h4 className="foothd">{t("label_imp_links")}</h4>
                    <Link to="/">{t("Home")}</Link><br />
                    <Link to="/about-us">{t("label_aboutus")}</Link><br />
                    <Link to="/faq">{t("label_faqs")}</Link><br />
                    <Link to="/contactUs">{t("label_contact_us")}</Link><br />
                    <Link to="/privacy">{t("label_privacy_policy")}</Link><br />
                    <Link to="/terms">{t("label_terms_conditions")}</Link><br />
                    <Link to="/cookie-policy/">{t("label_ck_policy")}</Link><br />
                </div>

                <div className="footer-section">
                    <h4 className="foothd">{t("label_usefull")}</h4>
                    <Link to="/recentlyViewed">{t("label_recent")}</Link><br />
                    <Link to="/searchFavourite">{t("page_title_fav")}</Link><br />
                    <Link to="/resourceFavorite">{t("label_library_fav")}</Link><br />
                    <Link to="/events">{t("label_upcom_events")}</Link><br />
					 {!logintoken && (
                        <Link to="#" onClick={handleLinkClick}>{t("add_temple")}</Link>
                    )}
                    {!logintoken && (<br />)}
                </div>


                <div className="footer-section">
                    <h4 className="foothd">{t("label_quick_access")}</h4>
                    <Link to="/viewPanchangam">{t("label_daily_panchamgam")}</Link><br />
                    <Link to="/viewHoroscope">{t("label_daily_horoscope")}</Link><br />
                    <Link to="/library">{t("label_libraries")}</Link><br />
                    <Link to="/articles">{t("label_articles")}</Link><br />
                    <Link to="/mantras">{t("label_mantras")}</Link><br />
                    <Link to="/livetv">{t("label_livetv")}</Link><br />
                    <Link to="/books">{t("label_books")}</Link><br />
                    <Link to="/videos">{t("label_videos")}</Link><br />
                    <Link to="/audios">{t("label_audios")}</Link><br />
                </div>

                <div className="footer-section">
                    <h4 className="foothd">{t("label_temple_category")}</h4>
                    <Link to="/templeGroup/101">{t("label_div_desam")}</Link><br />
                    <Link to="/templeGroup/117">{t("label_iskon_temples")}</Link><br />
                    <Link to="/subCategery/57">{t('label_ancient_tpls')}</Link><br />
                    <Link to="/subCategery/61">{t("label_tpls5")}</Link><br />
                    <Link to="/templeGroup/111">{t("label_tpls7")}</Link><br />
                    <Link to="/templeGroup/103">{t("label_tpls4")}</Link><br />
                    <Link to="/templeGroup/102">{t("label_shakti_peethas")}</Link><br />
                    <Link to="/templeGroup/106">{t("label_muruga_temples")}</Link><br />
                    <Link to="/templeGroup/120">{t("label_remedy")}</Link><br />
                </div>

                <div className="footer-section">
                    <h4 className="foothd">{t("label_popular_temples")}</h4>
                    <Link to="/nearby">{t("label_temples_by_map")}</Link><br />
                    <Link to="/recentlyViewed">{t("label_recent")}</Link><br />
                    {!logintoken && (
                        <Link to="#" onClick={handleLinkClick}>{t("add_temple")}</Link>
                    )}
                    {!logintoken && (<br />)}
                    <Link to="/templeCtgry">{t('label_fam_temples')}</Link><br />
                    <Link to="/inIndia/507">{t('label_india_temples')}</Link><br />
                    <Link to="/outsideIndia">{t('label_intl_temples')}</Link><br />
                    <Link to="/state/telangana/city/All">{t("label_temples_in_state")}</Link><br />
                    <Link to="/state/andhra%20pradesh/city/nellore">{t("label_temples_in_nellore")}</Link><br />
                    <Link to="/state/karnataka/city/All">{t("label_temples_in_karnataka")}</Link><br />
                </div>
            </div>
            <div className='copyright'>
                <Typography.Text>{t("label_about_company")}</Typography.Text>
            </div>
        </div>
    );
};

export default Footer;