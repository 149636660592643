import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { postClearALLNotifi, fetchTempleADNotifications, deleteNotification } from '../../../../redux/actions/acions';
import { gettempleAdminNotificationsError, gettempleAdminNotificationstionsLoading, gettempleAdminNotificationsList } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Popconfirm, Result, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import dayjs from 'dayjs';
import moment from 'moment';

let last_rec = 0;
const ManageNotification = () => {
    const { t } = useTranslation();
    const records_per_page = 12;
    const [clearAll, setClearAll] = useState(false);
    const [deletedIds, setDeletedIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
const storedLanguage = localStorage.getItem('lng');
    const userrole = localStorage.getItem('urole')
    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector(state => ({
        nList: gettempleAdminNotificationsList(state),
        nLoading: gettempleAdminNotificationstionsLoading(state),
        nError: gettempleAdminNotificationsError(state),
    }));

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_clearnotifi_success'))
    };
    const warning = () => {
        showToast('info', t('label_notifideletd_success'))
    };
    const failure = (msg) => {
        showToast('fail', t('label_loginerror'))
    };

    const deletesuccess = () => {
        showToast('success', t('label_succes_delete'))
    };
    const deletefailure = (msg) => {
        showToast('fail', t('label_loginerror'))
    };


    useEffect(() => {
        if (userrole === "AD" || userrole === "AS") {
            dispatch(fetchTempleADNotifications("appadmin", storedLanguage, 0));
        } else if (userrole === "TA" || userrole === "TU") {
            dispatch(fetchTempleADNotifications("templeadmin", storedLanguage, 0));
        }
    }, [userrole, storedLanguage, dispatch]);


    function removeNotification(id) {
        dispatch(deleteNotification(id, deletesuccess, deletefailure));
        window.location.reload();
    }





    function ClearALLNotifi() {
        dispatch(postClearALLNotifi(success, failure))
        setClearAll(true);
    }



    // const nextSetRef = useRef(null);


    // useEffect(() => {
    //     // Add a scroll event listener to the window
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         // Remove the scroll event listener when the component unmounts
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // const handleScroll = () => {
    //     const windowHeight =
    //         "innerHeight" in window
    //             ? window.innerHeight
    //             : document.documentElement.offsetHeight;
    //     const body = document.body;
    //     const html = document.documentElement;
    //     const docHeight = Math.max(
    //         body.scrollHeight,
    //         body.offsetHeight,
    //         html.clientHeight,
    //         html.scrollHeight,
    //         html.offsetHeight
    //     );
    //     const windowBottom = windowHeight + window.pageYOffset;

    //     // Detect if the user has scrolled to the bottom of the page
    //     if (windowBottom >= docHeight && !isLoading) {
    //         // Load more data when scrolling to the end
    //         nextTemplesList();
    //     }
    // };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    function isNotificationDeleted(id) {
        return deletedIds.includes(id);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageTemples, setCurrentPageTemples] = useState([]);


    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const currentDate = dayjs();
    const dvdtDate = nList && dayjs(nList.dvdt);

    return (
        <div className="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_manage_notifications')}</span>
                </div>
                <div></div>
            </div>
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                        <span className="d-flex align-items-center">
                            <Link style={{ color: 'black' }} to="/">
                                <span className="bc-active-link" onClick={handleGoBack}>{t('Home')}</span>
                            </Link>
                            <span className="ml-3 mr-3">
                                <i className="fa-solid fa-angle-right f-9"></i>
                            </span>
                        </span>
                        <span className="bc-link">{t("label_manage_notifications")}</span>
                    </div>
            <div className="bg-details-highlight" style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)' }}>
                <div class=" details-headertxt-singleLine container">
                    <div className="header_txt">
                        {t("label_manage_notifications")}
                    </div>
                    <div class="details-headertxt-singleLine-btns">
                        <Link to='/addNotification' type='button' className="button_move" style={{ marginLeft: '20px' }}>
                            {t("label_add_notify")}
                        </Link>
                    </div>
                </div>

            </div>
            <div className="mainsec-block-addtemple-detailspage">
                {clearAll ? (
                    <></>
                ) : (
                    <div className="container">
                        {nLoading ? (
                            <Error />
                        ) : (
                            nList && nList.length > 0 ? (
                                nList
                                    .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                                    .map((notificationList) => {
                                        const isRead = notificationList.read;
                                        const notificationClass = isRead ? 'notification-read' : 'notification-unread'; // apply different class based on read status
                                        return (
                                            <div
                                                key={notificationList.nid}
                                                className="tiles-designs-events"
                                            >
                                                <div className="d-flex flex-space-between">
                                                    <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                                                        <div className="f1 events-block-link-title">
                                                            <i className="fa-solid fa-bell mr-3"></i>{' '}
                                                            <span className="f1">{notificationList.subject}</span>{' '}
                                                        </div>
                                                    </Link>
                                                    <div className="d-flex">
                                                        <Popconfirm
                                                            title="Are you sure you want to delete this notification?"
                                                            okText="Yes"
                                                            okType="danger"
                                                            cancelText="No"
                                                            onConfirm={() => {
                                                                const dvdtDate = moment(notificationList.dvdt); // Assuming dvdt is in a format compatible with moment.js
                                                                const currentDate = moment();

                                                                if (dvdtDate.isAfter(currentDate)) {
                                                                    removeNotification(notificationList.nid);
                                                                } else {
                                                                    showToast('error', 'Notification is already delivered, cannot be deleted!');
                                                                }
                                                            }}
                                                            onCancel={() => {
                                                                console.warn('Deletion cancelled');
                                                            }}
                                                        >
                                                            <DeleteOutlined style={{ marginBottom: '4px' }} />
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                                <span className="f1 events-block-link-value">{notificationList.txt}</span>
                                            </div>
                                        );
                                    })
                            ) : (
                                <Result
                                    status="404"
                                    title={t("label_nodata_found")}
                                    subTitle={t("label_no_notify_msg")}
                                />
                            )
                        )}
                    </div>
                )}
                <Footer />
            </div>

        </div>

    )
}

export default ManageNotification;
