import g50 from '../../assets/images/g50.png';
import g51 from '../../assets/images/g51.png';
import g52 from '../../assets/images/g52.png';
import g53 from '../../assets/images/g53.png';
import g54 from '../../assets/images/g54.png';
import g55 from '../../assets/images/g55.png';
import g57 from '../../assets/images/g57.png';
import g58 from '../../assets/images/g58.png';
import g59 from '../../assets/images/g59.png';
import g60 from '../../assets/images/g60.png';
import g61 from '../../assets/images/g61.png';
import g101 from '../../assets/images/g101.png';
import g102 from '../../assets/images/g102.png';
import g103 from '../../assets/images/g103.png';
import g106 from '../../assets/images/g106.png';
import g110 from '../../assets/images/g110.png';
import g111 from '../../assets/images/g111.png';
import g114 from '../../assets/images/g114.png';
import g115 from '../../assets/images/g115.png';
import g119 from '../../assets/images/g119.png';
import g120 from '../../assets/images/g120.png';
import g125 from '../../assets/images/120.jpg';
import g126 from '../../assets/images/g126.png';
import oc from '../../assets/images/126.jpg';
import baps from '../../assets/images/126.jpg';
import iskon from '../../assets/images/iskon.jpg';
import india from '../../assets/images/india.png';

const Images = {
    "g50.jpg": g50,
    "g51.jpg": g51,
    "g52.jpg": g52,
    "g53.jpg": g53,
    "g54.jpg": g54,
    "g55.jpg": g55,
    "g57.jpg": g57,
    "g58.jpg": g58,
    "g59.jpg": g59,
    "g60.jpg": g60,
    "g61.jpg": g61,
    "g101.jpg": g101,
    "g102.jpg": g102,
    "g103.jpg": g103,
    "g106.jpg": g106,
    "g110.jpg": g110,
    "g111.jpg": g111,
    "g114.jpg": g114,
    "g115.jpg": g115,
    "g119.jpg": g119,
    "g120.jpg": g120,
    "g125.jpg": g125,
    "g126.jpg": g126,
    "oc.jpg": oc,
    "baps.jpg": baps,
    "iskon.jpg": iskon,
    "null": india,
};

export default Images;
