import { Button, Col, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getByStDtError, getByStDtList, getByStDtLoading, getCityStDescrList, getCityStDescrListError, getCityStDescrListLoading, getFilterByStDtError, getFilterByStDtList, getFilterByStDtLoading, getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../error';
import TemplesPage from '../../../common/TemplesPage';
import { fetchByStDt, fetchCityStDesList, fetchFilterByStDt, fetchGodNames, retrieveGodNames } from '../../../../redux/actions/acions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import deftemples from '../../../../assets/images/templeicon.jpg';
import statesData from '../../states.json';
import statesDataTelugu from '../../states_1.json';

const StateFilter = () => {
  const [filterId, setFilterId] = useState(null);
  const [lastRec, setLastRec] = useState(0);  // Track the last record index
  const [godLastRec, setGodLastRec] = useState(0);
  const records_per_page = 12;
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [city, setCity] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedState, selectedCity } = useParams();
  const localEnv = false;
  const [messageApi, contextHolder] = message.useMessage();

  const statesDataSource = storedLanguage === 1 ? statesDataTelugu : statesData;


  const englishState = statesData.states.find(
    (state) => state.name.toLowerCase() === selectedState.toLowerCase()
  );

  const teluguState = statesDataTelugu.states.find(
    (state) => state.name.toLowerCase() === selectedState.toLowerCase()
  );


  let displayedCity = selectedCity === "all" ? selectedState : selectedCity;
  if (storedLanguage === 1 && englishState && teluguState) {
    const cityIndex = englishState.cities.findIndex(
      (city) => city.toLowerCase() === selectedCity.toLowerCase()
    );

    if (cityIndex !== -1 && cityIndex < teluguState.cities.length) {
      displayedCity = teluguState.cities[cityIndex]; // Get the Telugu city name
    }
  }

  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: t('label_loginerror'),
    });
  };

  const { godCtgryList, godCtgryListLoading, godCtgryListError, stList, stLoading, stError, fstList, fstLoading, fstError, gList, gLoading, gError } = useSelector(state => ({
    godCtgryList: getGodNames(state),
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
    stList: getFilterByStDtList(state),
    stLoading: getFilterByStDtLoading(state),
    stError: getFilterByStDtError(state),
    fstList: getByStDtList(state),
    fstLoading: getByStDtLoading(state),
    fstError: getByStDtError(state),
    gList: getCityStDescrList(state),
    gLoading: getCityStDescrListLoading(state),
    gError: getCityStDescrListError(state),
  }));

  useEffect(() => {
    dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, lastRec));
  }, [storedLanguage, selectedState, selectedCity, lastRec]);

  useEffect(() => {
    const lowercaseState = selectedState.toLowerCase();
    const lowercaseCity = selectedCity.toLowerCase();
    dispatch(fetchCityStDesList(storedLanguage, lowercaseState, lowercaseCity));
  }, [storedLanguage, selectedState, selectedCity]);

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage));
  }, [storedLanguage]);

  function filterTemplesList(gDid) {
    setDid(gDid);
    if (gDid === filterId) {
      setFilterId(null);
      dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, lastRec));
    } else {
      setFilterId(gDid);
      dispatch(fetchFilterByStDt(storedLanguage, selectedState, selectedCity, gDid, godLastRec));
    }
  }

  function getStyle(did) {
    if (did === filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      };
    } else {
      return {
        borderColor: '#d9d9d9',
        color: '#000'
      };
    }
  }

  const nextTemplesList = async () => {
    setIsLoading(true);
    if (filterId === null) {
      setLastRec(lastRec + records_per_page); // Increment lastRec
      dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, lastRec + records_per_page));
    } else {
      setGodLastRec(godLastRec + records_per_page); // Increment godLastRec
      dispatch(fetchFilterByStDt(storedLanguage, selectedState, selectedCity, did, godLastRec + records_per_page));
    }
    setIsLoading(false);
  };

  const prevTemplesList = async () => {
    setIsLoading(true);
    if (filterId === null) {
      setLastRec(lastRec - records_per_page); // Decrement lastRec
      dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, lastRec - records_per_page));
    } else {
      setGodLastRec(godLastRec - records_per_page); // Decrement godLastRec
      dispatch(fetchFilterByStDt(storedLanguage, selectedState, selectedCity, did, godLastRec - records_per_page));
    }
    setIsLoading(false);
  };

  return (
    <>
      {filterId === null ? (
        stLoading || stList === null ? (
          <Error />
        ) : (
          <TemplesPage
            title=
            {storedLanguage === 1
              ? `${displayedCity} ${t("label_in")}`
              : storedLanguage === 5
                ? `${t("label_in")} ${displayedCity}`
                : displayedCity}
            description={gList && gList.descr}
            tList={stList}
            tLoading={stLoading}
            setFilterId={setFilterId}
            godCtgryList={godCtgryList}
            filterTemplesList={filterTemplesList}
            godCtgryListLoading={godCtgryListLoading}
            pageTitle={t('label_filter_temples')}
            filterId={filterId}
            nextTemplesList={nextTemplesList}
            prevTemplesList={prevTemplesList}
            last_rec={lastRec}
            temple={gList && gList.descr}
            image={deftemples}
            selectedCity={selectedCity}
            selectedState={selectedState}
          />
        )
      ) : fstLoading ? (
        <Error />
      ) : (
        <TemplesPage
          title={t('label_city_templs')}
          description={gList && gList.descr}
          tList={fstList}
          tLoading={fstLoading}
          setFilterId={setFilterId}
          godCtgryList={godCtgryList}
          filterTemplesList={filterTemplesList}
          godCtgryListLoading={godCtgryListLoading}
          pageTitle={'label_filtered_temples'}
          filterId={filterId}
          nextTemplesList={nextTemplesList}
          prevTemplesList={prevTemplesList}
          last_rec={lastRec}
          temple={gList && gList.descr}
          selectedCity={selectedCity}
          selectedState={selectedState}
        />
      )}
    </>
  );
};

export default StateFilter;