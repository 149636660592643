import React, { useEffect, useState } from 'react';
import deftemples from '../../assets/images/templeicon.jpg';
import { Link } from 'react-router-dom';
import { getImageAPI } from '../../http-common';
import { message } from 'antd';
import spin from '../../assets/images/Rolling.gif';
import TempleMap from '../pages/Homepage/Temples/templeMap';
import { useDispatch } from 'react-redux';
import { deleteTempleImage, postUploadImageRequest } from '../../redux/actions/acions';
import temple1 from '../../assets/images/templeimg.png';
import temple2 from '../../assets/images/article.jpg';
import { useTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';
import article1 from "../../assets/images/altcles1.jpg";
import article2 from "../../assets/images/altcles-copy.jpg";
import article3 from "../../assets/images/altcles-3y.jpg";
import "./CardStyles.css";

let currentPlayingAudio = null;

const ResourceCard = ({
    id,
    title,
    text,
    tList,
    temple,
    publish_date,
    author,
    favList,
    toggleFavorite,
    handleClick,
    link,
    target,
    city,
}) => {
    const [showImage, setShowImage] = useState(true);
    const [showPdfPopup, setShowPdfPopup] = useState(false); // State to manage PDF popup visibility
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');
    const userRole = localStorage.getItem('urole');

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handlePdfPopup = () => {
        setShowPdfPopup(true); // Show the PDF popup
    };

    const closePdfPopup = () => {
        setShowPdfPopup(false); // Hide the PDF popup
    };

    const shareResLink = (title, txt, rtype, rid, lang) => {
        const web_url = 'https://www.templeswiki.com/share?id=' + rid + '&type=' + rtype + '&lang=' + lang;
		if (!window.flutter_inappwebview) {
            navigator
                .share({
                    title: title,
                    text: txt,
                    url: web_url,
                })
                .then(() => console.warn('Share successful.'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error('Web Share API not supported.');
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, web_url);
        }
    };

    const handleAudioPlay = (event) => {
        if (currentPlayingAudio && currentPlayingAudio !== event.target) {
            currentPlayingAudio.pause(); // Pause the previously playing audio
        }
        currentPlayingAudio = event.target; // Update the currently playing audio
    };

    return (
        <>
            {(text === 'articles' || text === 'mantras') && (
                <div className="col-lg-4 col-md-6 col-sm-12 animate__animated animate__flipInX">
                    <div className="campaignCard">
                        <div className="cardContainer">
                            <div className="descCardContainerMain">
                                <div className="descCardContainer">

                                    {/* Image with Overlay */}
                                    <div className="imageContainer">
                                        <img className="headerImage" src={temple2} alt="campaign-cover" loading="lazy" />

                                        {/* Overlay Content (Profile, Title, Date, Actions) */}
                                        <div className="overlayContent">
                                            <div className="author-info">
                                                <span className="profile-pic"></span>
                                                <div>
                                                    <Link to={`/publicprofile/${temple.authorid}/${temple.author}`} className="author-name">
                                                        {author}
                                                    </Link>
                                                    <div className="pub-on">{publish_date}</div>
                                                </div>
                                            </div>

                                            {/* Action Buttons */}
                                            <div className="action-buttons">
                                                {userRole === 'AS' || userRole === 'AD' && (
                                                    <a href={`/editResource/${temple.rid}`} className="edit-button">
                                                        <i className="fa-solid fa-edit"></i>
                                                    </a>
                                                )}
                                                <a className="share-button" onClick={() => shareResLink(`Know about ${title}`, `Share ${title}`, 'library', id, storedLanguage)}>
                                                    <i className="fa-solid fa-share-nodes"></i>
                                                </a>
                                                {favList && (
                                                    <a className="fav-button" onClick={() => toggleFavorite(temple, title)}>
                                                        {favList.some(t => t === id) ? <i className="fa-solid fa-heart"></i> : <i className="fa-regular fa-heart"></i>}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Campaign Details */}
                                    <div className="cardDetails">
                                        <div className="campaignName">
                                            <i className="fa fa-om"></i> {title} <i className="fa fa-om"></i>
                                        </div>
                                        {/* <div className="detailLabel"><i className="dsIco kalash x4"></i><span>Event: Maha Shivaratri (26th Feb 2025)</span></div>
                                    <div className="detailLabel"><i className="dsIco locationDot x4"></i><span>Location: Varanasi Jyotirlinga Kshetra</span></div>
                                    <div className="detailLabel"><i className="dsIco clock x4"></i><span>Booking closing in 4 hours</span></div> */}
                                        <div className="campaignDesc">
                                            <p>{city}</p>
                                        </div>
                                    </div>

                                </div>

                                {/* CTA Button */}
                                <div className="cardActions">
                                    <button
                                        className="bookNowButton"
                                        onClick={(e) => {
                                            if (
                                                (text === "articles" && temple.src_url === "www.templeswiki.com") ||
                                                (text === "mantras" && temple.src_url === "www.templeswiki.com")
                                            ) {
                                                e.preventDefault();
                                                handleClick();
                                            }
                                        }}
                                    >
                                        <div className="button_move">
                                            {t("label_show_more")} <i className="dsIco namstey x2"></i>
                                        </div>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            )}
            {(text !== 'articles' && text !== 'mantras') && (

                <div
                    className={`col-lg-${text === 'audios' ? 4 : 3} col-md-6 col-sm-12 animate__animated animate__flipInX`}
                >
                    <div className="weather-card one">
                        <div
                            className={`top ${text === 'articles'
                                ? 'article-image'
                                : text === 'books'
                                    ? 'books-image'
                                    : text === 'mantras'
                                        ? 'mantras-image'
                                        : 'audio-image'
                                }`}
                        >
                            <div className="wrapper">
                                <h3 className="location" onClick={handleClick}>
                                    <div>{title}</div>
                                </h3>
                                <div className="author-sec">
                                    <h1 className="heading2">
                                        <span className="face-icon"></span>
                                        <span>
                                            <Link
                                                to={`/publicprofile/${temple.authorid}/${temple.author}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <span>{author}</span>
                                            </Link>
                                            <div className="pub-on">{publish_date} &nbsp;</div>
                                        </span>
                                    </h1>
                                    <span className="d-flex">
                                        {(title === 'articles' || title === 'mantras') && (
                                            <div className="art-sub">Subscribe</div>
                                        )}
                                        <div className="tile-quicklink1">
                                            {(userRole === 'AS' || userRole === 'AD') && (
                                                <a
                                                    href={`/editResource/${temple.rid}`}
                                                    className="share-button1"
                                                >
                                                    <i className="fa-solid fa-edit"></i>
                                                </a>
                                            )}
                                            <a
                                                className="share-button1"
                                                onClick={() => {
                                                    const titleText =
                                                        storedLanguage === 5
                                                            ? `${t('label_know_about')} ${title}`
                                                            : `${title} ${t('label_know_about')}`;
                                                    shareResLink(
                                                        titleText,
                                                        `${t('label_share1')} ${title} ${t('label_share2')}`,
                                                        `/editResource/${id}`
                                                    );
                                                }}
                                            >
                                                <i
                                                    title={t('label_share')}
                                                    className="fa-solid fa-share-nodes"
                                                ></i>
                                            </a>
                                            {favList && (
                                                <a
                                                    className="share-button1"
                                                    onClick={() => toggleFavorite(temple, title)}
                                                >
                                                    {favList.some((t) => t === id) ? (
                                                        <i
                                                            className="fa-solid fa-heart"
                                                            title={t('Favourites')}
                                                        ></i>
                                                    ) : (
                                                        <i className="fa-regular fa-heart"></i>
                                                    )}
                                                </a>
                                            )}
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {text === 'books' && (
                            <div className="bottom">
                                <div className="wrapper">
                                    <ul className="forecast">
                                        <li className="active">
                                            <span className="date">{city}</span>
                                            <div className="showmore">
                                                <button className="button_move" onClick={handlePdfPopup}>
                                                    {t('label_show_more')}
                                                </button>
                                            </div>
                                        </li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {(text === 'mantras' || text === 'articles') && (
                            <div className="bottom">
                                <div className="wrapper">
                                    <ul className="forecast">
                                        <li className="active">
                                            <span className="date">{city}</span>
                                            <div className="showmore1">
                                                <button
                                                    className="button_move"
                                                    onClick={(e) => {
                                                        if (
                                                            text === 'articles' &&
                                                            temple.src_url === 'www.templeswiki.com'
                                                        ) {
                                                            e.preventDefault();
                                                            handleClick();
                                                        } else if (
                                                            text === 'mantras' &&
                                                            temple.src_url === 'www.templeswiki.com'
                                                        ) {
                                                            e.preventDefault();
                                                            handleClick();
                                                        } else if (text === 'books') {
                                                            handleClick();
                                                        }
                                                    }}
                                                >
                                                    {t('label_show_more')}
                                                </button>
                                            </div>
                                        </li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {text === 'audios' && (
                            <div className="bottom">
                                <div className="wrapper">
                                    <ul className="forecast">
                                        <li className="active">
                                            <div className="showmore">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginTop: '4px',
                                                    }}
                                                >
                                                    <audio
                                                        id={'song00' + id}
                                                        controls="controls"
                                                        onPlay={handleAudioPlay}
                                                        onLoadedMetadata={(event) =>
                                                            console.log('MP3=' + event.target.duration)
                                                        }
                                                    >
                                                        <source src={link} type="audio/mpeg" />
                                                    </audio>
                                                </div>
                                            </div>
                                            <span className="date">{city}</span>
                                        </li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/* PDF Popup */}
            {showPdfPopup && (
                <div className="bottom">
                    <div className="wrapper">
                        <ul className="forecast">
                            <li className="active">
                                <div class="popup-bg" style={{ display: showPdfPopup ? 'flex' : 'none' }} onClick={closePdfPopup}>
                                    <div class="popup-main animate__animated animate__fadeInUp ">
                                        <div class="popup-main-header">
                                            <span class="popup-main-header-text"> {title}</span>
                                            <div class="popup-close" onClick={closePdfPopup}><i class="fa-solid fa-xmark"></i></div>
                                            <div class="popup-back" onClick={closePdfPopup}><i class="fa-solid fa-chevron-left"></i></div>
                                        </div>
                                        <div class="popup-main-container">
                                            <div class="d-block htinner-popup-main-container">
                                                <iframe
                                                    src={link}
                                                    width="100%"
                                                    height="500px"
                                                    title="PDF Viewer"
                                                    style={{ border: 'none' }}
                                                ></iframe>
                                            </div>
                                        </div>

                                        <div class="popup-main-footer">

                                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closePdfPopup() }}>
                                                {t('label_cancel')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="popup-bg" onClick={closePdfPopup}>
                                    <div
                                        className="popup-content"
                                        onClick={(e) => e.stopPropagation()}
                                        style={{ position: 'relative', paddingBottom: '50px' }}
                                    >
                                        <iframe
                                            src={link}
                                            width="100%"
                                            height="500px"
                                            title="PDF Viewer"
                                            style={{ border: 'none' }}
                                        ></iframe>
                                        <button
                                            onClick={closePdfPopup}
                                            style={{
                                                position: 'absolute',
                                                bottom: '10px',
                                                right: '10px',
                                                backgroundColor: '#ff4d4d',
                                                color: 'white',
                                                border: 'none',
                                                padding: '8px 16px',
                                                cursor: 'pointer',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            {t('label_close')}
                                        </button>
                                    </div>
                                </div> */}
                            </li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResourceCard;