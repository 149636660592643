import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { geFilterNearestError, getFavouritesError, getFavouritesList, getFavouritesLoading, getFilterNearestList, getFilterNearestLoading, getGodNames, getGodNamesError, getGodNamesLoading, getNearToMeError, getNearToMeList, getNearToMeLoading, getNearestError, getNearestList, getNearestLoading } from '../../../../redux/selectors/selectors';
import { Col, Row, Button, message, Result, Skeleton, Progress, Input } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { fetchFavourites, fetchFilterNearest, fetchGodNames, fetchNearByMe, fetchNearest, postFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import Error from '../error';
import { GoogleMap, InfoWindow, Marker, LoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TemplesPage from '../../../common/TemplesPage';
import Footer from '../footer/footer';

function NearBy() {
  const { t } = useTranslation();
  const records_per_page = 12;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { txt } = useParams();
  const [lastRec, setLastRec] = useState(0);
  const [godLastRec, setGodLastRec] = useState(0);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const uid = localStorage.getItem('uid');
  const { nDetails, nLoading, nError } = useSelector((state) => ({
    nDetails: getNearestList(state),
    nLoading: getNearestLoading(state),
    nError: getNearestError(state),
  }));

  const { tDetails, tLoading, tError } = useSelector((state) => ({
    tDetails: getNearToMeList(state),
    tLoading: getNearToMeLoading(state),
    tError: getNearToMeError(state),
  }));



  const success = (tname) => {
    messageApi.open({
      type: 'success',
      content: `Added ${tname} to favorites`,
    });
  };
  const warning = (tname) => {
    messageApi.open({
      type: 'warning',
      content: `Removed ${tname} from favourites`,
    });
  };
  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: t('label_loginerror'),
    });
  };
  const { fDetails, fLoading, fError, godCtgryList, godCtgryListLoading, godCtgryListError, faList, faLoading, faError } = useSelector((state) => ({
    fDetails: getFilterNearestList(state),
    fLoading: getFilterNearestLoading(state),
    fError: geFilterNearestError(state),
    godCtgryList: getGodNames(state),
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
    faList: getFavouritesList(state),
    faLoading: getFavouritesLoading(state),
    faError: getFavouritesError(state),
  }));
  let favList = (faLoading === true || !Array.isArray(faList)) ? [] : faList.map(id => id.tid);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [lat, setLat] = useState(null); // Latitude state variable
  const [lon, setLon] = useState(null); // Longitude state variable
  const [showMap, setShowMap] = useState(true); // Initially show the map
  const [showResults, setShowResults] = useState(false);
  const [filterId, setFilterId] = useState(null);
  const [did, setDid] = useState();
  const [mapKey, setMapKey] = useState(1);
  const localEnv = false;
  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const handleGeoLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message.error("Location access denied. Please allow access.");
        break;
      case error.POSITION_UNAVAILABLE:
        message.error("Location information unavailable.");
        break;
      case error.TIMEOUT:
        message.error("Request to get location timed out.");
        break;
      default:
        message.error("An unknown error occurred.");
        break;
    }
  };


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = position.coords.latitude;
          const userLon = position.coords.longitude;

          console.log("Latitude:", userLat, "Longitude:", userLon); // Debugging

          setLat(userLat);
          setLon(userLon);
          setCurrentLocation({ lat: userLat, lng: userLon }); // Ensure map updates
          // Fetch nearby temples after setting lat & lon
          dispatch(fetchNearByMe(storedLanguage, userLat, userLon, txt, lastRec));
        },
        (error) => {
          console.error("Error getting current location:", error);
          handleGeoLocationError(error);
        }
      );
    } else {
      message.warning("Geolocation is not supported by this browser.");
    }
  }, []);  // Run only once when the component mounts

  useEffect(() => {
    if (lat !== null && lon !== null) {
      setCurrentLocation({ lat, lng: lon });
      setMapKey((prevKey) => prevKey + 1); // Force re-render
    }
  }, [lat, lon]);



  useEffect(() => {
    if (lat !== null && lon !== null) {
      dispatch(fetchNearest(storedLanguage, lat, lon, lastRec));
    }
  }, [storedLanguage, lat, lon, lastRec]);

  useEffect(() => {
    if (lat && lon && txt) {
      dispatch(fetchNearByMe(storedLanguage, lat, lon, txt, lastRec));
    }
  }, [storedLanguage, lat, lon, lastRec]);

  // useEffect(() => {
  //   if (lat && lon) {
  //     dispatch(fetchFilterNearest(storedLanguage, lat, lon, did, lastRec));
  //   }
  // }, [storedLanguage, lat, lon, did, lastRec]);
  // useEffect(() => {
  //   dispatch(fetchFavourites(storedLanguage, 0))
  // }, [getFavouriteTemples])
  const [filled, setFilled] = useState({}); // initialize as an empty object
  const [refresh, setRefresh] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  function toggleFilled(id) {
    setFilled(prevFilled => ({
      ...prevFilled,
      [id]: !prevFilled[id]
    }));
  }
  function addFavourites(id) {
    dispatch(postFavourites(id, failure))
  }
  function deleteFavourites(deleteId) {
    dispatch(removeFavourites(deleteId, failure))
  }
  const getToken = Boolean(localStorage.getItem('token'));
  function toggleFavorite(temple) {
    const index = favList.findIndex(t => t === temple.tid);
    if (index === -1) {
      addFavourites(temple.tid, temple.tname);
      setGetFavoriteTemples(!getFavouriteTemples);
      if (getToken) {
        success(temple.tname);
      }
    } else {
      deleteFavourites(temple.tid, temple.tname);
      setGetFavoriteTemples(!getFavouriteTemples);
      warning(temple.tname);
    }
  }
  // useEffect(() => {
  //   dispatch(fetchFavourites(0));
  // }, [refresh]);
  const handleGetCurrentLocation = () => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLat = position.coords.latitude;
          const newLon = position.coords.longitude;
          setCurrentLocation({ lat: newLat, lng: newLon });
          setLat(newLat);
          setLon(newLon);


          // Fetch nearby temples
          dispatch(fetchNearByMe(storedLanguage, newLat, newLon, txt, lastRec));
        },
        (error) => {

          console.error("Error getting current location:", error);
          message.error("Unable to fetch location.");
        }
      );
    } else {

      message.warning("Geolocation is not supported by this browser.");
    }
  };
  const [isRightClick, setIsRightClick] = useState(false); // Track right-click events
  // Handle click on the map to update the selectedLocation
  const handleMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLon = e.latLng.lng();
    setCurrentLocation({ lat: newLat, lng: newLon });
    setLat(newLat);
    setLon(newLon);
  };

  const handleMapClick = (e) => {
    setCurrentLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const handleSearchLocation = async () => {
    if (!searchText) return;

    const apiKey = "AIzaSyBLWiZul9MyYq8DwaGhosrNWP7QJJT9krU";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchText)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;
        setCurrentLocation({ lat, lng });
        setLat(lat);
        setLon(lng);
      } else {
        message.error("Location not found.");
      }
    } catch (error) {
      console.error("Geocoding error:", error);
      message.error("Failed to fetch location.");
    }
  };


  useEffect(() => {
    // Add event listeners to the map to detect right-click and left-click events
    const mapElement = document.querySelector('.google-map'); // Adjust the selector as needed
    if (mapElement) {
      mapElement.addEventListener('mousedown', (e) => {
        if (e.button === 2) {
          // Right-click event
          setIsRightClick(true);
        }
      });
      mapElement.addEventListener('mouseup', (e) => {
        if (e.button === 2) {
          // Reset the right-click flag on mouseup event
          setIsRightClick(false);
        }
      });
    }
  }, []);
  const handleFormSubmit = () => {
    if (lat && lon) {
      dispatch(fetchNearest(storedLanguage, lat, lon, 0));
    }
    setShowMap(false); // Hide the map
    setShowResults(true); // Show the results
  };

  const handleBackButtonClick = () => {
    setCurrentLocation(null); // Reset currentLocation
    setLat(null); // Reset latitude state
    setLon(null); // Reset longitude state
    setShowMap(true); // Show the map
    setShowResults(false); // Hide the results
    setMapKey((prevKey) => prevKey + 1); // Change the key to re-mount the GoogleMap component
    // Reset other state variables
    setFilterId(null);
    setDid(null);

  };

  useEffect(() => {
    if (lat && lon) {
      setMapKey((prev) => prev + 1);  // Force re-render of the map
    }
  }, [lat, lon]);


  function filterTemplesList(gDid) {
    setDid(gDid);
    if (gDid === filterId) {
      setFilterId(null)
      dispatch(fetchNearest(storedLanguage, lat, lon, lastRec));
    } else {
      setFilterId(gDid);
      dispatch(fetchFilterNearest(storedLanguage, lat, lon, gDid, godLastRec));
    }
  }
  function getStyle(did) {
    if (did === filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      };
    } else {
      return {
        borderColor: '#d9d9d9',
        color: '#000'
      };
    }
  }
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1);
  };
  // const [currentPage, setCurrentPage] = useState(1);
  // const [currentPageTemples, setCurrentPageTemples] = useState([]);
  // useEffect(() => {
  //   if (lat && lon) {
  //     dispatch(fetchNearest(storedLanguage, lat, lon, lastRec));
  //   }
  // }, [storedLanguage, lat, lon, currentPage]);
  // useEffect(() => {
  //   if (!nLoading && nDetails) {
  //     setCurrentPageTemples([...currentPageTemples, ...nDetails]);
  //   }
  // }, [nLoading]);

  const nextTemplesList = async () => {
    setIsLoading(true);
    if (filterId === null) {
      setLastRec(lastRec + records_per_page);
      dispatch(fetchNearest(storedLanguage, lat, lon, lastRec));
    } else {
      setGodLastRec(godLastRec + records_per_page);
      dispatch(fetchFilterNearest(storedLanguage, lat, lon, did, godLastRec));
    }
    setIsLoading(false);
  }
  const prevTemplesList = () => {
    if (filterId === null) {
      const newLastRec = lastRec - records_per_page;
      if (newLastRec < 0) {
        // Don't go below 0
        return;
      }
      setLastRec(newLastRec);
      dispatch(fetchNearest(storedLanguage, lat, lon, newLastRec));
    } else {
      const newGodLastRec = godLastRec - records_per_page;
      if (newGodLastRec < 0) {
        // Don't go below 0
        return;
      }
      setGodLastRec(newGodLastRec);
      dispatch(fetchFilterNearest(storedLanguage, lat, lon, did, newGodLastRec));
    }
  };

  const token = localStorage.getItem('token');
  const [open, setOpen] = useState(false);
  const handleLinkClick = () => {
    setOpen(false);
    message.info('Please Login to use additional features');
  };
  const renderMap = () => {
    const indiaCenter = { lat: 20.5937, lng: 78.9629 };
    return (
      <>
        <div className="search-width mb-3" >
          <Input
            placeholder="Search Location"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 400, marginRight: 10 }}
            allowClear
          />
          <button className="button_move mt-3" onClick={handleSearchLocation}>{t('value_search')}</button>
        </div>
        <div>
          <GoogleMap
            key={mapKey}
            center={currentLocation || { lat: 20.5937, lng: 78.9629 }}
            zoom={currentLocation ? 14 : 5}
            mapContainerStyle={{ width: "100%", height: "400px" }}
            onClick={handleMapClick}
          >
            {currentLocation && (
              <Marker position={currentLocation} draggable onDragEnd={handleMarkerDragEnd} />
            )}
          </GoogleMap>
        </div>
      </>
    );
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((filterId === null && nDetails) || (filterId !== null && fDetails)) {
      setLoading(false);
    }
  }, [nDetails, fDetails, filterId]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading">
          <Skeleton active />
        </div>
      );
    }
    const listToRender = txt ? tDetails : nDetails;
    if (filterId === null) {
      if (listToRender && listToRender.length > 0) {
        return (
          <TemplesPage
            title={t('label_nearest_tmpls')}
            description={t('label_nearby_descr')}
            tList={listToRender}
            tLoading={txt ? tLoading : nLoading}
            setFilterId={setFilterId}
            godCtgryList={godCtgryList}
            filterTemplesList={filterTemplesList}
            godCtgryListLoading={godCtgryListLoading}
            pageTitle={t('label_near_by_temples')}
            filterId={filterId}
            nextTemplesList={nextTemplesList}
            prevTemplesList={prevTemplesList}
            last_rec={lastRec}
            handleGoBackMap={handleBackButtonClick}
          />
        );
      }
      return (
        <>
          <div class=" mob-nav fluid-container page-title justify-content-between">
            <div class="d-flex align-items-center left-sec-mob-nav">
              <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
              <span class="bc-link">{t("label_tpl_det")}</span>
            </div>
            <div></div>
          </div>
          <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
            <span className="d-flex align-items-center">
              <Link style={{ color: 'black' }} to="/">
                <span className="bc-active-link">{t('Home')}</span>
              </Link>
              <span className="ml-3 mr-3">
                <i className="fa-solid fa-angle-right f-9"></i>
              </span>
              <span className="bc-active-link" onClick={handleGoBack}>{t("label_map_location")}</span>
              <span className="ml-3 mr-3">
                <i className="fa-solid fa-angle-right f-9"></i>
              </span>
            </span>
            <span className="bc- active-link" onClick={handleGoBack}>{t("label_map_location")}</span>
          </div>
          <div class="container cat-txt ">
            <div class="cat-detail">
              <span class="f3 d-flex justify-content-between">
                <div><b>{t('label_filtered_temples')}</b></div>
              </span>
              <p class="f1">{t('label_filter_temples')}
              </p>
            </div></div>
          <Result
            status="404"
            title={t("label_nodata_found")}
            subTitle={t("label_no_filtered_temples_msg")}
          />
        </>
      );
    } else {
      if (fDetails && fDetails.length > 0) {
        return (
          <TemplesPage
            title={t('label_filtered_temples')}
            description={t('label_filter_temples')}
            tList={fDetails}
            tLoading={fLoading}
            setFilterId={setFilterId}
            godCtgryList={godCtgryList}
            filterTemplesList={filterTemplesList}
            godCtgryListLoading={godCtgryListLoading}
            pageTitle={t('label_filtered_temples')}
            filterId={filterId}
            nextTemplesList={nextTemplesList}
            prevTemplesList={prevTemplesList}
            last_rec={lastRec}
            handleGoBackMap={handleBackButtonClick}
          />
        );
      }
      return (
        <>
          <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
            <Link to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link style={{ color: '#c15506' }} onClick={handleBackButtonClick}><span class="bc-link-active">{t('label_map_location')}</span></Link> - <span class="bc-link-active">{t('label_map_location')}</span>
          </div>
          <Result
            status="404"
            title={t("label_nodata_found")}
            subTitle={t("label_no_filtered_temples_msg")}
          />
        </>
      );
    }
  };


  return (
    <>
      {showMap && (
        <>
          <div class=" mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
              <div class="d-flex align-items-center left-sec-mob-nav">
                <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                <span class="bc-link">{t('label_temples_list')}</span>
              </div>
              <div></div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

              <Link to='/'><span class="bc-active-link">{t('Home')}</span></Link> <span class="ml-3 mr-3"> <i class="fa-solid fa-angle-right f-9"></i> </span><Link to='/templeCtgry'><span class="bc-active-link">{t('label_nearest_tmpls')}</span></Link> <span class="ml-3 mr-3"> <i class="fa-solid fa-angle-right f-9"></i> </span><span class="bc-link">{t('label_map_location')}</span>

            </div>
            <div class="mainsec-block">
              <div className={`container cat-txt`}>
                <div className="cat-detail">
                  <span className="f3 d-flex justify-content-between">
                    <div>
                      <b>Maps</b>
                    </div>
                  </span>
                </div></div>
              {/* {mapLoading && (
                // <div style={{ textAlign: 'center', padding: '20px' }}>
                //   <Progress percent={70} status="active" />
                //   <Skeleton active />
                // </div>
                <Error/>
              )} */}

              <div class="container">
                {renderMap()}
                <div class="position-relative">
                  <div class="position-absolute map-btns">
                    <button type="button" class="btnmap" onClick={handleGetCurrentLocation}><i class="fa-solid fa-location-crosshairs"></i> <span>{t('label_show_curr_loc')}</span></button><br />
                    <button type="button" class="btnmap" onClick={handleFormSubmit}><i class="fa-solid fa-magnifying-glass-location"></i> <span>{t('label_shop_by_map')}</span></button>
                  </div>
                </div>
                <div className='map-txt'>
                  {t("label_drag_pointer_to_change_location")}
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                  <a className="button_move" onClick={handleGoBack}>
                    {t("back")}
                  </a>

                </div>

              </div>
              <Footer />
            </div>
            <div class="mainsec-block container d-none">
              <div class="container d-flex mt-2 mb-2" style={{ overflowX: 'auto' }}>
                <div class="">
                  <button type="button" class="btn1 btn btn-secondary mb-3" onClick={handleGetCurrentLocation}>{t('label_show_curr_loc')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button><br />
                  {/* <button className='btn1 btn btn-secondary ' data-mdb-ripple-color="dark" onClick={handleGetCurrentLocation}>{t('Use Current Location')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button><br /> */}
                  {/* <Button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" type="primary" onClick={handleFormSubmit}>
                  {t('label_near_by_temples')}
                </Button> */}

                  <button type="button" class="btn1 btn btn-secondary mb-3" onClick={handleFormSubmit}>{t('label_shop_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button>
                </div>
              </div>
              <div class="container"><h5 class="card-text-title">{t("label_map")}
                {/* <span>| {t("label_enable_select_location")}</span> */}
              </h5>
              </div>

              {renderMap()}
              <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <a className="button_move" onClick={handleGoBack}>
                  {t("back")}
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      {nLoading || nDetails === null ? (
        <p></p>
      ) : showResults ? (
        <>
          {renderContent()}
        </>
      ) : null}
    </>
  );
}
export default NearBy;