import { Select, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import statesData from '../../components/pages/states.json';
import { fetchTempleUsersByStCity } from '../../redux/actions/acions';
import { getTempleUsersByStList, getTempleUsersByStListError, getTempleUsersByStListLoading } from '../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';

const TempleUsersBySt = ({ closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedState, setSelectedState] = useState("All");
    const [selectedCity, setSelectedCity] = useState("All");
    const [state, setState] = useState(null);
    const { Option } = Select;
    const [filteredStates, setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const handleNavigation = () => {
        // Use the navigate function to navigate to the other page with selected state and city as route parameters
        dispatch(fetchTempleUsersByStCity(selectedState, selectedCity, 0));
        //navigate(`/state/${selectedState}/city/${selectedCity}`);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleUsersByStList(state),
        rLoading: getTempleUsersByStListLoading(state),
        rError: getTempleUsersByStListError(state),
    }));

    useEffect(() => {
        dispatch(fetchTempleUsersByStCity(selectedState, selectedCity, 0));
    }, [dispatch]);

    const states = statesData.states.map((state) => state.name);

    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(value); // Update the selected state
        setSelectedCity("All"); // Reset the selected city to "All"
        setState(newState);
    };

    const handleStateSearch = (value) => {
        const filteredStates = states.filter((stateName) =>
            stateName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filteredStates);
    };

    // Function to handle city search
    const handleCitySearch = (value) => {
        const cities = state ? state.cities : [];
        const filteredCities = cities.filter((cityName) =>
            cityName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filteredCities);
    };

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        message.info('Please Login to use additional features');
        closeModal();
    };

    const modifiedData = rList && rList.map((item, index) => ({ ...item, "S.No": index + 1 }));
    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donation_id")}
                </span>
            ),
            dataIndex: "donid",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donor_name")}
                </span>
            ),
            dataIndex: "donor",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_donation_type")}
                </span>
            ),
            dataIndex: "dontype",
            key: "ctgry",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_date")}
                </span>
            ),
            dataIndex: "dondt",
            key: "sponsor",
            sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_amount")}
                </span>
            ),
            dataIndex: "amount",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
    ];


    return (

        <div className='container' style={{ marginTop: '2%' }} >
            {/* <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'><i onClick={handleGoBack} style={{ color: '#891491' }} className="fa-solid fa-chevron-left"></i>
                </Link>  <span class="bc-active-link" style={{ marginLeft: '5px' }}>Search</span>
            </div> */}
            <div class="search_header mt-3" style={{marginLeft:'20px'}}>
                <h6 className="home-temple-text">{t('label_search_temples_state_city')}</h6>

            </div>
            <div className='search_page'  >
                <div className="d-flex" style={{marginLeft:'21px'}}>
                    <Select
                        showSearch  // Enable search
                        name="state"
                        value={selectedState}
                        onChange={(value) => handleStateChange(value)}
                        onSearch={(value) => handleStateSearch(value)} // Add search function
                        className="select-element"
                        style={{ marginRight: '5px', width: '32%', marginLeft: '-4px' }}
                    >
                        <Option value="All">{t('label_all')}</Option>
                        {states.map((stateName) => (
                            <Select.Option key={stateName} value={stateName}>
                                {stateName}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        showSearch  // Enable search
                        name="city"
                        value={selectedCity}
                        onChange={(value) => setSelectedCity(value)}
                        onSearch={(value) => handleCitySearch(value)} // Add search function
                        className="select-element"
                        style={{ width: '32%' }}
                    >
                        <Option value="All">{t('label_all')}</Option>
                        {state && state.cities.map((city) => (
                            <Select.Option key={city} value={city}>
                                {city}
                            </Select.Option>
                        ))}
                    </Select>

                    {selectedState && selectedCity && (
                        <div className="button-sec" style={{ marginTop: '6px' }}>
                            <a className='button_move' style={{ backgroundColor: '#ffa900' }} type="submit" title="Search" onClick={handleNavigation}>
                            {t("label_go")}
                            </a>
                        </div>
                    )}
                </div>
                <div className='container' style={{ overflowX: 'auto' }}>
                    <Table dataSource={modifiedData} columns={columns} bordered
                        style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                    />
                </div>
            </div>
        </div>

    )
}

export default TempleUsersBySt;