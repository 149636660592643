import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { Helmet } from 'react-helmet';
import { getShare, getShareError, getShareLoading } from '../../../redux/selectors/selectors';
import { fetchTempleShare } from '../../../redux/actions/acions';
import { getMediumImageAPI } from '../../../http-common';

function Share() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [metaData, setMetaData] = useState({ title: "Temple Details", description: "", image: "", url: "" });

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const type = queryParams.get('type');
    const lang = queryParams.get('lang');

    const { sList, sLoading, sError } = useSelector((state) => ({
        sList: getShare(state),
        sLoading: getShareLoading(state),
        sError: getShareError(state),
    }));

    useEffect(() => {
        if (id && type) {
            dispatch(fetchTempleShare(type, id, lang, 'a1668999-c3ea-405f-8cab-49bb8a0ed178'));
        }
    }, [id, type, lang, dispatch]);

    useEffect(() => {
        if (sList) {
            setMetaData({
                title: type === "temple" ? sList.tname : sList.title,
                description: type === "temple" ? sList.descr || sList.history : sList.txt,
                image: sList.imgpath ? getMediumImageAPI() + sList.imgpath : "http://localhost:3000/static/media/temple-img.2b173a63.png",
                url: window.location.href
            });
        }
    }, [sList, type]);

    return (
        <>
            {/* <Helmet>
                <meta property="og:title" content={metaData.title} />
                <meta property="og:description" content={metaData.description} />
                <meta property="og:image" content={metaData.image} />
                <meta property="og:url" content={metaData.url} />
                <meta property="og:type" content="website" />
            </Helmet> */}

            <div className='mainsec'>
                <div className="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        {type === 'temple' && sList && (
                            <>
                                {sList.imgpath ? (
                                    <img src={getMediumImageAPI() + sList.imgpath} alt={sList.tname} />
                                ) : (
                                    <img src='http://localhost:3000/static/media/temple-img.2b173a63.png' />
                                )}
                                <h2>{sList.tname}</h2>
                                <p>{sList.descr}</p>
                                <p>{sList.history}</p>
                            </>
                        )}

                        {type === 'resource' && sList && (
                            <>
                                {sList.img_path && (
                                    <img src={getMediumImageAPI() + sList.img_path} alt={sList.title} />
                                )}
                                <h2>{sList.title}</h2>
                                <p>{sList.txt}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Share;
