import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Table } from 'antd';
import { getDonationsList, getDonationsListError, getDonationsListLoading} from '../../redux/selectors/selectors';
import { deleteDonation, fetchDonationsList } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const PriestList = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [ contextHolder] = message.useMessage();

    // const failure = (msg) => {
    //     showToast('fai', t(`label_priestlist_failure`)); // You can use 'error' directly
    // };

    const { rList } = useSelector(state => ({
        rList: getDonationsList(state),
        rLoading: getDonationsListLoading(state),
        rError: getDonationsListError(state),
    }));

    useEffect(() => {
        dispatch(fetchDonationsList(0));
    }, [dispatch]);

    // function deleteDon(did, ts) {
    //     dispatch(deleteDonation(did, ts));
    //   }

    const modifiedData = rList && rList.map((item, index) => ({ ...item, "S.No": index + 1 }));
    // const downloadAsPDF = () => {
    //     const doc = new jsPDF();

    //     // Define column headers
    //     const headers = columns.map(column => column.title.props.children);

    //     // Extract data from modifiedData
    //     const data = modifiedData.map(item => columns.map(column => item[column.dataIndex]));

    //     // Add headers and data to the PDF
    //     doc.autoTable({
    //         head: [headers],
    //         body: data,
    //     });

    //     // Save the PDF
    //     doc.save('donations.pdf');
    // };


    const columns = [
        
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Name
                </span>
            ),
            dataIndex: "name",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),          
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                   {t('label_contact_phone')}
                </span>
            ),
            dataIndex: "Contact Phone",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                   Email
                </span>
            ),
            dataIndex: "Email",
            key: "ctgry",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Photo
                </span>
            ),
            dataIndex: "Photo",
            key: "sponsor",
            sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Address
                </span>
            ),
            dataIndex: "address",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Country
                </span>
            ),
            dataIndex: "address",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Education
                </span>
            ),
            dataIndex: "address",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Temple
                </span>
            ),
            dataIndex: "address",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Temple Group
                </span>
            ),
            dataIndex: "address",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
    ];

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='container main'>
                                    <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_priest_by_spclty')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            <CustomToastContainer/>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">Home</span></Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                {/* <span className="bc-link" onClick={handleGoBack}> {title}</span> - */}
                &nbsp;<span className="bc-link">{t("Priests List")}</span>
            </div>
            <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end' }}>
                <Link to='/addDonations'><button type="button" class="btn1 btn btn-secondary " style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('Add Donation')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                {/*<Button className='profile-form-button' style={{ marginLeft: '5px',marginBottom:'5px',width:'9%' }} onClick={ClearALLNotifi}>
            Clear All
          </Button>*/}
            </div>
            <div className='container'>
                <Table dataSource={modifiedData} columns={columns} bordered
                    style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                />
            </div>
        </div>

    );
};

export default PriestList;
