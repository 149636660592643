import React from 'react';
import { Typography } from 'antd';
import './PrivacyPolicy.css';
import Footer from '../footer/footer';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;


const PrivacyPolicy = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div class='mainsec'>
      <div class="mob-nav fluid-container page-title justify-content-between">
        <div class="d-flex align-items-center left-sec-mob-nav">
          <Link onClick={handleGoBack}><i class="fa-solid fa-angle-left"></i></Link>
          <span class="bc-link">{t('label_privacy_policy')}</span>
        </div>
      </div>
      <div className="mb-3 mt-3 breadcrumb-link container d-flex justify-content-start align-items-center">
        <div class="white-space-pre d-flex">
          <span class="d-flex align-items-center">
            <Link style={{ color: 'black' }} to="/">
              <span class="bc-active-link">{t('Home')}</span>
            </Link>
            <span class="ml-3 mr-3">
              <i class="fa-solid fa-angle-right f-9"></i>
            </span>
          </span>
          <span class="bc-link">{t("label_privacy_policy")}</span>
        </div>
      </div>
      <div class="bg-details-highlight">
        <div class="details-headertxt-singleLine container">
          <div class="header_txt">{t('label_privacy_policy')}</div>
        </div>
      </div>
      <div class="mainsec-block-addtemple-detailspage">
      <div className="privacy-policy-container">
        <div className="container">
          <Typography>
            <Title level={2}>Privacy Policy for www.templeswiki.com</Title>
            <Title level={3}>Effective Date: 10-August-2024</Title>
            <Paragraph>
              At www.templeswiki.com, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
            </Paragraph>
            <Title level={3}>1. Information We Collect</Title>
            <Paragraph>
              We may collect information about you in a variety of ways, including:
              <ul>
                <li>Personal Information: When you register for an account, subscribe to our newsletter, or contact us, we may collect personal information such as your name, email address, phone number, and any other details you provide.</li>
                <li>Location Information: When you are looking for nearest Hindu Temples, we ask your current location or location to select on the Map. Also when new temple added, we request you to select temple location on Map.</li>
                <li>Gallery and Camera: When new temple added, we request you to upload temple image from Gallery or upload temple image instantly by taking from camera.</li>
                <li>Non-Personal Information: We may collect non-personal information automatically when you visit our site, including your IP address, browser type, referring URL, and pages visited.</li>
              </ul>
            </Paragraph>
            <Title level={3}>2. How We Use Your Information</Title>
            <Paragraph>
              We use the information we collect in the following ways:
              <ul>
                <li>To Provide Services: To operate and maintain our website and provide you with the information and services you request.</li>
                <li>To Improve Our Website: To analyze trends and user behavior, which helps us improve our content and user experience.</li>
                <li>To Communicate with You: To send you updates, notifications, newsletters, and respond to your inquiries.</li>
                <li>Unless user provided information, we donot access or use any of the resources avalable on Mobile or Device such as Photos, Location or Personal Information</li>
              </ul>
            </Paragraph>
            <Title level={3}>3. Cookies and Tracking Technologies</Title>
            <Paragraph>
              We may use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files stored on your device that help us remember your preferences and improve our services.
            </Paragraph>
            <Paragraph>
              You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer.
            </Paragraph>
            <Title level={3}>4. Sharing Your Information</Title>
            <Paragraph>
              We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except in the following situations:
              <ul>
                <li>Service Providers: We may share information with trusted third-party service providers who assist us in operating our website or conducting our business, so long as those parties agree to keep this information confidential.</li>
                <li>Legal Compliance: We may disclose your information when required to do so by law or in response to valid requests by public authorities.</li>
              </ul>
            </Paragraph>
            <Title level={3}>5. Data Security</Title>
            <Paragraph>
              We take reasonable precautions to protect your personal information from loss, theft, and unauthorized access. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
            </Paragraph>
            <Title level={3}>6. Third-Party Links</Title>
            <Paragraph>
              Our website may contain links to third-party websites. We do not control these websites and are not responsible for their privacy practices. We encourage you to review the privacy policies of any third-party sites you visit.
            </Paragraph>
            <Title level={3}>7. Children’s Privacy</Title>
            <Paragraph>
              Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
            </Paragraph>
            <Title level={3}>8. Your Rights</Title>
            <Paragraph>
              Depending on your jurisdiction, you may have the right to access, correct, or delete your personal information. If you wish to exercise any of these rights, please contact us using the information below.
            </Paragraph>
            <Title level={3}>9. Changes to This Privacy Policy</Title>
            <Paragraph>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the website after any modifications to the policy will constitute your acknowledgment of the changes and your consent to abide by and be bound by the modified policy.
            </Paragraph>
            <Title level={3}>10. Contact Us</Title>
            <Paragraph>
              If you have any questions about this Privacy Policy or our practices, please contact us at: contact@vavili.com
            </Paragraph>
          </Typography>
        </div>
      </div>
      <Footer />
      </div>
    </div>
  );

};

export default PrivacyPolicy;
