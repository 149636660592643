import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFilterByStDtError, getFilterByStDtList, getFilterByStDtLoading, getGodNames, getGodNamesError, getGodNamesLoading, getLatestTempleDetails, getLatestTempleDetailsError, getLatestTempleDetailsLoading, getTempleBooksError, getTempleBooksList, getTempleBooksLoading, getTempleDetails, getTempleDetailsError, getTempleDetailsLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading, getTemplePreviewImgError, getTemplePreviewImgList, getTemplePreviewImgLoading, getTemplesList, getTemplesListError, getTemplesListLoading, gettempleMinDetailsError, gettempleMinDetailsList, gettempleMinDetailsLoading } from "../../../../redux/selectors/selectors";
import { getImageAPI, getMediumImageAPI } from "../../../../http-common";
import { fetchTempleDetails, postUploadImageRequest, editAvatar, editTempleStatus, editTempleImageStatus, postImage, editTempleFields, postFavourites, removeFavourites, fetchTempleBooks, fetchTempleFavourites, deleteTempleImage, editTempleLocation, editTempleHist, editTempleDescr, editArchitecture, editAddInfo, updateTempleImage, fetchByStDt, fetchGodNames, retrieveGodNames, postPriestReq, retrieveTemples, postUserActivity, fetchTempleMinDetails, editTimings, editPujas, editTravel, editGovFlag, editNavagrahasFlag, fetchTempleReviewImg } from '../../../../redux/actions/acions';
import { Form, Typography, Row, Col, Button, message, FloatButton, Image, Popconfirm, Radio } from 'antd';
import { Link } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.jpg'
import TempleMap from './templeMap';
import TempleCard from '../../../common/card';
import { Helmet } from 'react-helmet';
import { CloseCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import deityImages from '../../../common/godImages';
import deityImages1 from '../../../common/godImages';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import Slider from 'react-slick';
import TempleBooks from './templeResource';
import Error from '../error';
import spin from '../../../../assets/images/Spinner.gif'
import TempleNotification from './templeNotifi';
import Footer from '../footer/footer';
import statesData from '../../states.json';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import AdBlock from '../Advertisements/adBlock';
import Popup from './popup';
import aum from '../../../../assets/icons/om.jpg'
import godNames from '../../../common/godNames';
import remedytemple1 from "../../../../assets/images/oldtemples.jpg";
import swayambhoTemp1 from "../../../../assets/images/swayambhotemples.jpg";
import ancienttemp1 from "../../../../assets/images/oldesttempl.jpg";
import diyadeesampic1 from "../../../../assets/images/devyadeesam.jpg";
import shakthipeetalu from '../../../../assets/images/shakth.jpg';
import jyothirligaalu from '../../../../assets/images/jyotir.jpg';
import Login1 from '../Header/login';

const { Title } = Typography;
var last_rec = 0;

function TemplesDetails() {
    const [did] = useState(0);
    const [setShowMaps] = useState(false)
    const [showLoginForm1, setShowLoginForm1] = useState(false);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');
    const videoRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [refresh] = useState(false);
    const token = localStorage.getItem('token');
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDeity, setSelectedDeity] = useState(null);
    // const defaultImage = aum; // Use any default image

    // const selectedDeity = deityImages1[templeDetails && templeDetails.avatar] || { src: defaultImage, name: "Aum" };

    const startCamera = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    };

    const captureImage = () => {
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageSrc = canvas.toDataURL('image/jpeg');
        setPreviewImage(imageSrc);
        video.srcObject.getTracks().forEach((track) => track.stop());
    };

    const deleteImage = () => {
        setPreviewImage(null);
        startCamera();
    };

    const [messageApi, contextHolder] = message.useMessage();

    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const [newImagePath, setNewImagePath] = useState('');

    const success = (tname) => {
        showToast('success', () => `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`, { duration: 5 });
    };

    const handleShareError = (message) => {
        showToast('success', () => message, { duration: 5 });
    };

    const warning = (tname) => {
        showToast('info', () => `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`, { duration: 5 });
    };

    const failure = () => {
        showToast('error', t(`label_fav_error`))

    };

    const saveSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'SUCCESSFULLY EDITED',
        });
        //form.resetFields()
    };
    const saveWarning = (msg) => {
        messageApi.open({
            type: 'warning',
            content: msg,
        });
    };


    const dispatch = useDispatch()

    const { tid } = useParams();

    const {
        tDetails,
        tLoading, templesList
    } = useSelector(state => ({
        tDetails: getTempleDetails(state),
        tLoading: getTempleDetailsLoading(state),
        tError: getTempleDetailsError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
        templesList: getTemplesList(state) || [],
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
    }));

    useEffect(() => {
        dispatch(fetchTempleDetails(storedLanguage, tid));
    }, [storedLanguage, tid]);
    const localEnv = false;

    useEffect(() => {
        dispatch(retrieveTemples(storedLanguage, last_rec));
    }, [storedLanguage, last_rec]);

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    const { latestDetails, latestLoading } = useSelector(state => ({
        latestDetails: getLatestTempleDetails(state),
        latestLoading: getLatestTempleDetailsLoading(state),
        latestError: getLatestTempleDetailsError(state)
    }))

    const { templePreviewImg } = useSelector(state => ({
        templePreviewImg: getTemplePreviewImgList(state),
        templePreviewImgLoading: getTemplePreviewImgLoading(state),
        templePreviewImgError: getTemplePreviewImgError(state)
    }))

    useEffect(() => {
        if (userid === 'AD' || userid === 'AS') {
            dispatch(fetchTempleReviewImg(tid))
        }
    }, [tid])

    const { nDetails, nLoading } = useSelector((state) => ({
        nDetails: getFilterByStDtList(state),
        nLoading: getFilterByStDtLoading(state),
        nError: getFilterByStDtError(state),
    }));

    const { templeMinDetails1, templeMinLoading } = useSelector(state => ({
        templeMinDetails1: gettempleMinDetailsList(state),
        templeMinLoading: gettempleMinDetailsLoading(state),
        templeMinError: gettempleMinDetailsError(state)
    }))


    const urlToObject = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', { type: blob.type });
        return file;
    };

    const shareResLink = (title, txt, rtype, rid, lang) => {
        const web_url = 'https://www.templeswiki.com/share?id=' + rid + '&type=' + rtype + '&lang=' + lang;
        if (!window.flutter_inappwebview) {
            navigator
                .share({
                    title: title,
                    text: txt,
                    url: web_url,
                })
                .then(() => console.warn('Share successful.'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error('Web Share API not supported.');
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, web_url);
        }
    };

    useEffect(() => {
        if (tDetails && tDetails.st && tDetails.city) {
            dispatch(fetchByStDt(storedLanguage, tDetails && tDetails.st, tDetails && tDetails.city, 0));
        }
    }, [storedLanguage, tDetails && tDetails.st, tDetails && tDetails.city, 0]);


    useEffect(() => {
        dispatch(fetchTempleBooks(storedLanguage, tid, did))
    }, [activeLib, storedLanguage, tid, did, dispatch])

    const { fList } = useSelector(state => ({
        fList: getTempleFavouritesList(state),
        fLoading: getTempleFavouritesLoading(state),
        fError: getTempleFavouritesError(state),
    }));


    useEffect(() => {
        if (getToken) {
            dispatch(fetchTempleFavourites());
        }
    }, [getToken, dispatch, refresh]);


    function addFavourites(id) {
        dispatch(postFavourites(id))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId))
    }

    function formatTabularText(txt) {
        if (txt) {
            return txt.replace(/<br\s*\/?>/gi, '\n') // Replace <br> tags with newline characters
                .split('\n') // Split by newline into individual timings
                .map(item => {
                    const [time, puja] = item.split(',');
                    return { time: time.trim(), puja: puja ? puja.trim() : '' };
                });
        } else {
            return [];
        }
    }

    function formatBoldText(txt) {
        if (txt) {
            return <div dangerouslySetInnerHTML={
                { __html: formatPlainText(txt) }
            } />
        } else {
            return ''
        }
    }

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = '';
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                let line = allLines[i];

                // Replace all occurrences of <QT> and </QT> with double quotes
                line = line.replace(/<QT>/g, '"').replace(/<\/QT>/g, '"');

                if (line.includes('<B>')) {
                    finalTxt += line + '</B>\n';
                } else {
                    finalTxt += line + '\n';
                }
            }
            return finalTxt;
        } else {
            return '';
        }
    }


    const [favList, setFavList] = useState([]);
    const [filled, setFilled] = useState({});
    const getToken = Boolean(localStorage.getItem("token"));
    let id = templeDetails && templeDetails.tid;

    useEffect(() => {
        if (getToken) {
            const cachedFavourites = JSON.parse(localStorage.getItem('favourites'));
            if (cachedFavourites) {
                setFavList(cachedFavourites);
            } else if (getToken) {
                dispatch(fetchTempleFavourites());
            }
        }
    }, [getToken]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
            localStorage.setItem('favourites', JSON.stringify(fList.fav_list));
        }
    }, [fList]);


    function addFavourites(id, tname) {
        dispatch(postFavourites(id));

        const updatedFavList = [...favList, id];
        setFavList(updatedFavList);
        localStorage.setItem('favourites', JSON.stringify(updatedFavList));

        success(tname);
    }

    function deleteFavourites(id, tname) {
        dispatch(removeFavourites(id));

        const updatedFavList = favList.filter(favId => favId !== id);
        setFavList(updatedFavList);
        localStorage.setItem('favourites', JSON.stringify(updatedFavList));

        warning(tname);
    }




    function toggleFavorite(temple) {
        if (getToken) {
            const templeId = temple.tid;
            const templeName = temple.tname;

            if (favList.includes(templeId)) {
                deleteFavourites(templeId, templeName);
            } else {
                addFavourites(templeId, templeName);
            }
        } else {
            failure("Please log in to favorite temples");
        }
    }



    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    const templeDetails = (tLoading || tDetails == null) ? {} : tDetails
    const templeMinDetails = (templeMinLoading || templeMinDetails1 == null) ? {} : templeMinDetails1


    const [showAllImages, setShowAllImages] = useState(false);

    const handleImageClick = () => {
        // if (enableModal) {
        setShowAllImages(true);
        if (templeDetails.avatar != 0) {
            navigate('/templesList', { state: { selectedAvatar: templeDetails.avatar } });
        }
        // }
    };

    const [currentImage, setCurrentImage] = useState(
        selectedDeity ? deityImages[selectedDeity] : (templeDetails && deityImages[templeDetails.avatar])
    );


    useEffect(() => {
        if (selectedDeity) {
            setCurrentImage(deityImages[selectedDeity]);
        } else if (templeDetails && templeDetails.avatar) {
            setCurrentImage(deityImages[templeDetails.avatar]);
        }
    }, [selectedDeity, templeDetails]);


    const userid = localStorage.getItem('urole')
    const [isTempleDeactivated, setIsTempleDeactivated] = useState(templeDetails.status === 0);



    const templeStatusSuccess = (tname) => {
        if (templeDetails.status === 1) {
            showToast('success', t(`Deactivated ${tname}`));
            setIsTempleDeactivated(true);  // Set deactivated status
        } else {
            showToast('success', t(`Activated ${tname}`));
            setIsTempleDeactivated(false); // Set activated status
        }

    };

    const templeStatusFailure = (msg) => {
        showToast('error', t(msg))

    };

    const handleSelectChange = () => {
        // Toggle between 0 and 1 for activating and deactivating
        const value = templeDetails.status === 0 ? 1 : 0;
        dispatch(editTempleStatus(storedLanguage, tid, { status: value }, templeStatusSuccess, templeStatusFailure));
        //window.location.reload();   
    };

    const onFinish = (imgid) => {
        const data = {
            'active': false
        };
        dispatch(deleteTempleImage(templeDetails.tid, imgid, data));
        showToast('success', t(`Image Deleted Successfully`));
    };

    const [selectedImgPath, setSelectedImgPath] = useState(templeDetails.imgpath);

    useEffect(() => {
        setSelectedImgPath(templeDetails.imgpath);
    }, [templeDetails.imgpath]);

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectedImgPath(templeDetails.imgpath);
        } else {
            setSelectedImgPath(''); // You can choose to reset or keep the last selected image path
        }
    };


    const updateSucess = () => {
        showToast('sucess', t(`label_image_update`))

    };

    const updateImageFinish = (imgid) => {
        const data = {
            'imgpath': imgid
        };
        dispatch(updateTempleImage(tid, data, updateSucess));
    };

    const [activeButton, setActiveButton] = useState('Details'); // Initialize with the default active button
    const [activeSection, setActiveSection] = useState('Details');
    const [activeLibrary, setActiveLibrary] = useState('Video');
    const [activeLib, setActiveLib] = useState('Video');
    const [formData, setFormData] = useState({
        "festivals": templeDetails.festivals || '',
        "splpujas": templeDetails.splpujas || '',
        "timings": templeDetails.timings || '',
        "howtoreach": templeDetails.howtoreach || '',
        "priestname": templeDetails.priestname || '',
        "priestphoneno": templeDetails.priestphoneno || '',
        "website": templeDetails.website || '',
        "email": templeDetails.email || '',
        "phoneno": templeDetails.phoneno || '',
        "inagurated": templeDetails.inagurated || '',
        'deityname': templeDetails.deityname || '',
        'otherdeities': templeDetails.otherdeities || '',
        'dailypujas': templeDetails.dailypujas || '',
        'accomodation': templeDetails.accomodation || '',
    });

    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails]);

    const [showMap, setShowMap] = useState(false);
    const [locationSelected, setLocationSelected] = useState(false);
    const [markerVisible, setMarkerVisible] = useState(false);
    const [descr, setDescr] = useState(null);
    // const [history, setHistory] = useState(null);
    const [addtl_info, setAddInfo] = useState(null);
    const [architecture, setArchitecture] = useState(null);
    const [timings, setTimigs] = useState(null);
    const [dailypujas, setDailypujas] = useState(null);
    const [splpujas, setSplpujas] = useState(null);
    const [howtoreach, setHowtoreach] = useState(null);
    const [gov, setGov] = useState(null);
    const [navagrahas, setNavagrahas] = useState(null);

    const locationSuccess = () => {
        showToast('success', t('label_edit_hist_sucess'))

    };

    const locationFailure = (msg) => {
        showToast('error', t(msg))

    };

    const previousLat = useRef(templeDetails.lat);
    const previousLon = useRef(templeDetails.lon);

    const histSuccess = () => {
        showToast('success', t('label_contribute_hist1'))

    };

    const histADSuccess = () => {
        showToast('success', t('label_edit_hist_sucess'))

    };

    const histFailure = (msg) => {
        showToast('error', t(msg))

    };

    const descrSuccess = () => {
        showToast('success', t('label_contribute_descr1'))

    };

    const descrADSuccess = () => {
        showToast('success', t('label_edit_descr_sucess'))

    };

    const descrFailure = (msg) => {
        showToast('error', t(msg))

    };

    const archSuccess = () => {
        showToast('success', t('label_edit_descr_sucess'))

    };

    const archFailure = (msg) => {
        showToast('error', t(msg))

    };

    const addInfoSuccess = () => {
        showToast('success', t('label_edit_descr_sucess'))

    };

    const addInfoFailure = (msg) => {
        showToast('error', t(msg))

    };

    const handleDescr = (e) => {
        setDescr(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateDescr = () => {
        if (userid === 'AD' || userid === 'AS') {
            dispatch(editTempleDescr(storedLanguage, templeDetails.tid, { descr: descr }, descrSuccess, descrFailure));
        }
        else {
            dispatch(editTempleDescr(storedLanguage, templeDetails.tid, { draft_descr: descr }, descrADSuccess, descrFailure));
        }
    };


    const handleHist = (e) => {
        setHistory(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateHist = () => {
        if (userid === 'AD' || userid === 'AS') {
            dispatch(editTempleHist(storedLanguage, templeDetails.tid, { history: history }, histSuccess, histFailure));
        }
        else {
            dispatch(editTempleHist(storedLanguage, templeDetails.tid, { draft_history: history }, histADSuccess, histFailure));
        }
    };

    const [history, setHistory] = useState(templeDetails.history || '');
    const [draftHistory, setDraftHistory] = useState(templeDetails.draft_history || '');

    const [description, setDescription] = useState(templeDetails.descr || '');
    const [draftDescription, setDraftDescription] = useState(templeDetails.draft_descr || '');

    const handleToggleHistory = () => {
        setHistory(prevHistory => {
            if (draftHistory) {
                // Append draftHistory if prevHistory has text, otherwise replace it
                return prevHistory ? `${prevHistory}\n${draftHistory}` : draftHistory;
            }
            return prevHistory; // No change if no draftHistory
        });
        setDraftHistory(''); // Clear draft history after appending/replacing
    };


    const handleToggleDescription = () => {
        setDescription(prevDescription => {
            if (draftDescription) {
                // Append or replace based on whether prevDescription has content
                return prevDescription ? `${prevDescription}\n${draftDescription}` : draftDescription;
            }
            return prevDescription; // No change if draftDescription is empty
        });
        setDraftDescription(''); // Clear draft description after applying it
    };


    const clearHistory = () => {
        setHistory('');
    };

    const clearDescription = () => {
        setDescription('');
    };

    const isHistoryUpdated = history !== '' || draftHistory !== '';
    const isDescriptionUpdated = description !== '' || draftDescription !== '';
    const [selectedCorrection, setSelectedCorrection] = useState(null);

    const correctionOptions = [
        { label: t('label_data_incorrect'), value: 'incorrect' },
        { label: t('label_duplicate_data'), value: 'duplicate' },
        { label: t('label_missing_info'), value: 'missing' },
        { label: t('label_other'), value: 'other' }
    ];

    const handleRadioChange = (e) => {
        setSelectedCorrection(selectedCorrection === e.target.value ? null : e.target.value);
    };


    const dataSuccess = () => {
        showToast('success', t('label_submitted_data_corr'));
        handleCorrectionCancel();  // Close popup after successful submission
    };


    const dataFailure = (msg) => {
        showToast('error', msg)
    };

    const handleCorrection = () => {
        form.validateFields(["query"])
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    "pid": tid,
                    "ctgry": "temple",
                    "reqtype": selectedCorrection || "",
                    "query": values.query || ""
                };

                dispatch(postPriestReq(data, dataSuccess, dataFailure));
            })
            .catch((errorInfo) => {
                if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
                    const missingFields = errorInfo.errorFields.map(field => field.name.join('.')).join(', ');
                    message.error(`Please fill the required fields: ${missingFields}`);
                }
                console.error("Validation failed:", errorInfo);
            });
    };


    const location = useLocation();
    const pageName = location.pathname;

    const openMap = () => {
        const data = {
            page: pageName,
            action: 'Click',
            message: `Visited ${pageName}`,
        };

        dispatch(postUserActivity(data));
    };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleIndex, setCurrentTempleIndex] = useState(0);
    const storedTemplesList = JSON.parse(localStorage.getItem('templesList')) || [];
    const storedTid = localStorage.getItem('currentTempleTid') || tid; // Fallback to `tid` if not in storage

    const handleFilterGodCancel = () => {
        setIsCorrectionModalVisible(false);
        setModalPreviewVisible(false);
        setIsDescrModalVisible(false);
        setIsHistoryModalVisible(false);
        setModalVisible(false)
    };

    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search);
        if (queryParam.get('showPopup') === 'true') {
            setModalPreviewVisible(true);
        }
    }, [window.location.search]);

    const openPreviewModal = () => {
        const currentTid = localStorage.getItem('currentTempleTid') || (storedTemplesList.length > 0 && currentTempleIndex < storedTemplesList.length ? storedTemplesList[currentTempleIndex].tid : null);

        if (currentTid) {
            const newIndex = storedTemplesList.findIndex(temple => temple.tid === parseInt(currentTid));
            if (newIndex !== -1) {
                setCurrentTempleIndex(newIndex); // Set the index based on currentTid
            }
            setModalPreviewVisible(true);
            dispatch(fetchTempleMinDetails(storedLanguage, currentTid));
        }
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (modalPreviewVisible) {
                closePreviewModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (modalPreviewVisible && event.key === 'Escape') {
                closePreviewModal();
                event.preventDefault();
            }
        };
        if (modalPreviewVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalPreviewVisible, closePreviewModal]);



    const closePreviewModal = () => {
        setModalPreviewVisible(false);
        const queryParam = new URLSearchParams(window.location.search);
        queryParam.delete('showPopup');
        window.history.replaceState(null, '', `${window.location.pathname}?${queryParam.toString()}`);
    };

    // Ensure templesList is set in local storage when it changes
    useEffect(() => {
        if (templesList.length > 0) {
            localStorage.setItem('templesList', JSON.stringify(templesList));
        }
    }, [templesList]);

    // Fetch temple details whenever tid changes
    useEffect(() => {
        if (tid) {
            dispatch(fetchTempleMinDetails(storedLanguage, tid));
            localStorage.setItem('currentTempleTid', tid, success);
        }
    }, [tid, storedLanguage]);

    // Update current temple index based on tid
    useEffect(() => {
        if (tid && storedTemplesList.length > 0) {
            const newIndex = storedTemplesList.findIndex((temple) => temple.tid === parseInt(tid));
            if (newIndex !== -1) {
                setCurrentTempleIndex(newIndex);
            }
        }
    }, [tid, storedTemplesList]);

    const currentTid = localStorage.getItem('currentTempleTid') || (storedTemplesList.length > 0 ? storedTemplesList[0].tid : null);

    const handleNext = () => {
        if (storedTemplesList.length > 0) {
            setLoading(true);
            const currentIndex = storedTemplesList.findIndex(temple => temple.tid === parseInt(currentTid));
            const newIndex = (currentIndex + 1) % storedTemplesList.length;
            const nextTemple = storedTemplesList[newIndex];

            setCurrentTempleIndex(newIndex);
            localStorage.setItem('currentTempleTid', nextTemple.tid);

            // Update the URL and fetch details for the next temple
            window.history.replaceState(null, '', `/templeDetails/${nextTemple.tid}`);
            dispatch(fetchTempleMinDetails(storedLanguage, nextTemple.tid));
            setLoading(true);
            setLoading(false);
            // Keep the popup open
            setModalPreviewVisible(true);
        }
    };

    // Handle Previous Temple
    const handlePrevious = () => {
        if (storedTemplesList.length > 0) {
            setLoading(true);
            const currentIndex = storedTemplesList.findIndex(temple => temple.tid === parseInt(currentTid));
            const newIndex = (currentIndex - 1 + storedTemplesList.length) % storedTemplesList.length; // Wrap around if needed
            const prevTemple = storedTemplesList[newIndex];

            setCurrentTempleIndex(newIndex);
            localStorage.setItem('currentTempleTid', prevTemple.tid);

            // Update the URL and fetch details for the previous temple
            window.history.replaceState(null, '', `/templeDetails/${prevTemple.tid}`);
            dispatch(fetchTempleMinDetails(storedLanguage, prevTemple.tid));
            setLoading(false);
            // Keep the popup open
            setModalPreviewVisible(true);
        }
    };

    const handleShowDetails = () => {
        const currentTid = localStorage.getItem('currentTempleTid') || storedTid;
        const currentTemple = storedTemplesList[currentTempleIndex];

        if (currentTemple) {
            window.history.replaceState(null, '', `/templeDetails/${currentTid}`);
            dispatch(fetchTempleDetails(storedLanguage, currentTid));
            setModalPreviewVisible(false);
        }
    };

    const currentPath = window.location.pathname;
    const mainSecBlockRef = useRef(null);
    const handleScrollToTop = () => {
        if (mainSecBlockRef.current) {
            // Scroll the referenced container to the top
            mainSecBlockRef.current.scrollTo({
                top: 0,
                behavior: 'smooth' // Adds smooth scrolling effect
            });
        }
    };


    const reset = () => {
        form.resetFields()
    };



    const [isArchitectureChanged, setIsArchitectureChanged] = useState(false);
    const [isAddInfoChanged, setIsAddInfoChanged] = useState(false);
    const [isTimingsChanged, setIsTimingsChanged] = useState(false);
    const [isPujasChanged, setIsPujasChanged] = useState(false);
    const [isTravelChanged, setIsTravelChanged] = useState(false);
    const [isGovChanged, setIsGovChanged] = useState(false);
    const [isNavagrahasChanged, setIsNavagrahasChanged] = useState(false);

    // Handle input changes
    const handleArchitectureChange = (e) => {
        setArchitecture(e.target.value);
        setIsArchitectureChanged(true); // Set change flag
    };

    const handleAddInfoChange = (e) => {
        setAddInfo(e.target.value);
        setIsAddInfoChanged(true); // Set change flag
    };

    const updateFields = () => {
        // Only update architecture if it has changed
        if (isArchitectureChanged && architecture !== templeDetails.architecture) {
            dispatch(editArchitecture(storedLanguage, templeDetails.tid, { architecture: architecture }, archSuccess, archFailure));
            setIsArchitectureChanged(false); // Reset change flag
        }

        // Only update additional information if it has changed
        if (isAddInfoChanged && addtl_info !== templeDetails.addtl_info) {
            dispatch(editAddInfo(storedLanguage, templeDetails.tid, { addtl_info: addtl_info }, addInfoSuccess, addInfoFailure));
            setIsAddInfoChanged(false); // Reset change flag
        }
    };

    const handleTimingsChange = (e) => {
        setTimigs(e.target.value);
        setIsTimingsChanged(true); // Set change flag
    };

    const handleSpltyPujasChange = (e) => {
        setSplpujas(e.target.value);
        setIsPujasChanged(true); // Set change flag
    };

    const handleDailyPujasChange = (e) => {
        setDailypujas(e.target.value);
        setIsPujasChanged(true); // Set change flag
    };

    const handleTravelChange = (e) => {
        setHowtoreach(e.target.value);
        setIsTravelChanged(true); // Set change flag
    };

    const handleGovFlagChange = (e) => {
        setGov(e.target.value);
        setIsGovChanged(true); // Set change flag
    };

    const handleNavagrahasFlagChange = (e) => {
        setNavagrahas(e.target.value);
        setIsNavagrahasChanged(true); // Set change flag
    };

    const updateOtherFields = () => {
        if (isTimingsChanged && timings !== templeDetails.timings) {
            dispatch(editTimings(storedLanguage, templeDetails.tid, { timings: timings }, archSuccess, archFailure));
            setIsTimingsChanged(false);
        }
        if (isPujasChanged && (dailypujas !== templeDetails.dailypujas || isPujasChanged && splpujas !== templeDetails.splpujas)) {
            dispatch(editPujas(storedLanguage, templeDetails.tid, { dailypujas: dailypujas, splpujas: splpujas }, addInfoSuccess, addInfoFailure));
            setIsPujasChanged(false);
        }
        if (isTravelChanged && howtoreach !== templeDetails.howtoreach) {
            dispatch(editTravel(storedLanguage, templeDetails.tid, { howtoreach: howtoreach }, archSuccess, archFailure));
            setIsTravelChanged(false);
        }
        if (isGovChanged && gov !== templeDetails.gov) {
            dispatch(editGovFlag(storedLanguage, templeDetails.tid, { gov: gov }, archSuccess, archFailure));
            setIsGovChanged(false);
        }
        if (isNavagrahasChanged && navagrahas !== templeDetails.navagrahas) {
            dispatch(editNavagrahasFlag(storedLanguage, templeDetails.tid, { navagrahas: navagrahas }, archSuccess, archFailure));
            setIsNavagrahasChanged(false);
        }
    };


    // const [activeButton, setActiveButton] = useState('Details');
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));

    useEffect(() => {
        setButtonText(editable ? t("label_cancel") : t("edit"));
    }, [storedLanguage, editable]);

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText(t("edit"));
        setEditable(false);
    };

    const handleEditClick = () => {
        navigate(`/editTemples/${tid}`)
    };

    // Function to handle the "Save" button click
    // const handleSaveClick = () => {
    //     form.validateFields().then((values) => {
    //         dispatch(editTempleFields(storedLanguage, tid, templeDetails.ludt, values, saveSuccess, saveWarning))
    //         // dispatch(fetchLatestTempleDetails(storedLanguage, tid))
    //         setEditable(false);
    //         setButtonText(t("edit"));
    //     });
    // };

    const formRef = useRef(null);

    const handleSaveClick = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    const handleFormSubmit = (values) => {
        // Handle the form submission logic
        const { lat, lon, addtl_info, architecture, ...restValues } = values;
        dispatch(editTempleFields(storedLanguage, tid, templeDetails.ludt, restValues, saveSuccess, saveWarning));
        setEditable(false);
        setButtonText(t("edit"));
    };

    const handleLibraryChange = (library) => {
        setActiveLibrary(library);
        setActiveSection('Library');
    };

    const navigate = useNavigate();
    // Extract the current pathname
    const [previousPageName, setPreviousPageName] = useState('');


    useEffect(() => {
        const previousLocation = new URLSearchParams(window.location.search).get('prevPage');
        setPreviousPageName(getPreviousPageName(previousLocation));
    }, []);


    const getPreviousPageName = (previousLocation) => {
        if (!previousLocation) {
            return 'Unknown Page';
        }

        const paths = previousLocation.split('/').filter((path) => path !== '');
        const lastPath = paths[paths.length - 1] || '/';

        switch (lastPath) {
            case 'nearby':
                return t('label_nearby_descr');
            case '/':
                return t('label_homepage');
            case 'searchFavourite':
                return t('page_title_fav');
            case 'recentlyViewed':
                return t('label_recently_visited');
            case 'contribution':
                return t('label_my_contributions');
            case 'templesList':
                return t("label_fam_temples");
            case 'templeGroup/101':
                return t('label_temple_group_name');
            case '108 Divya Desams':
                return t('label_div_desam');
            case '51 Shakthi Peethas':
                return t('label_shakti_peethas');
            case 'Jyotir Lingas':
                return t('label_jyo');
            case 'templeDashboard':
                return t('label_my_temple_dashboard');
            default:
                return t('unknownPage');
        }
    };

    const filterId = location.state ? location.state.filterId : null;

    function navigateBackToTemplesList() {
        navigate(-1);
    }



    // Use navigate to go back to the previous page
    // const handleGoBack = () => {
    //     navigate(-1);
    // };

    let link = <a href="https://ebooks.tirumala.org/downloads/maha_bharatham_vol_1_adi_parvam_p-1.pdf" target="_blank" rel="noopener noreferrer">More Info</a>

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const sliderRef = React.createRef();

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    };

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    };

    const handleCameraClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Access the device camera

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result); // Display image in modal for cropping
                    setIsModalVisible(true); // Open the cropping modal
                };
                reader.readAsDataURL(selectedFile);
            }
        });

        document.body.appendChild(fileInput);
        fileInput.click();

        // Clean up the file input to prevent memory leaks
        fileInput.remove();
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [cropper, setCropper] = useState(null);
    const imageRef = useRef(null);

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result); // Show image in modal
                    setIsModalVisible(true); // Open modal
                };
                reader.readAsDataURL(selectedFile);
            }
        });

        fileInput.click();
    };

    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy(); // Destroy cropper instance when modal is closed
        }
    };
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const handleCropAndUpload = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData();
                formData.append('file', blob);
                setLoading(true);
                setHasError(false);
                // Replace the following line with your image upload logic
                dispatch(postUploadImageRequest(tid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(blob);
                    setNewImagePath(uploadedImagePath);
                    handleImageCloseModal();
                    setLoading(false);
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                    setLoading(false);
                    setHasError(true);
                }));
            });
        }
    };

    useEffect(() => {
        if (isModalVisible && imageRef.current) {
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
                viewMode: 1,
                autoCropArea: 1, // Ensure the crop area covers the whole image
                responsive: true, // Ensure responsiveness
                scalable: false,
                zoomable: true,
                cropBoxResizable: true,
                minCropBoxHeight: 200, // Min height as per your style
                maxCropBoxHeight: 200, // Max height as per your style
                minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
                maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
            });
            setCropper(cropperInstance);
        }

        return () => {
            if (cropper) {
                cropper.destroy(); // Cleanup when the modal is closed or component unmounts
            }
        };
    }, [isModalVisible]);





    const [modalVisible, setModalVisible] = useState(false);

    const closeMapModal = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (modalVisible) {
                closeMapModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (modalVisible && event.key === 'Escape') {
                closeMapModal();
                event.preventDefault();
            }
        };
        if (modalVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalVisible, closeMapModal]);

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const closeIconStyle = {
        // Add styles to make the close icon visible
        color: '#ffc107', // You can customize the color
        fontSize: '20px', // You can adjust the font size
    };

    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const detailsRef = useRef(null);
    const galleryRef = useRef(null);
    const timingsRef = useRef(null);
    const pujasRef = useRef(null);
    const historyRef = useRef(null);
    const libraryRef = useRef(null);
    const notificationsRef = useRef(null);
    const serviceRef = useRef(null);

    const [highlightedSection, setHighlightedSection] = useState(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleLinkClick = () => {
        showToast('error',
            <>
                {t('label_loginerror')}
                <br /><br />
                <button
                    onClick={() => {
                        setShowLoginForm1(true);
                    }}
                    className='button_move'
                >
                    Please login
                </button>
            </>
        );
    };


    const handleImageLinkClick = () => {
        showToast('error', t(`label_loginerror`))
    };

    useEffect(() => {
        if (tDetails && tDetails.st) {
            // Find the state object based on the API-provided state name
            const initialState = statesData.states.find(state => state.name === tDetails.st);

            // Set the selected state and corresponding cities
            setSelectedState(initialState);
            setCities(initialState ? initialState.cities : []);

            // Set the form fields with state and city from the API
            if (formRef.current) {
                formRef.current.setFieldsValue({
                    st: tDetails.st,
                    city: tDetails.city || (initialState ? initialState.cities[0] : undefined),
                });
            }
        }
    }, [tDetails, statesData]);

    // Handle changes in the state dropdown
    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(newState);
        setCities(newState ? newState.cities : []);

        // Reset the city value in the form when state changes
        if (formRef.current) {
            formRef.current.setFieldsValue({
                city: newState && newState.cities ? newState.cities[0] : undefined,
            });
        }
    };

    const handleLangPage = () => {
        navigate(`/langTrans/${templeDetails.tid}`)
    };

    const capitalizeFirstLetter = (txt) => {
        if (!txt) return '';
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    }

    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
    const [isDescrModalVisible, setIsDescrModalVisible] = useState(false);
    const [contributeValue, setContributeValue] = useState("");
    const [isCorrectionModalVisible, setIsCorrectionModalVisible] = useState(false);
    const [resetHistory, setResetHistory] = useState("");
    const [resetDescr, setResetDescr] = useState("");

    // Show Modals
    const showHistoryModal = () => {
        setContributeValue(""); // Reset value when modal opens
        setIsHistoryModalVisible(true);
        form.setFieldsValue({ history: formatPlainText(templeDetails.history) });
    };

    const showDescrModal = () => {
        setContributeValue(""); // Reset value when modal opens
        setIsDescrModalVisible(true);
        form.setFieldsValue({ descr: formatPlainText(templeDetails.descr) });
    };

    const showCorrectionModal = () => {
        setIsCorrectionModalVisible(true);
    };

    // Handle OK (Submit)
    const handleHistoryOk = () => {
        updateHist({ target: { value: contributeValue } });
        setIsHistoryModalVisible(false);
        setContributeValue(""); // Clear the textarea after saving
    };

    const handleDescrOk = () => {
        handleDescr({ target: { value: contributeValue } });
        setIsDescrModalVisible(false);
        setContributeValue(""); // Clear the textarea after saving
    };

    // Handle {t("label_cancel")}
    const handleHistoryCancel = () => {
        setIsHistoryModalVisible(false);
    };

    const handleDescrCancel = () => {
        setIsDescrModalVisible(false);
    };

    const handleCorrectionCancel = () => {
        setIsCorrectionModalVisible(false);
    };

    const isMobileDevice = () => /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isMobileDevice1 = window.innerWidth < 768;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (modalPreviewVisible) {
                closePreviewModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (modalPreviewVisible && event.key === 'Escape') {
                closePreviewModal();
                event.preventDefault();
            }
        };
        if (modalPreviewVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalPreviewVisible, closePreviewModal]);
    const title = `Explore ${templeDetails.deityname || "Temple"} - Temples WIKI`;
    const historySnippet = templeDetails.history
        ? templeDetails.history.length > 100
            ? `${templeDetails.history.slice(0, 100)}...`
            : templeDetails.history
        : "Discover more about this temple!";
    const pageUrl = `https://www.templeswiki.com/templeDetails/${tid}`;
    const imageUrl =
        `https://images.templeswiki.com/images_small/base_images_02/ttd_007.jpg`
        || "https://via.placeholder.com/1200x630.png?text=Temple+Image";


    const [expandedCircleId, setExpandedCircleId] = useState(null);

    const handleCircleHover = (id) => {
        setExpandedCircleId(id);
    };

    const handleCircleHoverOut = () => {
        setExpandedCircleId(null);
    };

    const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState({ title: '', content: '' });

    const popupRef = useRef(null);

    const handleShowPopup = (type) => {
        let title, content;

        const closePopup = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupVisible(false);
                document.removeEventListener("mousedown", closePopup);
            }
        };

        switch (type) {
            case 'festivals':
                title = 'Festival Details';
                content = (
                    <p className='bold-text-line-popup'>
                        {formatBoldText(templeDetails.festivals || t('label_nodata_found'))}
                    </p>
                );
                break;
            case 'dailypujas':
                title = 'Daily Pujas';
                content = (
                    <>
                        <div class="card-text-title">{t('label_daily_pujas')}</div>
                        <table align="center" className="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                            <thead>
                                <tr>
                                    <th>{t('label_time')}</th>
                                    <th>{t('label_puja')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {templeDetails.dailypujas && templeDetails.dailypujas.length > 0 ? (
                                    formatTabularText(templeDetails.dailypujas).map((dlyPuja, index) => (
                                        <tr key={index}>
                                            <td align="left" className="gridbg1">{dlyPuja.time}</td>
                                            <td align="left" className="gridbg2">{dlyPuja.puja}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <p colSpan="2" align="center">{t('label_nodata_found')}</p>
                                )}
                            </tbody>
                        </table>
                        <div class="card-text-title">{t('label_special_pujas')}</div>
                        <table align="center" class="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                            <thead>
                                <tr>
                                    <th>{t('label_time')}</th>
                                    <th>{t('label_puja')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {templeDetails.dailypujas && templeDetails.splpujas.length > 0 ? (
                                    formatTabularText(templeDetails.splpujas).map((splPuja, index) => (
                                        <tr key={index}>
                                            <td align="left" className="gridbg1">{splPuja.time}</td>
                                            <td align="left" className="gridbg2">{splPuja.puja}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <p colSpan="2" align="center">{t('label_nodata_found')}</p>
                                )}
                            </tbody>
                        </table>
                    </>
                );
                break;
            case 'timings':
                title = 'Timings';
                content = (
                    <table align="center" class="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                        <thead>
                            <tr>
                                <th>{t('label_time')}</th>
                                <th>{t('label_puja')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templeDetails.dailypujas && templeDetails.timings.length > 0 ? (
                                formatTabularText(templeDetails.timings).map((timing, index) => (
                                    <tr key={index}>
                                        <td align="left" className="gridbg1">{timing.time}</td>
                                        <td align="left" className="gridbg2">{timing.puja}</td>
                                    </tr>
                                ))
                            ) : (
                                <p colSpan="2" align="center">{t('label_nodata_found')}</p>
                            )}
                        </tbody>
                    </table>
                );
                break;
            case 'splPujas':
                title = 'Special Pujas';
                content = (
                    <p>{formatBoldText(templeDetails.splpujas || t('label_nodata_found'))}</p>
                );
                break;
            case 'howtoreach':
                title = 'How To Reach';
                content = (
                    <p className='bold-text-line-popup'>{formatBoldText(templeDetails.howtoreach || t('label_nodata_found'))}</p>
                );
                break;
            case 'history':
                title = 'History';
                content = (
                    <p className='bold-text-line-popup'>
                        {formatBoldText(templeDetails.history || t('label_nodata_found'))}
                    </p>
                );
                break;
            case 'addInfo':
                title = 'Additional Info';
                content = (
                    <p className='bold-text-line-popup'>{formatBoldText(templeDetails.addtl_info || t('label_nodata_found'))}  </p>
                );
                break;
            case 'about':
                title = 'About';
                content = (
                    <p className='bold-text-line-popup'>
                        {formatBoldText(templeDetails.descr || t('label_nodata_found'))}
                    </p>
                );
                break;
            case 'notifications':
                title = 'Notifications';
                content = (
                    <div className="active-details">
                        <TempleNotification />
                    </div>
                );
                break;
            case 'architecture':
                title = 'Architecture';
                content = (
                    <p className='bold-text-line-popup'>
                        {formatBoldText(templeDetails.architecture || t('label_nodata_found'))}
                    </p>
                );
                break;
            case 'accomidation':
                title = 'Lodgeing';
                content = (
                    <p className='bold-text-line-popup'>
                        {formatBoldText(templeDetails.accomodation || t('label_nodata_found'))}
                    </p>
                );
                break;
            default:
                title = '';
                content = '';
        }

        setPopupContent({ title, content });
        setPopupVisible(true);
        document.addEventListener("mousedown", closePopup);
    };

    const handleOutsideClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            handleFilterGodCancel(); // Close the popup
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);


    const handleIconClick = () => {
        navigate(`/templenotification/${tid}`);
    };

    return (
        <>
            {contextHolder}
            <div
                className="popup-bg"
                style={{ display: modalPreviewVisible ? 'flex' : 'none' }}
                onClick={(e) => {
                    if (e.target.classList.contains('popup-bg')) {
                        closePreviewModal();
                    }
                }}
            >
                <CustomToastContainer />
                <div class="popup-main animate__animated animate__fadeInUp ">
                    <div class="popup-main-header">
                        <span className="popup-main-header-text">{templeMinDetails ? templeMinDetails && templeMinDetails.tname : 'Temple Name'} &nbsp;</span>
                        <div className="popup-close" onClick={closePreviewModal}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="popup-back" onClick={closePreviewModal}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </div>
                    </div>
                    <div className="popup-main-container">
                        <div className="d-block htinner-popup-main-container">
                            <img
                                className="grp-descr-img"
                                alt={templeMinDetails.tname}
                                src={templeMinDetails.imgpath ? getImageAPI() + templeMinDetails.imgpath : deftemples}
                            />
                            <div className='dialog-content-text' style={{ float: "left" }}>
                                <p><strong>{t('label_deity_name')} : </strong> {templeMinDetails.deityname || 'N/A'}</p>
                                <p><strong>{t('address_label')} : </strong> {(templeMinDetails.addr1) || 'N/A'}, {templeMinDetails.addr2 || ''}</p>
                                <p>
                                    <strong>{t('label_city_state')} : </strong>
                                    {`${(templeMinDetails.lang_city)}, ${t(templeMinDetails.st)}`}
                                </p>
                                <p><strong>{t('label_history')} : </strong>
                                    {templeMinDetails.history ? formatPlainText(templeMinDetails.history) : 'No history available'}
                                </p>
                                <p><strong>{t('label_descr')} : </strong>
                                    {templeMinDetails.descr ? formatPlainText(templeMinDetails.descr) : 'No description available'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popup-main-footer">
                        <div class="like-share-section " >
                            <button class="detailstxt-artical-icon ml-3" disabled={loading || currentTempleIndex <= 0 || storedTemplesList.length === 0} onClick={handlePrevious} style={{ float: 'left', marginRight: "2px" }}>
                                <i class="fa-solid fa-chevron-left"></i></button>

                            <div class="article_btm-btns">

                                <button class="detailstxt-artical-icon">
                                    <a
                                        onClick={handleShowDetails}

                                        style={{ float: 'right', marginRight: '10px' }}
                                        disabled={loading}
                                    >
                                        <i class="fa-solid fa-circle-info"></i>
                                    </a>
                                    <a
                                        className="share-button"
                                        onClick={() => {
                                            const titleText = storedLanguage === 5
                                                ? `${t("label_know_about")} ${Title}`
                                                : `${Title} ${t("label_know_about")}`;
                                            shareResLink(
                                                titleText,
                                                `${t("label_share1")} ${Title} ${t("label_share2")}`,
                                                'temple', id, storedLanguage
                                            );
                                        }}
                                    >
                                        <i title="Share" className="fa-solid fa-share-nodes" style={{ fontSize: '24px' }}></i>
                                    </a>

                                    {favList && (
                                        <a
                                            className="share-button"
                                            onClick={() => toggleFavorite(temple, Title, success)}
                                        >
                                            {favList.some(t => t === id) ? (
                                                <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i>
                                            ) : (
                                                <i className="fa-regular fa-heart" style={{ fontSize: '24px' }}></i>
                                            )}
                                        </a>
                                    )}
                                </button>
                            </div>
                            {/* {isLoading && (<Error />)} */}
                            <button class="detailstxt-artical-icon mr-3" disabled={loading || currentTempleIndex >= storedTemplesList.length - 1 || storedTemplesList.length === 0} onClick={handleNext} style={{ float: 'right' }}>
                                <i class="fa-solid fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-bg" style={{ display: isHistoryModalVisible ? 'flex' : 'none' }} onClick={handleFilterGodCancel}>
                <div class="popup-main animate__animated animate__fadeInUp ">
                    <div class="popup-main-header">
                        <span class="popup-main-header-text"> History</span>
                        <div class="popup-close" onClick={handleHistoryCancel}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={handleHistoryCancel}><i class="fa-solid fa-chevron-left"></i></div>
                    </div>
                    <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                            <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}

                            >
                                <Form.Item
                                    name="history"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <TextArea class="dialog-content-text-only"



                                        onChange={handleHist} // Use updated onChange handler
                                        placeholder={t("error_msg_04")}
                                    />
                                </Form.Item>

                            </Form>
                        </div>
                    </div>
                    <div class="popup-main-footer">
                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                            {t("label_reset")}
                        </a>
                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateHist(); setIsHistoryModalVisible(false); }}>
                            {t("label_save")}
                        </a>
                    </div>
                </div>
            </div>
            <div class="popup-bg" style={{ display: isDescrModalVisible ? 'flex' : 'none' }}>
                <div class="popup-main animate__animated animate__fadeInUp ">
                    <div class="popup-main-header">
                        <span class="popup-main-header-text"> Description</span>
                        <div class="popup-close" onClick={handleDescrCancel}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={handleDescrCancel}><i class="fa-solid fa-chevron-left"></i></div>
                    </div>
                    <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                            <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}

                            >
                                <Form.Item
                                    name="descr"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <TextArea


                                        onChange={handleDescr}
                                        placeholder={t("error_msg_01")}

                                    />
                                </Form.Item>


                            </Form>
                        </div>
                    </div>
                    <div class="popup-main-footer">
                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                            {t("label_reset")}
                        </a>
                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateDescr(); setIsDescrModalVisible(false); }}>
                            {t("label_save")}
                        </a>
                    </div>
                </div>
            </div>
            <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }} onClick={handleFilterGodCancel}>
                <div class="popup-main animate__animated animate__fadeInUp ">
                    <div class="popup-main-header">
                        <span class="popup-main-header-text">{templeDetails.tname}</span>
                        <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                    </div>
                    <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                            <TempleMap mapContainerStyle={{ width: '100%', height: '55vh' }} tname={templeDetails.tname} latit={templeDetails && templeDetails.latit} longi={templeDetails && templeDetails.longi} city={templeDetails && templeDetails.city} state={templeDetails && templeDetails.st} />
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="position-absolute map-btns ml-4">
                            <button
                                type="button"
                                className="btnmap"
                                onClick={() => {
                                    if (templeDetails && templeDetails.latit && templeDetails && templeDetails.longi) {
                                        window.open(
                                            `https://www.google.com/maps/dir/?api=1&destination=${templeDetails && templeDetails.latit},${templeDetails && templeDetails.longi}&travelmode=driving`,
                                            "_blank"
                                        );
                                    }
                                }}
                            >
                                <i className="fa-solid fa-location-crosshairs"></i>
                                <span>{t('label_get_directions')}</span>
                            </button>
                        </div>
                    </div>
                    <div className='map-txt1'>
                        {t("label_drag_pointer_to_change_location")}
                    </div>
                    {/* <AdBlock /> */}
                    <div class="popup-main-footer">

                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
                            {t("label_cancel")}
                        </a>
                    </div>
                </div>
            </div>


            <div className='mainsec' ref={mainSecBlockRef}>

                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span className="bc-link">{t("label_tpl_det")}</span>
                    </div>
                </div>
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                    <span className="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to="/">
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                        <span className="bc-active-link" onClick={handleGoBack}>{t("label_temples_list")}</span>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                    </span>
                    <span className="bc-link">{t("label_tpl_det")}</span>
                </div>
                <div class={`bg-details-highlight `} >
                    <div class=" details-headertxt-singleLine container">
                        <div class="header_txt"> {templeDetails.tname}</div>
                        <div class="details-headertxt-singleLine-btns">
                            <div className="">
                                {(userid === "AD" || userid === "AS") ? (
                                    <>
                                        <Popconfirm
                                            title={`${t('label_are_you')} ${templeDetails.status === 0 ? t('label_active') : t('label_deactive')}?`}
                                            onConfirm={handleSelectChange}
                                            ok={<a className="button_move" style={{ float: 'right', marginRight: "5px" }} title={t("label_temple_deactvte")}
                                                onClick={handleSelectChange}>
                                                {t('label_save')}
                                            </a>}
                                            cancelText="No"
                                        >
                                            <a className={`button_move-copy ${templeDetails.status === 0 ? 'btn-success' : 'btn-danger'}`} style={{ marginLeft: '4px' }} title={t("label_temple_deactvte")}>
                                                {templeDetails.status === 0 ? t('label_active') : t('label_deactive')}
                                            </a>
                                        </Popconfirm>
                                        <button onClick={() => { handleLangPage(); }} className="button_move mr-2" title={t("label_change_lng")} style={{ marginLeft: '4px' }}>
                                            {t('label_lang_trans')}
                                        </button>
                                    </>
                                ) :
                                    null}
                                {token && userid !== "AD" && userid !== "AS" ? (
                                    <Link to={`/addTempleEvent/${tid}/${templeDetails.tname}`} className="button_move mr-2" title={t("label_change_lng")} style={{ marginLeft: '4px' }}>
                                        {t('label_add_notify')}
                                    </Link>
                                ) : (
                                    <Link onClick={handleLinkClick} className="button_move mr-2" title={t("label_change_lng")} style={{ marginLeft: '4px' }}>
                                        {t('label_add_notify')}
                                    </Link>
                                )}


                                {token ? (
                                    <>
                                        <a
                                            className="button_move mr-2"
                                            title={t("edit")}
                                            onClick={handleEditClick}
                                        >
                                            {t("edit")}
                                        </a>
                                        {/* <a className="button_move mr-2" type="primary" onClick={showDescrModal} >
                                            {t("label_contribute_descr")}
                                        </a> */}
                                    </>
                                ) : (
                                    <>
                                        <a className="button_move mr-2" onClick={handleLinkClick}>
                                            {t("edit")}
                                        </a>
                                        {/* <a className="button_move mr-2 " type="primary" onClick={handleLinkClick} >
                                            {t("label_contribute_descr")}
                                        </a> */}
                                    </>
                                )}


                                <a
                                    className="button_move"
                                    title={t("label_tmpl_crrctions")}

                                    onClick={() => {
                                        if (token) {
                                            showCorrectionModal();
                                        } else {
                                            handleLinkClick();
                                        }
                                    }}
                                >
                                    {t('label_data_correction')}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="container d-none">
                        <div class=" details-headertxt  flex-column">

                            <div class="header_txt mb-2"> {templeDetails.tname}</div>
                            <div class="d-flex justify-content-between temp-details-secheader-btnsec">
                                <div class="d-flex flex-row header_star ">
                                    <div class="text-warning temple-details-ratingsec">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fas fa-star-half-alt"></i>
                                        <span class="ms-1">
                                            4.5
                                        </span>
                                    </div>
                                    <span class="text-white view-count temple-details-ratingsec">{t('label_viewed')}</span>

                                    <div class="mr-5 ml-4 temple-details-ratingsec" >
                                        {/* <i
                                            className="fa-solid fa-location-dot"
                                            title={t("label_location")}
                                            onClick={() => {
                                                openModal();
                                                openMap();
                                            }}
                                        ></i> */}
                                        {/* <Helmet>
                                            <meta property="og:title" content={title} />
                                            <meta property="og:description" content={historySnippet} />
                                            <meta property="og:image" content={imageUrl} />
                                            <meta property="og:url" content={pageUrl} />
                                        </Helmet> */}

                                        {/* &nbsp; &nbsp; &nbsp; */}
                                        {/* <i
                                            className="fa-solid fa-share-nodes"
                                            title={t("label_share")}
                                            onClick={() => shareResLink(templeDetails.deityname,
                                                        templeDetails.history,
                                                        `templeDetails/${tid}`
                                            )}
                                        ></i> &nbsp; &nbsp; */}
                                        <Button
                                            type="link"
                                            style={{ color: "white" }}
                                            onClick={() => {
                                                toggleFavorite(templeDetails, templeDetails.tname);
                                            }}
                                            icon={
                                                favList.some(t => t === templeDetails.tid) ? (
                                                    <i
                                                        className="fa-solid fa-heart"
                                                        onClick={() => toggleFilled(templeDetails.tid)}
                                                        title={t("label_remove_fav")}
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa-regular fa-heart"
                                                        onClick={() => toggleFilled(templeDetails.tid)}
                                                        title={t("label_add_fav")}
                                                    ></i>
                                                )
                                            }
                                        />
                                        <Button
                                            type="link"
                                            style={{ color: "white" }}
                                            className="icon-button"
                                            onClick={() =>
                                                openPreviewModal(id)}
                                            title={t("label_preview")}
                                        >
                                            <i className="fa-solid fa-eye" title={t("label_preview")}></i>
                                        </Button>

                                    </div>



                                </div>



                            </div>
                        </div>

                    </div>
                    <Popup
                        isVisible={isPopupVisible}
                        title={popupContent.title}
                        content={popupContent.content}
                        onClose={() => setPopupVisible(false)}
                    />
                    <div className="popup-bg" style={{ display: isCorrectionModalVisible ? 'flex' : 'none' }}>
                        <div className="popup-bg" style={{ display: isCorrectionModalVisible ? 'flex' : 'none' }}>
                            <div className="popup-main animate__animated animate__fadeInUp">
                                <div className="popup-main-header">
                                    <span className="popup-main-header-text">{templeDetails && templeDetails.tname}</span>
                                    <div className="popup-close" onClick={handleCorrectionCancel}><i className="fa-solid fa-xmark"></i></div>
                                    <div className="popup-back" onClick={handleCorrectionCancel}><i className="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div ref={popupRef} className="popup-main-container complaint-sec">
                                    <div className="d-block">
                                        <div className="radio-group">
                                            <Radio.Group onChange={handleRadioChange} value={selectedCorrection}>
                                                <div className="radio-columns">
                                                    {correctionOptions.map(option => (
                                                        <Radio key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Radio>
                                                    ))}
                                                </div>
                                            </Radio.Group>
                                        </div>

                                        <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                            <Form.Item
                                                label={t("label_additi_det")}
                                                name="query"
                                                rules={[{ required: true }]}
                                            >
                                                <TextArea
                                                    className="dialog-content-text-only"
                                                    style={{ height: "10px" }}
                                                    placeholder={t("label_enter_additional_det")}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                                <div className="popup-main-footer">
                                    <a className="button_move " style={{ float: 'right', marginRight: "5px" }} onClick={handleCorrection}>
                                        {t('label_save')}
                                    </a>

                                    <a className="button_move" style={{ float: 'left' }} onClick={handleCorrectionCancel}>
                                        {t('label_cancel')}
                                    </a>
                                </div>
                            </div>
                            {/* <div class="popup-main-footer">
                                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                            Reset
                                        </a>
                                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleCorrection(); setIsCorrectionModalVisible(false); }}>
                                            Save
                                        </a>
                                    </div> */}
                        </div>
                    </div>
                    <div className="details-icons-main d-none">
                        <div className="container details-icons">
                            <div className={`details-icons-list `} onClick={() => scrollToSection(detailsRef)}>
                                <i className="fa-regular fa-newspaper btn-c1"></i>
                                <span className='names' title={t("Information")} >{t("label_info_loc")}</span>
                            </div>
                            <div className={`details-icons-list `} title={t("label_timings")} onClick={() => scrollToSection(timingsRef)}>
                                <i className="fa-regular fa-clock btn-c1"></i>
                                <span className='names'>{t("label_timings")}</span>
                            </div>
                            <div className={`details-icons-list `} title={t("label_pujas")} onClick={() => scrollToSection(pujasRef)}>
                                <i className="fas fa-pray btn-c1"></i>
                                <span className='names'>{t("label_pujas")}</span>
                            </div>
                            <div className={`details-icons-list `} title={t('label_galary')} onClick={() => scrollToSection(galleryRef)}>
                                <i className="fa-regular fa-image btn-c2"></i>
                                <span>{t('label_galary')}</span>
                            </div>
                            <div className={`details-icons-list `} title={t('label_history')} onClick={() => scrollToSection(historyRef)}>
                                <i className="fa-solid fa-clock-rotate-left btn-c3"></i>
                                <span>{t('label_history')}</span>
                            </div>
                            {!editable && (
                                <>
                                    <div className={`details-icons-list `} title={t('label_library')} onClick={() => scrollToSection(libraryRef)}>
                                        <i className="fa-solid fa-book-open btn-c4"></i>
                                        <span>{t('label_library')}</span>
                                    </div>
                                    <div className={`details-icons-list ${highlightedSection === 'notifications' ? 'highlighted' : ''}`} title={t('label_notif')} onClick={() => scrollToSection(notificationsRef)}>
                                        <i className="fa-regular fa-bell btn-c6"></i>
                                        <span>{t('label_notif')}</span>
                                    </div>
                                    <Link to={`/templeService/${tid}/${templeDetails.tname}`} className='serv'>
                                        <div className="details-icons-list" title={t('label_services')} >
                                            <i class="fa fa-ticket btn-c5" aria-hidden="true"></i>
                                            <span>{t('label_services')}</span>
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {showLoginForm1 && <Login1 showLoginForm1={showLoginForm1} setShowLoginForm1={setShowLoginForm1} />}
                <div class="  mainsec-block-detailspage temple_detailsbg
                mainsec-block-addtemple-detailspage-new details-temple-main">

                    <div class="container w-100">

                        <div class="row">

                            <div class="templedetails-add-block d-flex bd1-tempdetails">

                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-5 p-0">
                                            <main class="h-100">
                                                <div class="h-100">
                                                    <div className="temp-details-slider slick-carousel-container h-100">
                                                        <Image.PreviewGroup allowClose={true} maskClosable={true} mask={false}>
                                                            <Slider {...settings} ref={sliderRef}>
                                                                {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                                                    templeDetails.images.map((image, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                {showImage ? (
                                                                                    <img className='det-image' src={spin} alt="loading" />
                                                                                ) : (
                                                                                    <Image src={getMediumImageAPI() + image.imgpath} alt="images" />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div>
                                                                        <img className='det-image' alt="temples" src={deftemples} />
                                                                    </div>
                                                                )}
                                                            </Slider>
                                                        </Image.PreviewGroup>
                                                        {/* Conditionally render the left and right outlines */}
                                                        {templeDetails.images != null && templeDetails.images.length > 1 && (
                                                            <>
                                                                <LeftOutlined className='leftoutlined' onClick={handlePrevClick}>t('label_previous')</LeftOutlined>
                                                                <RightOutlined className='rightoutlined' onClick={handleNextClick}>t('label_next')</RightOutlined>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </main>




                                        </div>
                                        <div class="col-md-12 col-lg-7">
                                            <div class="d-flex tempDetails-w">

                                                <div class="d-flex flex-column templedetails-add-block-main mt-2 mb-2 add-w-control" ref={detailsRef}>

                                                    <p className="card-text-title deity-img-sec">
                                                        <span className="float-left">
                                                            <img
                                                                src={deityImages[templeDetails.avatar]}
                                                                alt="Selected Deity Image"
                                                                onClick={handleImageClick}
                                                                className="details-img-god"
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </span>

                                                        {templeDetails.tname}<br />
                                                        <div class="d-flex mt-2 detaipage-moretemp-align">
                                                            <span>{templeDetails.deityname}</span>
                                                            {templeDetails.avatar != 0 && (
                                                                <span className='pt-2 mt-1 mb-1 showmore-deity detailpagebtn btn-shine' onClick={handleImageClick}> {t('label_show_more1')}  {t('label_temples_list1')}</span>
                                                            )}</div>

                                                        {(templeDetails.swayambu || templeDetails.onhill || templeDetails.gov || templeDetails.navagrahas) && (
                                                            <div className="gy-4 d-flex justify-content-start special-addon bg-highlight-tempdetail">
                                                                {templeDetails.swayambu && (
                                                                    <div>
                                                                        <i className="fa-solid fa-mountain"></i> {t('label_swayambu')}
                                                                    </div>
                                                                )}
                                                                {templeDetails.onhill && (
                                                                    <div>
                                                                        <i className="fa-solid fa-hill-rockslide"></i> {t('label_onhill')}
                                                                    </div>
                                                                )}
                                                                {templeDetails.gov && (
                                                                    <div>
                                                                        <i className="fa-solid fa-landmark"></i> {t('label_gov_related')}
                                                                    </div>
                                                                )}
                                                                {templeDetails.navagrahas && (
                                                                    <div>
                                                                        <i className="fa-solid fa-sun"></i> {t('label_navagraha')}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}




                                                    </p>

                                                    {templeDetails.deityname && (
                                                        <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                            <span className="details-info-txt">{t('label_main_deity')}:</span>
                                                            <span class="details-info-txt-value">{templeDetails.deityname}</span>
                                                        </p>
                                                    )}
                                                    {templeDetails.otherdeities && (

                                                        <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                            <span className="details-info-txt">{t('label_other_deities')}:</span>
                                                            <span class="details-info-txt-value">{templeDetails.otherdeities}</span>
                                                        </p>
                                                    )}
                                                    {/* <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                        <span className="details-info-txt">{t('label_navagrahalu')}:</span>
                                                        <span class="details-info-txt-value">{templeDetails.navagrahalu}</span>
                                                    </p> */}
                                                    {templeDetails.addr1 && templeDetails.addr2 && (
                                                        <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                            <span className="details-info-txt" >{t('label_addr')}:</span>
                                                            <span className="details-info-txt-value">
                                                                {templeDetails.addr1}{templeDetails.addr2 && `; ${templeDetails.addr2}`}
                                                            </span>
                                                        </p>
                                                    )}
                                                    <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                        <span className="details-info-txt"  >{t('label_city_state')}:</span>
                                                        <span class="details-info-txt-value">{templeDetails.lang_city}; {t(templeDetails.st)} {templeDetails.pincode ? ` - ${templeDetails.pincode}` : ''}
                                                        </span>
                                                    </p>
                                                    {templeDetails.phoneno && (
                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                            <span className="details-info-txt"  >{t('label_phone')}:</span>
                                                            <span className="details-info-txt-value">{templeDetails.phoneno}</span>
                                                        </p>
                                                    )}
                                                    {templeDetails.email && (
                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                            <span className="details-info-txt"  >{t('label_email')}:</span>
                                                            <span className="details-info-txt-value">{templeDetails.email}</span>
                                                        </p>
                                                    )}

                                                    {templeDetails.priestname && (
                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                            <span className="details-info-txt"  >{t('label_prname')}:</span>
                                                            <span className="details-info-txt-value">{templeDetails.priestname}</span>
                                                        </p>
                                                    )}
                                                    {templeDetails.priestphoneno
                                                        && (
                                                            <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                                <span className="details-info-txt"  >{t('label_priest_phone_no')}:</span>
                                                                <span className="details-info-txt-value">{templeDetails.priestphoneno
                                                                }</span>
                                                            </p>
                                                        )}
                                                    {templeDetails.website && (
                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center", wordBreak: "break-all" }}>
                                                            <span className="details-info-txt"  >{t('label_website')}:</span>
                                                            <span className="details-info-txt-value">
                                                                {templeDetails && templeDetails.website ? (
                                                                    <a
                                                                        href={
                                                                            templeDetails.website.startsWith("http")
                                                                                ? templeDetails.website
                                                                                : `http://${templeDetails.website}`
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ height: '35px' }}
                                                                    >
                                                                        {templeDetails.website}
                                                                    </a>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                        </p>
                                                    )}
                                                    {/* {templeDetails.pincode && (
                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                            <span className="details-info-txt"  >{t('label_pin')}:</span>
                                                            
                                                        </p>
                                                    )} */}
                                                    {templeDetails.inauguration && (
                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                            <span className="details-info-txt"  >{t('label_inauguration')}:</span>
                                                            <span className="details-info-txt-value">{templeDetails.inauguration}</span>
                                                        </p>
                                                    )}
                                                    <div class="d-flex">
                                                        <div class=" gy-4 d-flex justify-content-start " >

                                                            <div class="d-flex aos-init aos-animate mr-4" onClick={() => {
                                                                openModal();
                                                                openMap();
                                                            }} data-aos="fade-up" data-aos-delay="100">
                                                                <div class="service-item position-relative">
                                                                    <aside class="temple_icon_details_bottom">
                                                                        <i class="fa-solid fa-location-dot"></i>

                                                                    </aside>
                                                                    <h4><a class="stretched-link">{t('label_map')}</a></h4>

                                                                </div>
                                                            </div>

                                                            {/* <Helmet>
                                                                <title>{title} - Temples WIKI</title>
                                                                <meta property="og:title" content={templeDetails.deityname} />
                                                                <meta property="og:description" content={templeDetails.descr} />
                                                                <meta property="og:image" content={templeDetails.imgpath} />
                                                                <meta property="og:url" content={`https://www.templeswiki.com/templeDetails/${id}`} />
                                                                <meta name="twitter:title" content={templeDetails.deityname} />
                                                                <meta name="twitter:description" content={templeDetails.descr} />
                                                                <meta name="twitter:image" content={templeDetails.imgpath} />
                                                            </Helmet> */}

                                                            < div class=" d-flex aos-init aos-animate mr-4"
                                                                onClick={() => {
                                                                    const titleText = storedLanguage === 5
                                                                        ? `${t("label_know_about")} ${templeDetails.deityname}`
                                                                        : `${templeDetails.deityname} ${t("label_know_about")}`;

                                                                    shareResLink(
                                                                        titleText,
                                                                        `${t("label_share1")} ${templeDetails.deityname} ${t("label_share2")}`,
                                                                        'temple', tid, storedLanguage
                                                                    );
                                                                }}
                                                                data-aos="fade-up" data-aos-delay="400">
                                                                <div class="service-item position-relative" >
                                                                    <aside class="temple_icon_details_bottom">
                                                                        <i class="fa-solid fa-share-nodes"></i>
                                                                    </aside>
                                                                    <h4><a class="stretched-link">{t('label_share')}</a></h4>

                                                                </div>
                                                            </div>

                                                            {/* < div class=" d-flex aos-init aos-animate mr-4" onClick={() => handleShowPopup('festivals')} data-aos="fade-up" data-aos-delay="400">
                                                            <div class="service-item position-relative" >
                                                                <aside class="temple_icon_details_bottom">
                                                                    <i class="fa-solid fa-indian-rupee-sign "></i>
                                                                </aside>
                                                                <h4><a class="stretched-link">{t('Donate')}</a></h4>

                                                            </div>
                                                        </div> */}


                                                            <div
                                                                className="d-flex aos-init aos-animate mr-4"
                                                                onClick={() => {
                                                                    openPreviewModal(id);
                                                                }}
                                                                data-aos="fade-up"
                                                                data-aos-delay="400"
                                                            >
                                                                <div className="service-item position-relative">
                                                                    <aside className="temple_icon_details_bottom">
                                                                        <i className="fa-solid fa-eye"></i>
                                                                    </aside>
                                                                    <h4>
                                                                        <a className="stretched-link">{t('label_preview')}</a>
                                                                    </h4>
                                                                </div>
                                                            </div>

                                                            {/* <Button
                                                            type="link"
                                                            style={{ color: "white" }}
                                                            onClick={() => {
                                                                toggleFavorite(templeDetails, templeDetails.tname);
                                                            }}
                                                            icon={
                                                                favList.some(t => t === templeDetails.tid) ? (
                                                                    <i
                                                                        className="fa-solid fa-heart"
                                                                        onClick={() => toggleFilled(templeDetails.tid)}
                                                                        title={t("label_remove_fav")}
                                                                    ></i>
                                                                ) : (
                                                                    <i
                                                                        className="fa-regular fa-heart"
                                                                        onClick={() => toggleFilled(templeDetails.tid)}
                                                                        title={t("label_add_fav")}
                                                                    ></i>
                                                                )
                                                            }
                                                        /> */}

                                                            <div className="d-flex aos-init aos-animate mr-4" onClick={() => toggleFavorite(templeDetails)}>
                                                                <div className="service-item position-relative">
                                                                    <aside className="temple_icon_details_bottom">
                                                                        {favList && (
                                                                            <a
                                                                                className="share-button"
                                                                                onClick={() => toggleFavorite(templeDetails)}
                                                                            >
                                                                                {favList.includes(templeDetails.tid) ? (
                                                                                    <i className="fa-solid fa-heart"></i>
                                                                                ) : (
                                                                                    <i className="fa-regular fa-heart"></i>
                                                                                )}
                                                                            </a>
                                                                        )}
                                                                    </aside>
                                                                    <h4>
                                                                        <a className="stretched-link">{t("label_favorite")}</a>
                                                                    </h4>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-none flex-column templedetails-add-block-main mt-2 mb-2 details-info2 tempDetails-w">
                                                <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_gov_related')}:</span> <span className='temple-details-temlplelink'>{templeDetails && templeDetails.gov}</span></p>
                                                <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_email')}:</span> <span className='temple-details-temlplelink'>{templeDetails && templeDetails.email}</span></p>
                                                <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_contact')}:</span> <span>{templeDetails && templeDetails.phoneno}</span></p>
                                                <p className="mainsec-block-addtemple-detailspage-title-text">
                                                    <span className="details-info-txt">{t('label_website')}:</span>
                                                    <span className='temple-details-temlplelink'>
                                                        {templeDetails && templeDetails.website ? (
                                                            <a
                                                                href={
                                                                    templeDetails.website.startsWith("http")
                                                                        ? templeDetails.website
                                                                        : `http://${templeDetails.website}`
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ height: '35px' }}
                                                            >
                                                                {templeDetails.website}
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </span>
                                                </p>
                                                <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_role_pr')}:</span> <span>{templeDetails && templeDetails.priestname}</span></p>
                                                <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_priest_phone_no')}:</span> <span >{templeDetails && templeDetails.priestphoneno}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div class="temple-details-icon-sec-top">
                                {/* <p class="temple_icon_details-icons-title">Services</p> */}
                                <div class="d-flex clr-bth overflow-y-auto justify-content-evenly">

                                    <div class="container">
                                        <div class="row d-flex">
                                            <div ref={popupRef} className="popup-container">
                                                {popupContent.content}
                                            </div>
                                            <div class=" d-flex  temp-details-tiles">
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.descr || templeDetails.descr === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.descr && templeDetails.descr.length > 0 ? () => handleShowPopup('about') : null}
                                                >
                                                    <aside class="temple_icon_details btn-c2">
                                                        <i class="fa-solid fa-gopuram"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_details')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.history || templeDetails.dailypuhistory === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.history && templeDetails.history.length > 0 ? () => handleShowPopup('history') : null}
                                                >
                                                    <aside class="temple_icon_details" >
                                                        <i class="fa-solid fa-clock-rotate-left btn-c7" ></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text" >{t('label_history')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.dailypujas || templeDetails.dailypujas.length === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.dailypujas && templeDetails.dailypujas.length > 0 ? () => handleShowPopup('dailypujas') : null}
                                                >
                                                    <aside class="temple_icon_details btn-c3">
                                                        <i class="fa-solid fa-hands-praying "></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_puja')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.timings || templeDetails.timings.length === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.timings && templeDetails.timings.length > 0 ? () => handleShowPopup('timings') : null}
                                                >
                                                    <aside class="temple_icon_details btn-c6">
                                                        <i class="fa-solid fa-om"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_darshanam')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.architecture || templeDetails.architecture.length === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.architecture && templeDetails.architecture.length > 0 ? () => handleShowPopup('architecture') : null}
                                                >
                                                    <aside class="temple_icon_details">
                                                        <i class="fa-solid fa-archway btn-c4"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text ">{t('label_architecture')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.festivals || templeDetails.festivals === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.festivals && templeDetails.festivals.length > 0 ? () => handleShowPopup('festivals') : null}
                                                >
                                                    <aside class="temple_icon_details">
                                                        <i class="fa-solid fa-calendar-week btn-c6"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_events')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.addtl_info || templeDetails.addtl_info === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.addtl_info && templeDetails.addtl_info.length > 0 ? () => handleShowPopup('addInfo') : null}
                                                >
                                                    <aside class="temple_icon_details">
                                                        <i class="fa-solid fa-circle-info btn-c1"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_addtl_info')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.howtoreach || templeDetails.howtoreach.length === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.howtoreach && templeDetails.howtoreach.length > 0 ? () => handleShowPopup('howtoreach') : null}
                                                >
                                                    <aside class="temple_icon_details btn-c7">
                                                        <i class="fa-solid fa-person-walking-luggage"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_how_to_reach')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.accomidation || templeDetails.accomidation === 0 ? 'disabled' : ''}`}
                                                    onClick={templeDetails.accomidation && templeDetails.accomidation.length > 0 ? () => handleShowPopup('accomidation') : null}
                                                >
                                                    <aside class="temple_icon_details">
                                                        <i class="fa-solid fa-building-user btn-c8"></i>
                                                    </aside>
                                                    <aside class="temple-details-icon-text">{t('label_lodgeing')}</aside>
                                                </span>
                                                <span
                                                    className={`temple_icon_details-icons-pos ${!templeDetails.bookings ? 'disabled' : ''}`}
                                                >
                                                    <Link to={`/templeService/${tid}/${templeDetails.tname}`} className="ignore-styles">
                                                        <aside className="temple_icon_details">
                                                            <i className="fa-solid fa-ticket btn-c9 btn-c2"></i>
                                                        </aside>
                                                        <aside class="temple-details-icon-text">{t('label_bookings')}</aside>
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {!isMobile && (
                                <div className='container mt-2'>
                                    <div className="info-item d-flex align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                        <i className="icon fa-solid fa-clock-rotate-left"></i>

                                        <div className="w-100 d-flex align-items-center">
                                            <h3 className="me-2">{t('label_descr_history')}</h3>

                                        </div>
                                    </div>


                                    <div className="active-details">
                                        <div className="tiles-designs">
                                            {templeDetails.history ? (
                                                <Form.Item
                                                    label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                                                    name="history">

                                                    <div>
                                                        <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                                                            {isMobile ? (
                                                                <ShowMoreText charLimit={200} text={formatPlainText(templeDetails.history)} />
                                                            ) : (
                                                                <ShowMoreText charLimit={500} text={formatPlainText(templeDetails.history)} />
                                                            )}
                                                        </Typography.Text>
                                                    </div>

                                                </Form.Item>
                                            ) : null}
                                            {/* <div className='contribute' >
                                            <br />
                                            <Typography.Text className='contribute-warning'>{t("label_contribute")}</Typography.Text>
                                            <div class="buttons-left-right-jumping w-100">
                                                {getToken ? (
                                                    <a className="button_move mt-2 mb-2 float-left" type="primary" onClick={showHistoryModal}>
                                                        {t("label_contribute_hist")}
                                                    </a>
                                                ) : (
                                                    <a className="button_move mt-2 mb-2 float-left" type="primary" onClick={handleLinkClick}>
                                                        {t("label_contribute_hist")}
                                                    </a>
                                                )}
                                            </div>
                                        </div> */}

                                        </div>
                                        {templeDetails.descr ? (
                                            <div className="tiles-designs tiles-design-divider">
                                                <Form.Item label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>} name="descr">

                                                    <div>
                                                        <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                                                            {isMobile ? (
                                                                <ShowMoreText charLimit={200} text={formatPlainText(templeDetails.descr)} />
                                                            ) : (
                                                                <ShowMoreText charLimit={500} text={formatPlainText(templeDetails.descr)} />
                                                            )}
                                                        </Typography.Text>
                                                    </div>

                                                </Form.Item>
                                                {/* <div className='contribute' >
                                            <br />
                                            <Typography.Text className='contribute-warning'>{t("label_contribute_det")}</Typography.Text>

                                            <div class="buttons-left-right-jumping mt-2 w-100">
                                                {getToken ? (
                                                    <a className="button_move mt-3 mb-2 float-left" type="primary" onClick={showDescrModal} >
                                                        {t("label_contribute_descr")}
                                                    </a>
                                                ) : (
                                                    <a className="button_move mt-3 mb-2 float-left" type="primary" onClick={handleLinkClick} >
                                                        {t("label_contribute_descr")}
                                                    </a>
                                                )}
                                            </div>
                                        </div> */}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            {(userid !== 'AD' && userid !== 'AS' && !isMobile) && (
                                <div className='container mt-2'>
                                    {/* <div className="float-left w-100" ref={pujasRef}>
                                        <div className="card-text-title">{t('label_daily_pujas')}</div>
                                    </div> */}


                                    <div className="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="300">
                                        <i className="icon fa-solid fa-hands-praying"></i>

                                        <div className="w-100 d-flex align-items-center">
                                            <h3 className="me-2">{t('label_daily_pujas')}</h3>
                                        </div>
                                    </div>

                                    {templeDetails.dailypujas && templeDetails.dailypujas.length > 0 ? (
                                        <table
                                            align="center"
                                            className="timming-grid"
                                            border="0"
                                            cellPadding="0"
                                            cellSpacing="0"
                                            rules="all"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>{t('label_time')}</th>
                                                    <th>{t('label_puja')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formatTabularText(templeDetails.dailypujas).map((dlyPuja, index) => (
                                                    <tr key={index}>
                                                        <td align="left" className="gridbg1">{dlyPuja.time}</td>
                                                        <td align="left" className="gridbg2">{dlyPuja.puja}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="no-data-message">{t('label_no_puja_timing')}</div>
                                    )}
                                </div>
                            )}

                            <div className='container mt-3'>
                                <div class="row gy-4 ">

                                    {(userid === 'AD' || userid === 'AS') && (
                                        // <div class="mainsec-block-detailspage ">
                                        <div className='container'>

                                            {
                                                tLoading ? <Error /> :
                                                    <div class="details-page float-left w-100"  >

                                                        <section class="py-5 d-none">
                                                            <div class="container">
                                                                <main class="col-lg-6 ">
                                                                    <div >
                                                                        <div className="slick-carousel-container">
                                                                            <Slider {...settings} ref={sliderRef}>
                                                                                {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                                                                    templeDetails.images.map((image, index) => {
                                                                                        return (
                                                                                            <div key={index}>
                                                                                                {showImage ? (
                                                                                                    <img className='det-image' src={spin} alt="loading" />
                                                                                                ) : (

                                                                                                    <img className='det-image' src={getMediumImageAPI() + image.imgpath} alt="images" />
                                                                                                )}
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <div>
                                                                                        <img className='det-image' alt="temples" src={deftemples} />
                                                                                    </div>
                                                                                )}
                                                                            </Slider>

                                                                            {/* Conditionally render the left and right outlines */}
                                                                            {templeDetails.images != null && templeDetails.images.length > 1 && (
                                                                                <>
                                                                                    <LeftOutlined className='leftoutlined' onClick={handlePrevClick}>t('label_previous')</LeftOutlined>
                                                                                    <RightOutlined className='rightoutlined' onClick={handleNextClick}>t('label_next')</RightOutlined>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </main>

                                                                <main class="col-lg-12">
                                                                    <div class="ps-lg-3">
                                                                        <div class="row">
                                                                            <dt class="col-2"></dt>
                                                                            <dt class="col-2"></dt>
                                                                        </div>
                                                                    </div>
                                                                </main>
                                                            </div>
                                                        </section>

                                                        <div class="d-none">
                                                            <Row justify='space-between' >
                                                                <Col span={11} order={2} style={{ top: '27px' }}>
                                                                    <div className='tmple-name'>


                                                                        <Button
                                                                            className='edit-button'
                                                                            data-mdb-ripple-color="dark"
                                                                            onClick={editable ? handleCancelClick : handleEditClick}
                                                                        >
                                                                            {buttonText}
                                                                        </Button>

                                                                        {editable && (
                                                                            <Button
                                                                                className='btn btn-outline-secondary'
                                                                                data-mdb-ripple-color="dark"
                                                                                onClick={handleSaveClick}
                                                                                style={{ marginLeft: '5px' }}
                                                                            >
                                                                                {t("label_save")}
                                                                            </Button>
                                                                        )}
                                                                        {/* <Link to='/templeMap'><i title="Location" class="fa-solid fa-location-dot"></i></Link> */}

                                                                        {/* <div class="tiles-quicklink" style={{ display: 'inline-block' }}>
                                                            <i title="Location" class="fa-solid fa-location-dot" onClick={openModal}></i>
                                                        </div> */}
                                                                        {/* <Button
                                                            className="share-button"
                                                            type="link"
                                                            onClick={() => shareResLink(templeDetails.tname,
                                                                            "Check out this temple!",
                                                                            'temple', tid, storedLanguage
                                                            )}
                                                        >
                                                            <i title="Share" class="fa-solid fa-share-nodes"></i>
                                                        </Button> */}


                                                                    </div>

                                                                    <div style={{ marginTop: '5px' }}>
                                                                        <Image
                                                                            src={deityImages[templeDetails.avatar]}
                                                                            alt="Selected Deity Image"
                                                                            onClick={handleImageClick}
                                                                            style={{
                                                                                // cursor: enableModal ? 'pointer' : 'not-allowed',
                                                                                width: '15%',
                                                                                marginRight: '13%',
                                                                                marginTop: '-18%',
                                                                                height: '10%'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                        <Form
                                                            style={{
                                                                float: 'left',
                                                                width: '100%'
                                                            }}
                                                            layout="vertical"
                                                            labelCol={{
                                                                span: 4,
                                                            }}
                                                            wrapperCol={{
                                                                span: 20,
                                                            }}
                                                            form={formRef.current}
                                                            onFinish={handleFormSubmit}
                                                            ref={formRef} // Assign ref to the form
                                                        >


                                                            <div className="active-details ">
                                                                <div className="tiles-designs">


                                                                    {/* <p class="mainsec-block-addtemple-detailspage-title" ref={detailsRef}>{templeDetails && templeDetails.tname}</p> */}

                                                                    {/* <div class="templedetails-add-block d-flex bd1-tempdetails">

                                                            <div class="d-flex templedetails-add-sec d-none">
                                                                <div class="d-flex tempDetails-w">
                                                                    <span class="d-flex align-items-center justify-content-center">
                                                                        <img
                                                                            src={deityImages[templeDetails.avatar]}
                                                                            alt="Selected Deity Image"
                                                                            onClick={handleImageClick}
                                                                            className='details-img-god'

                                                                        />
                                                                    </span>
                                                                    <div class="d-flex flex-column templedetails-add-block-main mt-2 mb-2 add-w-control" ref={detailsRef}>
                                                                        <p class="card-text-title">{templeDetails.deityname}</p>
                                                                        <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                            <span className="details-info-txt">{t('label_other_deities')}:</span>
                                                                            <span class="details-info-txt-value">{templeDetails.otherdeities}</span>
                                                                        </p>
                                                                        <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                            <span className="details-info-txt">{t('label_addr')}:</span>
                                                                            <span className="details-info-txt-value">
                                                                                {templeDetails.addr1}{templeDetails.addr2 && `; ${templeDetails.addr2}`}
                                                                            </span>
                                                                        </p>
                                                                        <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                            <span className="details-info-txt" style={{ marginRight: "5px" }}>{t('label_city_state')}:</span>
                                                                            <span class="details-info-txt-value">{templeDetails.lang_city}; {t(templeDetails.st)}</span>
                                                                        </p>
                                                                        <p className="mainsec-block-addtemple-detailspage-title-text1" style={{ display: "flex", alignItems: "center" }}>
                                                                            <span className="details-info-txt" style={{ marginRight: "5px" }}>{t('label_pin')}:</span>
                                                                            <span className="details-info-txt-value">{templeDetails.pincode}</span>
                                                                        </p>

                                                                        <div class="container">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex flex-column templedetails-add-block-main mt-2 mb-2 details-info2 tempDetails-w">

                                                                    <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_email')}:</span> <span className='temple-details-temlplelink'>{templeDetails && templeDetails.email}</span></p>
                                                                    <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_contact')}:</span> <span>{templeDetails && templeDetails.phoneno}</span></p>
                                                                    <p className="mainsec-block-addtemple-detailspage-title-text">
                                                                        <span className="details-info-txt">{t('label_website')}:</span>
                                                                        <span className='temple-details-temlplelink'>
                                                                            {templeDetails && templeDetails.website ? (
                                                                                <a
                                                                                    href={
                                                                                        templeDetails.website.startsWith("http")
                                                                                            ? templeDetails.website
                                                                                            : `http://${templeDetails.website}`
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{ height: '35px' }}
                                                                                >
                                                                                    {templeDetails.website}
                                                                                </a>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                    <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_role_pr')}:</span> <span>{templeDetails && templeDetails.priestname}</span></p>
                                                                    <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_priest_phone_no')}:</span> <span >{templeDetails && templeDetails.priestphoneno}</span></p>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                                    <section class="float-left w-100 d-none">


                                                                        <section id="works" class="padding-medium mt-5 ">
                                                                            <div class="position-relative z-n1">
                                                                                <div class="position-absolute w-100 text-center">
                                                                                    <h2 class="sectiontext text-uppercase">{t("label_tpl_det")}</h2>
                                                                                </div>
                                                                            </div>
                                                                            <div class="container text-center position-relative">
                                                                                <div>
                                                                                    <h3 class="fw-bolder text-center mb-5">
                                                                                        Our Works
                                                                                    </h3>
                                                                                </div>
                                                                                <div class="row py-3">
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-1.jpg" class="img-fluid" alt="Hindu Temple Opening and Closing Timings" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Timings</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-2.jpg" class="img-fluid" alt="Hindu Temple Puja Details" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Pujas</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-3.jpg" class="img-fluid" alt="How to reach Hindu Temple location" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">How to Reach?</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2" >
                                                                                        <img src="images/product-4.jpg" class="img-fluid" alt="Hindu Temple Festivals and Events" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Events</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-5.jpg" class="img-fluid" alt="Hindu Temple Architecture" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Architecture</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-6.jpg" class="img-fluid" alt="Hindu Temple Images" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Imagess</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-6.jpg" class="img-fluid" alt="Hindu Temple History" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">History</h6>
                                                                                        </a>
                                                                                    </div>

                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-6.jpg" class="img-fluid" alt="Accomodation near to Temple" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Accomodation</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-6.jpg" class="img-fluid" alt="Notifications about Hindu Temple" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Notifications</h6>
                                                                                        </a>
                                                                                    </div>

                                                                                    <div class="col-3 col-lg-2">
                                                                                        <img src="images/product-6.jpg" class="img-fluid" />
                                                                                        <a href="#">
                                                                                            <h6 class="py-3">Others</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </section>


                                                                        <div className="image-container container-fluid d-none">

                                                                            <div className="circle-info-container">
                                                                                <div
                                                                                    className={`circle-image ${expandedCircleId === 'circle2' ? 'expanded' : ''}`}
                                                                                    onMouseEnter={() => handleCircleHover('circle2')}
                                                                                    onMouseLeave={handleCircleHoverOut}
                                                                                    onClick={() => handleShowPopup('festivals')}
                                                                                >
                                                                                    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBbhsOO28AafySpCPGYpl7k2NOdBkFcqjHxw&usqp=CAU' alt="Hindu Temple Festivals" />
                                                                                </div>
                                                                                <div className='daily' >
                                                                                    <Typography.Title level={4}>festivals</Typography.Title>

                                                                                </div>
                                                                            </div>
                                                                            <div className="circle-info-container d-none">
                                                                                <div
                                                                                    className={`circle-image ${expandedCircleId === 'circle3' ? 'expanded' : ''}`}
                                                                                    onMouseEnter={() => handleCircleHover('circle3')}
                                                                                    onMouseLeave={handleCircleHoverOut}
                                                                                    onClick={() => handleShowPopup('splpujas')}
                                                                                >
                                                                                    <img src='https://images.herzindagi.info/image/2019/Oct/puja.jpg' alt="Hindu Temple Special Pujas" />
                                                                                </div>
                                                                                <div className='daily' >
                                                                                    <Typography.Title level={4}>Special Pujas</Typography.Title>

                                                                                </div>
                                                                            </div>
                                                                            <div className="circle-info-container d-none">
                                                                                <div
                                                                                    className={`circle-image ${expandedCircleId === 'circle4' ? 'expanded' : ''}`}
                                                                                    onMouseEnter={() => handleCircleHover('circle4')}
                                                                                    onMouseLeave={handleCircleHoverOut}
                                                                                >
                                                                                    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7Q-pmodw6nFchWdY7P7md7r2DGZ9ONxQexA&usqp=CAU' alt="Travel to Hindu Temple" />
                                                                                </div>
                                                                                <div className='daily' >
                                                                                    <Typography.Title level={4}>Travel</Typography.Title>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="image-container container-fluid d-none" >
                                                                            <div className="circle-info-container">
                                                                                <div
                                                                                    className={`circle-image ${expandedCircleId === 'circle1' ? 'expanded' : ''}`}
                                                                                    onMouseEnter={() => handleCircleHover('circle1')}
                                                                                    onMouseLeave={handleCircleHoverOut}
                                                                                    onClick={() => handleShowPopup('howtoreach')}
                                                                                >
                                                                                    <img src='https://www.teluguone.com/teluguoneUserFiles/tirumala%200(3).png' alt="Hindu Temple Daily Pujas" />
                                                                                </div>

                                                                                <div className='daily'>
                                                                                    <Typography.Title level={4}>Daily Pujas</Typography.Title>

                                                                                </div>

                                                                            </div>
                                                                            <div className="circle-info-container">
                                                                                <div
                                                                                    className={`circle-image ${expandedCircleId === 'circle2' ? 'expanded' : ''}`}
                                                                                    onMouseEnter={() => handleCircleHover('circle2')}
                                                                                    onMouseLeave={handleCircleHoverOut}
                                                                                >
                                                                                    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBbhsOO28AafySpCPGYpl7k2NOdBkFcqjHxw&usqp=CAU' alt="Hindu Temple Festivals" />
                                                                                </div>
                                                                                <div className='daily' >
                                                                                    <Typography.Title level={4}>festivals</Typography.Title>

                                                                                </div>
                                                                            </div>
                                                                            <div className="circle-info-container">
                                                                                <div
                                                                                    className={`circle-image ${expandedCircleId === 'circle3' ? 'expanded' : ''}`}
                                                                                    onMouseEnter={() => handleCircleHover('circle3')}
                                                                                    onMouseLeave={handleCircleHoverOut}
                                                                                >
                                                                                    <img src='https://images.herzindagi.info/image/2019/Oct/puja.jpg' alt="Hindu Temple Special Pujas" />
                                                                                </div>
                                                                                <div className='daily' >
                                                                                    <Typography.Title level={4}>Special Pujas</Typography.Title>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </section>
                                                                    {!isMobile && (
                                                                        <div>
                                                                            <div className="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="300">
                                                                                <i className="icon fa-solid fa-hands-praying"></i>

                                                                                <div className="w-100 d-flex align-items-center">
                                                                                    <h3 className="me-2">{t('label_timings_pujas')}</h3>
                                                                                </div>
                                                                            </div>
                                                                            <div className="float-left w-100" ref={timingsRef}>
                                                                                <h4 className="w-100 mt-3 mb-2">{t('label_timings')}</h4>
                                                                            </div>

                                                                            {templeDetails.timings && templeDetails.timings.length > 0 ? (
                                                                                <table align="center" className="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{t('label_time')}</th>
                                                                                            <th>{t('label_puja')}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {formatTabularText(templeDetails.timings).map((timing, index) => (
                                                                                            <tr key={index}>
                                                                                                <td align="left" className="gridbg1">{timing.time}</td>
                                                                                                <td align="left" className="gridbg2">{timing.puja}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            ) : (
                                                                                <div className="no-data-message">{t('label_no_timings_available')}</div>
                                                                            )}

                                                                            <div className="tiles-designs tiles-design-divider" ref={pujasRef}>
                                                                                <h4 className="w-100 mt-3 mb-2">{t('label_daily_pujas')}</h4>
                                                                            </div>

                                                                            {templeDetails.dailypujas && templeDetails.dailypujas.length > 0 ? (
                                                                                <table align="center" className="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{t('label_time')}</th>
                                                                                            <th>{t('label_puja')}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {formatTabularText(templeDetails.dailypujas).map((dlyPuja, index) => (
                                                                                            <tr key={index}>
                                                                                                <td align="left" className="gridbg1">{dlyPuja.time}</td>
                                                                                                <td align="left" className="gridbg2">{dlyPuja.puja}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            ) : (
                                                                                <div className="no-data-message">{t('label_no_puja_timing')}</div>
                                                                            )}

                                                                            <div className="tiles-designs tiles-design-divider">
                                                                                <h4 className="w-100 mt-3 mb-2">{t('label_special_pujas')}</h4>
                                                                            </div>

                                                                            {templeDetails.splpujas && templeDetails.splpujas.length > 0 ? (
                                                                                <table align="center" className="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{t('label_time')}</th>
                                                                                            <th>{t('label_puja')}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {formatTabularText(templeDetails.splpujas).map((splPuja, index) => (
                                                                                            <tr key={index}>
                                                                                                <td align="left" className="gridbg1">{splPuja.time}</td>
                                                                                                <td align="left" className="gridbg2">{splPuja.puja}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            ) : (
                                                                                <div className="no-data-message">{t('label_no_special_pujas')}</div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>

                                                                {/* <div class="float-left w-100 mt-4" ><h5 class="card-text-title">{t('label_more_details')}
                                                                    
                                                                </h5>
                                                                </div> */}
                                                                <div className="active-details">
                                                                    <div className="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="300">
                                                                        <i className="icon fa-solid fa-hands-praying"></i>

                                                                        <div className="w-100 d-flex align-items-center">
                                                                            <h3 className="me-2">{t('label_more_details')}</h3>
                                                                        </div>
                                                                    </div>
                                                                    {templeDetails.festivals ? (
                                                                        <div className="tiles-designs">
                                                                            <Form.Item
                                                                                label={<Typography.Title level={4}>{t('label_festival_details')}</Typography.Title>}
                                                                                name="festivals"
                                                                            >
                                                                                {templeDetails.festivals && (
                                                                                    <Typography.Text style={{ whiteSpace: "pre-wrap" }}>{formatBoldText(templeDetails.festivals)}</Typography.Text>
                                                                                )
                                                                                }
                                                                            </Form.Item>
                                                                        </div>
                                                                    ) : null}
                                                                    <div className="tiles-designs tiles-design-divider">
                                                                        <h4 className="w-100 mt-3">{t('label_how_to_reach')}</h4>
                                                                    </div>
                                                                    {templeDetails.howtoreach ? (
                                                                        <div className="tiles-designs">
                                                                            <Form.Item
                                                                                // label={<Typography.Title level={4}>{t('label_how_to_reach')}</Typography.Title>}
                                                                                name="howtoreach">
                                                                                <Typography.Text style={{ whiteSpace: "pre-wrap" }}>{formatBoldText(templeDetails.howtoreach)}</Typography.Text>
                                                                            </Form.Item>
                                                                        </div>
                                                                    ) : null}
                                                                    {templeDetails.accomodation ? (
                                                                        <>
                                                                            <div className="tiles-designs tiles-design-divider">
                                                                                <h4 className="w-100 mt-3">{t('label_accommodation')}</h4>
                                                                            </div>
                                                                            <div className="tiles-designs">
                                                                                <Form.Item
                                                                                    // label={<Typography.Title level={4}>{t('label_accommodation')}</Typography.Title>}
                                                                                    name="accomodation">
                                                                                    <Typography.Text style={{ whiteSpace: "pre-wrap" }}>{formatBoldText(templeDetails.accomodation)}</Typography.Text>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </>
                                                                    ) : null}

                                                                    {templeDetails.architecture ? (
                                                                        <>
                                                                            <div className="tiles-designs tiles-design-divider">
                                                                                <h4 className="w-100 mt-3">{t('label_architecture')}</h4>
                                                                            </div>
                                                                            <div className="tiles-designs">
                                                                                <Form.Item
                                                                                    // label={<Typography.Title level={4}>{t('label_architecture')}</Typography.Title>}
                                                                                    name="architecture">
                                                                                    <Typography.Text style={{ whiteSpace: "pre-wrap" }}>{formatBoldText(templeDetails.architecture)}</Typography.Text>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                    {templeDetails.addtl_info ? (
                                                                        <>
                                                                            <div className="tiles-designs tiles-design-divider">
                                                                                <h4 className="w-100 mt-3">{t('label_addtl_info')}</h4>
                                                                            </div>

                                                                            <div className="tiles-designs">
                                                                                <Form.Item
                                                                                    // label={<Typography.Title level={4}>{t('label_addtl_info')}</Typography.Title>}
                                                                                    name="addtl_info">
                                                                                    <Typography.Text style={{ whiteSpace: "pre-wrap" }}>{formatBoldText(templeDetails.addtl_info)}</Typography.Text>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </Form>



                                                        {/* <AdBlock /> */}

                                                        <div class="float-left w-100 d-none" ref={galleryRef}><h5 class="card-text-title">{t('label_galary')}
                                                            {/* <span>| {t('label_gallery_head')}</span> */}
                                                        </h5>
                                                        </div>
                                                        <div class="active-details d-none" >
                                                            <div class="tiles-designs" >
                                                                {token ? (
                                                                    <div class="float-left w-100 d-block" text="Add Image" >
                                                                        {previewImage ? (
                                                                            <img src={previewImage} alt="Temple Image" />
                                                                        ) : (
                                                                            <>
                                                                                {isMobile && (
                                                                                    <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                                                                        {t('label_camera')}
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <button className="button_move" onClick={handleGalleryClick} style={{ marginLeft: '8px', marginBottom: '8px' }}>{t('label_gallery')}
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div class="float-left w-100 d-block" text="Add Image" >
                                                                        {previewImage ? (
                                                                            <img src={previewImage} alt="Devaalaya Image" />
                                                                        ) : (
                                                                            <>
                                                                                {isMobile && (
                                                                                    <button className="button_move float-left" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                                                                        {t('label_camera')}
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <button className="button_move float-left" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>


                                                                    </div>)}

                                                                <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }} onClick={handleFilterGodCancel}>
                                                                    <div class="popup-main animate__animated animate__fadeInUp ">
                                                                        <div class="popup-main-header">
                                                                            <span class="popup-main-header-text">{t('label_crop_image')}</span>
                                                                            <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                                            <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                                        </div>
                                                                        <div class="popup-main-container">
                                                                            <div class="d-block htinner-popup-main-container">
                                                                                {/* <span className="close" onClick={handleImageCloseModal}>&times;</span> */}
                                                                                {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                                                            </div>
                                                                        </div>
                                                                        <div class="popup-main-footer">
                                                                            <button class="button_move" onClick={handleCropAndUpload}>{t('label_crop_upload')}</button>

                                                                        </div>
                                                                        {loading && <Error />}
                                                                        {hasError && <div className="error-message">Upload failed. Please try again.</div>}
                                                                    </div>
                                                                </div>


                                                                <div className='detail-images' style={{ marginTop: '2px', clear: 'both' }}>
                                                                    {/* Div for images without AD and AS */}
                                                                    {templeDetails && templeDetails.images && templeDetails.images.length > 0 ? (
                                                                        <div className="tiles-grid">
                                                                            <Image.PreviewGroup allowClose={true} maskClosable={true} mask={false}>
                                                                                {templeDetails.images.map((image, index) => (
                                                                                    <div key={index}>
                                                                                        <div className="detail-container detail-container-image">
                                                                                            <Image
                                                                                                className="card-img"
                                                                                                src={getMediumImageAPI() + image.imgpath}
                                                                                                alt="images"
                                                                                                preview={{
                                                                                                    mask: (
                                                                                                        <div className="customize-close-icon" style={closeIconStyle}>
                                                                                                            {t('label_see_them')}
                                                                                                        </div>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                <div className="detail-container detail-container-image">
                                                                                    {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                                                    {newImagePath && editable ? (
                                                                                        <CloseCircleOutlined
                                                                                            className="close-icon"
                                                                                            onClick={onFinish}
                                                                                        />
                                                                                    ) : null}
                                                                                </div>
                                                                            </Image.PreviewGroup>
                                                                        </div>
                                                                    ) : <div className="detail-container detail-container-image">
                                                                        <img
                                                                            className="card-img"
                                                                            alt="temples"
                                                                            src={deftemples}
                                                                            style={{
                                                                                width: '20%',
                                                                                height: '20%',
                                                                                marginBottom: '2px',
                                                                            }}
                                                                        />
                                                                    </div>}

                                                                    {/* Div for images with AD and AS */}
                                                                    {userid === 'AD' || userid === 'AS' ? (
                                                                        templePreviewImg && templePreviewImg.images && templePreviewImg.images.length > 0 ? (
                                                                            <div className="tiles-grid">
                                                                                <h5 className='card-text-title'>Inactive Images</h5>
                                                                                <Image.PreviewGroup allowClose={true} maskClosable={true} mask={false}>
                                                                                    {templePreviewImg.images.map((image, index) => (
                                                                                        <div key={index}>
                                                                                            <div className="detail-container detail-container-image">
                                                                                                <Image
                                                                                                    className="card-img"
                                                                                                    src={getMediumImageAPI() + image.imgpath}
                                                                                                    alt="images"
                                                                                                    preview={{
                                                                                                        mask: (
                                                                                                            <div className="customize-close-icon" style={closeIconStyle}>
                                                                                                                {t('label_see_them')}
                                                                                                            </div>
                                                                                                        ),
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </Image.PreviewGroup>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="detail-container detail-container-image">
                                                                                <h5 className='card-text-title'>Inactive Images</h5>
                                                                                <img
                                                                                    className="card-img"
                                                                                    alt="temples"
                                                                                    src={deftemples}
                                                                                    style={{
                                                                                        width: '20%',
                                                                                        height: '20%',
                                                                                        marginBottom: '2px',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    ) : null}
                                                                </div>


                                                            </div>
                                                        </div>




                                                        <div class="float-left w-100 d-none" ref={libraryRef}>
                                                            {editable ? null : (
                                                                <>
                                                                    <h5 className="card-text-title">{t('label_library')}
                                                                        {/* <span>| {t('label_lib_head')}</span> */}
                                                                    </h5>

                                                                </>
                                                            )}
                                                        </div>





                                                        <div class="popup-bg" style={{ display: isDescrModalVisible ? 'flex' : 'none' }} onClick={handleFilterGodCancel}>
                                                            <div class="popup-main animate__animated animate__fadeInUp ">
                                                                <div class="popup-main-header">
                                                                    <span class="popup-main-header-text"> Description</span>
                                                                    <div class="popup-close" onClick={handleDescrCancel}><i class="fa-solid fa-xmark"></i></div>
                                                                    <div class="popup-back" onClick={handleDescrCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                                                </div>
                                                                <div class="popup-main-container">
                                                                    <div class="d-block htinner-popup-main-container">
                                                                        <Form form={form}
                                                                            labelCol={{ span: 24 }}
                                                                            wrapperCol={{ span: 24 }}

                                                                        >
                                                                            <Form.Item
                                                                                name="descr"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <TextArea


                                                                                    onChange={handleDescr}
                                                                                    placeholder={t("error_msg_01")}

                                                                                />
                                                                            </Form.Item>


                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                                <div class="popup-main-footer">
                                                                    <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                                                        {t("label_reset")}
                                                                    </a>
                                                                    <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateDescr(); setIsDescrModalVisible(false); }}>
                                                                        {t("label_save")}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* {editable ? null : (
                                            <div className="active-details">
                                                <TempleBooks />
                                            </div>
                                        )}
                                        <div class="float-left w-100" ref={notificationsRef}>
                                            {editable ? null : (
                                                <>
                                                    <h5 className="card-text-title">{t('label_notif')}
                                                       
                                                    </h5>

                                                </>
                                            )}
                                        </div>
                                        <div className="active-details">
                                            {editable ? null : (
                                                <TempleNotification />
                                            )}
                                        </div>


                                        <div className="mt-3 float-left w-100">
                                            {editable ? null : (
                                                <h5 className="card-text-title">{t('label_near_by_temples')}
                                                   
                                                </h5>
                                            )}
                                        </div>
                                        <div class="active-details">

                                            <div className="search">
                                                {editable ? null : (
                                                    <>
                                                        {nLoading || nDetails === null ? (
                                                            <Error />
                                                        ) : nDetails.length > 0 ? (
                                                            <div className="tiles-main">
                                                                {nDetails
                                                                    .filter((temple) => {
                                                                        const currentId = parseInt(window.location.pathname.split('/').pop(), 10);
                                                                        return temple.tid !== currentId;
                                                                    })
                                                                    .map((temple) => {
                                                                        const currentPath = window.location.pathname;
                                                                        return (
                                                                            <TempleCard
                                                                                key={temple.tid}
                                                                                id={temple.tid}
                                                                                imgpath={temple.imgpath}
                                                                                title={temple.tname}
                                                                                addr1={temple.addr1}
                                                                                addr2={temple.addr2}
                                                                                city={temple.lang_city}
                                                                                state={temple.st}
                                                                                temple={temple}
                                                                                favList={favList}
                                                                                toggleFavorite={toggleFavorite}
                                                                                toggleFilled={toggleFilled}
                                                                                tList={nDetails}
                                                                                latit={temple.latit}
                                                                                longi={temple.longi}
                                                                                link={`/templeDetails/${temple.tid}?prevPage=${encodeURIComponent(currentPath)}`}
                                                                            />
                                                                        );
                                                                    })}
                                                            </div>
                                                        ) : (
                                                            <h4>No Temples Found</h4>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            {userid === 'AD' && (
                                                <Link to={`/details/${templeDetails.tid}`} className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginTop: '5px', marginRight: '3px' }}>Blog</Link>
                                            )}
                                        </div> */}
                                                    </div>

                                            }
                                            <FloatButton.BackTop />

                                        </div>


                                        // </div>
                                    )}
                                    <div class="col-md-12 mb-3">
                                        <div className="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="300">
                                            <i className="icon fa-regular fa-bell"></i>
                                            <div className="w-100 d-flex align-items-center">
                                                <h3 className="me-2">{t('label_notif')}</h3>
                                                <i
                                                    className="fa-solid fa-up-right-from-square fa-lg"
                                                    onClick={handleIconClick}
                                                    style={{ cursor: 'pointer' }}
                                                ></i>
                                            </div>
                                        </div>
                                        <TempleNotification />
                                    </div>
                                    <div class="col-md-12 mb-3" >
                                        <div class="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="200">

                                            <i class="icon fa-solid fa-gopuram"></i>
                                            <div class="w-100">
                                                <h3>{t('label_xplre_temps')}</h3>
                                                {/* <a class=" btn-shine fa-lg" href="/templeCtgry">{t('label_view_all')}</a> */}
                                            </div>
                                        </div>
                                        <div class="position-relative space-1 space-lg-1 bg-soft-primary">
                                            <div class="container space-0 mt-2">
                                                {/* <div class="align-items-end  d-flex justify-content-between mb-md-7 mx-md-auto pb-3 mt-2">
                                                <h3 class="font-weight-bold">{t('label_xplre_temps')}</h3>  <span>
                                                    <a class=" btn-shine" href="/templeCtgry">{t('label_view_all')}</a></span>
                                            </div> */}
                                                <div class="tab-content" id="houseHeroTabContent">
                                                    <div class="tab-pane show active" id="for-sale" role="tabpanel" aria-labelledby="for-sale-tab">


                                                        <div class="row mx-n2">

                                                            <div class="col-lg-2 col-md-5 col-6 px-2 mb-3 p-0">
                                                                <div class="overflow-hidden rounded-lg-pseudo">
                                                                    <a
                                                                        href="/remedytemples"
                                                                        className="card shadow-none min-h-270rem bg-img-hero gradient-y-overlay-lg-dark transition-zoom-hover"
                                                                        style={{ backgroundImage: `url(${remedytemple1})` }}
                                                                    >
                                                                        <div className="position-absolute top-0 left-0 p-4">
                                                                            <span className="badge badge-light">{t('label_77_temps')}</span>
                                                                        </div>
                                                                        <div className="position-absolute right-0 bottom-0 left-0 p-4">
                                                                            <p className="text-white mb-0 font-weight-light">{t('label_remedy')}</p>
                                                                        </div>
                                                                    </a>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-2 col-md-5 col-6 px-2 mb-3 p-0">
                                                                <div class="overflow-hidden rounded-lg-pseudo">
                                                                    <a
                                                                        href='/templeGroup/102'
                                                                        class="card shadow-none min-h-270rem bg-img-hero gradient-y-overlay-lg-dark transition-zoom-hover"

                                                                        style={{ backgroundImage: `url(${swayambhoTemp1})` }}>
                                                                        <div class="position-absolute top-0 left-0 p-4">
                                                                            <span class="badge badge-light">{t('label_28_temps')}</span>
                                                                        </div>
                                                                        <div class="position-absolute right-0 bottom-0 left-0 p-4">
                                                                            <p class="text-white mb-0 font-weight-light">{t('label_swayambho_temps')}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-5 col-6 px-2 mb-3">
                                                                <div class="overflow-hidden rounded-lg-pseudo">

                                                                    <a
                                                                        href='/templeGroup/105'
                                                                        class="card shadow-none min-h-270rem bg-img-hero gradient-y-overlay-lg-dark transition-zoom-hover"
                                                                        style={{ backgroundImage: `url(${ancienttemp1})` }}>


                                                                        <div class="position-absolute top-0 left-0 p-4">
                                                                            <span class="badge badge-light">{t('label_20_temps')}</span>
                                                                        </div>
                                                                        <div class="position-absolute right-0 bottom-0 left-0 p-4">
                                                                            <p class="text-white mb-0 font-weight-light">{t('label_ancnt_temps')}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-5 col-6 px-2 mb-3">
                                                                <div class="overflow-hidden rounded-lg-pseudo">
                                                                    <a
                                                                        href='/templeGroup/101'
                                                                        class="card shadow-none min-h-270rem bg-img-hero gradient-y-overlay-lg-dark transition-zoom-hover"

                                                                        style={{ backgroundImage: `url(${diyadeesampic1})` }}>
                                                                        <div class="position-absolute top-0 left-0 p-4">
                                                                            <span class="badge badge-light">{t('label_10_temps')}</span>
                                                                        </div>
                                                                        <div class="position-absolute right-0 bottom-0 left-0 p-4">
                                                                            <p class="text-white mb-0 font-weight-light">{t('label_div_desam')}</p>

                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-5 col-6 px-2 mb-3">
                                                                <div class="overflow-hidden rounded-lg-pseudo">
                                                                    <a
                                                                        href='/templeGroup/102'
                                                                        class="card shadow-none min-h-270rem bg-img-hero gradient-y-overlay-lg-dark transition-zoom-hover"

                                                                        style={{ backgroundImage: `url(${shakthipeetalu})` }}>
                                                                        <div class="position-absolute top-0 left-0 p-4">
                                                                            <span class="badge badge-light">{t('label_32_temps')}</span>
                                                                        </div>
                                                                        <div class="position-absolute right-0 bottom-0 left-0 p-4">
                                                                            <p class="text-white mb-0 font-weight-light">{t('label_shakti_peethas')}</p>

                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-5 col-6 px-2 mb-3">
                                                                <div class="overflow-hidden rounded-lg-pseudo">
                                                                    <a
                                                                        href='/templeGroup/103'
                                                                        class="card shadow-none min-h-270rem bg-img-hero gradient-y-overlay-lg-dark transition-zoom-hover"

                                                                        style={{ backgroundImage: `url(${jyothirligaalu})` }}>
                                                                        <div class="position-absolute top-0 left-0 p-4">
                                                                            <span class="badge badge-light">{t('label_24_temps')}</span>
                                                                        </div>
                                                                        <div class="position-absolute right-0 bottom-0 left-0 p-4">
                                                                            <p class="text-white mb-0 font-weight-light">{t('label_jyo')}</p>

                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mb-3" >
                                        <div class="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="200">

                                            <i class="icon fa-solid fa-book-open"></i>
                                            <div class="w-100">
                                                <h3>{t('label_library')}</h3>

                                            </div>
                                        </div>
                                        <TempleBooks />
                                    </div>


                                    <div class="col-md-12 mb-3 details-nearbytemples" >
                                        <div class="info-item d-flex align-items-center aos-init aos-animate mt-4" data-aos="fade-up" data-aos-delay="200">

                                            <i class="icon fa-solid fa-road"></i>
                                            <div class="w-100">
                                                <h3>{t('label_near_by_temples')}</h3>

                                            </div>
                                        </div>

                                        <>
                                            {nLoading || nDetails === null ? (
                                                <Error />
                                            ) : nDetails.length > 0 ? (
                                                <div className="tiles-main">
                                                    {nDetails
                                                        .filter((temple) => {
                                                            const currentId = parseInt(window.location.pathname.split('/').pop(), 10);
                                                            return temple.tid !== currentId;
                                                        })
                                                        .slice(0, 10)
                                                        .map((temple) => {
                                                            const currentPath = window.location.pathname;
                                                            return (
                                                                <TempleCard
                                                                    key={temple.tid}
                                                                    id={temple.tid}
                                                                    imgpath={temple.imgpath}
                                                                    title={temple.tname}
                                                                    addr1={temple.addr1}
                                                                    addr2={temple.addr2}
                                                                    city={temple.lang_city}
                                                                    state={temple.st}
                                                                    temple={temple}
                                                                    favList={favList}
                                                                    toggleFavorite={toggleFavorite}
                                                                    toggleFilled={toggleFilled}
                                                                    tList={nDetails}
                                                                    latit={temple.latit}
                                                                    longi={temple.longi}
                                                                    link={`/templeDetails/${temple.tid}?prevPage=${encodeURIComponent(currentPath)}`}
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            ) : (
                                                <h4>No Temples Found</h4>
                                            )}
                                        </>
                                    </div>








                                </div>
                            </div>

                            <div class="container d-none">

                                <div class="row gy-4">

                                    <div class="col-xl-3 col-md-6 d-flex aos-init aos-animate" onClick={() => handleShowPopup('dailypujas')} data-aos="fade-up" data-aos-delay="100">
                                        <div class="service-item position-relative">
                                            <aside class="temple_icon_details_bottom">
                                                <i class="fa-solid fa-hands-praying "></i>
                                            </aside>
                                            <h4><a class="stretched-link">Puja</a></h4>
                                            <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 d-flex aos-init aos-animate" onClick={() => handleShowPopup('timings')} data-aos="fade-up" data-aos-delay="200">
                                        <div class="service-item position-relative">
                                            <aside class="temple_icon_details_bottom">
                                                <i class="fa-solid fa-om"></i>
                                            </aside>
                                            <h4><a class="stretched-link">Darshanam</a></h4>
                                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                        <div class="service-item position-relative">
                                            <aside class="temple_icon_details_bottom">
                                                <i class="fa-solid fa-ticket btn-c9"></i>
                                            </aside>
                                            <h4><a href={`/templeService/${tid}/${templeDetails.tname}`} class="stretched-link">Bookings</a></h4>
                                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
                                        </div>
                                    </div>
                                    < div class="col-xl-3 col-md-6 d-flex aos-init aos-animate" onClick={() => handleShowPopup('festivals')} data-aos="fade-up" data-aos-delay="400">
                                        <div class="service-item position-relative" >
                                            <aside class="temple_icon_details_bottom">
                                                <i class="fa-solid fa-calendar-week"></i>
                                            </aside>
                                            <h4>Events</h4>
                                            <p >At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
                                        </div>
                                    </div>

                                </div>

                            </div>




                        </div>
                    </div>
                    {/* <Footer /> */}

                    <Footer />
                </div>

            </div>


        </>
    )
}

export default TemplesDetails;
