import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Footer from './footer';
import { useDispatch } from 'react-redux';
import { postPriestReq } from '../../../../redux/actions/acions';
import showToast from '../../../../utils/showToast';



const { TextArea } = Input;

const ContactUs = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Load stored name from localStorage on component mount
  useEffect(() => {
    const storedName = localStorage.getItem('name');
    if (storedName) {
      form.setFieldsValue({ name: storedName });
    }
  }, [form]);

  const success = () => {
    showToast('success', t(`label_contactus_success`));
    form.resetFields();
  };

  const failure = (msg) => {
    showToast('error', msg);
  };

  const onFinish = () => {
    form.validateFields()
        .then(() => {
            console.log("Form Validation Passed");

            const values = form.getFieldsValue();

            // Save name to localStorage
            localStorage.setItem('name', values.name);

            const data = {
                pid: 0,
                name: values.name,
                ctgry: values.email,
                reqtype: values.subject,
                query: values.message,
            };

            console.log("Submitting Data:", data);

            dispatch(postPriestReq(data, success, failure));
        })
        .catch((errorInfo) => {
            if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
                const missingFields = errorInfo.errorFields
                    .map(field => field.name.join('.'))
                    .join(', ');

                // Display error message for missing fields
                message.error(`Please fill the required fields: ${missingFields}`);
            }
            console.error("Validation Failed:", errorInfo);
        });
};


  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='mainsec'>
      <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
        <span className="d-flex align-items-center">
          <Link style={{ color: 'black' }} to="/">
            <span className="bc-active-link" onClick={handleGoBack}>{t('Home')}</span>
          </Link>
          <span className="ml-3 mr-3">
            <i className="fa-solid fa-angle-right f-9"></i>
          </span>
        </span>
        <span className="bc-link">{t("label_get_in_touch")}</span>
      </div>
      <div className="bg-details-highlight">
        <div className="details-headertxt-singleLine container">
          <div className="header_txt">{t("label_get_in_touch")}</div>
        </div>
      </div>
      <div className="mainsec-block-addtemple-detailspage">
        <div className='container'>
          <div className='horoscope-item'>
            <div className="container">
              <h5 className="card-text-title">{t('label_get_in_touch')}</h5>
            </div>

            <div className='container'>
              <Form
                form={form}
                name="contact_us"
                onFinish={onFinish}
                layout="vertical"
                className="active-details"
              >
                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t("label_name")}</Typography.Title>}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('label_enter_name'),
                      },
                    ]}
                  >
                    <Input placeholder={t("label_your_name")} />
                  </Form.Item>
                </div>
                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t("label_email")}</Typography.Title>}
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: t('label_enter_email'),
                      },
                    ]}
                  >
                    <Input placeholder={t("label_your_email")} />
                  </Form.Item>
                </div>
                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t("Subject")}</Typography.Title>}
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: t('label_enter_subject'),
                      },
                    ]}
                  >
                    <Input placeholder={t("label_your_subject")} />
                  </Form.Item>
                </div>
                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t("Message")}</Typography.Title>}
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: t('label_enter_msg'),
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder={t("label_your_msg")} />
                  </Form.Item>
                </div>
                <div className="tiles-designs">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t("label_submit")}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

