import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Button, Row, Col, Checkbox, Typography, Modal, message, Tour, Popconfirm } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import '../Profile/profile.css';
import ProfilePassword from './profilePassword';
import ProfileLocation from './ProfileLocation';
import { getProfileImageError, getProfileImageList, getProfileImageLoading, getStateError, getStateList, getStateLoading, getUserRolesList, getUserRolesLoading, getUserRolesError } from '../../../../redux/selectors/selectors';
import { deleteMyProfile, editGender, editLang, editName, fetchProfileImage, fetchState, postAddTempleAdmin, postProfileImageRequest, fetchMultiRoles, editNakshtra } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import showToast from '../../../../utils/showToast';
import Cropper from 'cropperjs';
import { getServerImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg';
import AP_LANGUAGES from '../../../common/appLangs';

const { Option } = Select;
const Profile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem('urole');
  const userid = localStorage.getItem('uid');
  const storedlng = Number(localStorage.getItem('lng'));
  const userloggedtime = Boolean(localStorage.getItem('logintime'));
  const displayName = storedlng == 5 ? sDetails && sDetails.fullname : sDetails && sDetails.lang_fullname;
  const [messageApi, contextHolder] = message.useMessage();
  const [userRoles, setUserRoles] = useState('');
  const [fullname, setFullname] = useState(null);
  const [nakshtra, setNakshtra] = useState(null);
  const [newImagePath, setNewImagePath] = useState('');
  const [tourVisible, setTourVisible] = useState(false);
  const editButtonRef = useRef(null);
  const detailsSectionRef = useRef(null);
  const passwordSectionRef = useRef(null);

  const tourSteps = userloggedtime
    ? []
    : [
      {
        title: 'Welcome to Your Profile Page',
        description: 'This is your Profile Page where you can manage your personal information.',
        target: null,
      },
      {
        title: t('edit'),
        description: 'Here you can edit your profile information.',
        target: () => editButtonRef.current,
      },
      {
        title: t('label_details'),
        description: 'Here you can view your profile details.',
        target: () => detailsSectionRef.current,
      },
    ];

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    if (!hasSeenTour) {
      setTourVisible(true); // Show the tour only if the user hasn't seen it before
    }
  }, []);

  // Set the flag in localStorage after the tour is shown
  useEffect(() => {
    if (tourVisible) {
      localStorage.setItem('hasSeenTour', 'true');
    }
  }, [tourVisible]);

  const deletesuccess = () => {
    showToast('success', t('label_user_delete_success'))
    localStorage.clear()
    navigate("/")
  };

  const deletefailure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  const success = (msg) => {
    message.success(msg);
  };

  const namesuccess = () => {
    showToast('success', t('label_update_name_sucess'))
  };

  const nakshsuccess = () => {
    showToast('success', t('label_naks_success'))
  };

  const failure = (msg) => {
    showToast('failure', msg)
  };

  const switchsuccess = () => {
    showToast("success", t('label_success_role'));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };


  const switchfailure = (message) => {
    showToast("failure", t('label_failure_role'));
  };

  const langSuccess = () => {
    showToast('success', t('label_langupdt_success'));
  };

  const gendSuccess = () => {
    showToast('success', t('label_genderupdt_success'));
  };

  const handleSaveName = (e) => {
    setFullname(e.target.value);
  };

  const updateSaveName = () => {
    dispatch(editName({ fullname: fullname }, namesuccess, failure));
  };

  const handleNakshatraChange = (value) => {
    dispatch(
      editNakshtra({ nakshtra: `${value}` }, nakshsuccess, failure))
  };


  const deleteProfile = () => {
    dispatch(deleteMyProfile(deletesuccess, deletefailure));
  };

  const handleSelectChange = (value) => {
    dispatch(editGender({ gender: `${value}` }, gendSuccess, failure));
  };

  const handleSelect = (value) => {
    dispatch(
      editLang({ lang: `${value}` }, () => {
        localStorage.setItem('lng', value)
        langSuccess();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    );
  };

  const uid = localStorage.getItem('userRole');
  const { sDetails, sLoading, sError, iList, iLoading, iError, uDetails, uLoading, uError } = useSelector((state) => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state),
    iList: getProfileImageList(state),
    iLoading: getProfileImageLoading(state),
    iError: getProfileImageError(state),
    uDetails: getUserRolesList(state),
    uLoading: getUserRolesLoading(state),
    uError: getUserRolesError(state),
  }));

  const getLinkPath = (userRole) => {
    switch (userRole) {
      case 'TA':
      case 'TU':
        return '/temple-dashboard';
      case 'PU':
      case 'PL':
      case 'PR':
        return '/priest-profile';
      default:
        return '/';
    }
  };

  const getToken = Boolean(localStorage.getItem('token'));
  useEffect(() => {
    if (getToken) {
      dispatch(fetchState());
    }
  }, [dispatch, getToken]);

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    dispatch(fetchMultiRoles(role, switchsuccess, switchfailure));
  };

  const getRoleLabel = (role) => {
    switch (role.trim()) {
      case 'TA':
        return t('label_role_ta');
      case 'PL':
      case 'PR':
        return t('label_role_pl');
      case 'AD':
        return t('label_role_ad');
      case 'AS':
        return t('label_role_as');
      case 'TU':
        return t('label_role_tu');
      case 'SU':
        return t('label_role_su');
      default:
        return t('label_notchosen');
    }
  };

  const [fetchedImage, setFetchedImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await dispatch(fetchProfileImage('images_2024_12_00/Img_2DT19__05_06_32_blob.jpg'));

        if (response) {
          const blob = new Blob([response], { type: 'image/jpg' });
          const url = URL.createObjectURL(blob);
          setFetchedImage(url);
        }
      } catch (error) {
        console.error('Failed to fetch profile image:', error);
      }
    };

    fetchImage();
  }, [dispatch]);

  useEffect(() => {
    if (sDetails && Object.keys(sDetails).length > 0 && form) {
      const { lang, ...rest } = sDetails; // Exclude lang from being set
      form.setFieldsValue(rest);
    }
  }, [sDetails, form]);

  useEffect(() => {
    if (sDetails && sDetails.lang) {
      const defaultLanguage = sDetails.lang.trim();
      const defaultLanguageObj = AP_LANGUAGES.find(lang => lang.value === defaultLanguage);

      if (defaultLanguageObj && form) {
        form.setFieldsValue({
          lang: defaultLanguageObj.value, // Ensures the correct value is selected in <Select>
        });
      }
    }
  }, [sDetails, form]);

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'));
  }, []);

  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [firstLetter, setFirstLetter] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const getName = localStorage.getItem('name');
    if (getToken && getName) {
      setToken(getToken);
      setName(getName);
    }
  }, []);

  React.useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      const firstChar = getName.charAt(0);
      setFirstLetter(firstChar);
    }
  }, []);

  useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      setName(getName);
    }

    if (userid) {
      const profileImageURL = localStorage.getItem(`profileImage_${userid}`);
      if (profileImageURL) {
        setUser(profileImageURL);
      } else {
        setUser(null);
      }
    }
  }, [userid]);

  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const [formData, setFormData] = useState({});
  const [editable, setEditable] = useState(false);
  const [buttonText, setButtonText] = useState(t('edit'));
  const [selectedRole, setSelectedRole] = useState(sDetails && sDetails.urole || "");

  const handleCancelClick = () => {
    setButtonText(t('edit'));
    setEditable(false);
    window.location.reload();
  };

  const handleEditClick = () => {
    if (!editable) {
      setButtonText(t('label_cancel'));
      setFormData({ ...formData, deityname: sDetails && sDetails.deityname });
      setFormData({ ...formData, otherdeities: sDetails && sDetails.otherdeities });
    } else {
      setButtonText(t('edit'));
    }
    setEditable(!editable);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPremiumVisible, setIsPremiumModalVisible] = useState(false);

  const showPremiumModal = () => {
    setIsPremiumModalVisible(true);
  };

  const handlePremiumOk = () => {
    setIsPremiumModalVisible(false);
  };

  const handlePremiumCancel = () => {
    setIsPremiumModalVisible(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    dispatch(postAddTempleAdmin(userRoles), () => success('Gender Updated Successfully'));
  };

  const id = localStorage.getItem('uid');
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleGalleryClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
          setIsModalVisible(true);
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    fileInput.click();
  };

  const imageRef = useRef();
  const [cropper, setCropper] = useState(null);
  const handleImageCloseModal = () => {
    setIsModalVisible(false);
    if (cropper) {
      cropper.destroy();
    }
  };

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleCropAndUpload = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob);
        setLoading(true);
        setHasError(false);
        dispatch(
          postProfileImageRequest(
            formData,
            () => {
              const uploadedImagePath = URL.createObjectURL(blob);
              setNewImagePath(uploadedImagePath);
              handleImageCloseModal();
              setLoading(false);
              if (getToken) {
                dispatch(fetchState());
              }
            },
            (errorMsg) => {
              console.error('Image upload failed:', errorMsg);
              setLoading(false);
              setHasError(true);
            }
          )
        );
      });
    }
  };

  useEffect(() => {
    if (isModalVisible && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 16 / 9,
        viewMode: 1,
        autoCropArea: 1,
        responsive: true,
        scalable: false,
        zoomable: true,
        cropBoxResizable: true,
        minCropBoxHeight: 200,
        maxCropBoxHeight: 200,
        minCropBoxWidth: imageRef.current.clientWidth,
        maxCropBoxWidth: imageRef.current.clientWidth,
      });
      setCropper(cropperInstance);
    }

    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [isModalVisible]);

  const cachedFavourites = JSON.parse(localStorage.getItem('favourites')) || [];
  const recentlyviewed = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];

  return (
    <div className="mainsec">
      <Tour open={tourVisible} onClose={() => setTourVisible(false)} steps={tourSteps} />
      <div className="mob-nav fluid-container page-title justify-content-between">
        <div className="d-flex align-items-center left-sec-mob-nav">
          <Link onClick={handleGoBack}>
            <i className="fa-solid fa-angle-left"></i>
          </Link>
          <span className="bc-link">{t('label_profile')}</span>
        </div>
        <div></div>
      </div>
      <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
        <span className="d-flex align-items-center">
          <Link style={{ color: 'black' }} to="/">
            <span className="bc-active-link" onClick={handleGoBack}>{t('Home')}</span>
          </Link>
          <span className="ml-3 mr-3">
            <i className="fa-solid fa-angle-right f-9"></i>
          </span>
        </span>
        <span className="bc-link">{t("label_profile")}</span>
      </div>
      <div className="bg-details-highlight">
        <div className="details-headertxt-singleLine container">
          <div className="header_txt">
            {sDetails && (Number(localStorage.getItem('lng')) === 5 ? sDetails.fullname : sDetails.lang_fullname)}
          </div>
          <div className="details-headertxt-singleLine-btns">
            <a ref={editButtonRef} className="button_move ml-2" onClick={editable ? handleCancelClick : handleEditClick}>
              {editable ? t('label_cancel') : t('edit')}
            </a>
            {userRole === 'PU' || userRole === 'PL' || userRole === 'PR' ? (
              <>
                {/* <Link to={`/editpriestDetails/${userid}/${sDetails && sDetails.fullname}`} > */}
                <Link to={`/priestDetails/${userid}/${sDetails && sDetails.fullname}/${sDetails && sDetails.city}/${sDetails && sDetails.st}`} >
                  <button type="button" className="button_move ml-2">
                    {t('label_edit_priest_profile')}
                  </button>
                </Link>
              </>
            ) : (
              ''
            )}
            {userRole === 'AD' || userRole === 'AS' ? (
              <>
                <button type="button" onClick={() => navigate('/quicklinks')} className="button_move ml-2">
                  {t('label_app_admin_links')}
                </button>
              </>
            ) : (
              ''
            )}
            {(userRole === 'TA' || userRole === 'TU') && (
              <>
               <button type="button" onClick={() => navigate('/templeDashboard')} className="button_move ml-2" >
                  {t('label_my_temple_dashboard')}
                </button>
                <button type="button" onClick={() => navigate('/templeadminlinks')} className="button_move ml-2" >
                  {t('label_templ_adm_links')}
                </button>
              </>           
            )}
          </div>
        </div>
      </div>
      <div className="mainsec-block-addtemple-detailspage">
        <div className="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: 'nowrap', marginTop: '5px' }}>
          <div className="popup-bg" style={{ display: isModalPremiumVisible ? 'flex' : 'none' }}>
            <div className="popup-main animate__animated animate__fadeInUp ">
              <div className="popup-main-header">
                <span className="popup-main-header-text">{t('Benefits of Becoming a Premium User')}</span>
                <div className="popup-close" onClick={handlePremiumCancel}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="popup-back" onClick={handlePremiumCancel}>
                  <i className="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div className="popup-main-container">
                <div className="d-block htinner-popup-main-container">
                  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <img className="grp-descr-img" src={deftemples} alt="Premium User" />
                  </div>
                  <div className="dialog-content cat-discription font_main ">
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>Benefit 1: Access to exclusive content.</li>
                      <li>Benefit 2: Priority customer support.</li>
                      <li>Benefit 3: Ad-free experience.</li>
                      <li>Benefit 4: Early access to new features.</li>
                      <li>Benefit 5: Discounts on products and services.</li>
                      <li>Benefit 6: Access to premium resources.</li>
                      <li>Benefit 7: Enhanced security features.</li>
                      <li>Benefit 8: Personalized recommendations.</li>
                      <li>Benefit 9: Higher storage limits.</li>
                      <li>Benefit 10: Invitations to exclusive events.</li>
                      <li>Benefit 11: Detailed analytics and reports.</li>
                      <li>Benefit 12: Customizable user interface.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="popup-main-footer">
                <a type="primary" className="button_move" onClick={handleCancel}>
                  Proceed
                </a>
              </div>
            </div>
          </div>
          {userRole === 'SU' && process.env.REACT_APP_VERSION === "V0.2" && (
            <a href={`/createNewPriest`} type="primary" style={{ marginLeft: '10px' }}>
              <button type="button" className="button_move">
                {t('label_build_my_priest')}
              </button>
            </a>
          )}
          <div className="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
            <div className="popup-main-filter animate__animated animate__fadeInUp ">
              <div className="popup-main-header">
                <span className="popup-main-header-text">Main header</span>
                <div className="popup-close" onClick={handleImageCloseModal}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="popup-back" onClick={handleImageCloseModal}>
                  <i className="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div className="popup-main-container">
                <div className="d-block htinner-popup-main-container">
                  {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                </div>
              </div>
              <div className="popup-main-footer">
                <button className="button_move" onClick={handleCropAndUpload}>
                  Crop and Upload
                </button>
              </div>
            </div>
          </div>
          <div className="popup-bg" style={{ display: isModalOpen ? 'flex' : 'none' }}>
            <div className="popup-main animate__animated animate__fadeInUp ">
              <div className="popup-main-header">
                <span className="popup-main-header-text">{t('label_add_temple_admin')}</span>
                <div className="popup-close" onClick={handleCancel}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="popup-back" onClick={handleCancel}>
                  <i className="fa-solid fa-chevron-left"></i>
                </div>
              </div>
              <div className="popup-main-container">
                <div className="d-block htinner-popup-main-container">
                  <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ padding: '10px 10px' }}>
                    <div style={{ padding: '10px 10px' }}>
                      <div className="tiles-designs">
                        <Form.Item label="User ID" name="userRole" rules={[{ required: true, message: 'Please enter userRole' }]}>
                          <Input value={userRole} onChange={(e) => setUserRoles(e.target.value)} />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="popup-main-footer">
                <a type="primary" className="button_move" onClick={handleCancel} style={{ marginRight: '2px' }}>
                  {' '}
                  {t('label_cancel')}
                </a>
                <a type="primary" className="button_move" onClick={handleModalSubmit}>
                  {t('label_save')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container">
            <div className="main-body">
              <div className="row gutters-sm">
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        <section>
                          <div className="user-pic">
                            {editable && (
                              <div className="upload-overlay">
                                <a onClick={handleGalleryClick} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                                  <i className="fa-solid fa-camera" style={{ fontSize: '20px' }}></i>
                                  <span>Change Profile Image</span>
                                </a>
                              </div>
                            )}
                            {sDetails && sDetails.profimg ? (
                              <img alt={sDetails.fullname || 'Default Name'} src={sDetails.profimg ? getServerImageAPI() + sDetails.profimg : deftemples} />
                            ) : previewImage ? (
                              <img src={previewImage} alt="Profile Image" />
                            ) : (
                              <img src={deftemples} alt="Default Profile" />
                            )}
                          </div>
                        </section>
                        <div className="mt-3">
                          <h4>{sDetails && sDetails.fullname}</h4>
                          <p className="text-secondary mb-1">
                            {selectedRole
                              ? (() => {
                                switch (selectedRole.trim()) {
                                  case 'TA':
                                    return t('label_role_ta');
                                  case 'PL':
                                    return t('label_role_pl');
                                  case 'PR':
                                    return t('label_role_pl');
                                  case 'AD':
                                    return t('label_role_ad');
                                  case 'AS':
                                    return t('label_role_as');
                                  case 'TU':
                                    return t('label_role_tu');
                                  case 'SU':
                                    return t('label_role_su');
                                  case 'PU':
                                    return t('label_role_pu');
                                  default:
                                    return t('label_notchosen');
                                }
                              })()
                              : t('label_notchosen')}
                          </p>

                          <p className="text-muted font-size-sm">
                            {`${t(
                              sDetails &&
                              (Number(localStorage.getItem('lng')) === 5 ? sDetails.city : sDetails.lang_city)
                            )}, ${t(sDetails && sDetails.st)}`}
                          </p>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <ul className="list-group list-group-flush">
                      <Link to="/searchFavourite" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                          <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>{t('page_title_fav')}</h6>
                          <span className="text-secondary">{cachedFavourites.length}</span>
                        </li>
                      </Link>
                      <Link to="/recentlyViewed" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                          <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="fa-solid fa-file-pen mr-2"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>{t('lable_recent_view')}</h6>
                          <span className="text-secondary">{recentlyviewed.length}</span>
                        </li>
                      </Link>
                      <Link to="/contribution" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                          <h6 className="mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit-2 mr-2 icon-inline">
                              <path d="M12 20h9"></path>
                              <path d="M14 4l7 7-11 11H3v-7L14 4z"></path>
                            </svg>
                            {t('label_my_contributions')}
                          </h6>
                          <span className="text-secondary">{recentlyviewed.length}</span>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card mb-3">
                    <div className="card-body">
                      <Form form={form} name="nest-messages" labelCol={{ span: 6 }} wrapperCol={{ span: 8 }} onFinish={handleSaveName}>
                        <div className="float-left w-100" >
                          <h5 className="card-text-title2">{t('label_details')}
                          </h5></div>
                        <div className="active-details" ref={detailsSectionRef}>
                          <div className="tiles-designs">
                            <Form.Item label={<Typography.Title level={4}>{t('label_userid')}</Typography.Title>} name="userid">
                              <Typography.Text level={5} className="typ">
                                {sDetails && sDetails.userid}
                              </Typography.Text>
                            </Form.Item>
                          </div>
                          <div className="tiles-designs">
                            <Form.Item label={<Typography.Title level={4}>{t('label_role')}</Typography.Title>} name="fullname">
                              <Typography.Text level={5} className="typ">
                                {selectedRole
                                  ? (() => {
                                    switch (selectedRole.trim()) {
                                      case 'TA':
                                        return t('label_role_ta');
                                      case 'PL':
                                        return t('label_role_pl');
                                      case 'PR':
                                        return t('label_role_pr');
                                      case 'AD':
                                        return t('label_role_ad');
                                      case 'AS':
                                        return t('label_role_as');
                                      case 'TU':
                                        return t('label_role_tu');
                                      case 'SU':
                                        return t('label_role_su');
                                      case 'PU':
                                        return t('label_role_pu');
                                      default:
                                        return t('label_notchosen');
                                    }
                                  })()
                                  : t('label_notchosen')}
                              </Typography.Text>
                            </Form.Item>
                          </div>
                          <div className="tiles-designs">
                            <Form.Item
                              label={
                                <Typography.Title level={4}>
                                  {t('label_fullname')}
                                </Typography.Title>
                              }
                              name="fullname"
                              rules={[{ required: editable, message: 'Please enter your full name' }]}
                            >
                              {editable ? (
                                <Input defaultValue={sDetails && sDetails.fullname} onChange={handleSaveName} onBlur={updateSaveName} />
                              ) : (
                                <Typography.Text level={5} className="typ">
                                  {Number(localStorage.getItem('lng')) === 5 ? sDetails && sDetails.fullname : sDetails && sDetails.lang_fullname}
                                </Typography.Text>
                              )}
                            </Form.Item>
                          </div>
                          <div className="tiles-designs">
                            <Form.Item
                              label={<Typography.Title level={4}>{t('label_gender')}</Typography.Title>}
                              name="gender"
                              rules={[{ required: editable, message: 'Please select your gender' }]}
                            >
                              {editable ? (
                                <Select onChange={handleSelectChange} style={{ width: '100%' }}>
                                  <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                  <Select.Option value="M">{t('label_male')}</Select.Option>
                                  <Select.Option value="F">{t('label_female')}</Select.Option>
                                  <Select.Option value="O">{t('label_other')}</Select.Option>
                                </Select>
                              ) : (
                                <Typography.Text level={5} className="typ">
                                  {sDetails
                                    ? (() => {
                                      switch (sDetails.gender) {
                                        case 'M':
                                          return t('label_male');
                                        case 'F':
                                          return t('label_female');
                                        case 'O':
                                          return t('label_other');
                                        default:
                                          return t('label_notchosen');
                                      }
                                    })()
                                    : t('label_notchosen')}
                                </Typography.Text>
                              )}
                            </Form.Item>
                          </div>
                          <div className="tiles-designs">
                            <Form.Item
                              label={<Typography.Title level={4}>{t('label_my_language')}</Typography.Title>}
                              name="lang"
                              rules={[{ required: editable, message: 'Please select your preferred language' }]}
                            >
                              {editable ? (
                                <Select onChange={handleSelect} style={{ width: '100%' }} defaultValue={sDetails
                                  ? (AP_LANGUAGES.find(lang => lang.value === sDetails.lang.trim()).label || t('label_notchosen'))
                                  : t('label_notchosen')}>
                                  <Select.Option value="">-- {t('label_pls_choose_option')} --</Select.Option>
                                  {AP_LANGUAGES.map((item) => (
                                    <Select.Option key={item.value} value={item.value}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              ) : (
                                <Typography.Text level={5} className="typ">
                                  {sDetails
                                    ? (AP_LANGUAGES.find(lang => lang.value === sDetails.lang.trim()).label || t('label_notchosen'))
                                    : t('label_notchosen')}
                                </Typography.Text>
                              )}
                            </Form.Item>
                          </div>
                          <div className="tiles-designs">
                            <Form.Item
                              label={<Typography.Title level={4}>{t('label_nak')}</Typography.Title>}
                              name="nakshatra"
                              rules={[{ required: editable, message: 'Please select your nakshatra' }]}
                            >
                              {editable ? (
                                <Select defaultValue={sDetails.nakshtra || undefined} onChange={handleNakshatraChange} style={{ width: '100%' }}>
                                  <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                  <Select.Option value="Mesha">{t('label_mesha')}</Select.Option>
                                  <Select.Option value="Vrushab">{t('label_vrushab')}</Select.Option>
                                  <Select.Option value="Midhuna">{t('label_midhuna')}</Select.Option>
                                  <Select.Option value="Karkataka">{t('label_karktaka')}</Select.Option>
                                  <Select.Option value="Simha">{t('label_simha')}</Select.Option>
                                  <Select.Option value="Kanya">{t('label_kanya')}</Select.Option>
                                  <Select.Option value="Thula">{t('label_thula')}</Select.Option>
                                  <Select.Option value="Vruchika">{t('label_vruchika')}</Select.Option>
                                  <Select.Option value="Dhanusu">{t('label_dhanassu')}</Select.Option>
                                  <Select.Option value="Makara">{t('label_makara')}</Select.Option>
                                  <Select.Option value="Kumbha">{t('label_kumbha')}</Select.Option>
                                  <Select.Option value="Meena">{t('label_meena')}</Select.Option>
                                </Select>
                              ) : (
                                <Typography.Text level={5} className="typ">
                                  {sDetails
                                    ? (() => {
                                      switch (sDetails.nakshtra) {
                                        case 'Mesha':
                                          return t('label_mesha');
                                        case 'Vrushab':
                                          return t('label_vrushab');
                                        case 'Midhuna':
                                          return t('label_midhuna');
                                        case 'Karkataka':
                                          return t('label_karktaka');
                                        case 'Simha':
                                          return t('label_simha');
                                        case 'Kanya':
                                          return t('label_kanya');
                                        case 'Thula':
                                          return t('label_thula');
                                        case 'Vruchika':
                                          return t('label_vruchika');
                                        case 'Dhanusu':
                                          return t('label_dhanassu');
                                        case 'Makara':
                                          return t('label_makara');
                                        case 'Kumbha':
                                          return t('label_kumbha');
                                        case 'Meena':
                                          return t('label_meena');
                                        default:
                                          return t('label_notchosen');
                                      }
                                    })()
                                    : t('label_notchosen')}
                                </Typography.Text>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  {userRole !== 'SU' && (
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="float-left w-100">
                          <h5 className="card-text-title2">{t("label_multi_roles")}</h5>
                        </div>
                        <div className="tiles-designs">
                          <Form.Item
                            label={<Typography.Title level={4}>{t('label_multi_role')}</Typography.Title>}
                            name="multiroles"
                          >
                            {sDetails && sDetails.multi_roles.length > 1 ? (
                              <Select
                                defaultValue={userRole}
                                value={selectedRole}
                                onChange={handleRoleChange}
                                dropdownRender={(menu) => (
                                  <div style={{ backgroundColor: "white", border: "1px solid #ddd" }}>
                                    {menu}
                                  </div>
                                )}
                                style={{ width: "100%" }}
                              >
                                {sDetails.multi_roles.map((role) => (
                                  <Select.Option key={role} value={role} style={{ backgroundColor: "white" }}>
                                    {role}
                                  </Select.Option>
                                ))}
                              </Select>
                            ) : (
                              <Typography.Text level={5} className="typ">
                                {sDetails && sDetails.multi_roles[0] || ""}
                              </Typography.Text>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card mb-3">
                    <div className="card-body">
                      <ProfilePassword editable={editable} />
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <ProfileLocation editable={editable} />
                    </div>
                  </div>
                  {token ? (
                    <Popconfirm
                      title="Are you sure you want to delete your profile?"
                      onConfirm={deleteProfile}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button type="button" className="button_move ml-2 mb-4">
                        {t('label_delete_user')}
                      </button>
                    </Popconfirm>
                  ) : null}
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Profile;