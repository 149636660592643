import HTTPClient from "../http-common";
import API_KEYS from "./apiKeys";

class TemplesDataService {

  getGodCategories(lang) {
    return HTTPClient.getHttp().get(`groups/godcategories/${lang}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getAll(lang, lr) {
    return HTTPClient.getHttp().get(`/temple/${lang}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  get(lang, id) {
    return HTTPClient.getHttp().get(`/temple/${lang}/details/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTname(id, lang) {
    return HTTPClient.getHttp().get(`/temple/${id}/lang/${lang}/info`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleReviewImage(tid) {
    return HTTPClient.getAuthHttp().get(`/temple/reviewimages/${tid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleDetailsInOtherLang(lang, id) {
    return HTTPClient.getHttp().get(`/temple/${lang}/details/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTemplesOutsideInd(lang, country, st, city, avatar, lr) {
    return HTTPClient.getHttp().get(`/temple/${lang}/country/${country}/st/${st}/city/${city}/avatar/${avatar}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getLatestTempleDetails(lang, id) {
    return HTTPClient.getAuthHttp().get(`/temple/${lang}/latest/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getResourceDetails(lang, rid) {
    return HTTPClient.getAuthHttp().get(`/resource/${lang}/rid/${rid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleGroupDetails(lang, gid) {
    return HTTPClient.getAuthHttp().get(`/groups/${lang}/gid/${gid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleSubgroups(lang, gid) {
    return HTTPClient.getHttp().get(`/groups/${lang}/subgroups/${gid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.RESOURCE_API_KEY
      }
    });
  }

  getTempleMinDetails(lang, tid) {
    return HTTPClient.getAuthHttp().get(`/temple/${lang}/mindetails/${tid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateGroupName(gid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/groups/${gid}/lang/${lang}/name`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateGroupRank(gid, data) {
    return HTTPClient.getAuthHttp().put(`/groups/${gid}/rank`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateGroupDescription(gid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/groups/${gid}/lang/${lang}/descr`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateHoroDetails(hid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/horoscope/${hid}/lang/${lang}/details`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateWeeklyHoroDetails(hid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/horoscope/whoro/${hid}/lang/${lang}/details`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  deleteResource(rid) {
    return HTTPClient.getAuthHttp().delete(`resource/${rid}/delete`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  publishResource(rid) {
    return HTTPClient.getAuthHttp().put(`resource/${rid}/publish`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getMyContributions(lang, avatar, lr) {
    return HTTPClient.getAuthHttp().get(`/temple/${lang}/mine/avatar/${avatar}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createHoroscope(data) {
    return HTTPClient.getAuthHttp().post("/calendar", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createWeeklyHoroscope(data) {
    return HTTPClient.getAuthHttp().post("/horoscope/whoro", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createDailyHoroscope(data) {
    return HTTPClient.getAuthHttp().post("/horoscope/dhoro", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateHoroTitle(hid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/horoscope/${hid}/lang/${lang}/title`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateHoroText(hid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/horoscope/${hid}/lang/${lang}/txt`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  updateHoroLocation(hid, lang, data) {
    return HTTPClient.getAuthHttp().put(`/horoscope/${hid}/lang/${lang}/location`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY
      }
    });
  }

  create(data) {
    return HTTPClient.getAuthHttp().post("/temple", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  update(id, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${id}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateResource(rid, data) {
    return HTTPClient.getAuthHttp().put(`/resource/${rid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  translateResourcelang(rid, slang, tlang) {
    return HTTPClient.getAuthHttp().post(`/resource/${slang}/rid/${rid}/lang/${tlang}`, null, {
        headers: {
            'X-API-CID': API_KEYS.getClientToken(),
        },
    });
}

  updateArchitecture(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/architecture/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateResourceTitle(lang, rid, data) {
    return HTTPClient.getAuthHttp().put(`/resource/${lang}/rid/${rid}/title`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateResourceTxt(lang, rid, data) {
    return HTTPClient.getAuthHttp().put(`/resource/${lang}/rid/${rid}/txt`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTimings(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/timings/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateTravel(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/howtoreach/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateGovFlag(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/gov/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateOnHillFlag(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/onhill/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateSwayambhuFlag(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/swayambu/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateAddInfo(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/addtlinfo/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePujas(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/dailypujas/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateAccomodation(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/accommodation/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateSplPujas(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/splpujas/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateFestivals(lang, tid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${lang}/festivals/${tid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }


  postImage(rid, filePath) {
    const formData = new FormData();
    formData.append('image', filePath);
    return HTTPClient.getAuthHttp().post(`/temple/${rid}/image`, formData);
  }

    deleteTemple(id, tsm) {
      return HTTPClient.getAuthHttp().delete(`/temple/${id}/ts/${tsm}`, {
        headers: {
          'X-API-CID': API_KEYS.getClientToken()
        }
      });
    }

  findByNameOrCity(name_city) {
    return HTTPClient.getHttp().get(`/temple?title=${name_city}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }
}

export default new TemplesDataService();