import React, { useEffect, useState } from 'react'
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import videos from '../../../../assets/images/video.jpg'
import audios from '../../../../assets/images/audio.jpg'
import mantras from '../../../../assets/images/mantras.jpg'
import articles from '../../../../assets/images/article.jpg'
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const QuickLinks = () => {
    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    return (
        <>

            <div class="mainsec">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_app_admin_links')}</span>
                    </div>
                    <div></div>
                </div>
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                        <span className="d-flex align-items-center">
                            <Link style={{ color: 'black' }} to="/">
                                <span className="bc-active-link">{t('Home')}</span>
                            </Link>
                            <span className="ml-3 mr-3">
                                <i className="fa-solid fa-angle-right f-9"></i>
                            </span>
                        </span>
                        <span className="bc-link">{t("label_app_admin_links")}</span>
                    </div>
                <div class="mainsec-block">
                    <div className="container">
                        <div class="container">
                            <h5 class="card-text-title">{t("label_app_admin_links")}
                            </h5>
                        </div>
                        <div className="tiles-main">
                            <Link to='/contribution'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_my_contributions")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/addTemple'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("add_temple")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/recentlyViewed'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("lable_recent_view")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/addResource'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={videos} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_create_resource")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/editDailyHoroscope/0'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={articles} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_create_daily_horo")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/adddailypanchangam'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={articles} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_create_daily_panchangam")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/editWeeklyHoroscope/0'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_create_weekly_horo")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/templeGroupsList'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_tmpl_groups_list")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/addNotification'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_create_notify")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/manageNotification'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_manage_notifications")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/appusers'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_app_users")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/pendingTemples'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_rev_temples")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/templeadmin'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_templ_adm_page")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/templecomplaints'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_temple_complaints")}</span> <span>{("label_temple_complaints")}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )

}

export default QuickLinks;