import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import i18next from 'i18next';
import Footer from '../pages/Homepage/footer/footer';
import { postNewPriest, postPrIest, postPrest, postPriest } from '../../redux/actions/acions';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../common/CustomToastContainer';




const normFile = (e) => {

    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const AddPriestbyAD = () => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const { TextArea } = Input;
    const phoneRegex = /^[0-9]{1,10}$/;

    const validatePhoneNumber = (_, value) => {
        if (value && !phoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
        }
        return Promise.resolve();
    };

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t(`label_addpriest_success`))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY SUBMITTED',
        // });
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('error', `msg`)
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,

        // });
    };

    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    "userid": values.userid,
                    "addr1": values.addr1,
                    "phone": values.phone,
                    "pujas": values.pujas,
                    "summary": values.summary
                }
                dispatch(postNewPriest(values.userid, data, success, failure))
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });
    };

    const storedLanguage = localStorage.getItem('lng');
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const handleChange = (value) => {
        localStorage.setItem('lng', value);
        setSelectedLanguage(value);
        // Make API request here with the selected language
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
        //setButtonText('Edit');
        //setEditable(false);
    };

    return (
        <>
            {contextHolder}

            <div className='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_add_priest')}</span>
                    </div>
                    <div></div>
                </div>
                <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                    <span className="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to="/">
                            <span className="bc-active-link">{t('Home')}</span>
                        </Link>
                        <span className="ml-3 mr-3">
                            <i className="fa-solid fa-angle-right f-9"></i>
                        </span>
                        <span className="bc-link" onClick={handleGoBack}>{t("label_add_priest_by_admin")}</span>
                    </span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt-singleLine container">
                        <div class="header_txt"> {t('label_add_priest_by_admin')}</div>
                        <div class="details-headertxt-singleLine-btns">
                            <a class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t("label_cancel")}
                            </a>
                            <a class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                                {t("label_save")}
                            </a>
                        </div>


                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages" onFinish={onFinish}

                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }} 
                        >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_enter_priest_details")} <span>| {t('label_userid_pujas_phone')}</span></h5></div>
                            <div class="active-details">
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='userid'
                                        label={<Typography.Title level={4}>{t('label_userid')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ height: '30px' }}
                                            placeholder={t('label_enter_userid')}
                                        />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='addr1'
                                        label={<Typography.Title level={4}>{t("label_addr")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={2} placeholder={t('label_enter_address')} />
                                    </Form.Item>
                                </div>

                                {/* <Row gutter={12}>
                                <Col span={12}> */}
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='phone'
                                        label={<Typography.Title level={4}>{t('label_priest_phone_no')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                                validator: validatePhoneNumber,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ height: '30px' }}
                                            placeholder={t('label_enter_prnumber')}
                                            maxLength={10}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                {/* </Col>
                                <Col span={12}> */}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        // labelCol={{
                                        //   span: 11,
                                        // }}
                                        // wrapperCol={{
                                        //   span: 18,
                                        // }}
                                        name='pujas'
                                        label={<Typography.Title level={4}>{t('label_pujas')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} placeholder={t('label_enter_pujas')} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='summary'
                                        label={<Typography.Title level={4}>{t('label_summary')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} placeholder={t('label_enter_summary')} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>
        </>

    );
};
export default AddPriestbyAD;