import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Image, Modal, Result, Row, Select, Typography, message } from 'antd';
import TempleCard from './card';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import aum from '../../assets/icons/om.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getFilterByStDtList, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from '../../redux/selectors/selectors';
import { deleteTemple, fetchByStDt, fetchContributions, fetchFavourites, fetchPendingTemples, fetchTempleFavourites, postFavourites, removeFavourites, retrieveTemples } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Error from '../pages/Homepage/error';
import Footer from '../pages/Homepage/footer/footer';
import NearBy from '../pages/Homepage/Temples/nearby';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import TemplesListMap from '../pages/Homepage/Temples/templesListMap';
import Images from './images';
import deityImages from './godImages';
import TemplePreviewModal from './templePreviewModal';

const TemplesPage = ({ title, description, gList, tLoading, godLastRec, templesList, tList, setFilterId, selectedCity, selectedState, godCtgryList, filterTemplesList, image, filterId, selectedGod, temple, nextTemplesList, prevTemplesList, last_rec, handleDelete, show, handleGoBackMap }) => {

    const { t } = useTranslation();
    const records_per_page = 12;

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    function getStyle(did) {
        if (did === filterId) {
            return {

                boxShadow: '#d3d3d3 3px -2px 4px',
                borderRadius: '8px',
                filter: 'saturate(1)',
                border: '3px solid var(--colororange2)',
            };
        } else {
            return {
                border: '1px solid #ddd',
            };
        }
    }

    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };


    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const storedLanguage = localStorage.getItem('lng');
    const [selectedFilterGod, setSelectedFilterGod] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [isGodModalVisible, setIsGodModalVisible] = useState(false);


    useEffect(() => {
        const loginTime = localStorage.getItem('logintime');

        if (!loginTime) {
            const timer = setTimeout(() => {
                setIsGodModalVisible(true);
                localStorage.setItem('logintime', true);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, []);


    const handleFilterGodClick = () => {
        if (window.location.pathname.includes('/templeGroup')) {
            setHideGodSection(false);
        }
        else if (window.location.pathname.includes('/searchResults')) {
            setHideGodSection(false);
        }
        else {
            setIsGodModalVisible(true);
        }
    };

    const handleFilterGodCancel = () => {
        setIsGodModalVisible(false);
    };

    const success = (tname) => {
        showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
    };

    const warning = (tname) => {
        showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
    };


    const failure = () => {
        showToast('error', t(`label_fav_error`))

    };

    const dispatch = useDispatch();
    const { fList, fLoading, fError, stList } = useSelector(state => ({
        fList: getTempleFavouritesList(state),
        fLoading: getTempleFavouritesLoading(state),
        fError: getTempleFavouritesError(state),
        stList: getFilterByStDtList(state),
    }));


    // useEffect(() => {
    //     if (getToken) {
    //         dispatch(fetchTempleFavourites());
    //     }
    // }, [getToken]);

    useEffect(() => {
        if (getToken) {
            const cachedFavourites = JSON.parse(localStorage.getItem('favourites'));
            if (cachedFavourites) {
                setFavList(cachedFavourites);
            } else if (getToken) {
                dispatch(fetchTempleFavourites());
            }
        }
    }, [getToken]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
            localStorage.setItem('favourites', JSON.stringify(fList.fav_list));
        }
    }, [fList]);


    function addFavourites(id, tname) {
        dispatch(postFavourites(id));
        setFavList(prevFavList => [...prevFavList, id]);
        localStorage.setItem('favourites', JSON.stringify([...favList, id]));
        success(tname);
    }

    function deleteFavourites(id, tname) {
        dispatch(removeFavourites(id));
        setFavList(prevFavList => prevFavList.filter(favId => favId !== id));
        localStorage.setItem('favourites', JSON.stringify(favList.filter(favId => favId !== id)));
        warning(tname);
    }

    const [favList, setFavList] = useState([]);


    // useEffect(() => {
    //     if (fList && fList.fav_list) {
    //         setFavList(fList.fav_list);
    //     }
    // }, [fList]);


    // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(temple) {
        if (getToken) {
            const templeId = temple.tid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.tname);
                // warning(temple.tname);
                // Update favList by removing the temple ID
                // setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.tname);
                // success(temple.tname);
                // Update favList by adding the temple ID
                // setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }


    const [filled, setFilled] = useState({});

    const location = useLocation();

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    // const tid = temple&&temple.tid;

    // function handleDelete(temple) {
    //     const templeId = temple.tid;
    //     dispatch(deleteTemple(118006, temple.ludt, failure))
    // }

    // const isTempleInFavorites = (templeId) => {
    //     return favList.includes(templeId);
    // }

    const god = {
        1: t("Ganesha"),
        2: t("Vishnu"),
        3: t("Lakshmi"),
        4: t("Durga"),
        5: t("Sri Rama"),
        6: t("Shiva"),
        7: t("Krishna"),
        8: t("Kartikeya"),
        9: t("Hanuma"),
        10: t("Brahma"),
        11: t("Saraswati"),
        12: t("Ayyappa"),
        13: t("Saibaba"),
        14: t("Narasimha"),
        15: t("Surya"),
        16: t("Budha"),
        17: t("Naga"),
        18: t("Gurudev"),
        19: t("Balaji")
    }


    const isGroup = () => {
        // Replace the following logic with your own to determine the page type
        const isGroupPage = window.location.pathname.includes('/templeGroup');

        // Return true if the location button should be visible, false otherwise
        return (isGroupPage);
    };

    const displayList = isGroup() ? null : godCtgryList;

    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const handlePopState = (event) => {
            if (isModalVisible) {
                closeShowMoreModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (isModalVisible && event.key === 'Escape') {
                closeShowMoreModal();
                event.preventDefault();
            }
        };
        if (isModalVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalVisible, closeShowMoreModal]);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [hideGodSection, setHideGodSection] = useState(false);
    const [hideNext, setHideNext] = useState(false);

    useEffect(() => {
        if (title === 'Famous Temples') {
            setHideGodSection(true);
        }
        if (title === 'Australia' || 'Thailand') {
            setHideGodSection(true);
        }
        if (window.location.pathname.includes('/templeGroup')) {
            setHideGodSection(false);
        }
        else {
            setHideGodSection(false);
        }
    }, [title]);

    useEffect(() => {
        if (title === 'Recently viewed Temples') {
            setHideNext(true);
        }
    })

    useEffect(() => {
        if (tList && tList.length > 0) {
            // Scroll to the top of the page after the component renders or when tList changes
            window.scrollTo(10, 10);
        }
    }, [tList]);

    function formatBoldText(txt) {
        if (txt) {
            return <div dangerouslySetInnerHTML={
                { __html: formatPlainText(txt) }
            } />
        } else {
            return ''
        }
    }

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = ''
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                if (allLines[i].includes('<B>')) {
                    finalTxt = finalTxt + (allLines[i] + '</B>') + '\n';
                } else {
                    finalTxt = finalTxt + allLines[i] + '\n';
                }
            }
            return finalTxt;
        } else {
            return ''
        }
    }



    const isNearbyVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isNearbyPage = window.location.pathname.includes('/nearby');


        // Return true if the edit button should be visible, false otherwise
        return getToken ? (isNearbyPage) : false;
    };

    function handleGoBack() {
        const path = window.location.pathname;
        if (!path.startsWith('/searchResults') && !path.startsWith('/recentlyViewed')) {
            setFilterId(null);
            localStorage.removeItem('filterId');
        }
        navigate(-1);
    }


    const navigate = useNavigate()


    const [modalVisible, setModalVisible] = useState(false);
    const [iconsVisible, setIconsVisible] = useState(false);
    const toggleIcons = () => setIconsVisible(!iconsVisible);

    useEffect(() => {
        const handlePopState = (event) => {
            if (modalVisible) {
                closeMapModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (modalVisible && event.key === 'Escape') {
                closeMapModal();
                event.preventDefault();
            }
        };
        if (modalVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalVisible, closeMapModal]);

    const closeMapModal = () => {
        setModalVisible(false);
        setIsGodModalVisible(false);
    };

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const isLocationButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');

        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage);
    };

    const [isPreviewModalVisible, setPreviewModalVisible] = useState(false);

    const openPreviewModal = () => {
        setPreviewModalVisible(true);
    };

    const closePreviewModal = () => {
        setIsGodModalVisible(false);
    };

    const closeShowMoreModal = () => {
        setIsModalVisible(false);
    };

    const handleClick = () => {
        // Check if the link corresponds to the books or articles page
        // if (target === '_blank') {
        //     // If it does, do nothing else
        //     return;
        // }
        // For other pages, execute storeTempleDetails
        storeTempleDetails(temple);
    };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);


    useEffect(() => {
        const handlePopState = (event) => {
            if (isGodModalVisible) {
                closePreviewModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (isGodModalVisible && event.key === 'Escape') {
                closePreviewModal();
                event.preventDefault();
            }
        };
        if (isGodModalVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isGodModalVisible, closePreviewModal]);

    return (
        <div class="mainsec">
            {contextHolder}
            {/*  */}
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} to='/'><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{title}</span>
                </div>
                <div class="right-sec-mob-nav d-flex align-items-center">

                    {(!window.location.pathname.startsWith('/templeGroup') &&
                        !window.location.pathname.startsWith('/searchResults') &&
                        !window.location.pathname.startsWith('/searchFavourite')) &&
                        !window.location.pathname.startsWith('/recentlyViewed') && (
                            <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>
                                {t("label_sel_god")}
                                <i className="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>

                                {filterId && (
                                    <>
                                        <div className="detail-container">
                                            <span className="god-button">{t(god[filterId])}</span>
                                            <CloseCircleOutlined
                                                className="close-icon"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setFilterId(null)
                                                }}

                                            />
                                        </div>
                                    </>
                                )}
                                {filterId === null && (
                                    <div className="detail-container d-flex align-items-center">
                                        <span className="detail-but-god ml-2">{t("label_all")}</span>
                                    </div>
                                )}
                            </button>
                        )}
                </div>
            </div>

            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <div class="d-flex align-items-center">
                    {!window.location.pathname.startsWith('/nearby') && (
                        <>
                            <span >
                                <Link style={{ color: 'black' }} to='/' >
                                    <span className="bc-active-link">{t('Home')}</span>
                                </Link>
                                <span className="ml-3 mr-3">
                                    <i className="fa-solid fa-angle-right f-9"></i>
                                </span>
                                <span className="bc-active-link" onClick={handleGoBack}>{t('label_temples_list')}</span>
                                <span className="ml-3 mr-3">
                                    <i className="fa-solid fa-angle-right f-9"></i>
                                </span>
                                {' '}
                            </span> &nbsp;<span className="bc-link mr-2"> {title}</span>
                        </>
                    )}
                    {window.location.pathname.startsWith('/nearby') && (
                        <>
                            <span >
                                <Link style={{ color: 'black' }} to='/' >
                                    <span className="bc-active-link">{t('Home')}</span>
                                </Link>
                                <span className="ml-3 mr-3">
                                    <i className="fa-solid fa-angle-right f-9"></i>
                                </span>
                                <span className="bc-active-link" onClick={handleGoBackMap}>{t('label_map')}</span>
                                <span className="ml-3 mr-3">
                                    <i className="fa-solid fa-angle-right f-9"></i>
                                </span>
                                {' '}
                            </span> &nbsp;<span className="bc-link mr-2"> {title}</span>
                        </>
                    )}
                    {(!window.location.pathname.startsWith('/templeGroup') &&
                        !window.location.pathname.startsWith('/searchResults') &&
                        !window.location.pathname.startsWith('/recentlyViewed')) && (
                            <>
                                &nbsp;|&nbsp;<button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>
                                    {t("label_sel_god")}
                                    <i className="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>

                                    {filterId ? (
                                        <>
                                            <div className="detail-container">
                                                <span className="god-button">{god[filterId]}</span>
                                                <CloseCircleOutlined
                                                    className="close-icon"
                                                    onClick={(event) => {
                                                        event.stopPropagation();

                                                        if (title === 'My Contributions') {
                                                            dispatch(fetchContributions(storedLanguage, 0, 0));
                                                            setFilterId(0);
                                                        }
                                                        else if (title === 'Pending Temples') {
                                                            dispatch(fetchPendingTemples(storedLanguage, 0, 0));
                                                            setFilterId(0);
                                                        }
                                                        else if (title === 'Famous Temples') {

                                                            setFilterId(null);
                                                            localStorage.setItem('filterId', null);
                                                        }
                                                        else if (title === t('label_city_templs')) {
                                                            dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
                                                            setFilterId(null);
                                                        }
                                                        else {
                                                            dispatch(fetchFavourites(storedLanguage, 0, 0));
                                                            setFilterId(0);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="detail-container d-flex align-items-center">
                                            <span className="detail-but-god ml-2">{t("label_all")}</span>
                                        </div>
                                    )}
                                </button>
                            </>
                        )}



                </div>
            </div>

            {/* {hideGodSection && ( */}
            <>

                {!location.pathname.startsWith("/templeGroup/") && (
                    <div
                        className="popup-bg"
                        style={{ display: isGodModalVisible ? "flex" : "none" }}
                        onClick={handleFilterGodCancel}
                    >
                        <div className="popup-main-filter animate__animated animate__fadeInUp">
                            <div className="popup-main-header-filter">
                                <span className="popup-main-header-text-filter">{t("label_god_categ")}</span>
                                <div className="popup-close" onClick={handleFilterGodCancel}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                                <div className="popup-back" onClick={handleFilterGodCancel}>
                                    <i className="fa-solid fa-chevron-left"></i>
                                </div>
                            </div>
                            <div className="popup-main-container-filter">
                                <div className="d-block float-left content-filter">
                                    <div className="god-category-list">
                                        <div className="all-gods-sec">{t("label_all")}</div>
                                        <div className="god_select">
                                            <div className="god_pic">
                                                <img
                                                    style={getStyle(null)}
                                                    className="dietyImg"
                                                    src={aum}
                                                    alt="All"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        if (title === "My Contributions") {
                                                            dispatch(fetchContributions(storedLanguage, 0, 0));
                                                            setFilterId(0);
                                                            setIsGodModalVisible(false);
                                                        } else if (title === "Pending Temples") {
                                                            dispatch(fetchPendingTemples(storedLanguage, 0, 0));
                                                            setFilterId(0);
                                                            setIsGodModalVisible(false);
                                                        } else if (title === "Famous Temples") {
                                                            setFilterId(null);
                                                            localStorage.setItem("filterId", null);
                                                        } else if (title === "Filtered Temples") {
                                                            dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
                                                            setFilterId(null);
                                                            setIsGodModalVisible(false);
                                                        } else {
                                                            dispatch(fetchFavourites(storedLanguage, 0, 0));
                                                            setFilterId(0);
                                                            setIsGodModalVisible(false);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                        <Row gutter={[16, 16]}>
                                            {displayList &&
                                                displayList.map((item, index) => (
                                                    <Col key={item.avatar}>
                                                        <div className="god_select">
                                                            <div className="god_pic">
                                                                <img
                                                                    style={getStyle(item.avatar)}
                                                                    src={deityImages[item.avatar]}
                                                                    onClick={() => {
                                                                        filterTemplesList(item.avatar);
                                                                        setIsGodModalVisible(false);
                                                                    }}
                                                                />
                                                            </div>
                                                            <p>{item.deity}</p>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className="popup-main-footer">
                                <a className="button_move" onClick={handleFilterGodCancel}>
                                    {t("label_cancel")}
                                </a>
                            </div>
                        </div>
                    </div>
                )}

            </>
            {/* )} */}

            <div class="mainsec-block">


                <div className={`container cat-txt`}>
                    <div className="cat-detail">
                        <span className="f3 d-flex justify-content-between">
                            <div>
                                <b className="title-text">{title}</b>
                            </div>

                            {description && description.trim().length > 100 && (
                                <div className="detail-but btn-shine" onClick={showModal}>
                                    {t('label_show_more')}
                                </div>
                            )}

                        </span>
                        <p
                            className="f1 m-0 more-info-text"
                            dangerouslySetInnerHTML={{
                                __html: description
                                    ? description.replace(/<BR>/g, '<br>').replace(/<B>(.*?)<\/B>/g, '<strong>$1</strong>')
                                    : ''
                            }}
                        />
                        <div className="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                            <div class="popup-main animate__animated animate__fadeInUp ">
                                <div class="popup-main-header">
                                    <span class="popup-main-header-text">{title}</span>
                                    <div class="popup-close" onClick={handleCancel}><i class="fa-solid fa-xmark"></i></div>
                                    <div class="popup-back" onClick={handleCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div class="popup-main-container">
                                    <div class="d-block htinner-popup-main-container">

                                        <div >
                                            <img className="grp-descr-img" src={image} alt="Image Alt Text" />
                                        </div>
                                        <div
                                            className="diag-image-content"
                                            style={{ whiteSpace: "pre-wrap" }}
                                            dangerouslySetInnerHTML={{
                                                __html: description
                                                    ? description.replace(/<BR>/g, '<br>').replace(/<B>(.*?)<\/B>/g, '<strong>$1</strong>')
                                                    : ''
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="popup-main-footer">
                                    <a className="button_move" onClick={handleCancel}>
                                        {t("label_cancel")}
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="tile-quicklinks">
                    {/* {isLocationButtonVisible() && (
                        <i class="fa fa-list-alt" aria-hidden="true" onClick={toggleIcons} style={{marginRight:'8px',fontSize:'20px'}}></i>
                    )}
                    {iconsVisible && (
                        <div className={`additional-icons ${iconsVisible ? 'visible' : ''}`}>
                           
                            
                            <i class="fa fa-bars" aria-hidden="true" style={{fontSize:'20px'}}></i>
                        </div>
                    )} */}
                    <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                                <span class="popup-main-header-text"> {title}</span>
                                <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                                <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                                <div class="d-block htinner-popup-main-container">
                                    <TemplesListMap templesList={templesList} mapContainerStyle={{ width: '100%', height: '55vh' }} />
                                </div>
                            </div>
                            <div class="popup-main-footer">

                                <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
                                    {t("label_cancel")}
                                </a>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="container search">
                    {tLoading ? (
                        <Error />
                    ) : (
                        <div className="tiles-main">
                            {tList && tList.length > 0 ? (
                                tList.map(temple => (
                                    <TempleCard
                                        key={temple.tid}
                                        id={temple.tid}
                                        imgpath={temple.imgpath}
                                        title={temple.tname}
                                        addr1={temple.addr1}
                                        city={temple.lang_city}
                                        state={temple.st}
                                        temple={temple}
                                        favList={favList}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        storeTempleDetails={storeTempleDetails}
                                        latit={temple.latit}
                                        longi={temple.longi}
                                        link={`/templeDetails/${temple.tid}`}
                                        handleDelete={handleDelete}
                                        handleClick={handleClick}
                                        tList={tList}
                                        description={description}
                                    />
                                ))
                            ) : (
                                <Result
                                    status="404"
                                    title={t("label_nodata_found")}
                                    subTitle={t("label_nodata_temple_msg")}
                                />
                            )}
                        </div>
                    )}
                </div>

                {!hideNext && (
                    <div className="next-row">
                        {tList && tList.length > 0 && (
                            <>
                                <Col>
                                    {window.location.pathname.includes('/templeslist') ? (
                                        <a
                                            className={`button_move `}
                                            onClick={last_rec === 0 && godLastRec === 0 ? null : prevTemplesList}
                                            disabled={last_rec === 0 && godLastRec === 0}
                                        >
                                            {t("label_previous")}
                                        </a>
                                    ) : (
                                        <a
                                            className={`button_move`}
                                            onClick={last_rec === 0 ? null : prevTemplesList}
                                            disabled={last_rec === 0}
                                        >
                                            {t("label_previous")}
                                        </a>

                                    )}

                                </Col>
                                <Col>
                                    <a
                                        className={`button_move`}
                                        onClick={tList.length < records_per_page ? null : nextTemplesList}
                                        disabled={tList.length < records_per_page}
                                    >
                                        {t("label_next")}
                                    </a>
                                </Col>
                            </>
                        )}
                    </div>
                )}
                <Footer />
            </div>
        </div>
    )
}

export default TemplesPage;