import React, { useEffect, useState } from 'react';
import { Typography, Collapse } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deftemples from '../../../../assets/images/templeicon.jpg';
import horoscope_icon from "../../../../assets/images/horoscope-img.png";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const FAQs = () => {
    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    const storedLanguage = localStorage.getItem('lng');
    const faqLabel = storedLanguage === 5 ? t('label_faq') : t('label_faqs');

    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', t('Please login to use additional features'));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='mainsec'>
            <div className="mob-nav fluid-container page-title justify-content-between">
                <div className="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack}><i className="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{faqLabel}</span>
                </div>
            </div>

            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'label_faqs' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                </span>
                <span className="bc-link">{faqLabel}</span>
            </div>

            <div className="bg-details-highlight">
                <div className="details-headertxt-singleLine container">
                    <div className="header_txt">{faqLabel}</div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className="container">
                    <div className="privacy-policy-container" style={{ marginTop: '24px' }}>
                        <div className="privacy-policy-content">
                            <Typography>
                                <Title level={2}>{t('Frequently Asked Questions')}</Title>
                                <Paragraph>
                                    {t('Welcome to www.templeswiki.com. Here you will find answers to common questions about our platform, features, and how to use our services effectively.')}
                                </Paragraph>
                            </Typography>
                        </div>
                    </div>

                    <div className="faq-section container" style={{ marginTop: '24px' }}>
                        <Collapse accordion>
                            <Panel header={t('What is TemplesWiki?')} key="1">
                                <p>{t('TemplesWiki is an online platform that provides comprehensive information about temples worldwide, their history, significance, and visitor guidelines.')}</p>
                            </Panel>
                            <Panel header={t('How can I contribute to TemplesWiki?')} key="2">
                                <p>{t('Users can contribute by submitting temple details, images, and historical information. However, you need to create an account to contribute.')}</p>
                            </Panel>
                            <Panel header={t('Do I need an account to use TemplesWiki?')} key="3">
                                <p>{t('No, browsing temple information is free. However, creating an account allows you to contribute, save your favorite temples, and access additional features.')}</p>
                            </Panel>
                            <Panel header={t('Is there a mobile app available?')} key="4">
                                <p>{t('Currently, TemplesWiki is accessible via a mobile-friendly website. A dedicated mobile app is in development and will be available soon!')}</p>
                            </Panel>
                            <Panel header={t('How do I report incorrect information?')} key="5">
                                <p>{t('You can report incorrect information by using the “Report” feature on each temple page or by contacting our support team.')}</p>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default FAQs;
