import React, { useEffect, useState } from 'react'
import india from '../../../../assets/images/india.png';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';

const RemedyTemples = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng');

    const dispatch = useDispatch();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };

    const templeData = [
        {
            imageSrc: india,
            labelFamousTemples: t("label_career_templs"),
            gid: "172"
        },
        {
            imageSrc: india,
            labelFamousTemples: t("label_study_templs"),
            labelTemplesList: t("label_temples_list"),
            gid: "125"
        },
        {
            imageSrc: india,
            labelFamousTemples: t("label_marriage_templs"),
            labelTemplesList: t("label_temples_list"),
            gid: "105"
        },
        {
            imageSrc: india,
            labelFamousTemples: t("label_kids_templs"),
            labelTemplesList: t("label_temples_list"),
            gid: "145"
        }
    ];


    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };

    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups('groups', storedLanguage));
    }, [storedLanguage]);


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_remedy')}</span>
                </div>
                <div></div>
            </div>
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'black' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                    <span className="bc-active-link" onClick={handleGoBack}>{t("label_temples_list")}</span>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                </span>
                <span className="bc-link">{t("label_remedy")}</span>
            </div>
            <div class="mainsec-block">
                
                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
                
                Temple Categories</div> */}
                <div className="container">
                    <div class="container">
                        <h5 class="card-text-title">{t("label_remedy")}
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </h5>
                    </div>
                    <div className="tiles-main">
                        {templeData.map((temple, index) => (
                            <Link to={`/OutsideIndia/${temple.labelFamousTemples}/${temple.gid}`} key={index}>
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt="temples" src={temple.imageSrc} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.labelFamousTemples}</span> <span>{temple.labelTemplesList}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {/* {nList && nList
                            .filter(temple => temple.group_name.startsWith("Temples in"))
                            .map((temple, index) => (
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX" key={index} onClick={() => {
                                    if (temple.group_name.toLowerCase() === 'outside of india') {
                                        // Navigate to /outsideIndia page
                                        navigate('/outsideIndia');
                                    } else {
                                        // Navigate to the specific group temples page
                                        fetchGroupTemples(temple.gid);
                                    }
                                }}>
                                    <div className="title-img-bg ">
                                        <img className="card-img-temple-cat " alt="temples" src={Images[temple.imgpath] || ''} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.group_name}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            ))} */}

                    </div>
                </div>

                <Footer />
            </div>
        </div>



    )

}

export default RemedyTemples;