import React, { useEffect, useState } from 'react'

import { message, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import statesData from '../../states.json';
import deftemples from '../../../../assets/images/templeicon.jpg'
import india from '../../../../assets/images/india.png';
import ap from '../../../../assets/images/ap.jpg';
import ts from '../../../../assets/images/ts.jpg';
import arp from '../../../../assets/images/arp.jpg';
import assam from '../../../../assets/images/assam.jpg';
import bihar from '../../../../assets/images/bihar.jpg';
import chgr from '../../../../assets/images/chgr.jpg';
import tripura from '../../../../assets/images/tripura.jpg';
import mizoram from '../../../../assets/images/mizoram.jpg';
import delhi from '../../../../assets/images/delhi.jpg';
import cdgr from '../../../../assets/images/cdgr.jpg';
import dnh from '../../../../assets/images/dnh.jpg';
import dnd from '../../../../assets/images/dnd.jpg';
import ann from '../../../../assets/images/andaman.jpg';
import gujarat from '../../../../assets/images/gujarat.jpg';
import goa from '../../../../assets/images/goa.jpg';
import haryana from '../../../../assets/images/haryana.jpg';
import hp from '../../../../assets/images/hp.jpg';
import kerala from '../../../../assets/images/kerala.jpg';
import karnataka from '../../../../assets/images/karnataka.jpg';
import jharkhand from '../../../../assets/images/jharkhand.jpg';
import manipur from '../../../../assets/images/manipur.jpg';
import mp from '../../../../assets/images/mp.jpg';
import maharashtra from '../../../../assets/images/maharashtra.jpg';
import meghalaya from '../../../../assets/images/meghalaya.jpg';
import nagaland from '../../../../assets/images/nagaland.jpg';
import odisha from '../../../../assets/images/odisha.jpg';
import rajasthan from '../../../../assets/images/rajasthan.jpg';
import panjab from '../../../../assets/images/panjab.jpg';
import sikkim from '../../../../assets/images/sikkim.jpg';
import tn from '../../../../assets/images/tn.jpg';
import uttarakhand from '../../../../assets/images/wb.jpg';
import wb from '../../../../assets/images/uttarakhand.jpg';
import up from '../../../../assets/images/up.jpg';
import lakshadweep from '../../../../assets/images/lakshadweep.jpg';
import puducherry from '../../../../assets/images/puducherry.jpg';
import dad from '../../../../assets/images/dad.jpg';
import dadraandnagarhaveli from '../../../../assets/images/dadraandnagarhaveli.jpg';
import uttaranchal from '../../../../assets/images/uttaranchal.jpg';
import jnk from '../../../../assets/images/jnk.jpg';
import Search from 'antd/es/transfer/search';


const TempleInIndia = () => {
    const { t } = useTranslation();
    const [states, setStates] = useState([]);
    const { gid } = useParams();
    const [filteredStates, setFilteredStates] = useState([]);
    const [recentlyViewed, setRecentlyViewed] = useState([]);
    const recentlyViewedCities = JSON.parse(localStorage.getItem('recentlyViewedCities')) || [];



    const stateImages = {
        "Andhra Pradesh": ap,
        "Arunachal Pradesh": arp,
        "Assam": assam,
        "Bihar": bihar,
        "Chhattisgarh": chgr,
        "Goa": goa,
        "Gujarat": gujarat,
        "Haryana": haryana,
        "Himachal Pradesh": hp,
        "Jharkhand": jharkhand,
        "Karnataka": karnataka,
        "Kerala": kerala,
        "Madhya Pradesh": mp,
        "Maharashtra": maharashtra,
        "Manipur": manipur,
        "Meghalaya": meghalaya,
        "Mizoram": mizoram,
        "Nagaland": nagaland,
        "Odisha":odisha,
        "Punjab": panjab,
        "Rajasthan": rajasthan,
        "Sikkim": sikkim,
        "Tamil Nadu": tn,
        "Telangana": ts,
        "Tripura": tripura,
        "Uttar Pradesh": up,
        "Uttarakhand": uttarakhand,
        "West Bengal": wb,
        "Andaman and Nicobar": ann,
        "Chandigarh": cdgr,
        "Dadra and Nagar Haveli": dnh,
        "Lakshadweep": lakshadweep,
        "Delhi": delhi,
        "Puducherry": puducherry,
        "Uttaranchal": uttaranchal,
        "Daman and Diu":dad,
        "Jammu and Kashmir": jnk,
    };

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const handleStateClick = (state) => {
        // Get previously viewed cities from localStorage
        let recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewedCities')) || [];

        // Add the clicked state to the list (if it's not already in the list)
        if (!recentlyViewed.includes(state)) {
            recentlyViewed.push(state);
        }

        // Keep only the last 4 cities in the list
        if (recentlyViewed.length > 4) {
            recentlyViewed.shift(); // Remove the first element (oldest)
        }

        // Save the updated list to localStorage
        localStorage.setItem('recentlyViewedCities', JSON.stringify(recentlyViewed));

        // Navigate to the respective page
        if (gid === "507") {
            navigate(`/cityPage/${state}`);
        } else {
            navigate(`/state/${state}/city/all`);
        }
    };


    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Initialize states and filteredStates with data from statesData
        const stateNames = statesData.states.map((state) => state.name);
        setStates(stateNames);
        setFilteredStates(stateNames);
    }, []);

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (value.trim() === '') {
            setFilteredStates(states);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = states.filter((state) =>
                state.toLowerCase().includes(lowercasedValue)
            );
            setFilteredStates(filtered);
        }
    };

    const handleReset = () => {
        setSearchTerm('');
        setFilteredStates(states);
    };


    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_states_in_india')}</span>
                </div>
                <div></div>
            </div>
            <div className="mb-3 mt-3 breadcrumb-link container d-none d-md-flex justify-content-start align-items-center">
                <span className="d-flex align-items-center">
                    <Link style={{ color: 'black' }} to="/">
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                    <span className="bc-active-link" onClick={handleGoBack}>{t("label_temples_list")}</span>
                    <span className="ml-3 mr-3">
                        <i className="fa-solid fa-angle-right f-9"></i>
                    </span>
                </span>
                <span className="bc- active-link" onClick={handleGoBack}>{t("label_states_in_india")}</span>
            </div>
            <div class="mainsec-block">
                {/* <div class="f2 font-weight-bold  container title-head-txt" style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }} >
                    {token ? (
                        <Link to='/addTemple'><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    )}
                    <Link to='/recentlyViewed'><button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    <Link to='/nearby'> <button type="button" class="declineButton ">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    )}
                </div> */}


                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
                
                Temple Categories</div> */}
                <div className="container">
                    {recentlyViewedCities.length > 0 && (
                        <div class="container">
                            <div class="card-text-title d-flex justify-content-between mob-search-row">
                                <div class="d-flex align-items-center">
                                    {t("lable_recent_view")}


                                </div>

                            </div>


                        </div>
                    )}
                    {recentlyViewedCities.length > 0 && (
                        <div className="tiles-main resent-viewlist">
                            {recentlyViewedCities.map((city, index) => (
                                <div className="tiles-design-temple-cat animate_animated animate_flipInX" onClick={() => handleStateClick(city)} key={index}>
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt={city} src={stateImages[city] || india} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{t(city.toLowerCase())}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div class="container">
                        <div class="card-text-title d-flex justify-content-between mob-search-row">
                            <div class="d-flex align-items-center">
                                {t("label_states_in_india")}
                            </div>
                            <div class="sec-cat">
                                <Search
                                    placeholder={t("label_search_states")}
                                    value={searchTerm}
                                    onSearch={handleSearch}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </div>
                    </div>

                    <div className="tiles-main">
                        {filteredStates.map((state, index) => (
                            // <Link to={/cityPage/${state}} key={index}>
                            <div className="tiles-design-temple-cat animate_animated animate_flipInX" onClick={() => handleStateClick(state)}>
                                <div className="title-img-bg">
                                    <img className="card-img-temple-cat" alt="temples" src={stateImages[state] || india} />
                                </div>
                                <div className="tile-text-temple-cat">
                                <span>{t(state.toLowerCase())}</span>
                                </div>
                            </div>
                            // </Link>
                        ))}

                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )

}

export default TempleInIndia;