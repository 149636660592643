import { Button, Col, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Error from '../error';
import deftemples from '../../../../assets/images/templeicon.png'
import { getImageAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import ResponsiveAd from '../Advertisements/responsiveAd';
import AdBlock from '../Advertisements/adBlock';


const YouTube = ({ temple, toggleFavorite, toggleFilled, favList, getYouTubeVideoId, text }) => {
    const { t } = useTranslation();
    const userid = localStorage.getItem('urole')
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [isGodModalVisible, setIsGodModalVisible] = useState(false);
    // Function to open the modal and set the selected video URL
    const openVideoModal = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setIsModalOpen(true);
    };

    // Function to close the modal and clear the selected video URL
    const closeVideoModal = () => {
        setSelectedVideoUrl(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (isModalOpen) {
                closeVideoModal();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (isModalOpen && event.key === 'Escape') {
                closeVideoModal();
                event.preventDefault();
            }
        };
        if (isModalOpen) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, closeVideoModal]);

    const modalStyle = {
        height: '400px', // Set the desired height
    };

    const handleFilterGodCancel = () => {
        setIsModalOpen(false);
    };


    const shareResLink = (title, txt, rtype, rid, lang) => {
        const web_url = 'https://www.templeswiki.com/share?id=' + rid + '&type=' + rtype + '&lang=' + lang;
		if (!window.flutter_inappwebview) {
            navigator.share({
                title: title,
                text: txt,
                url: web_url
            })
                .then(() => console.warn("Share successful."))
                .catch((error) => console.error("Error sharing:", error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error("Web Share API not supported.");
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, web_url);
        }
    };

    return (
        <>
            {/* <Col className='youtube-video' key={temple.rid}> */}
            {isModalOpen && selectedVideoUrl && (
                <>
                    <div class="popup-bg" style={{ display: isModalOpen ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                                <span class="popup-main-header-text">{temple.title}</span>
                                <div class="popup-close" onClick={closeVideoModal}><i class="fa-solid fa-xmark"></i></div>
                                <div class="popup-back" onClick={closeVideoModal}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                                <div class="d-block htinner-popup-main-container">
                                    {isLoading ? (
                                        <Error />
                                    ) : (
                                        <iframe
                                            title="YouTube Video"
                                            width="100%"
                                            height="400"
                                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(selectedVideoUrl)}`}
                                            frameBorder="0"
                                            allowFullScreen
                                            onLoad={() => setIsLoading(false)}
                                        ></iframe>
                                    )}
                                </div>
                                 {/* <AdBlock /> */}
                            </div>
                            <div class="popup-main-footer">
                                <a className="button_move" onClick={closeVideoModal}>{t('label_cancel')}</a>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div class="col-lg-3 col-md-6 col-sm-12 animate__animated animate__flipInX">
                <div class="weather-card one" >
                    <div class={`top`}>
                        {temple.src_url.includes('youtube.com') ? (
                            <div className="card-img">


                                <img
                                    className="card-img play-img"
                                    src={`https://img.youtube.com/vi/${getYouTubeVideoId(temple.src_url)}/0.jpg`}
                                    alt="YouTube Thumbnail"
                                    onClick={() => openVideoModal(temple.src_url)}
                                />
                                {/* <div
                                        className="play-button-overlay youtube-vdo-icon"
                                        onClick={() => openVideoModal(temple.src_url)}

                                    >
                                        <i
                                            className="fas fa-play"
                                            onClick={() => openVideoModal(temple.src_url)}

                                        ></i>
                                    </div> */}
                            </div>
                        ) : temple.imgpath != null ? (
                            <div className="card-img" style={{ backgroundImage: `url(${getImageAPI()}${temple.imgpath})` }}></div>
                        ) : (
                            <img className="card-img" alt="temples" src={deftemples} />
                        )}
                        <div class="wrapper youtube-vdo-wrapper">
                            <h3 class="location">

                                <div class="youtube-vdo-title">{temple.title}</div></h3>
                            <div class="author-sec">
                                <h1 class="heading2" >
                                    <span class="face-icon"></span>
                                    <span>
                                        <span> {temple.author}</span>
                                        <div class="pub-on">{temple.publish_date} &nbsp;</div>
                                    </span>
                                </h1>
                                <span class="d-flex">
                                    < div class="art-sub" >Subscribe</div>
                                    <div className="tile-quicklink1">
                                        {userid === 'AS' || userid === 'AD' && (
                                            <a href={`/editResource/${temple.rid}`} className="share-button1">
                                                <i className="fa-solid fa-edit"></i>
                                            </a>
                                        )}
                                        <a
                                            className="share-button1"
                                            onClick={() => shareResLink(temple.title,
                                                "Know " + temple.title,
                                                'library', temple.rid, storedLanguage
                                            )}
                                        >
                                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                                        </a>
                                        {favList && (
                                            <a
                                                className="share-button1"
                                                onClick={() => {
                                                    toggleFavorite(temple, temple.title);
                                                }}
                                            >
                                                {
                                                    favList.some((t) => t === temple.rid) ? (
                                                        <i title="Remove fav" className="fa-solid fa-heart" onClick={() => toggleFilled(temple.rid)}></i>
                                                    ) : (
                                                        <i title="Add fav" className="fa-regular fa-heart" onClick={() => toggleFilled(temple.rid)}></i>
                                                    )
                                                }
                                            </a>
                                        )}
                                    </div>
                                </span>
                            </div>


                        </div>
                    </div>
                    <div
                        className="bottom"
                        onClick={handleFilterGodCancel}
                    >
                        <div className="wrapper" onClick={(e) => e.stopPropagation()}>
                            <ul className="forecast">
                                <li className="active">
                                    <span className="date">{temple.info}</span>
                                    <div className="showmore">
                                        <button
                                            className="button_move"
                                            onClick={() => openVideoModal(temple.src_url)}
                                        >
                                            {t("label_watch_now")}
                                        </button>
                                    </div>
                                </li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div >
            </div >
            {/* <div class="tiles-design animate__animated animate__flipInX">
                    <div >
                        {temple.src_url.includes('youtube.com') ? (
                            <div className="card-img">

                               
                                <img
                                    className="card-img play-img"
                                    src={`https://img.youtube.com/vi/${getYouTubeVideoId(temple.src_url)}/0.jpg`}
                                    alt="YouTube Thumbnail"
                                    onClick={() => openVideoModal(temple.src_url)}
                                />
                                 <div
                                    className="play-button-overlay"
                                    onClick={() => openVideoModal(temple.src_url)}
                                
                                >
                                    <i
                                        className="fas fa-play"
                                        onClick={() => openVideoModal(temple.src_url)}
                                      
                                    ></i>
                                </div>
                            </div>
                        ) : temple.imgpath != null ? (
                            <div className="card-img" style={{ backgroundImage: `url(${getImageAPI()}${temple.imgpath})` }}></div>
                        ) : (
                            <img className="card-img" alt="temples" src={deftemples} />
                        )}

                        <div class="tile-text"  onClick={() => openVideoModal(temple.src_url)}>
                            <div>
                                <h6 className="home-temple-text" title={temple.title}>
                                    {temple.title}
                                </h6>
                                <p className="mb-1 f-grey tile-add">{temple.author}</p>
                            </div>
                        </div>

                        <div className="tile-quicklink">
                            {userid === 'AS' || userid === 'AD' ? (
                                <a href={`/editResource/${temple.rid}`} className="icon-button">
                                    <i className="fa-solid fa-edit"></i>
                                </a>
                            ) : null}
                            <a
                                className="share-button"
                                type="link"
                                onClick={() => shareResLink(temple.title,
                                                'Check out this Live!',
                                                'videos', temple.rid, storedLanguage
                                        )}
                            >
                                <i title="Share" className="fa-solid fa-share-nodes"></i>
                            </a>
                            <a
                                className="share-button"
                                onClick={() => {
                                    toggleFavorite(temple, temple.title);
                                }}
                            >
                                {
                                    favList.some((t) => t === temple.rid) ? (
                                        <i title="Remove fav" className="fa-solid fa-heart" onClick={() => toggleFilled(temple.rid)}></i>
                                    ) : (
                                        <i title="Add fav" className="fa-regular fa-heart" onClick={() => toggleFilled(temple.rid)}></i>
                                    )
                                }
                            </a>


                        </div>
                    </div>
                </div> */}
            {/* </Col> */}

        </>

    )
}

export default YouTube;