import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, message, Row, Col, Image, Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourites, fetchContributions, fetchFavourites, fetchGodNames, fetchTemplesList, postFavourites, removeFavourites, retrieveGodNames, retrieveTemples } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CloseCircleOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons';
import { getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getTemplesList, getTemplesListError, getTemplesListLoading } from '../../../../redux/selectors/selectors';
import Error from '../error';
import Footer from '../footer/footer';
import TempleMap from './templeMap';
import Hammer from 'react-hammerjs';
import showToast from '../../../../utils/showToast';
import TemplesPage from '../../../common/TemplesPage';

const SearchFavourite = () => {
    const records_per_page = 12;
    const [lastRec, setLastRec] = useState(0);
    const [filterId, setFilterId] = useState(0);
    const localEnv = false;
    const [isLoading, setIsLoading] = useState(false);
    const [did, setDid] = useState(0);
    const { t } = useTranslation();
const storedLanguage = localStorage.getItem('lng');
    const dispatch = useDispatch();
    const { fList, fLoading, fError, templesList, templesListLoading, templesListError, godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        templesList: getTemplesList(state),
        templesListLoading: getTemplesListLoading(state),
        templesListError: getTemplesListError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }));


    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage]);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);
    const getToken = Boolean(localStorage.getItem('token'));
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const success = (tname) => {
        showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
    };


    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId, failure));
        success();
    }

    useEffect(() => {
        if (getToken) {
        dispatch(fetchFavourites(storedLanguage, filterId, lastRec));
        }
    }, [storedLanguage, refresh]);

    function addFavourites(id) {
        dispatch(postFavourites(id, failure))
    }

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);

    function filterTemplesList(gDid) {
        setDid(gDid);
        if (gDid === filterId) {
            setFilterId(0)
            dispatch(retrieveTemples(storedLanguage, lastRec));
            dispatch(fetchFavourites(storedLanguage, 0, 0))
        } else {
            setFilterId(gDid);
            dispatch(fetchFavourites(storedLanguage, gDid, 0))
        }
    }

    function getStyle(godCategoryId) {
        if (godCategoryId === did) {
            return {
                color: '#4096ff',
                borderColor: '#4096ff',
            };
        } else {
            return {
                borderColor: '#d9d9d9',
                color: '#000',
            };
        }
    }

    const nextTemplesList = async () => {
        setLastRec(prevLastRec => {
        setIsLoading(true);
        const newLastRec = prevLastRec + records_per_page;
        dispatch(fetchFavourites(storedLanguage, filterId, newLastRec));
    });
    }

    function prevTemplesList() {
        setLastRec(prevLastRec => {
            const validLastRec = isNaN(prevLastRec) || prevLastRec === undefined ? records_per_page : prevLastRec;
            const newLastRec = validLastRec - records_per_page < 0 ? 0 : validLastRec - records_per_page;
            dispatch(fetchFavourites(storedLanguage, filterId, newLastRec));
            return newLastRec;
        });
    }
    

    const storeTempleDetails = (temple) => {
        const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
        const updatedTemples = [temple, ...recentlyViewedTemples.slice(0, 10)]; // Keep only the latest 10 recently viewed temples
        localStorage.setItem('recentlyViewedTemples', JSON.stringify(updatedTemples));
    };

    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    function toggleFavorite(temple) {
        const index = favList.findIndex(t => t === temple.tid);
        if (index === -1) {
            addFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            if (getToken) {
                success(temple.tname);
            }
        } else {
            deleteFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            // setRefresh(!refresh);
        }

    }
    const temple = fList;

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNav = () => {
        // navigate(`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`)
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedTemple, setSelectedTemple] = useState(null);
    // Function to open the modal
    const openModal = (temple) => {
        setSelectedTemple(temple);  // Store the selected temple
        setModalVisible(true);      // Open the modal
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedTemple(null);    // Clear the selected temple when closing the modal
    };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleId, setCurrentTempleId] = useState(null);

    const currentTemple = fList && Array.isArray(fList) && currentTempleId
        ? fList.find(temple => temple.tid === currentTempleId)
        : null;

    const openPreviewModal = (templeId) => {
        setCurrentTempleId(templeId);
        setModalPreviewVisible(true);
    };

    const closePreviewModal = () => {
        setModalPreviewVisible(false);
    };

    const handleNext = () => {
        if (fList && Array.isArray(fList) && currentTempleId) {
            const currentIndex = fList.findIndex(temple => temple.tid === currentTempleId);
            if (currentIndex !== -1 && currentIndex < fList.length - 1) {
                setCurrentTempleId(fList[currentIndex + 1].tid);
            } else {
                console.error("No more temples to navigate forward.");
            }
        } else {
            console.error("fList is not available or currentTempleId is invalid.");
        }
    };

    const handlePrevious = () => {
        if (fList && Array.isArray(fList) && currentTempleId) {
            const currentIndex = fList.findIndex(temple => temple.tid === currentTempleId);
            if (currentIndex > 0) {
                setCurrentTempleId(fList[currentIndex - 1].tid);
            } else {
                console.error("No more temples to navigate backward.");
            }
        } else {
            console.error("fList is not available or currentTempleId is invalid.");
        }
    };



    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const [isGodModalVisible, setIsGodModalVisible] = useState(false);
    const [selectedFilterGod, setSelectedFilterGod] = useState(null);
    const handleFilterGodClick = () => {
        setIsGodModalVisible(true);
    };

    const handleFilterGodCancel = () => {
        setIsGodModalVisible(false);
    };

    return (
<>
        {/* <div class="right-sec-mob-nav d-flex align-items-center">
        <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
          <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>

          {did !== 0 && (
            <>
              <div className="detail-container">
                <span className="god-button">{god[filterId]}</span>
                <CloseCircleOutlined
                  className="close-icon"
                  onClick={() => setFilterId(null)}
                />
              </div>
            </>
          )}
          {did === 0 && (
            <div className="detail-container d-flex align-items-center">
              <span className="detail-but-god ml-2">{t("label_all")}</span>
            </div>
          )}

        </button>
      </div> */}
      
        <TemplesPage
        title={t("page_title_fav")}
        tLoading={fLoading}
        description={t('label_fav_page_msg')}
        tList={fList}
        pageTitle={t("page_title_fav")}
        nextTemplesList={nextTemplesList}
        prevTemplesList={prevTemplesList}
        last_rec={lastRec}
        filterId={filterId}
        setFilterId={setFilterId}
        godCtgryList={godCtgryList}
        filterTemplesList={filterTemplesList}
        godCtgryListLoading={godCtgryListLoading}
      />
      </>
    );
};

export default SearchFavourite;
